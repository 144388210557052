const styles = {
  listBtnChat: {
    position: "fixed",
    bottom: "0px",
    right: "25px",
    display: "flex",
    flexDirection: "row-reverse",
    height: "100%",
    maxHeight: "500px",
    alignItems: "flex-end",
    pointerEvents: "none",
  },
  wrapper: {
    height: "550px",
  },
  appBarChat: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#747474",
    borderRadius: "10px 10px 0px 0px",
    color: "#ffffff",
  },
  btnChat: {
    backgroundColor: "#03a9f4",
    padding: "8px 15px",
    borderRadius: "10px 10px 0px 0px",
    color: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    margin: "0px 5px",
    width: "120px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    "&:hover": {
      boxShadow: "0 7px 40px 2px hsla(210,1%,58%,.3)",
    },
  },
  chatWindow: {
    width: "120px",
    height: "30px",
    WebkitTransition: ".3s ease-in-out",
    Oransition: ".3s ease-in-out",
    transition: ".3s ease-in-out",
    transform: "translateY(0)",
    margin: "0px 5px !important",
    background: "#ffff",
    borderRadius: "10px 10px 0px 0px",
    pointerEvents: "visible",
    "&.opened": {
      width: "370px",
      height: "100%",
      maxHeight: "500px",
      WebkitBoxSizing: "border-box",
      boxSizing: "border-box",
      WebkitBoxShadow: "0 7px 40px 2px hsla(210,1%,58%,.3)",
      boxShadow: "0 7px 40px 2px hsla(210,1%,58%,.3)",
      display: "flex",
      WebkitBoxOrient: "vertical",
      WebkitBoxDirection: "normal",
      MsFlexdirection: "column",
      flexDirection: "column",
      WebkitBoxPack: "justify",
      MsFlexPack: "justify",
      justifyContent: "space-between",
    },
    "& h6": {
      margin: "0px 5px",
      padding: "8px 15px",
      cursor: "pointer",
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontFamily: "Quicksand !important",
    },
  },
  btnWindow: {
    padding: "0px 5px",
    borderRadius: "0px 15px 0px 0px",
  },
  mobileContainer: {
    backgroundColor: "#ffffff",
    width: "100%",
    height: "100%",
    position: "fixed",
    bottom: "0px",
    color: "white",
    display: "flex",
    flexDirection: "column",
  },
  appBarMobile: {
    display: "flex",
    width: "100%",
    backgroundColor: "#747474",
    justifyContent: "flex-end",
  },
  wrapperMobile: {
    height: "calc(100% - 25px)",
  },
};

export default styles;
