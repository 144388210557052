import FavIcon from 'company/public/images/ico/favicon.ico'
import CompanyLogo from 'company/public/images/svg/company-logo.svg'
import LoginLogo from 'company/public/images/svg/logo.svg'
import SidebarLogo from 'company/public/images/svg/sidebar-logo.svg'
import LoginBackground from 'company/public/images/background/login.jpeg'

const Config = {
	saasConfig	 		: true,
	saasHost		 	: "https://asteris.id/api",

	host				: "https://expressmartgw.asteris.id/api",
	defaultPage			: "/admin/dashboard",
	title 				: "Asteris ERP",

	instantSalesItemPick		: "branch",
	disableMarketplace 			: true,
	unitRepresentedByAnItem 	: true,

	favicon 			: FavIcon,
	companyLogo 		: CompanyLogo,
	loginLogo 			: LoginLogo,
	sidebarLogo 		: SidebarLogo,
	loginBackground		: LoginBackground,	

	loginCardColor		: "#ee252e",
	loginButtonColor	: "#ee252e",
	activeSidebarColor  : "#ee252e",
	accounting			: true,
	property			: true,
	hr					: true,
	base 				: true,
	shop 				: true,
	web 				: true,
	freight 			: true,
	saas 				: true,
	realEstate 			: true,
	themePark 			: true
}

export default Config
