//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

//material icons
import WebIcon from '@material-ui/icons/Web'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ContactSubmissionIcon from '@material-ui/icons/Mail'
import TopicICon from "@material-ui/icons/Textsms"
import NewsletterIcon from "@material-ui/icons/Drafts"
import ParticipantIcon from "@material-ui/icons/People"
import CampaignIcon from "@material-ui/icons/Flag"
import SocialMediaContentIcon from "@material-ui/icons/Public"
import CategoryIcon from "@material-ui/icons/Category"
import TagIcon from "@material-ui/icons/Label"
import PortfolioIcon from "@material-ui/icons/VerticalSplit"
import PageIcon from "@material-ui/icons/Description"
import TestimonyIcon from "@material-ui/icons/Feedback"
import WriterIcon from "@material-ui/icons/RateReview"

//activities
import BlogPostActivity from 'app/modules/Web/activities/Blog/BlogPostActivity'
import BlogCategoryActivity from 'app/modules/Web/activities/BlogCategory/BlogCategoryActivity'
import ContactSubmissionActivity from 'app/modules/Web/activities/ContactSubmission/ContactSubmissionActivity'
import ContactSubmissionTopicActivity from 'app/modules/Web/activities/ContactSubmissionTopic/ContactSubmissionTopicActivity'
import NewsletterActivity from "app/modules/Web/activities/Newsletter/NewsletterActivity";
import ParticipantActivity from "app/modules/Web/activities/Participant/ParticipantActivity";
import CampaignActivity from "app/modules/Web/activities/Campaign/CampaignActivity";
import SocialMediaContentActivity from "app/modules/Web/activities/SocialMediaContent/SocialMediaContentActivity";
import PortfolioActivity from "app/modules/Web/activities/Portfolio/PortfolioActivity";
import PortfolioTagActivity from "app/modules/Web/activities/PortfolioTag/PortfolioTagActivity";
import PortfolioCategoryActivity from "app/modules/Web/activities/PortfolioCategory/PortfolioCategoryActivity";
import PageActivity from "app/modules/Web/activities/Page/PageActivity";
import TestimonyActivity from "app/modules/Web/activities/Testimony/TestimonyActivity";
import WriterActivity from "app/modules/Web/activities/Writer/WriterActivity";
import ReferralActivity from 'app/modules/Web/activities/Referral/Referral'
import ReferralVisitActivity from 'app/modules/Web/activities/Referral/ReferralVisit'
import SiteActivity from 'app/modules/Web/activities/Referral/Site'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Web"),
	    menu		: "BLOG",
	    icon 		: WebIcon,
	    state		: "BLOG",
	    views		:[
			{
				path		: "/blog-post/:id?",
				name		: tl(Cookies.get("lang"),"Blog Post"),
				menu		: "BLOG01",
				icon 		: LibraryBooksIcon,
				component 	: BlogPostActivity,
				layout		: "/admin"
			},
			{
				path		: "/blog-category/:id?",
				name		: tl("BlogCategory"),
				menu		: "BLOG03",
				icon 		: CategoryIcon,
				component 	: BlogCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/contact-submission/:id?",
				name		: tl(Cookies.get("lang"),"ContactSubmission"),
				menu		: "BLOG02",
				icon 		: ContactSubmissionIcon,
				component 	: ContactSubmissionActivity,
				layout		: "/admin"
			},
			{
				path		: "/newsletter/:id?",
				name		: tl("Newsletter"),
				menu		: "NWSLTR",
				icon 		: NewsletterIcon,
				component 	: NewsletterActivity,
				layout		: "/admin"
			},
			{
				path		: "/campaign/:id?",
				name		: tl("Campaign"),
				menu		: "WEBCMPGN",
				icon 		: CampaignIcon,
				component 	: CampaignActivity,
				layout		: "/admin"
			},
			{
				path		: "/social-media-content/:id?",
				name		: tl("SocialMediaContent"),
				menu		: "WEBSOCMED",
				icon 		: SocialMediaContentIcon,
				component 	: SocialMediaContentActivity,
				layout		: "/admin"
			},
			{
				path		: "/participant/:id?",
				name		: tl("Participant"),
				menu		: "WEBPTCP",
				icon 		: ParticipantIcon,
				component 	: ParticipantActivity,
				layout		: "/admin"
			},
			{
				path		: "/portfolio/:id?",
				name		: tl("Portfolio"),
				menu		: "PRTF",
				icon 		: PortfolioIcon,
				component 	: PortfolioActivity,
				layout		: "/admin"
			},
			{
				path		: "/testimony/:id?",
				name		: tl(Cookies.get("lang"),"Testimony"),
				menu		: "WEB-TTMY",
				icon 		: TestimonyIcon,
				component 	: TestimonyActivity,
				layout		: "/admin"
			},
			{
				path		: "/writer/:id?",
				name		: tl(Cookies.get("lang"),"Writer"),
				menu		: "WEB-WRT",
				icon 		: WriterIcon,
				component 	: WriterActivity,
				layout		: "/admin"
			},
		]
	},
	{
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Web Configuration"),
	    menu		: "WEBCONF",
	    icon 		: WebIcon,
	    state		: "WEBCONF",
		views		:[
			{
				path		: "/contact-submission-topic/:id?",
				name		: tl(Cookies.get("lang"),"ContactSubmissionTopic"),
				menu		: "BLOG03",
				icon 		: TopicICon,
				component 	: ContactSubmissionTopicActivity,
				layout		: "/admin"
			},
			{
				path		: "/portfolio-tag/:id?",
				name		: tl(Cookies.get("lang"),"PortfolioTag"),
				menu		: "PRTFTAG",
				icon 		: TagIcon,
				component 	: PortfolioTagActivity,
				layout		: "/admin"
			},
			{
				path		: "/portfolio-category/:id?",
				name		: tl(Cookies.get("lang"),"PortfolioCategory"),
				menu		: "PRTFCAT",
				icon 		: CategoryIcon,
				component 	: PortfolioCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/page/:id?",
				name		: tl(Cookies.get("lang"),"Page"),
				menu		: "PAGE",
				icon 		: PageIcon,
				component 	: PageActivity,
				layout		: "/admin"
			},
			{
				path		: "/referral/:id?",
				name		: tl("Referral"),
				menu		: "WEB-REF",
				icon 		: PageIcon,
				component 	: ReferralActivity,
				layout		: "/admin"
			},
			{
				path		: "/referral-visit/:id?",
				name		: tl("ReferralVisit"),
				menu		: "WEB-REFV",
				icon 		: PageIcon,
				component 	: ReferralVisitActivity,
				layout		: "/admin"
			},
			{
				path		: "/site/:id?",
				name		: tl("Site"),
				menu		: "WEB-SITE",
				icon 		: PageIcon,
				component 	: SiteActivity,
				layout		: "/admin"
			},
		]
	}
	
]
export default Routes