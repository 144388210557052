const Translations = {
	id:{
		"property":"property",
		//Blok
		"PropertyBlock":"Block",
		"property_block":"Block",
		"property_block.activate":"Aktifkan",
		"PropertyBlock.confirmTitle.action/activate":"Aktifkan",
		"PropertyBlock.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Block ini?",
		"PropertyBlock.success.action/activate":"Block telah berhasil diaktifkan",
		"property_block.deactivate":"Nonaktifkan",
		"PropertyBlock.confirmTitle.action/deactivate":"Nonaktifkan",
		"PropertyBlock.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Block ini?",
		"PropertyBlock.success.action/deactivate":"Block telah berhasil dinonaktifkan",
		"PropertyBlock.code":"Kode",
		"PropertyBlock.name":"nama",
		"PropertyBlock.state":"Status",
		//Floor
		"PropertyFloor":"Lantai",
		"property_floor":"Lantai",
		"property_floor.activate":"Aktifkan",
		"PropertyFloor.confirmTitle.action/activate":"Aktifkan",
		"PropertyFloor.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Lantai ini?",
		"PropertyFloor.success.action/activate":"Lantai telah berhasil diaktifkan",
		"property_floor.deactivate":"Nonaktifkan",
		"PropertyFloor.confirmTitle.action/deactivate":"Nonaktifkan",
		"PropertyFloor.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Lantai ini?",
		"PropertyFloor.success.action/deactivate":"Lantai telah berhasil dinonaktifkan",
		"PropertyFloor.code":"Kode",
		"PropertyFloor.name":"nama",
		"PropertyFloor.state":"Status",
		//Type
		"PropertyType":"Tipe",
		"property_type":"Tipe",
		"property_type.activate":"Aktifkan",
		"PropertyType.confirmTitle.action/activate":"Aktifkan",
		"PropertyType.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Tipe ini?",
		"PropertyType.success.action/activate":"Tipe telah berhasil diaktifkan",
		"property_type.deactivate":"Nonaktifkan",
		"PropertyType.confirmTitle.action/deactivate":"Nonaktifkan",
		"PropertyType.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Tipe ini?",
		"PropertyType.success.action/deactivate":"Tipe telah berhasil dinonaktifkan",
		"PropertyType.code":"Kode",
		"PropertyType.name":"nama",
		"PropertyType.state":"Status",
		//Unit
		"PropertyUnit":"Unit",
		"property_unit":"Unit",
		"property_unit.activate":"Aktifkan",
		"PropertyUnit.confirmTitle.action/activate":"Aktifkan",
		"PropertyUnit.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Unit ini?",
		"PropertyUnit.success.action/activate":"Unit telah berhasil diaktifkan",
		"property_unit.deactivate":"Nonaktifkan",
		"PropertyUnit.confirmTitle.action/deactivate":"Nonaktifkan",
		"PropertyUnit.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Unit ini?",
		"PropertyUnit.success.action/deactivate":"Unit telah berhasil dinonaktifkan",
		"PropertyUnit.name":"Nama",
		"PropertyUnit.unit":"Unit",
		"PropertyUnit.blockId":"Blok",
		"PropertyUnit.block":"Blok",
		"PropertyUnit.floorId":"Lantai",
		"PropertyUnit.floor":"Lantai",
		"PropertyUnit.typeId":"Tipe",
		"PropertyUnit.type":"Tipe",
		"PropertyUnit.netArea":"Luas Bersih",
		"PropertyUnit.grossArea":"Luas Kotor",
		"PropertyUnit.description":"Deskripsi",
		"PropertyUnit.developmentState":"Status Pembangunan",
		"PropertyUnit.active":"Aktif",
		"PropertyUnit.state":"Status",
		//Ownership
		"PropertyOwnership":"Kepemilikan",
		"property_ownership":"Kepemilikan",
		"property_ownership.activate":"Aktifkan",
		"PropertyOwnership.confirmTitle.action/activate":"Aktifkan",
		"PropertyOwnership.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kepemilikan ini?",
		"PropertyOwnership.success.action/activate":"Kepemilikan telah berhasil diaktifkan",
		"property_ownership.deactivate":"Nonaktifkan",
		"PropertyOwnership.confirmTitle.action/deactivate":"Nonaktifkan",
		"PropertyOwnership.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kepemilikan ini?",
		"PropertyOwnership.success.action/deactivate":"Kepemilikan telah berhasil dinonaktifkan",
		"PropertyOwnership.name":"Nomor",
		"PropertyOwnership.ownershipDate":"Tanggal Kepemilikan",
		"PropertyOwnership.unitId":"Unit",
		"PropertyOwnership.unit":"Unit",
		"PropertyOwnership.partnerId":"Pemilik",
		"PropertyOwnership.partner":"Pemilik",
		"PropertyOwnership.contract":"Kontrak",
		"PropertyOwnership.document":"Dokumen",
		"PropertyOwnership.tenancyPermit":"Ijin",
		"PropertyOwnership.state":"Status",
		//Billing Preset
		"BillingPreset":"Rencana Penagihan",
		"billing_preset":"Rencana Penagihan",
		"billing_preset.activate":"Aktifkan",
		"BillingPreset.confirmTitle.action/activate":"Aktifkan",
		"BillingPreset.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Rencana Penagihan ini?",
		"BillingPreset.success.action/activate":"Rencana Penagihan telah berhasil diaktifkan",
		"billing_preset.deactivate":"Nonaktifkan",
		"BillingPreset.confirmTitle.action/deactivate":"Nonaktifkan",
		"BillingPreset.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Rencana Penagihan ini?",
		"BillingPreset.success.action/deactivate":"Rencana Penagihan telah berhasil dinonaktifkan",
		"BillingPreset.name":"Nama",
		"BillingPreset.rounded":"Dibulatkan?",
		"BillingPreset.roundingType":"Tipe Pembulatan",
		"BillingPreset.roundingDecimal":"Desimal Pembulatan",
		"BillingPreset.roundingNearestValue":"Nilai Terdekat Pembulatan",
		"BillingPreset.penaltyRounded":"Denda Dibulatkan?",
		"BillingPreset.penaltyRoundingType":"Tipe Pembulatan Denda",
		"BillingPreset.penaltyRoundingDecimal":"Desimal Pembulatan",
		"BillingPreset.penaltyRoundingNearestValue":"Nilai Terdekat Pembulatan",
		"BillingPreset.isCashBasis":"Berbasis Kas",
		"BillingPreset.lines":"Daftar Item",
		"BillingPreset.state":"Status",
		//Billing Preset Item
		"BillingPresetLine":"Item Rencana Penagihan",
		"billing_preset_line":"Item Rencana Penagihan",
		"BillingPresetLine.itemId":"Item",
		"BillingPresetLine.item":"Item",
		"BillingPresetLine.quantity":"Jumlah",
		"BillingPresetLine.billingPresetId":"Rencana Penagihan",
		"BillingPresetLine.billingPreset":"Rencana Penagihan",
		"BillingPresetLine.type":"TODO ID",
		"BillingPresetLine.taxes":"Pajak",
		//Billing Cycle
		"BillingCycle":"Siklus Penagihan",
		"billing_cycle":"Siklus Penagihan",
		"billing_cycle.activate":"Aktifkan",
		"BillingCycle.confirmTitle.action/activate":"Aktifkan",
		"BillingCycle.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Siklus Penagihan ini?",
		"BillingCycle.success.action/activate":"Siklus Penagihan telah berhasil diaktifkan",
		"billing_cycle.deactivate":"Nonaktifkan",
		"BillingCycle.confirmTitle.action/deactivate":"Nonaktifkan",
		"BillingCycle.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Siklus Penagihan ini?",
		"BillingCycle.success.action/deactivate":"Siklus Penagihan telah berhasil dinonaktifkan",
		"BillingCycle.name":"Nama",
		"BillingCycle.cycle":"Siklus (Bulan)",
		"BillingCycle.state":"Status",
		//Ownership Billing
		"OwnershipBillingPreset":"Penagihan Kepemilikan",
		"ownership_billing_preset":"Penagihan Kepemilikan",
		"ownership_billing_preset.activate":"Aktifkan",
		"OwnershipBillingPreset.confirmTitle.action/activate":"Aktifkan",
		"OwnershipBillingPreset.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Penagihan Kepemilikan ini?",
		"OwnershipBillingPreset.success.action/activate":"Penagihan Kepemilikan telah berhasil diaktifkan",
		"ownership_billing_preset.deactivate":"Nonaktifkan",
		"OwnershipBillingPreset.confirmTitle.action/deactivate":"Nonaktifkan",
		"OwnershipBillingPreset.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Penagihan Kepemilikan ini?",
		"OwnershipBillingPreset.success.action/deactivate":"Penagihan Kepemilikan telah berhasil dinonaktifkan",
		"OwnershipBillingPreset.name":"Nama",
		"OwnershipBillingPreset.ownershipId":"Kepemilikan",
		"OwnershipBillingPreset.ownership":"Kepemilikan",
		"OwnershipBillingPreset.billingPresetId":"Rencana Penagihan",
		"OwnershipBillingPreset.billingPreset":"Rencana Penagihan",
		"OwnershipBillingPreset.lines":"Daftar Tagihan",
		"OwnershipBillingPreset.overrideRate":"Atur Ulang Nilai",
		"OwnershipBillingPreset.overridenRate":"Nilai Atur Ulang",
		"OwnershipBillingPreset.penalized":"Denda",
		"OwnershipBillingPreset.billingCycleId":"Siklus Tagihan",
		"OwnershipBillingPreset.billingCycle":"Siklus Tagihan",
		"OwnershipBillingPreset.handoverDate":"Tanggal Penyerahan",
		"OwnershipBillingPreset.paymentPlanId":"Rencana Pembayaran",
		"OwnershipBillingPreset.paymentPlan":"Rencana Pembayaran",
		"OwnershipBillingPreset.realizations":"Realisasi",
		"OwnershipBillingPreset.freezes":"Pembekuan Nilai",
		"OwnershipBillingPreset.overridenRateHistory":"Sejarah Nilai Yang Diatur Ulang",
		"OwnershipBillingPreset.explanationId":"Penjelasan Pembayaran",
		"OwnershipBillingPreset.explanation":"Penjelasan Pembayaran",
		"OwnershipBillingPreset.state":"Status",
		//Ownership Billing Item
		"OwnershipBillingPresetLine":"Item Penagihan Kepemilikan",
		"ownership_billing_preset_line":"Item Penagihan Kepemilikan",
		"ownership_billing_preset_line.activate":"Aktifkan",
		"OwnershipBillingPresetLine.confirmTitle.action/activate":"Aktifkan",
		"OwnershipBillingPresetLine.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Item Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetLine.success.action/activate":"Item Penagihan Kepemilikan telah berhasil diaktifkan",
		"ownership_billing_preset_line.deactivate":"Nonaktifkan",
		"OwnershipBillingPresetLine.confirmTitle.action/deactivate":"Nonaktifkan",
		"OwnershipBillingPresetLine.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Item Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetLine.success.action/deactivate":"Item Penagihan Kepemilikan telah berhasil dinonaktifkan",
		"OwnershipBillingPresetLine.ownershipBillingPresetId":"",
		"OwnershipBillingPresetLine.ownershipBillingPreset":"",
		"OwnershipBillingPresetLine.billingPresetLineId":"Rencana Penagihan",
		"OwnershipBillingPresetLine.billingPresetLine":"Rencana Penagihan",
		"OwnershipBillingPresetLine.itemId":"Item",
		"OwnershipBillingPresetLine.item":"Item",
		"OwnershipBillingPresetLine.taxes":"Pajak",
		"OwnershipBillingPresetLine.prepaid":"Ditagih Dimuka",
		"OwnershipBillingPresetLine.overridePrice":"Atur Ulang Nilai",
		"OwnershipBillingPresetLine.overridenPrice":"Nilai Atur Ulang",
		//Ownership Billing Preset Rate Freeze
		"OwnershipBillingPresetPriceFreeze":"Pembekuan Nilai Penagihan Kepemilikan",
		"ownership_billing_preset_price_freeze":"Pembekuan Nilai Penagihan Kepemilikan",
		"ownership_billing_preset_price_freeze.activate":"Aktifkan",
		"OwnershipBillingPresetPriceFreeze.confirmTitle.action/activate":"Aktifkan",
		"OwnershipBillingPresetPriceFreeze.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pembekuan Nilai Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetPriceFreeze.success.action/activate":"Pembekuan Nilai Penagihan Kepemilikan telah berhasil diaktifkan",
		"ownership_billing_preset_price_freeze.deactivate":"Nonaktifkan",
		"OwnershipBillingPresetPriceFreeze.confirmTitle.action/deactivate":"Nonaktifkan",
		"OwnershipBillingPresetPriceFreeze.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pembekuan Nilai Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetPriceFreeze.success.action/deactivate":"Pembekuan Nilai Penagihan Kepemilikan telah berhasil dinonaktifkan",
		"OwnershipBillingPresetPriceFreeze.periodId":"Periode",
		"OwnershipBillingPresetPriceFreeze.period":"Periode",
		"OwnershipBillingPresetPriceFreeze.ownershipBillingPresetId":"",
		"OwnershipBillingPresetPriceFreeze.ownershipBillingPreset":"",
		"OwnershipBillingPresetPriceFreeze.price":"Nilai",
		"OwnershipBillingPresetPriceFreeze.state":"Status",
		//Ownership Billing Preset Realization
		"OwnershipBillingPresetRealization":"Realisasi Penagihan Kepemilikan",
		"ownership_billing_preset_realization":"Realisasi Penagihan Kepemilikan",
		"OwnershipBillingPresetRealization.periodId":"Periode",
		"OwnershipBillingPresetRealization.period":"Periode",
		"OwnershipBillingPresetRealization.ownershipBillingPresetId":"",
		"OwnershipBillingPresetRealization.ownershipBillingPreset":"",
		"OwnershipBillingPresetRealization.billingPresetId":"Rencana Penagihan",
		"OwnershipBillingPresetRealization.billingPreset":"Rencana Penagihan",
		"OwnershipBillingPresetRealization.invoiceId":"Tagihan",
		"OwnershipBillingPresetRealization.invoice":"Tagihan",
		"OwnershipBillingPresetRealization.monthylyBillingId":"Tagihan Bulanan",
		"OwnershipBillingPresetRealization.monthylyBilling":"Tagihan Bulanan",
		"OwnershipBillingPresetRealization.total":"Total",
		"OwnershipBillingPresetRealization.paid":"Terbayar",
		"OwnershipBillingPresetRealization.balance":"Saldo",
		"OwnershipBillingPresetRealization.state":"Status",
		//Ownership Billing Preset Rate History
		"OwnershipBillingPresetHistory":"Sejarah Nilai Penagihan Kepemilikan",
		"ownership_billing_preset_history":"Sejarah Nilai Penagihan Kepemilikan",
		"ownership_billing_preset_history.activate":"Aktifkan",
		"OwnershipBillingPresetHistory.confirmTitle.action/activate":"Aktifkan",
		"OwnershipBillingPresetHistory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Sejarah Nilai Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetHistory.success.action/activate":"Sejarah Nilai Penagihan Kepemilikan telah berhasil diaktifkan",
		"ownership_billing_preset_history.deactivate":"Nonaktifkan",
		"OwnershipBillingPresetHistory.confirmTitle.action/deactivate":"Nonaktifkan",
		"OwnershipBillingPresetHistory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Sejarah Nilai Penagihan Kepemilikan ini?",
		"OwnershipBillingPresetHistory.success.action/deactivate":"Sejarah Nilai Penagihan Kepemilikan telah berhasil dinonaktifkan",
		"OwnershipBillingPresetHistory.ownershipBillingPresetId":"",
		"OwnershipBillingPresetHistory.ownershipBillingPreset":"",
		"OwnershipBillingPresetHistory.date":"Tanggal",
		"OwnershipBillingPresetHistory.price":"Nilai",
		//Payment Explanation
		"OwnershipBillingPresetPaymentExplanation":"Penjelasan Pembayaran",
		"ownership_billing_preset_payment_explanation":"Penjelasan Pembayaran",
		"ownership_billing_preset_payment_explanation.activate":"Aktifkan",
		"OwnershipBillingPresetPaymentExplanation.confirmTitle.action/activate":"Aktifkan",
		"OwnershipBillingPresetPaymentExplanation.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Penjelasan Pembayaran ini?",
		"OwnershipBillingPresetPaymentExplanation.success.action/activate":"Penjelasan Pembayaran telah berhasil diaktifkan",
		"ownership_billing_preset_payment_explanation.deactivate":"Nonaktifkan",
		"OwnershipBillingPresetPaymentExplanation.confirmTitle.action/deactivate":"Nonaktifkan",
		"OwnershipBillingPresetPaymentExplanation.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Penjelasan Pembayaran ini?",
		"OwnershipBillingPresetPaymentExplanation.success.action/deactivate":"Penjelasan Pembayaran telah berhasil dinonaktifkan",
		"OwnershipBillingPresetPaymentExplanation.name":"Nama",
		"OwnershipBillingPresetPaymentExplanation.explanation":"Penjelasan",
		//Monthly Billing
		"MonthlyBilling":"Penagihan Bulanan",
		"monthly_billing":"Penagihan Bulanan",
		"monthly_billing.invoice":"Buat Tagihan",
		"MonthlyBilling.confirmTitle.invoice":"Buat Tagihan",
		"MonthlyBilling.confirmContent.invoice":"Buat Tagihan?",
		"MonthlyBilling.success.invoice":"Penagihan Bulanan berhasil",
		"monthly_billing.penalty":"Buat Denda",
		"MonthlyBilling.confirmTitle.penalty":"Buat Denda",
		"MonthlyBilling.confirmContent.penalty":"Buat Denda?",
		"MonthlyBilling.success.penalty":"Penagihan Bulanan berhasil",
		"monthly_billing.email":"Buat Email",
		"MonthlyBilling.confirmTitle.email":"Buat Email",
		"MonthlyBilling.confirmContent.email":"Buat Email?",
		"MonthlyBilling.success.email":"Penagihan Bulanan berhasil",
		"monthly_billing.confirm":"Konfirmasi",
		"MonthlyBilling.confirmTitle.confirm":"Konfirmasi",
		"MonthlyBilling.confirmContent.confirm":"Apakah Anda yakin ingin mengkonfirmasi Penagihan Bulanan ini?",
		"MonthlyBilling.success.confirm":"Penagihan Bulanan telah berhasil dikonfirmasi",
		"monthly_billing.rollback":"Tahap Sebelumnya",
		"MonthlyBilling.confirmTitle.rollback":"Tahap Sebelumnya",
		"MonthlyBilling.confirmContent.rollback":"Tahap Sebelumnya?",
		"MonthlyBilling.success.rollback":"Penagihan Bulanan berhasil",
		"monthly_billing.cancel":"Batalkan",
		"MonthlyBilling.confirmTitle.cancel":"Batalkan",
		"MonthlyBilling.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Penagihan Bulanan ini?",
		"MonthlyBilling.success.cancel":"Penagihan Bulanan telah berhasil dibatalkan",
		"monthly_billing.draft":"Draf",
		"MonthlyBilling.confirmTitle.draft":"Draf",
		"MonthlyBilling.confirmContent.draft":"Draf?",
		"MonthlyBilling.success.draft":"Penagihan Bulanan berhasil",
		"MonthlyBilling.name":"Nama",
		"MonthlyBilling.blockId":"Blok",
		"MonthlyBilling.block":"Blok",
		"MonthlyBilling.ownershipId":"Kepemilikan",
		"MonthlyBilling.ownership":"Kepemilikan",
		"MonthlyBilling.billingPresetId":"Rencana Penagihan",
		"MonthlyBilling.billingPreset":"Rencana Penagihan",
		"MonthlyBilling.invoices":"Daftar Tagihan",
		"MonthlyBilling.mails":"Daftar Email",
		"MonthlyBilling.state":"Status",
		//MENUS
		"Billing":"Penagihan",
		"Monthly Billing":"Penagihan Bulanan",
		"BillingMonthlyBilling":"Penagihan Bulanan",
		"Billing Configuration":"Konfigurasi Penagihan",
		"Ownership Billing":"Penagihan Kepemilikan",
		"BillingConfigurationOwnershipBilling":"Penagihan Kepemilikan",
		"Billing Preset":"Rencana Penagihan",
		"BillingConfigurationBillingPreset":"Rencana Penagihan",
		"Payment Explanation":"Penjelasan Pembayaran",
		"BillingConfigurationPaymentExplanation":"Penjelasan Pembayaran",
		"Billing Cycle":"Siklus Tagihan",
		"BillingConfigurationBillingCycle":"Siklus Tagihan",
		"Property":"Properti",
		"Ownership":"Kepemilikan",
		"PropertyOwnership":"Kepemilikan",
		"Unit":"Unit",
		"PropertyUnit":"Unit",
		"Block":"Blok",
		"PropertyBlock":"Blok",
		"Floor":"Lantai",
		"PropertyFloor":"Lantai",
		"Type":"Tipe",
		"PropertyType":"Tipe",
	},
	en:{
		"property":"property",
		//Blok
		"PropertyBlock":"Blok",
		"property_block":"Blok",
		"property_block.activate":"Activate",
		"PropertyBlock.confirmTitle.action/activate":"Activate",
		"PropertyBlock.confirmContent.action/activate":"Are you sure you want to activate this Blok?",
		"PropertyBlock.success.action/activate":"Blok is successfully activated",
		"property_block.deactivate":"Deactivate",
		"PropertyBlock.confirmTitle.action/deactivate":"Deactivate",
		"PropertyBlock.confirmContent.action/deactivate":"Are you sure you want to deactivate this Blok?",
		"PropertyBlock.success.action/deactivate":"Blok is successfully deactivated",
		"PropertyBlock.code":"Code",
		"PropertyBlock.name":"Name",
		"PropertyBlock.state":"State",
		//Floor
		"PropertyFloor":"Floor",
		"property_floor":"Floor",
		"property_floor.activate":"Activate",
		"PropertyFloor.confirmTitle.action/activate":"Activate",
		"PropertyFloor.confirmContent.action/activate":"Are you sure you want to activate this Floor?",
		"PropertyFloor.success.action/activate":"Floor is successfully activated",
		"property_floor.deactivate":"Deactivate",
		"PropertyFloor.confirmTitle.action/deactivate":"Deactivate",
		"PropertyFloor.confirmContent.action/deactivate":"Are you sure you want to deactivate this Floor?",
		"PropertyFloor.success.action/deactivate":"Floor is successfully deactivated",
		"PropertyFloor.code":"Code",
		"PropertyFloor.name":"Name",
		"PropertyFloor.state":"State",
		//Type
		"PropertyType":"Type",
		"property_type":"Type",
		"property_type.activate":"Activate",
		"PropertyType.confirmTitle.action/activate":"Activate",
		"PropertyType.confirmContent.action/activate":"Are you sure you want to activate this Type?",
		"PropertyType.success.action/activate":"Type is successfully activated",
		"property_type.deactivate":"Deactivate",
		"PropertyType.confirmTitle.action/deactivate":"Deactivate",
		"PropertyType.confirmContent.action/deactivate":"Are you sure you want to deactivate this Type?",
		"PropertyType.success.action/deactivate":"Type is successfully deactivated",
		"PropertyType.code":"Code",
		"PropertyType.name":"Name",
		"PropertyType.state":"State",
		//Unit
		"PropertyUnit":"Unit",
		"property_unit":"Unit",
		"property_unit.activate":"Activate",
		"PropertyUnit.confirmTitle.action/activate":"Activate",
		"PropertyUnit.confirmContent.action/activate":"Are you sure you want to activate this Unit?",
		"PropertyUnit.success.action/activate":"Unit is successfully activated",
		"property_unit.deactivate":"Deactivate",
		"PropertyUnit.confirmTitle.action/deactivate":"Deactivate",
		"PropertyUnit.confirmContent.action/deactivate":"Are you sure you want to deactivate this Unit?",
		"PropertyUnit.success.action/deactivate":"Unit is successfully deactivated",
		"PropertyUnit.name":"Name",
		"PropertyUnit.unit":"Unit",
		"PropertyUnit.blockId":"Block",
		"PropertyUnit.block":"Block",
		"PropertyUnit.floorId":"Floor",
		"PropertyUnit.floor":"Floor",
		"PropertyUnit.typeId":"Type",
		"PropertyUnit.type":"Type",
		"PropertyUnit.netArea":"Net Large",
		"PropertyUnit.grossArea":"Gross Large",
		"PropertyUnit.description":"Description",
		"PropertyUnit.developmentState":"Development State",
		"PropertyUnit.active":"Active",
		"PropertyUnit.state":"State",
		//Ownership
		"PropertyOwnership":"Ownership",
		"property_ownership":"Ownership",
		"property_ownership.activate":"Activate",
		"PropertyOwnership.confirmTitle.action/activate":"Activate",
		"PropertyOwnership.confirmContent.action/activate":"Are you sure you want to activate this Ownership?",
		"PropertyOwnership.success.action/activate":"Ownership is successfully activated",
		"property_ownership.deactivate":"Deactivate",
		"PropertyOwnership.confirmTitle.action/deactivate":"Deactivate",
		"PropertyOwnership.confirmContent.action/deactivate":"Are you sure you want to deactivate this Ownership?",
		"PropertyOwnership.success.action/deactivate":"Ownership is successfully deactivated",
		"PropertyOwnership.name":"Number",
		"PropertyOwnership.ownershipDate":"Ownership Date",
		"PropertyOwnership.unitId":"Unit",
		"PropertyOwnership.unit":"Unit",
		"PropertyOwnership.partnerId":"Owner",
		"PropertyOwnership.partner":"Owner",
		"PropertyOwnership.contract":"Contract",
		"PropertyOwnership.document":"Document",
		"PropertyOwnership.tenancyPermit":"Permit",
		"PropertyOwnership.state":"State",
		//Billing Preset
		"BillingPreset":"Billing Preset",
		"billing_preset":"Billing Preset",
		"billing_preset.activate":"Activate",
		"BillingPreset.confirmTitle.action/activate":"Activate",
		"BillingPreset.confirmContent.action/activate":"Are you sure you want to activate this Billing Preset?",
		"BillingPreset.success.action/activate":"Billing Preset is successfully activated",
		"billing_preset.deactivate":"Deactivate",
		"BillingPreset.confirmTitle.action/deactivate":"Deactivate",
		"BillingPreset.confirmContent.action/deactivate":"Are you sure you want to deactivate this Billing Preset?",
		"BillingPreset.success.action/deactivate":"Billing Preset is successfully deactivated",
		"BillingPreset.name":"Name",
		"BillingPreset.rounded":"Rounded?",
		"BillingPreset.roundingType":"Rounding Type",
		"BillingPreset.roundingDecimal":"Rounding Decimal",
		"BillingPreset.roundingNearestValue":"Rounding Nearest Value",
		"BillingPreset.penaltyRounded":"Penalty Rouding?",
		"BillingPreset.penaltyRoundingType":"Penalty Rounding Type",
		"BillingPreset.penaltyRoundingDecimal":"Desimal Pembulatan",
		"BillingPreset.penaltyRoundingNearestValue":"Rounding Nearest Value",
		"BillingPreset.isCashBasis":"Cash Basis",
		"BillingPreset.lines":"Items",
		"BillingPreset.state":"State",
		//Billing Preset Item
		"BillingPresetLine":"Billing Preset Item",
		"billing_preset_line":"Billing Preset Item",
		"BillingPresetLine.itemId":"Item",
		"BillingPresetLine.item":"Item",
		"BillingPresetLine.quantity":"Quantity",
		"BillingPresetLine.billingPresetId":"Billing Preset",
		"BillingPresetLine.billingPreset":"Billing Preset",
		"BillingPresetLine.type":"TODO EN",
		"BillingPresetLine.taxes":"Taxes",
		//Billing Cycle
		"BillingCycle":"Billing Cycle",
		"billing_cycle":"Billing Cycle",
		"billing_cycle.activate":"Activate",
		"BillingCycle.confirmTitle.action/activate":"Activate",
		"BillingCycle.confirmContent.action/activate":"Are you sure you want to activate this Billing Cycle?",
		"BillingCycle.success.action/activate":"Billing Cycle is successfully activated",
		"billing_cycle.deactivate":"Deactivate",
		"BillingCycle.confirmTitle.action/deactivate":"Deactivate",
		"BillingCycle.confirmContent.action/deactivate":"Are you sure you want to deactivate this Billing Cycle?",
		"BillingCycle.success.action/deactivate":"Billing Cycle is successfully deactivated",
		"BillingCycle.name":"Name",
		"BillingCycle.cycle":"Cycle (Month)",
		"BillingCycle.state":"State",
		//Ownership Billing
		"OwnershipBillingPreset":"Ownership Billing",
		"ownership_billing_preset":"Ownership Billing",
		"ownership_billing_preset.activate":"Activate",
		"OwnershipBillingPreset.confirmTitle.action/activate":"Activate",
		"OwnershipBillingPreset.confirmContent.action/activate":"Are you sure you want to activate this Ownership Billing?",
		"OwnershipBillingPreset.success.action/activate":"Ownership Billing is successfully activated",
		"ownership_billing_preset.deactivate":"Deactivate",
		"OwnershipBillingPreset.confirmTitle.action/deactivate":"Deactivate",
		"OwnershipBillingPreset.confirmContent.action/deactivate":"Are you sure you want to deactivate this Ownership Billing?",
		"OwnershipBillingPreset.success.action/deactivate":"Ownership Billing is successfully deactivated",
		"OwnershipBillingPreset.name":"Name",
		"OwnershipBillingPreset.ownershipId":"Ownership",
		"OwnershipBillingPreset.ownership":"Ownership",
		"OwnershipBillingPreset.billingPresetId":"Billing Preset",
		"OwnershipBillingPreset.billingPreset":"Billing Preset",
		"OwnershipBillingPreset.lines":"Items",
		"OwnershipBillingPreset.overrideRate":"Override Rate",
		"OwnershipBillingPreset.overridenRate":"Overriden Rate",
		"OwnershipBillingPreset.penalized":"Penalized",
		"OwnershipBillingPreset.billingCycleId":"Billing Cycle",
		"OwnershipBillingPreset.billingCycle":"Billing Cycle",
		"OwnershipBillingPreset.handoverDate":"Handover Date",
		"OwnershipBillingPreset.paymentPlanId":"Payment Plan",
		"OwnershipBillingPreset.paymentPlan":"Payment Plan",
		"OwnershipBillingPreset.realizations":"Realizations",
		"OwnershipBillingPreset.freezes":"Rate Freezes",
		"OwnershipBillingPreset.overridenRateHistory":"Overriden Rate History",
		"OwnershipBillingPreset.explanationId":"Payment Explanation",
		"OwnershipBillingPreset.explanation":"Payment Explanation",
		"OwnershipBillingPreset.state":"State",
		//Ownership Billing Item
		"OwnershipBillingPresetLine":"Ownership Billing Item",
		"ownership_billing_preset_line":"Ownership Billing Item",
		"ownership_billing_preset_line.activate":"Activate",
		"OwnershipBillingPresetLine.confirmTitle.action/activate":"Activate",
		"OwnershipBillingPresetLine.confirmContent.action/activate":"Are you sure you want to activate this Ownership Billing Item?",
		"OwnershipBillingPresetLine.success.action/activate":"Ownership Billing Item is successfully activated",
		"ownership_billing_preset_line.deactivate":"Deactivate",
		"OwnershipBillingPresetLine.confirmTitle.action/deactivate":"Deactivate",
		"OwnershipBillingPresetLine.confirmContent.action/deactivate":"Are you sure you want to deactivate this Ownership Billing Item?",
		"OwnershipBillingPresetLine.success.action/deactivate":"Ownership Billing Item is successfully deactivated",
		"OwnershipBillingPresetLine.ownershipBillingPresetId":"",
		"OwnershipBillingPresetLine.ownershipBillingPreset":"",
		"OwnershipBillingPresetLine.billingPresetLineId":"Billing Preset",
		"OwnershipBillingPresetLine.billingPresetLine":"Billing Preset",
		"OwnershipBillingPresetLine.itemId":"Item",
		"OwnershipBillingPresetLine.item":"Item",
		"OwnershipBillingPresetLine.taxes":"Taxes",
		"OwnershipBillingPresetLine.prepaid":"Prepaid",
		"OwnershipBillingPresetLine.overridePrice":"Override Rate",
		"OwnershipBillingPresetLine.overridenPrice":"Overriden Rate",
		//Ownership Billing Preset Rate Freeze
		"OwnershipBillingPresetPriceFreeze":"Ownership Billing Preset Rate Freeze",
		"ownership_billing_preset_price_freeze":"Ownership Billing Preset Rate Freeze",
		"ownership_billing_preset_price_freeze.activate":"Activate",
		"OwnershipBillingPresetPriceFreeze.confirmTitle.action/activate":"Activate",
		"OwnershipBillingPresetPriceFreeze.confirmContent.action/activate":"Are you sure you want to activate this Ownership Billing Preset Rate Freeze?",
		"OwnershipBillingPresetPriceFreeze.success.action/activate":"Ownership Billing Preset Rate Freeze is successfully activated",
		"ownership_billing_preset_price_freeze.deactivate":"Deactivate",
		"OwnershipBillingPresetPriceFreeze.confirmTitle.action/deactivate":"Deactivate",
		"OwnershipBillingPresetPriceFreeze.confirmContent.action/deactivate":"Are you sure you want to deactivate this Ownership Billing Preset Rate Freeze?",
		"OwnershipBillingPresetPriceFreeze.success.action/deactivate":"Ownership Billing Preset Rate Freeze is successfully deactivated",
		"OwnershipBillingPresetPriceFreeze.periodId":"Period",
		"OwnershipBillingPresetPriceFreeze.period":"Period",
		"OwnershipBillingPresetPriceFreeze.ownershipBillingPresetId":"",
		"OwnershipBillingPresetPriceFreeze.ownershipBillingPreset":"",
		"OwnershipBillingPresetPriceFreeze.price":"Rate",
		"OwnershipBillingPresetPriceFreeze.state":"State",
		//Ownership Billing Preset Realization
		"OwnershipBillingPresetRealization":"Ownership Billing Preset Realization",
		"ownership_billing_preset_realization":"Ownership Billing Preset Realization",
		"OwnershipBillingPresetRealization.periodId":"Period",
		"OwnershipBillingPresetRealization.period":"Period",
		"OwnershipBillingPresetRealization.ownershipBillingPresetId":"",
		"OwnershipBillingPresetRealization.ownershipBillingPreset":"",
		"OwnershipBillingPresetRealization.billingPresetId":"Billing Preset",
		"OwnershipBillingPresetRealization.billingPreset":"Billing Preset",
		"OwnershipBillingPresetRealization.invoiceId":"Invoice",
		"OwnershipBillingPresetRealization.invoice":"Invoice",
		"OwnershipBillingPresetRealization.monthylyBillingId":"Monthly Billing",
		"OwnershipBillingPresetRealization.monthylyBilling":"Monthly Billing",
		"OwnershipBillingPresetRealization.total":"Total",
		"OwnershipBillingPresetRealization.paid":"Paid",
		"OwnershipBillingPresetRealization.balance":"Balance",
		"OwnershipBillingPresetRealization.state":"State",
		//Ownership Billing Preset Rate History
		"OwnershipBillingPresetHistory":"Ownership Billing Preset Rate History",
		"ownership_billing_preset_history":"Ownership Billing Preset Rate History",
		"ownership_billing_preset_history.activate":"Activate",
		"OwnershipBillingPresetHistory.confirmTitle.action/activate":"Activate",
		"OwnershipBillingPresetHistory.confirmContent.action/activate":"Are you sure you want to activate this Ownership Billing Preset Rate History?",
		"OwnershipBillingPresetHistory.success.action/activate":"Ownership Billing Preset Rate History is successfully activated",
		"ownership_billing_preset_history.deactivate":"Deactivate",
		"OwnershipBillingPresetHistory.confirmTitle.action/deactivate":"Deactivate",
		"OwnershipBillingPresetHistory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Ownership Billing Preset Rate History?",
		"OwnershipBillingPresetHistory.success.action/deactivate":"Ownership Billing Preset Rate History is successfully deactivated",
		"OwnershipBillingPresetHistory.ownershipBillingPresetId":"",
		"OwnershipBillingPresetHistory.ownershipBillingPreset":"",
		"OwnershipBillingPresetHistory.date":"Date",
		"OwnershipBillingPresetHistory.price":"Rate",
		//Payment Explanation
		"OwnershipBillingPresetPaymentExplanation":"Payment Explanation",
		"ownership_billing_preset_payment_explanation":"Payment Explanation",
		"ownership_billing_preset_payment_explanation.activate":"Activate",
		"OwnershipBillingPresetPaymentExplanation.confirmTitle.action/activate":"Activate",
		"OwnershipBillingPresetPaymentExplanation.confirmContent.action/activate":"Are you sure you want to activate this Payment Explanation?",
		"OwnershipBillingPresetPaymentExplanation.success.action/activate":"Payment Explanation is successfully activated",
		"ownership_billing_preset_payment_explanation.deactivate":"Deactivate",
		"OwnershipBillingPresetPaymentExplanation.confirmTitle.action/deactivate":"Deactivate",
		"OwnershipBillingPresetPaymentExplanation.confirmContent.action/deactivate":"Are you sure you want to deactivate this Payment Explanation?",
		"OwnershipBillingPresetPaymentExplanation.success.action/deactivate":"Payment Explanation is successfully deactivated",
		"OwnershipBillingPresetPaymentExplanation.name":"Name",
		"OwnershipBillingPresetPaymentExplanation.explanation":"Explanation",
		//Monthly Billing
		"MonthlyBilling":"Monthly Billing",
		"monthly_billing":"Monthly Billing",
		"monthly_billing.invoice":"Generate Invoice",
		"MonthlyBilling.confirmTitle.invoice":"Generate Invoice",
		"MonthlyBilling.confirmContent.invoice":"Generate Invoice?",
		"MonthlyBilling.success.invoice":"Monthly Billing is successful",
		"monthly_billing.penalty":"Generate Penalty",
		"MonthlyBilling.confirmTitle.penalty":"Generate Penalty",
		"MonthlyBilling.confirmContent.penalty":"Generate Penalty?",
		"MonthlyBilling.success.penalty":"Monthly Billing is successful",
		"monthly_billing.email":"Generate Email",
		"MonthlyBilling.confirmTitle.email":"Generate Email",
		"MonthlyBilling.confirmContent.email":"Generate Email?",
		"MonthlyBilling.success.email":"Monthly Billing is successful",
		"monthly_billing.confirm":"Confirm",
		"MonthlyBilling.confirmTitle.confirm":"Confirm",
		"MonthlyBilling.confirmContent.confirm":"Are you sure you want to confirm this Monthly Billing?",
		"MonthlyBilling.success.confirm":"Monthly Billing is successfully confirmed",
		"monthly_billing.rollback":"Rollback",
		"MonthlyBilling.confirmTitle.rollback":"Rollback",
		"MonthlyBilling.confirmContent.rollback":"Rollback?",
		"MonthlyBilling.success.rollback":"Monthly Billing is successful",
		"monthly_billing.cancel":"Cancel",
		"MonthlyBilling.confirmTitle.cancel":"Cancel",
		"MonthlyBilling.confirmContent.cancel":"Are you sure you want to cancel this Monthly Billing?",
		"MonthlyBilling.success.cancel":"Monthly Billing is successfully cancelled",
		"monthly_billing.draft":"Draft",
		"MonthlyBilling.confirmTitle.draft":"Draft",
		"MonthlyBilling.confirmContent.draft":"Draft?",
		"MonthlyBilling.success.draft":"Monthly Billing is successful",
		"MonthlyBilling.name":"Name",
		"MonthlyBilling.blockId":"Block",
		"MonthlyBilling.block":"Block",
		"MonthlyBilling.ownershipId":"Ownership",
		"MonthlyBilling.ownership":"Ownership",
		"MonthlyBilling.billingPresetId":"Billing Preset",
		"MonthlyBilling.billingPreset":"Billing Preset",
		"MonthlyBilling.invoices":"Invoices",
		"MonthlyBilling.mails":"Mails",
		"MonthlyBilling.state":"State",
		//MENUS
		"Billing":"Billing",
		"Monthly Billing":"Monthly Billing",
		"BillingMonthlyBilling":"Monthly Billing",
		"Billing Configuration":"Billing Configuration",
		"Ownership Billing":"Ownership Billing",
		"BillingConfigurationOwnershipBilling":"Ownership Billing",
		"Billing Preset":"Billing Preset",
		"BillingConfigurationBillingPreset":"Billing Preset",
		"Payment Explanation":"Payment Explanation",
		"BillingConfigurationPaymentExplanation":"Payment Explanation",
		"Billing Cycle":"Billing Cycle",
		"BillingConfigurationBillingCycle":"Billing Cycle",
		"Property":"Property",
		"Ownership":"Ownership",
		"PropertyOwnership":"Ownership",
		"Unit":"Unit",
		"PropertyUnit":"Unit",
		"Block":"Block",
		"PropertyBlock":"Block",
		"Floor":"Floor",
		"PropertyFloor":"Floor",
		"Type":"Type",
		"PropertyType":"Type",
	}
}

export default Translations
