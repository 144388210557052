import React, { Component } from "react";
import { withStyles, InputBase } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from "lodash";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";
import styles from "../styles/ListRoomStyle";

class ListRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: this.props.uid,
      rooms: this.props.rooms,
    };
    this.delayedSearch = _.debounce(this.searchRoom, 1000);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ uid: nextProps.uid, rooms: nextProps.rooms });
  }

  handleClick = (id, name, users) => {
    let uid = _.find(users, { uid: this.state.uid.toString() });
    this.props.onClick(id, name, uid.roomUserId);
  };

  searchHandler = (event) => {
    event.persist();
    this.delayedSearch(event);
  };

  searchRoom = (event) => {
    let searchRoom = event.target.value.toLowerCase();
    let searchResult;
    searchResult = this.props.rooms.filter((el) => {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(searchRoom) !== -1;
    });
    this.setState({
      rooms: searchResult,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className={classes.wrapperTop}>
          <div className={classes.account}>
            <Avatar>A</Avatar>
            <p className={classes.accountName}>{this.props.username}</p>
          </div>
          <div className={classes.search}>
            <InputBase
              fullWidth
              placeholder={tl(Cookies.get("lang"), "typeToSearch")}
              onChange={this.searchHandler}
              startAdornment={
                <InputAdornment position="start" style={{ color: "#999" }}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
        </div>
        <div className={classes.listRoomChat}>
          {this.state.rooms.map((obj, key) => (
            <div
              key={key}
              className={classes.item}
              onClick={this.handleClick.bind(this, obj.id, obj.name, obj.users)}
            >
              <Avatar>{obj.id}</Avatar>
              <p className={classes.roomName}>{obj.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ListRoom);
