import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import { tl } from "framework/utils/Translator";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    backgroundColor: "#f7f7f7",
    border: "1px solid #dedede",
    borderRadius: "20px",
    maxHeight: "37px",
    padding: "0px 10px",
    alignItems: "center",
    "&.dark": {
      backgroundColor: "#ddd",
      "& input": {
        "&::placeholder": {
          opacity: "0.8",
          color: "#333",
        },
      },
    },
  },
  inputContainer: {
    padding: "20px",
  },
  iconContainer: {
    width: "auto",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    this.delayedCallback = _.debounce(this.applyData, 500);
    this.searchRef = React.createRef();
  }

  handleChange = (event) => {
    let { value } = event.target ? event.target : "";
    this.setState({ value });
    event.persist();
    this.delayedCallback(value);
  };

  applyData = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  triggerFocus = (isAutoSelect) => {
    if (this.searchRef && this.searchRef) {
      this.searchRef.focus();
      if (isAutoSelect) {
        this.searchRef.select();
      }
    }
  };

  triggerBlur = () => {
    if (this.searchRef && this.searchRef) {
      this.searchRef.blur();
    }
  };

  triggerClear = () => {
    this.searchRef.value = "";
  };

  handleKeyDown = (e) => {
    if ([38, 40].includes(e.keyCode) && this.props.disableUpDownKey) {
      e.preventDefault();
    }
    if (e.keyCode === 13 && this.props.onEnterPressed) {
      this.props.onEnterPressed();
    }
  };

  handleFocus = () => {
    if (this.props.autoSelectWhenFocus) {
      this.searchRef.select();
    }
  };

  render() {
    const { classes, theme, placeholder } = this.props;
    return (
      <div className={classes.container + " " + (theme || "")}>
        <InputBase
          fullWidth
          placeholder={placeholder || tl("typeToSearch")}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          inputProps={{
            style: { fontFamily: "Quicksand" },
            ref: (ref) => {
              if (this.props.innerRef) {
                this.props.innerRef(ref);
              }
              this.searchRef = ref;
            },
          }}
        />
        <div className={classes.iconContainer}>
          <SearchIcon />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SearchBar);
