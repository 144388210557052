import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class DashboardActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("./Dashboard")
	}

	render() {
		return ( 
			<Activity 
				name="Dashboard"
				RenderedComponent={this.RenderedComponent}
				context="Dashboard"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(DashboardActivity)