import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'

import FileDownload from 'js-file-download'

class EmploymentReportAttendanceAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
		this.FileDownload = FileDownload
	}

	handleDownload = () => {
		let record = this.props.record
		let format = record.reportType === "excel"?".xlsx":".pdf"
		API("download","AttendanceReport/attendance_report",record)
		.then(response=>{
			this.FileDownload(response.data, 'report'+format)
		})
		.catch(error=>{
			if(error.response.status === 401){
				this.props.error(error.response.data.fields)
			}
		})
	}
	
	render() {
		let buttons = []
		buttons.push(
			<Button 
				color="success" 
				key="download-pdf"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleDownload()}>
				<DownloadIcon/>{tl(Cookies.get("lang"),"Download")}
			</Button>
		)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EmploymentReportAttendanceAction)
