//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import ExcelIcon from "@material-ui/icons/GridOn";
import ThemeParkIcon from "@material-ui/icons/Nature";

//activities
import QueueReportActivity from 'app/modules/ThemePark/activities/QueueReport/QueueReportActivity'
import CancelledQueueReportActivity from 'app/modules/ThemePark/activities/CancelledQueueReport/CancelledQueueReportActivity'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"ThemeParkReports"),
	    menu		: "ACCT",
	    icon 		: ThemeParkIcon,
	    state		: "TPRPT",
	    views		:[
			{
				path		: "/theme-park-report-queue",
				name		: tl(Cookies.get("lang"),"QueueReport"),
				menu		: "ACCT", //TPQRPT
				icon 		: ExcelIcon,
				component 	: QueueReportActivity,
				layout		: "/admin"
			},
			{
				path		: "/theme-park-report-cancelled-queue",
				name		: tl(Cookies.get("lang"),"CancelledQueueReport"),
				menu		: "ACCT", //TPQCRPT
				icon 		: ExcelIcon,
				component 	: CancelledQueueReportActivity,
				layout		: "/admin"
			},
		]
	},
]

export default Routes