import { createStore, combineReducers } from "redux";
import Session from "framework/reducers/Session";
import View from "framework/reducers/View";

export default createStore(
  combineReducers(
    {
      Session,
      View,
    },
    {},
  ),
);
