import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
// import axios from 'framework/utils/API'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'

import {Tooltip, Hidden} from "@material-ui/core"
import ConfigForm from "framework/components/ConfigForm"
import MiniBrowser from "framework/components/MiniBrowser"
import DraftBrowser from "framework/components/DraftBrowser"
import ConfirmDialog from 'framework/components/ConfirmDialog'
import Config from "company/Config"

//redux
import {mapStateToProps, mapDispatchToProps} from "framework/utils/Redux"
import {connect} from 'react-redux'

//icons
import HistoryIcon from "@material-ui/icons/History"
import CancelIcon from "@material-ui/icons/Cancel"
// import AddIcon from '@material-ui/icons/LibraryAdd'
import SaveIcon from '@material-ui/icons/Save'
// import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import PreviewIcon from '@material-ui/icons/VerticalSplit'

//other component
import InstantSalesConfiguration from './InstantSalesConfiguration'

class InstantSalesAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			previousRecord 	: JSON.parse(JSON.stringify(props.record)),
			popup 			: false,
			action 			: "",
			confirmDialog	: {
				open		: false,
				title		: "",
				content		: "",
				handleNo	: this.handleClickClosePopup,
				handleYes	: this.handleClickPopupConfirm
			},
			config:{},
			configErrors:{}
		}

		//config
		this.ERPConfig 		= null
		if (Config.saasConfig) {
			this.ERPConfig = this.props.Session
		} else {
			this.ERPConfig = Config
		}

		this.configFormRef = React.createRef()
	}


	handleClickUpdate = () => {
		let record = {
			...this.props.record,
			lines:this.props.record.lines.filter((line)=>line.itemId)
		}
		if (this.props.context !== "WarehouseCrossBranchDeliveryOrder"){
			record.branchOriginId = null
			record.branchOrigin = null
		}

		if (record.receivable) {
			delete record.paymentTypeId
			delete record.paymentType
		}

		API("update","instant_sales",record)
		.then(response=>{
			this.setState({
				previousRecord 	: JSON.parse(JSON.stringify(response.data)),
				configErrors 	: {}
			})
			if (this.props.onAfterUpdate) {
				this.props.onAfterUpdate(response.data)
			}
		})
		.catch(error=>{
			if (error && error.response && error.response.data && error.response.data.fields) {
				if (this.props.error) {
					this.props.error(error.response.data.fields)
				}
				this.setState({
					configErrors:error.response.data.fields
				})
			}
		})
	}

	handleClickCancel = () => {
		if (this.props.payload.viewType === "modal") {
			this.props.payload.closeCallback()
		} else {
			if (this.props.renderMode === "formView") {
				this.props.back(this.props.payload)
			} else if (this.props.renderMode === "formCreate") {
				this.props.backFrom(this.props.payload)
			} else if  (this.props.renderMode === "formEdit"){
				this.props.onRecordChange(JSON.parse(JSON.stringify(this.state.previousRecord)))
				this.props.formViewChange("for	mView")
			}
		}
	}

	handleClickEdit = () => {
		this.props.formViewChange("formEdit")
	}

	handleClickNew = () => {
		API("default","instant_sales",null)
		.then(response=>{
			this.props.formViewChange("formCreate")
			this.props.onRecordChange(response.data)
		})		
	}

	handleClickOpenPopup = (action) => {
		if (action === "action/refreshIMP") {
			this.setState({
				confirmDialog 	: {
					open		: true,
					title		: tl(Cookies.get("lang"),"action_refresh_IMP.confirmTitle"),
					content		: tl(Cookies.get("lang"),"action_refresh_IMP.confirmContent"),
					handleNo	: this.handleClickClosePopup,
					handleYes	: ()=>{
						if (this.props.onRefreshIMP) {
							this.props.onRefreshIMP()
						}
					}
				},
				action			: action
			})
		} else {
			let splitContent = tl("deleteRowContent").split("*")
			let draftCancelText = <>{splitContent[0]}<b>{splitContent[1]}</b>{splitContent[2]}</>
			this.setState({
				confirmDialog 	: {
					open		: true,
					title		: tl(Cookies.get("lang"),"InstantSales.confirmTitle."+action),
					content		: action === "action/cancelDraft" ? draftCancelText : tl(Cookies.get("lang"),"InstantSales.confirmContent."+action),
					handleNo	: this.handleClickClosePopup,
					handleYes	: this.handleClickPopupConfirm
				},
				action			: action
			})
		}
	}

	handleMiniBrowserCancel = (id) => {
		this.setState({
			confirmDialog 	: {
				open		: true,
				title		: tl(Cookies.get("lang"),"InstantSales.confirmTitle.cancel"),
				content		: tl(Cookies.get("lang"),"InstantSales.confirmContent.cancel"),
				handleNo	: this.handleClickClosePopup,
				handleYes	: ()=>this.handleMiniBrowserConfirmCancel(id)
			},
			action			: "action/cancel"
		})
	}

	confirmSales = () => {
		if (this.props.onConfirmSales) {
			this.props.onConfirmSales()
		}
	}

	handleMiniBrowserConfirmCancel = (id) => {
		this.handleClickClosePopup()
		this.setState({isLoading:true})
		let route = "/api/instant_sales/"+id+"/"+this.state.action
		API("action","instant_sales",route)
		.then(()=>{
			this.props.setNotification(true,tl("InstantSales.cancelled"),"success")
			setTimeout(()=>{
				this.props.setNotification(false,tl("InstantSales.cancelled"),"success")
			},3000)
			if (this.props.onAfterAction) {
				this.props.onAfterAction()
			}
		}).catch(error=>{
			if (error) {
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.fields) {
							this.props.error(error.response.data.fields)
						}
					}
				} 
			}
		}).finally(()=>{	
			this.setState({isLoading:false})
		})
	}

	handleClickClosePopup = () => {
		this.setState({
			confirmDialog 	: {
				open		: false,
				title		: "",
				content		: "",
				handleNo	: this.handleClickClosePopup,
				handleYes	: this.handleClickPopupConfirm
			},
			action			: null
		})
	}

	handleShortcut=()=>{
		if (this.props.renderMode === "formEdit") {
			this.handleClickUpdate()
		} else {
			this.handleClickSave()
		}
	}

	handleClickPopupConfirm = () => {
		this.handleClickClosePopup()
		if (this.state.action === "action/cancelDraft") {
			this.props.onCancelDraft()
		} else {
			this.setState({isLoading:true})
			let recordId = this.props.record.id
			let route = "/api/instant_sales/"+recordId+"/"+this.state.action
			API("action","instant_sales",route)
			.then(()=>{
				this.props.setNotification(true,tl("InstantSales.cancelled"),"success")
				setTimeout(()=>{
					this.props.setNotification(false,tl("InstantSales.cancelled"),"success")
				},3000)
				if (this.props.onAfterAction) {
					this.props.onAfterAction()
				}
			}).catch(error=>{
				if (error) {
					if (error.response) {
						if (error.response.data) {
							if (error.response.data.fields) {
								this.props.error(error.response.data.fields)
							}
						}
					} 
				}
			}).finally(()=>{	
				this.setState({isLoading:false})
			})
		}
	}

	handleClickBack = () => {
		this.props.back(this.props.payload)
	}

	handleConfigChange = (configChanges) => {
		if (this.props.onConfigChange) {
			this.props.onConfigChange(configChanges)
		}
	}

	handleMiniBrowserClickRow = (data) => {
		if (this.props.onMiniBrowserClickRow) {
			this.props.onMiniBrowserClickRow(data)
		}
		
	}

	handleDiscardChanges = () => {
		if (this.props.onDiscardChanges) {
			this.props.onDiscardChanges()
		}
		this.setState({
			configErrors:{},
		})
	}

	handleClearForm = () => {
		if (this.props.onClearForm){
			this.props.onClearForm()
		}
	}

	triggerDialog = () => {
		let dialog = null

		if (Cookies.get("lang") === "id") {
			dialog = {
				confirmTitle:"Kosongkan list",
				confirmContent:"Apakah anda yakin akan mengosongkan list?"
			}
		} else {
			dialog = {
				confirmTitle:"Clear list",
				confirmContent:"Are you sure want to clear the list?"
			}
		}
		this.setState({
			confirmDialog 	: {
				open		: true,
				title		: dialog.confirmTitle,
				content		: dialog.confirmContent,
				handleNo	: this.handleClickClosePopup,
				handleYes	: ()=>{

					this.props.onClearForm()
					this.handleClickClosePopup()
				}
			},
			action			: "action/cancel"
		})
	}

	handlePrint = () => {
		API("print", "instant_sales", this.props.record.id).then(()=>{
			console.log("printing document")
		}).catch(()=>{
			this.props.setNotification(true, Cookies.get("lang") === "id"?"Gagal mencetak dokumen":"Failed to print a document", "danger")
			setTimeout(()=>{
				this.props.setNotification(false, Cookies.get("lang") === "id"?"Gagal mencetak dokumen":"Failed to print a document", "danger")
			},3000)
		})
	}

	triggerSaveConfig = (changes) => {
		if (this.configFormRef) {
			this.configFormRef.triggerSaveConfig(changes)
		}
	}

	saveDraft = () => {
		if (this.draftRef) {
			console.log(this.draftRef)
			this.draftRef.handleSaveDraft()
		}
	}

	handleDraftSaved = (draftedRecord) => {
		if (this.props.onDraftSaved) {
			this.props.onDraftSaved(draftedRecord)
		}
	} 

	render() {
		let buttons = []
		// if (this.props.renderMode === "formCreate" || this.props.renderMode === "formEdit") {
		// 	buttons.push(
		// 		<Button key="cancel" size="sm" onClick={()=>this.handleClickCancel()}>
		// 			{tl(Cookies.get("lang"),"cancel")}
		// 		</Button>
		// 	)
		// }
		if (this.props.renderMode === "formCreate" && this.props.draftId) {
			buttons.push(<Button 
				color="transparent"
				key="cancel"
				size="sm"
				className="rounded-button negative"
				disabled={this.state.isLoading}
				onClick={()=>this.handleClickOpenPopup("action/cancelDraft")}>
				{tl("cancel")} Draft
			</Button>)
		}

		if (this.props.renderMode === "formEdit"){
			buttons.push(
				<Hidden only={["xs"]} implementation='css'>
					<Button 
						color="transparent"
						key="discardChanges"
						size="sm"
						className="rounded-button negative"
						disabled={this.state.isLoading}
						onClick={this.handleDiscardChanges}>
						{tl(this.props.strictMode?"Close":"discardChanges")}
					</Button>
				</Hidden>
			)
			
			if (can(this.props.model+".cancel") && !this.props.strictMode) {
				buttons.push(
					<>
						<Hidden smUp implementation='css'>
							<Button 
								justIcon
								color="default"
								key="cancel-xs"
								size="sm"
								disabled={this.state.isLoading}
								onClick={()=>this.handleClickOpenPopup("action/cancel")}>
								<CancelIcon/>
							</Button>
						</Hidden>
						<Hidden only={["xs"]} implementation='css'>
							<Button 
								color="transparent"
								key="cancel"
								size="sm"
								className="rounded-button negative"
								disabled={this.state.isLoading}
								onClick={()=>this.handleClickOpenPopup("action/cancel")}>
								{tl(Cookies.get("lang"),"cancel")}
							</Button>
						</Hidden>
					</>
					
				)
			}
			
			
		}

		if (["formCreate", "formEdit"].includes(this.props.renderMode) && !(this.props.strictMode && this.props.renderMode === "formEdit")) {
			buttons.push(
				<Hidden only={["xs"]} implementation='css'>
					<Button 
						color="transparent"
						key="refreshIMP"
						size="sm"
						className="rounded-button negative"
						disabled={this.state.isLoading}
						onClick={()=>this.handleClickOpenPopup("action/refreshIMP")}>
						{tl(Cookies.get("lang"),"action_refresh_IMP")}
					</Button>
				</Hidden>
			)
		}
		if (this.props.renderMode !== "formEdit" && this.props.Session.draftMode && this.props.Session.draftMode !== "single_draft") {
			buttons.push(
				<Button 
					key="finance-print"
					size="sm"
					color="transparent"
					className="rounded-button negative"
					disabled={this.state.isLoading}
					onClick={()=>this.handleClearForm()}>
					{tl("clear")}
				</Button>
			)
		}
		if (!(this.props.strictMode && this.props.renderMode === "formEdit")) {
			buttons.push(
				<Tooltip title="Shortcut: F10" key="save-withTooltip">
					<Button 
						color="success" 
						key="save"
						size="sm" 
						className="rounded-button"
						disabled={this.state.isLoading} 
						onFocus={(e)=>{
							e.target.blur();
						}}
						onKeyDown={(e)=>{
							if (e.keyCode === 13) {
								e.preventDefault()
							}
						}}
						onClick={()=>{
							this.confirmSales()
						}}>
						<SaveIcon/>{tl(Cookies.get("lang"),this.props.renderMode === "formEdit"?"update":"save")}
					</Button>
				</Tooltip>
			)
		} else {
			buttons.push(
				<Tooltip title="Shortcut: F10" key="view-payment">
					<Button 
						key="view-payment"
						size="sm" 
						className="rounded-button"
						disabled={this.state.isLoading} 
						onFocus={(e)=>{
							e.target.blur();
						}}
						onKeyDown={(e)=>{
							if (e.keyCode === 13) {
								e.preventDefault()
							}
						}}
						onClick={()=>{
							this.confirmSales()
						}}>
							{tl(Cookies.get("lang"), "InstantSales.viewPayments")}
					</Button>
				</Tooltip>
			)
		}
		

		return (
			<React.Fragment>
				{(this.props.record.id && !this.state.strictMode) && <Tooltip title="Print">
                    <Button 
                        justIcon 
                        size="sm"
                        color="transparent"
						buttonType="form-toolbar"
                        onClick={()=>this.handlePrint()}
                    >
                        <PrintIcon/>
                    </Button>
                </Tooltip>}
				{/* <DraftBrowser
					customRef={(ref)=>{this.draftRef = ref}}
					record={this.props.record}
					title={tl(Cookies.get("lang"),"draftInstantSales")}
					model="draft_instant_sales"
					renderMode={this.props.renderMode}
					onClickRow={this.handleMiniBrowserClickRow}
					onDraftSaved={this.handleDraftSaved}
					// onClickCancel={this.handleMiniBrowserCancel}
					// isOnline={this.props.isOnline}
					// offlineHost={this.props.Session.offlineHost || Config.offlineHost}
				/> */}
				<Tooltip title={tl("preview")}>
                    <Button 
                        justIcon 
                        size="sm"
                        color={this.props.previewTable?"success":"transparent"}
						buttonType="form-toolbar"
                        onClick={()=>{
							this.props.onTogglePreview()
						}}
                    >
                        <PreviewIcon/>
                    </Button>
                </Tooltip>
				<MiniBrowser
					icon={<HistoryIcon/>}
					title={tl(Cookies.get("lang"),"recentInstantSales")}
					model="recent_instant_sales"
					getModel="instant_sales"
					detailsPath="instant-sales-browse"
					onClickRow={this.handleMiniBrowserClickRow}
					onClickCancel={this.handleMiniBrowserCancel}
					disableCancellation={this.props.strictMode}
					// isOnline={this.props.isOnline}
					// offlineHost={this.props.Session.offlineHost || Config.offlineHost}
				/>
				<ConfigForm
					customRef={ref=>{
						this.configFormRef = ref
					}}
					title={tl(Cookies.get("lang"),"InstantSalesConfiguration")}
					model="instant_sales_config"
					form={InstantSalesConfiguration}
					record={this.state.config}
					errors={this.state.configErrors}
					onFormChange={this.handleConfigChange}
					onConfigLoaded={(config)=>{
						if (this.props.onConfigLoaded) {
							this.props.onConfigLoaded(config)
						}
					}}
					formRecord={this.props.record}
					configFields={[
						"branch",
						"branchId",
						"currency",
						"currencyId",
						"exchangeRate",
						"taxed",
						"location",
						"locationId"
					]}
					formRecordFields={[
						"customer",
						"customerId",
						"customerLocked",
						"paymentType",
						"paymentTypeId",
						"marketplace",
						"marketplaceId",
						"vat",
						"defaultVat",
						"defaultVatId"
					]}
				/>
				{buttons}
				<ConfirmDialog
					open={this.state.confirmDialog ? this.state.confirmDialog.open : false}
					title={this.state.confirmDialog.title}
					content={this.state.confirmDialog.content}					
					handleYes={this.state.confirmDialog.handleYes}
					handleNo={this.state.confirmDialog.handleNo}
				/>
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef:true})(InstantSalesAction)
