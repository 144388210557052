import {tl} from "framework/utils/Translator"

//activities
import SalesOrderOnlineActivity from "./activities/SalesOrderOnlineActivity"
import SalesOrderCancelledActivity from "./activities/SalesOrderCancelledActivity"
import SalesOrderArrivedActivity from "./activities/SalesOrderArrivedActivity"
import SalesOrderCustomerActivity from "./activities/SalesOrderCustomerActivity"
import SalesOrderReportActivity from "./activities/SalesOrderReportActivity"
import BakeryItemStatisticReport from "./activities/BakeryItemStatisticReport"

//icons
import SalesIcon from "@material-ui/icons/BusinessCenter"
import SalesOrderIcon from "@material-ui/icons/Assignment"
import CustomerIcon from "@material-ui/icons/Person"
import DoneIcon from "@material-ui/icons/CheckCircleOutline"
import CancelIcon from "@material-ui/icons/CancelOutlined"
import ExcelIcon from "@material-ui/icons/GridOn"

const routes = [
    {
        collapse	: true,
	    name		: tl("Bakery"),
	    menu		: "BKR",
	    icon 		: SalesIcon,
	    state		: "BKR",
	    views		:[
            {
                path		: "/sales-order-online/:id?",
                name		: tl("SalesOrderOnline"),
                menu		: "BKRSOO",
                icon 		: SalesOrderIcon,
                component 	: SalesOrderOnlineActivity,
                layout		: "/admin"
            },
            {
                path		: "/sales-order-online-arrived/:id?",
                name		: tl("SalesOrder.arrivedBrowse"),
                menu		: "BKRASO",
                icon 		: DoneIcon,
                component 	: SalesOrderArrivedActivity,
                layout		: "/admin"
            },
            {
                path		: "/sales-order-online-cancelled/:id?",
                name		: tl("SalesOrder.cancelledBrowse"),
                menu		: "BKRCSO",
                icon 		: CancelIcon,
                component 	: SalesOrderCancelledActivity,
                layout		: "/admin"
            },
            {
                path		: "/bakery-customer/:id?",
                name		: tl("Customer"),
                menu		: "BKRCST",
                icon 		: CustomerIcon,
                component 	: SalesOrderCustomerActivity,
                layout		: "/admin"
            },
        ]
    },
    {
        collapse	: true,
	    name		: tl("BakeryReport"),
	    menu		: "BKRRPT",
	    icon 		: ExcelIcon,
	    state		: "BKRRPT",
	    views		:[
            {
                path		: "/bakery-sales-report",
                name		: tl("SalesReport"),
                menu		: "BKRSLSRPT",
                icon 		: ExcelIcon,
                component 	: SalesOrderReportActivity,
                layout		: "/admin"
            },
            {
                path		: "/bakery-item-statistic-report",
                name		: tl("ItemStatisticReport"),
                menu		: "BKRITSRPT",
                icon 		: ExcelIcon,
                component 	: BakeryItemStatisticReport,
                layout		: "/admin"
            },
        ]
    }
]
export default routes 