import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/ItemPriceReport/ItemPriceReportAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class ItemPriceSalesPurchaseActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/ItemPriceReport/ItemPriceReport")
	}

	render() {
		return (
			<Activity 
				name="ItemPriceSalesPurchase"
				RenderedComponent={this.RenderedComponent}
				context="ItemPriceSalesPurchase"
				action="ItemPriceSalesPurchase"
				formActions={FormAction}/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ItemPriceSalesPurchaseActivity)
