import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/InvoiceInReport/ARReportInvoiceAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class ARReportInvoiceActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/InvoiceInReport/ARReportInvoice")
	}

	render() {
		return (
			<Activity 
				name="ARReportInvoice"
				RenderedComponent={this.RenderedComponent}
				context="ARReportInvoice"
				action="ARReportInvoice"
				formActions={FormAction}			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ARReportInvoiceActivity)
