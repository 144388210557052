import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/OutstandingGiroReport/ARReportOutstandingGiroAction'
import {can} from "framework/utils/AccessRights"
import TranslationAsatorindo from "app/modules/Accounting/TranslationAsatorindo"
import Cookies from 'js-cookie'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class ARReportOutstandingGiroActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/OutstandingGiroReport/ARReportOutstandingGiro")
		this.lang = Cookies.get("lang")
	}

	render() {
		let label = "ARReportOutstandingGiro"
		if (can("menu.asatorindo")){
			label = TranslationAsatorindo[this.lang][label]
		}
		return (
			<Activity 
				name={label}
				RenderedComponent={this.RenderedComponent}
				context="ARReportOutstandingGiro"
				action="ARReportOutstandingGiro"
				formActions={FormAction}			
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ARReportOutstandingGiroActivity)
