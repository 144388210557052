import React, { Component } from "react";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReportIcon from "@material-ui/icons/Report";
import Tooltip from "@material-ui/core/Tooltip";
// import CheckOn from '@material-ui/icons/CheckBox'
// import CheckOff from '@material-ui/icons/CheckBoxOutlineBlank'

export default class Boolean extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.value || false,
    };
    if (this.props.renderMode === "gridEditor") {
      this.Ref = React.createRef();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.checked) {
      this.setState({
        checked: nextProps.value,
      });
    }
  }

  handleChange = () => {
    if (this.props.renderMode === "browserFilter") {
      this.props.onChange(this.state.checked);
    } else {
      this.setState({
        checked: !this.state.checked,
      });
      this.props.onChange(this.props.field, !this.state.checked);
    }
  };

  handleKeyDown = (event) => {
    if (event.KeyCode === 32) {
      this.setState({ checked: !this.props.checked });
    }
  };

  toggleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  afterGuiAttached() {
    if (this.props.renderMode === "gridEditor") {
      this.Ref.focus();
    }
  }

  getValue() {
    return this.state.checked === "" ? false : this.state.checked;
  }

  isPopup() {
    return false;
  }

  applyFilter = () => {
    this.props.onChange(this.props.value === "true" ? "false" : "true");
  };

  render() {
    let labelText = null;
    if (this.props.label) {
      labelText = this.props.label;
    } else {
      labelText = tl(Cookies.get("lang"), this.props.field);
    }
    let labelComponent = (
      <InputLabel
        shrink={this.props.inlineLabel ? false : true}
        className="input-label"
        onClick={(this.props.inlineLabel && this.props.editable !== false && this.props.renderMode !== "formView") ? () => this.handleChange() : () => {}}
      >
        {labelText}
        {this.props.required ? "*" : ""}
      </InputLabel>
    );
    let renderedValue = null;
    if (this.props.value === undefined) {
      if (this.props.defaultValue === undefined) {
        renderedValue = false;
      } else {
        renderedValue = this.props.defaultValue;
      }
    } else {
      renderedValue = this.state.checked;
    }
    let InputComponent = null;
    if (
      ["formCreate", "formEdit", "formView"].includes(this.props.renderMode)
    ) {
      InputComponent = (
        <div style={{ display: this.props.hidden ? "none" : "block" }}>
          {!this.props.disableLabel &&
            !this.props.inlineLabel &&
            labelComponent}
          <div
            className={`boolean-input-container ${
              this.props.renderMode !== "formView" &&
              this.props.editable === false &&
              "input-disabled"
            } ${this.props.inlineLabel ? "inline-label" : ""}`}
          >
            <Checkbox
              disabled={
                this.props.renderMode === "formView" ||
                this.props.editable === false
                  ? true
                  : false
              }
              color="primary"
              onClick={() => this.handleChange()}
              checked={renderedValue}
            />
            {this.props.inlineLabel && labelComponent}
            <Tooltip
              style={{ display: this.props.error ? "inline-block" : "none" }}
              title={this.props.error ? this.props.error : ""}
            >
              <ReportIcon className="basic-input-error-icon" />
            </Tooltip>
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridEditor") {
      InputComponent = (
        <div onKeyDown={(event) => this.handleKeyDown(event)}>
          <div className="boolean-input-container">
            <Checkbox
              disabled={this.props.renderMode === "formView" ? true : false}
              color="primary"
              defaultChecked={renderedValue}
              onClick={() => this.toggleCheck()}
              inputRef={(ref) => (this.Ref = ref)}
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridRenderer") {
      InputComponent = (
        <div>
          <div className="boolean-input-container">
            <Checkbox
              disabled={this.props.renderMode === "formView" ? true : false}
              color="primary"
              checked={renderedValue}
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "browserFilter") {
      InputComponent = (
        <div onKeyDown={(event) => this.handleKeyDown(event)}>
          <Checkbox
            disabled={this.props.renderMode === "formView" ? true : false}
            color="default"
            onChange={() => this.handleChange()}
            defaultChecked={renderedValue}
            className="checkbox-browser-filter"
          />
        </div>
      );
    }
    return InputComponent;
  }
}
