import React, { Component } from "react";
import io from 'socket.io-client';
import { withSnackbar } from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment-timezone";
// import { EventSourcePolyfill } from "event-source-polyfill";
import parse from "html-react-parser";
import InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import { tl } from "framework/utils/Translator";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";

//icons
import Icon from "@material-ui/core/Icon";
import BellIcon from "@material-ui/icons/Notifications";
import CloseIcon from "@material-ui/icons/Close";

//styles
import NotificationButtonStyle from "../styles/NotificationButtonStyle";
import "assets/scss/animate.css";

class NotificationButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
		notifications: [],
		isLoading: false,
		count: 0,
		hasMore: true,
		loaded: 0,
		isOpen: false,
		expand: false,
		animated: false,
		};
		this.headers = {
			headers: {
				Authorization: "Bearer " + Cookies.get("accessToken"),
			},
		};
		const { host } = props.Session;
	}

	componentDidMount() {
		this.mounted = true;
		const { host } = this.props.Session;
		if (this.mounted) {
			axios.post(host + "/push/count", {}, this.headers).then((res) => {
				this.setState({
					count: res.data.count < 100 ? res.data.count : "99+",
				});
			});
		}
		// if (!this.socket) {
		// 	this.socket = io("https://uitesting.asteris.id/api/ws", {
		// 		path: '/api/ws'
		// 	})
		// 	this.socket.onopen = () => {console.log("websocket connected")}
		// 	this.socket.on("message", this.socketShowNotif)
		// 	this.socket.on("close",()=>{console.log("websocket disconnected")})
		// 	this.socket.on("error", ()=>{
		// 		this.socket.disconnect()
		// 		console.log("socket io disconnected")
		// 	})
		// }
		
	}

	componentWillUnmount() {
		this.mounted = false;
		if (this.socket) {
			this.socket.disconnect();
			this.socket = null;
		}
	}

	handleTogglePane = () => {
		const { host } = this.props.Session;
		if (!this.state.isOpen) {
		this.setState({ isLoading: true });
		axios.post(host + "/push/browse",
			{
				limit: 10,
				offset: 0,
			},
			this.headers,
			)
			.then((res) => {
				this.setState({
					loaded: 10,
					isLoading: false,
					notifications: res.data.records,
				});
			});
		}

		this.setState({
			isOpen: !this.state.isOpen,
			animated: true,
		});
		setTimeout(() => {
			this.setState({
				animated: false,
			});
		}, 1000);
	};

	socketShowNotif = (notif) => {
		console.log("result:", notif)
	} 

	addNotification = (event) => {
		const { classes } = this.props;
		let data = JSON.parse(event.data);
		let icon = data.payloads.icon || "mail";
		this.props.enqueueSnackbar("", {
				content: (key) => (<div className={classes.notifPush} key={key}>
					<div className={classes.notifPushHeader}>
					<div className={classes.notifPushIcon}>
						<Icon className={classes.miniICon}>{icon}</Icon>
					</div>
					<div className={classes.notifPushTitle}>{data.payloads.title}</div>
					<div
						className={classes.notifPushClose}
						onClick={() => this.props.closeSnackbar(key)}
					>
						<CloseIcon />
					</div>
					</div>
					<div className={classes.notifPushBody}>
					{parse(data.payloads.body)}
					</div>
					{/* <div className={classes.notifPushActions}>
							<Button size="sm">Hello</Button>
						</div> */}
				</div>
			),
		});

		this.setState({
			animated: true,
			count: data.count < 100 ? data.count : "99+",
		});
		setTimeout(() => {
			this.setState({animated: false});
		}, 1000);
	};

	handleOpenAll = () => {
		this.setState({
			expand: true,
		});
	};

	handleCloseModal = () => {
		this.setState({
			expand: false,
		});
	};

	timeSince = (date) => {
		moment.locale(Cookies.get("lang"));
		return moment(date).fromNow();
	};

	handleClickNotif = (url) => {
		window.open(url, "_blank");
	};

  // only for top level and produce only string values
	parseMalformedJson = (malformedJson) => {
		let newString = malformedJson;
		let newJson = {};
		let keyValuePairs = [];

		//remove unnecessary characters
		newString = newString.replace("{", "");
		newString = newString.replace("}", "");
		newString = newString.replace(/"/g, "");
		newString = newString.replace(/'/g, "");

		//create key value pair & construct new object
		keyValuePairs = newString.split(",");
		keyValuePairs.map((pair) => {
			let key = pair.split(":")[0];
			let value = pair.split(":")[1];
			newJson = {
				...newJson,
				[key]: value,
			};
		});

		return newJson;
	};

	safeJsonParse = (jsonString) => {
		try {
			return JSON.parse(jsonString);
		} catch {
			return this.parseMalformedJson(jsonString);
		}
	};

	resolveModelName = (model) => {
		let resolvedModel = model;
		switch (model) {
			case "sales-order":
			resolvedModel = "sales-sales-order";
			break;
			default:
			break;
		}
		return resolvedModel;
	};

	getNotificationItem = (classes, notification, key) => {
		// if (key === 0 && notification.data) {
		//     console.log(this.safeJsonParse(notification.data))
		// }
		let data = notification.data ? this.safeJsonParse(notification.data) : null;
		let isClickable = data && data.model && data.modelId ? true : false;
		return (
			<div
			className={classes.notifItem + ` ${isClickable && "clickable"}`}
			key={key}
			{...(isClickable
				? {
					onClick: () =>
					this.handleClickNotif(
						"/admin/" +
						this.resolveModelName(data.model) +
						"/" +
						data.modelId,
					),
				}
				: {})}
			>
			<div className={classes.notifIcon}>
				<Icon>{notification.icon ? notification.icon : "mail"}</Icon>
			</div>
			<div className={classes.notifContent}>
				<div className={classes.notifHeading}>
				<h5
					className={
					classes[notification.viewed ? "notifTitleRead" : "notifTitle"]
					}
				>
					{notification.title}
				</h5>
				</div>
				<div className={classes.notifText}>
				{notification.body && parse(notification.body)}
				</div>
				{notification.action && (
				<div className={classes.notifAction}>
					<Button color="primary" size="sm">
					{notification.action}
					</Button>
				</div>
				)}
				<div className={classes.notifTime}>
				{this.timeSince(notification.sendDate)}
				</div>
			</div>
			</div>
		);
	};

	loadMore = () => {
		this.setState({
			hasMore: false,
		});
		const { host } = this.props.Session;
		axios.post(
			host + "/push/browse",
			{
				limit: 10,
				offset: this.state.loaded - 1,
			},
			this.headers,
			)
			.then((res) => {
			let newNotifs = res.data.records.map((notif) => ({
				icon: notif.icon ? notif.icon : "mail",
				title: notif.title,
				desc: notif.body,
				date: notif.sendDate,
				model: notif.model || null,
				modelId: notif.modelId,
			}));

			this.setState({
				hasMore: res.data.records.length > 0 ? true : false,
				loaded: this.state.loaded + res.data.records.length,
				notifications: [...this.state.notifications, ...newNotifs],
			});
		});
	};

	render() {
		const { parentClasses, classes } = this.props;
		return (
		<>
			<div style={{ display: "inline-block" }}>
				<div>
					<Button
						justIcon
						simple
						color="transparent"
						size="xs"
						className={parentClasses.buttonLink + " " + classes.mainButton}
						onClick={() => this.handleTogglePane()}
					>
						<Badge
							color="primary"
							badgeContent={this.state.count}
							classes={{ badge: classes.customBadge }}
						>
							<BellIcon
							className={
								` ${
								this.state.animated ? "animated swing infinite" : ""
								} ` +
								parentClasses.headerLinksSvg +
								" " +
								parentClasses.links
							}
							/>
						</Badge>
					</Button>
					<div className={classes.notifPane + ` ${!this.state.isOpen && "hide"}`}>
						<div className={classes.paneContent}>
							<div className={classes.paneHeading}>
								<div className={classes.paneTitle}>
									{tl(Cookies.get("lang"), "notifications")}
								</div>
								<CloseIcon
									className={classes.paneClose}
									onClick={() => this.handleTogglePane()}
								/>
							</div>

							<div className={classes.notificationItems}>
								{this.state.isLoading && (
									<div className={classes.loadingPane}>
										<div className={classes.paneSpinner}>
											<CircularProgress size={20} />
										</div>
										{tl(Cookies.get("lang"), "waitASec")}...
									</div>
								)}
								{!this.state.isLoading &&
									this.state.notifications.length > 0 &&
									this.state.notifications.map((notif, key) => {
									return this.getNotificationItem(classes, notif, key);
									})}
								{!this.state.isLoading &&
									this.state.notifications.length === 0 && (
									<div className={classes.noNotification}>
										{tl(Cookies.get("lang"), "noNotification")}
									</div>
								)}
							</div>
							<div className={classes.paneBottom}>
								<Button
									onClick={() => this.handleOpenAll()}
									className={classes.bottomButton}
									color="transparent"
									fullWidth
									simple
								>
									{tl(Cookies.get("lang"), "seeAllNotifications")}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				open={this.state.expand}
				onClose={() => this.handleCloseModal()}
				maxWidth="md"
				fullWidth
			>
				<DialogContent style={{ padding: "10px" }}>
					<div
						style={{
							width: "100%",
							display: "flex",
							height: "40px",
							alignItems: "center",
						}}
					>
					<h5
						style={{
							margin: "0px 5px",
							padding: "0px",
							fontWeight: "bold",
							fontSize: "1.3",
						}}
					>
						{tl(Cookies.get("lang"), "allNotifications")}
					</h5>
					<CloseIcon
						className={classes.paneClose}
						onClick={() => this.handleCloseModal()}
					/>
					</div>
					<div className={classes.notificationItems}>
						<InfiniteScroll
							pageStart={1}
							loadMore={this.loadMore}
							hasMore={this.state.hasMore}
							loader={
							<div className={classes.infiniteLoader + " loader"} key={0}>
								<CircularProgress size={30} />
								<div className={classes.loadingLabel}> Loading ... </div>
							</div>
							}
							useWindow={false}
							threshold={60}
						>
							{this.state.notifications.map((data, key) => {
							return this.getNotificationItem(classes, data, key);
							})}
						</InfiniteScroll>
					</div>
				</DialogContent>
			</Dialog>
		</>
		);
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(withSnackbar(
	withStyles(NotificationButtonStyle)(withRouter(NotificationButton)),
  ),
);
