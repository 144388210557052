import React, { Component, Suspense } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class LazyContainer extends Component {
  shouldComponentUpdate(nextProps) {
    let differentIndex = this.props.payload.index !== nextProps.payload.index;
    if (differentIndex) {
      return true;
    }
    let differentMode = this.props.payload.mode !== nextProps.payload.mode;
    if (differentMode) {
      return true;
    }
    let differentComponent =
      this.props.payload.component !== nextProps.payload.component;
    if (differentComponent) {
      return true;
    }
    let differentFormRecord =
      this.props.payload.formRecord !== nextProps.payload.formRecord;
    if (differentFormRecord) {
      return true;
    }
    let differentRecord = false;
    if (
      "record" in this.props.payload &&
      this.props.payload.record &&
      "id" in this.props.payload.record &&
      "record" in nextProps.payload &&
      "id" in nextProps.payload.record
    ) {
      differentRecord =
        this.props.payload.record.id !== nextProps.payload.record.id;
    }
    if (differentRecord) {
      return true;
    }
    return false;
  }

  render() {
    const RenderedComponent = React.lazy(() => this.props.payload.component);
    return (
      <React.Fragment>
        <Suspense
          fallback={
            <div align="center">
              <CircularProgress style={{ margin: "20px" }} />
            </div>
          }
        >
          <RenderedComponent {...this.props} />
        </Suspense>
      </React.Fragment>
    );
  }
}
