import React, { Component } from 'react'
import Grid from "@material-ui/core/Grid"
import ManyToOne from 'framework/inputs/ManyToOne'
import Boolean from 'framework/inputs/Boolean'
import Number from 'framework/inputs/Number'
import _ from "lodash"
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'

//loaded actions
import BranchAction from 'app/components/Accounting/Branch/BranchAction'
import CurrencyAction from 'app/components/Accounting/Currency/CurrencyAction'
import PaymentTypeAction from 'app/components/Accounting/PaymentType/PaymentTypeAction'
import LocationAction from 'app/components/Accounting/Location/LocationAction'

export default class InstantSalesConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            renderMode:"formCreate",
            record:{},
            errors:{}
        }

        //form & browsers
        this.BranchForm = import("app/components/Accounting/Branch/BranchForm")
        this.BranchBrowser = import("app/components/Accounting/Branch/BranchBrowser")
        this.CurrencyForm = import("app/components/Accounting/Currency/CurrencyForm")
        this.CurrencyBrowser = import("app/components/Accounting/Currency/CurrencyBrowser")
        this.PaymentTypeForm = import("app/components/Accounting/PaymentType/PaymentTypeForm")
        this.PaymentTypeBrowser = import("app/components/Accounting/PaymentType/PaymentTypeBrowser")
        this.LocationForm = import("app/components/Accounting/Location/LocationForm")
        this.LocationBrowser = import("app/components/Accounting/Location/LocationBrowser")
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            record:nextProps.record
        })
        // if (!_.isEqual(nextProps.record,this.state.record)) {
        //     this.setState({
        //         record:nextProps.record
        //     })
        // }
        // if (!_.isEqual(nextProps.errors,this.state.errors)) {
        //     this.setState({
        //         errors:nextProps.errors
        //     })
        // }
    }

    handleInputChange = (field,value) => {
        let newErrors = {...this.state.errors}
        let newRecord = {...this.state.record}
        
        if (typeof value === "object" && value.type && value.type === "manyToOne") {
            Object.assign(newRecord,value.values)
            if (newErrors[value.field]) {
                delete newErrors[field]
            } else if (newErrors[value.fieldId]) {
                delete newErrors[value.fieldId]
            }
        } else {
            Object.assign(newRecord,{[field]:value})
            if (newErrors[field]) {
                delete newErrors[field]
            }
        }
        this.setState({
            record : newRecord,
            errors : newErrors
        },()=>{
            if (this.props.onFormChange) {
                this.props.onFormChange({
                    record : this.state.record,
                    errors : this.state.errors
                })
            }
        })
        
    }

    render() {
        return (
            <>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ManyToOne
                            key   			= "InstantSales.branchId"
                            label 			= {tl("InstantSales.branch")}
                            field 			= "branch"
                            fieldId 		= "branchId"
                            fieldAsValue 	= "id"
                            fieldAsLabel 	= "name"
                            form			= {this.BranchForm}
                            formActions 	= {BranchAction}
                            browser 		= {this.BranchBrowser}
                            model 			= "branch"
                            value 			= {this.state.record.branch?this.state.record.branch:null}
                            onChange 		= {this.handleInputChange}
                            renderMode 		= {this.state.renderMode}
                            required		= {true}
                            error  			= {!this.state.errors?null:this.state.errors.branchId}
                            packedValues
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ManyToOne
                            key   			= "InstantSales.location"
                            label 			= {tl("InstantSales.specificLocation")}
                            field 			= "location"
                            fieldId 		= "locationId"
                            fieldAsValue 	= "id"
                            fieldAsLabel 	= "name"
                            form			= {this.LocationForm}
                            formActions 	= {LocationAction}
                            browser 		= {this.LocationBrowser}
                            model 			= "location"
                            value 			= {this.state.record.location?this.state.record.location:null}
                            onChange 		= {this.handleInputChange}
                            renderMode 		= {this.state.renderMode}
                            required		= {true}
                            error  			= {!this.state.errors?null:this.state.errors.locationId}
                            packedValues
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ManyToOne
                            key   			= "InstantSales.currencyId"
                            label 			= {tl("InstantSales.currency")}
                            field 			= "currency"
                            fieldId 		= "currencyId"
                            fieldAsValue 	= "id"
                            fieldAsLabel 	= "name"
                            form			= {this.CurrencyForm}
                            formActions 	= {CurrencyAction}
                            browser 		= {this.CurrencyBrowser}
                            model 			= "currency"
                            value 			= {this.state.record.currency?this.state.record.currency:null}
                            onChange 		= {this.handleInputChange}
                            renderMode 		= {this.state.renderMode}
                            required		= {true}
                            error  			= {!this.state.errors?null:this.state.errors.currencyId}
                            packedValues
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ManyToOne
                            key   			= "InstantSales.paymentType"
                            label 			= {tl("InstantSales.paymentType")}
                            field 			= "paymentType"
                            fieldId 		= "paymentTypeId"
                            fieldAsValue 	= "id"
                            fieldAsLabel 	= "name"
                            form			= {this.PaymentTypeForm}
                            formActions 	= {PaymentTypeAction}
                            browser 		= {this.PaymentTypeBrowser}
                            model 			= "payment_type"
                            value 			= {this.state.record.paymentType?this.state.record.paymentType:null}
                            onChange 		= {this.handleInputChange}
                            renderMode 		= {this.state.renderMode}
                            required		= {true}
                            error  			= {!this.state.errors?null:this.state.errors.paymentTypeId}
                            packedValues
                        />
                    </Grid> */}
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Number
                            key   		= "InstantSales.exchangeRate"
                            label 		= {tl("InstantSales.exchangeRate")}
                            field 		= "exchangeRate"
                            value 		= {this.state.record.exchangeRate}
                            onChange 	= {this.handleInputChange}
                            renderMode 	= {this.state.renderMode}
                            error  		= {!this.state.errors?null:this.state.errors.exchangeRate}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Boolean
                            key   			= "InstantSales.taxed"
                            label 			= {tl(Cookies.get("lang"),"DeliveryOrder.taxed")}
                            field 			= "taxed"
                            value 			= {this.state.record.taxed}
                            onChange 		= {this.handleInputChange}
                            renderMode 		= {this.state.renderMode}
                            error  			= {!this.state.errors?null:this.state.errors.taxed}
                        />
                    </Grid> */}
                </Grid>
            </>
        )
    }
}
