import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Hr/reports/ShiftReport/RepresentativeReportShiftAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class RepresentativeReportShiftActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Hr/reports/ShiftReport/RepresentativeReportShift")
	}

	render() {
		return (
			<Activity 
				name="RepresentativeReportShift"
				RenderedComponent={this.RenderedComponent}
				context="RepresentativeReportShift"
				action="RepresentativeReportShift"
				formActions={FormAction}			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(RepresentativeReportShiftActivity)
