//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

//activities
import HumanCapitalEmployeeActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalEmployeeActivity'
import HumanCapitalContractActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalContractActivity'
import HumanCapitalWarningNoticeActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalWarningNoticeActivity'
import HumanCapitalPayrollPeriodActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalPayrollPeriodActivity'
import HumanCapitalLeaveQuotaActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalLeaveQuotaActivity'
import HumanCapitalOffDaysActivity from 'app/modules/Hr/activities/HumanCapital/HumanCapitalOffDaysActivity'
import HumanCapitalReportShiftActivity from 'app/modules/Hr/activities/HumanCapitalReport/HumanCapitalReportShiftActivity'
import HumanCapitalReportAttendanceActivity from 'app/modules/Hr/activities/HumanCapitalReport/HumanCapitalReportAttendanceActivity'
import HumanCapitalReportEmploymentStatisticActivity from 'app/modules/Hr/activities/HumanCapitalReport/HumanCapitalReportEmploymentStatisticActivity'
import HumanCapitalConfigurationEmployerActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationEmployerActivity'
import HumanCapitalConfigurationBranchActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationBranchActivity'
import HumanCapitalConfigurationDepartmentActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationDepartmentActivity'
import HumanCapitalConfigurationSectionActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationSectionActivity'
import HumanCapitalConfigurationPositionActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationPositionActivity'
import HumanCapitalConfigurationLevelActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationLevelActivity'
import HumanCapitalConfigurationReligionActivity from 'app/modules/Hr/activities/HumanCapitalConfiguration/HumanCapitalConfigurationReligionActivity'
import RepresentativeMonthlyShiftActivity from 'app/modules/Hr/activities/Representative/RepresentativeMonthlyShiftActivity'
import RepresentativeEmployeeShiftActivity from 'app/modules/Hr/activities/Representative/RepresentativeEmployeeShiftActivity'
import RepresentativeNonPayrollEmployeeActivity from 'app/modules/Hr/activities/Representative/RepresentativeNonPayrollEmployeeActivity'
import RepresentativeFetchAttendanceActivity from 'app/modules/Hr/activities/Representative/RepresentativeFetchAttendanceActivity'
import RepresentativeShiftTemplateActivity from 'app/modules/Hr/activities/Representative/RepresentativeShiftTemplateActivity'
import RepresentativeReportShiftActivity from 'app/modules/Hr/activities/RepresentativeReport/RepresentativeReportShiftActivity'
import RepresentativeReportAttendanceActivity from 'app/modules/Hr/activities/RepresentativeReport/RepresentativeReportAttendanceActivity'
import EmploymentLeaveActivity from 'app/modules/Hr/activities/Employment/EmploymentLeaveActivity'
import EmploymentRollingOffActivity from 'app/modules/Hr/activities/Employment/EmploymentRollingOffActivity'
import EmploymentReportShiftActivity from 'app/modules/Hr/activities/EmploymentReport/EmploymentReportShiftActivity'
import EmploymentReportAttendanceActivity from 'app/modules/Hr/activities/EmploymentReport/EmploymentReportAttendanceActivity'
import FingerprintConfigurationEmployeeActivity from 'app/modules/Hr/activities/FingerprintConfiguration/FingerprintConfigurationEmployeeActivity'
import FingerprintConfigurationNonPayrollEmployeeActivity from 'app/modules/Hr/activities/FingerprintConfiguration/FingerprintConfigurationNonPayrollEmployeeActivity'
import FingerprintConfigurationBiometricActivity from 'app/modules/Hr/activities/FingerprintConfiguration/FingerprintConfigurationBiometricActivity'
import FingerprintConfigurationClientActivity from 'app/modules/Hr/activities/FingerprintConfiguration/FingerprintConfigurationClientActivity'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Human Capital"),
	    menu		: "HCM",
	    icon 		: DashboardIcon,
	    state		: "HCM",
	    views		:[
			{
				path		: "/human-capital-employee/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalEmployee"),
				menu		: "HCM01",
				icon 		: DashboardIcon,
				component 	: HumanCapitalEmployeeActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-contract/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalContract"),
				menu		: "HCM02",
				icon 		: DashboardIcon,
				component 	: HumanCapitalContractActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-warning-notice/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalWarningNotice"),
				menu		: "HCM03",
				icon 		: DashboardIcon,
				component 	: HumanCapitalWarningNoticeActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-payroll-period/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalPayrollPeriod"),
				menu		: "HCM04",
				icon 		: DashboardIcon,
				component 	: HumanCapitalPayrollPeriodActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-leave-quota/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalLeaveQuota"),
				menu		: "HCM05",
				icon 		: DashboardIcon,
				component 	: HumanCapitalLeaveQuotaActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-off-days/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalOffDays"),
				menu		: "HCM06",
				icon 		: DashboardIcon,
				component 	: HumanCapitalOffDaysActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Human Capital Report"),
	    menu		: "HCMREPORT",
	    icon 		: DashboardIcon,
	    state		: "HCMREPORT",
	    views		:[
			{
				path		: "/human-capital-report-shift",
				name		: tl(Cookies.get("lang"),"HumanCapitalReportShift"),
				menu		: "HCMREPORT01",
				icon 		: DashboardIcon,
				component 	: HumanCapitalReportShiftActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-report-attendance",
				name		: tl(Cookies.get("lang"),"HumanCapitalReportAttendance"),
				menu		: "HCMREPORT02",
				icon 		: DashboardIcon,
				component 	: HumanCapitalReportAttendanceActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-report-employment-statistic",
				name		: tl(Cookies.get("lang"),"HumanCapitalReportEmploymentStatistic"),
				menu		: "HCMREPORT03",
				icon 		: DashboardIcon,
				component 	: HumanCapitalReportEmploymentStatisticActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Human Capital Configuration"),
	    menu		: "HCMCONF",
	    icon 		: DashboardIcon,
	    state		: "HCMCONF",
	    views		:[
			{
				path		: "/human-capital-configuration-employer/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationEmployer"),
				menu		: "HCMCONF01",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationEmployerActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-branch/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationBranch"),
				menu		: "HCMCONF02",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationBranchActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-department/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationDepartment"),
				menu		: "HCMCONF03",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationDepartmentActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-section/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationSection"),
				menu		: "HCMCONF04",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationSectionActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-position/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationPosition"),
				menu		: "HCMCONF05",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationPositionActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-level/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationLevel"),
				menu		: "HCMCONF06",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationLevelActivity,
				layout		: "/admin"
			},
			{
				path		: "/human-capital-configuration-religion/:id?",
				name		: tl(Cookies.get("lang"),"HumanCapitalConfigurationReligion"),
				menu		: "HCMCONF07",
				icon 		: DashboardIcon,
				component 	: HumanCapitalConfigurationReligionActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Representative"),
	    menu		: "REP",
	    icon 		: DashboardIcon,
	    state		: "REP",
	    views		:[
			{
				path		: "/representative-monthly-shift/:id?",
				name		: tl(Cookies.get("lang"),"RepresentativeMonthlyShift"),
				menu		: "REP01",
				icon 		: DashboardIcon,
				component 	: RepresentativeMonthlyShiftActivity,
				layout		: "/admin"
			},
			{
				path		: "/representative-employee-shift/:id?",
				name		: tl(Cookies.get("lang"),"RepresentativeEmployeeShift"),
				menu		: "REP02",
				icon 		: DashboardIcon,
				component 	: RepresentativeEmployeeShiftActivity,
				layout		: "/admin"
			},
			{
				path		: "/representative-non-payroll-employee/:id?",
				name		: tl(Cookies.get("lang"),"RepresentativeNonPayrollEmployee"),
				menu		: "REP03",
				icon 		: DashboardIcon,
				component 	: RepresentativeNonPayrollEmployeeActivity,
				layout		: "/admin"
			},
			{
				path		: "/representative-fetch-attendance/:id?",
				name		: tl(Cookies.get("lang"),"RepresentativeFetchAttendance"),
				menu		: "REP04",
				icon 		: DashboardIcon,
				component 	: RepresentativeFetchAttendanceActivity,
				layout		: "/admin"
			},
			{
				path		: "/representative-shift-template/:id?",
				name		: tl(Cookies.get("lang"),"RepresentativeShiftTemplate"),
				menu		: "REP05",
				icon 		: DashboardIcon,
				component 	: RepresentativeShiftTemplateActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Representative Report"),
	    menu		: "REPREPORT",
	    icon 		: DashboardIcon,
	    state		: "REPREPORT",
	    views		:[
			{
				path		: "/representative-report-shift",
				name		: tl(Cookies.get("lang"),"RepresentativeReportShift"),
				menu		: "REPREPORT01",
				icon 		: DashboardIcon,
				component 	: RepresentativeReportShiftActivity,
				layout		: "/admin"
			},
			{
				path		: "/representative-report-attendance",
				name		: tl(Cookies.get("lang"),"RepresentativeReportAttendance"),
				menu		: "REPREPORT02",
				icon 		: DashboardIcon,
				component 	: RepresentativeReportAttendanceActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Employment"),
	    menu		: "EMP",
	    icon 		: DashboardIcon,
	    state		: "EMP",
	    views		:[
			{
				path		: "/employment-leave/:id?",
				name		: tl(Cookies.get("lang"),"EmploymentLeave"),
				menu		: "EMP01",
				icon 		: DashboardIcon,
				component 	: EmploymentLeaveActivity,
				layout		: "/admin"
			},
			{
				path		: "/employment-rolling-off/:id?",
				name		: tl(Cookies.get("lang"),"EmploymentRollingOff"),
				menu		: "EMP02",
				icon 		: DashboardIcon,
				component 	: EmploymentRollingOffActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Employment Report"),
	    menu		: "EMPREPORT",
	    icon 		: DashboardIcon,
	    state		: "EMPREPORT",
	    views		:[
			{
				path		: "/employment-report-shift",
				name		: tl(Cookies.get("lang"),"EmploymentReportShift"),
				menu		: "EMPREPORT01",
				icon 		: DashboardIcon,
				component 	: EmploymentReportShiftActivity,
				layout		: "/admin"
			},
			{
				path		: "/employment-report-attendance",
				name		: tl(Cookies.get("lang"),"EmploymentReportAttendance"),
				menu		: "EMPREPORT02",
				icon 		: DashboardIcon,
				component 	: EmploymentReportAttendanceActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Fingerprint Configuration"),
	    menu		: "FINGCONF",
	    icon 		: DashboardIcon,
	    state		: "FINGCONF",
	    views		:[
			{
				path		: "/fingerprint-configuration-employee/:id?",
				name		: tl(Cookies.get("lang"),"FingerprintConfigurationEmployee"),
				menu		: "FINGCONF01",
				icon 		: DashboardIcon,
				component 	: FingerprintConfigurationEmployeeActivity,
				layout		: "/admin"
			},
			{
				path		: "/fingerprint-configuration-non-payroll-employee/:id?",
				name		: tl(Cookies.get("lang"),"FingerprintConfigurationNonPayrollEmployee"),
				menu		: "FINGCONF02",
				icon 		: DashboardIcon,
				component 	: FingerprintConfigurationNonPayrollEmployeeActivity,
				layout		: "/admin"
			},
			{
				path		: "/fingerprint-configuration-biometric/:id?",
				name		: tl(Cookies.get("lang"),"FingerprintConfigurationBiometric"),
				menu		: "FINGCONF03",
				icon 		: DashboardIcon,
				component 	: FingerprintConfigurationBiometricActivity,
				layout		: "/admin"
			},
			{
				path		: "/fingerprint-configuration-client/:id?",
				name		: tl(Cookies.get("lang"),"FingerprintConfigurationClient"),
				menu		: "FINGCONF04",
				icon 		: DashboardIcon,
				component 	: FingerprintConfigurationClientActivity,
				layout		: "/admin"
			},
		]
	},
]
export default Routes
