import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
// import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
// import {can} from 'framework/utils/AccessRights'
import Modal from 'framework/containers/Modal'
import ReportDownloadButton from "framework/components/ReportDownloadButton"
import FileDownload from 'js-file-download'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import PrintIcon from '@material-ui/icons/Print'

class ItemPricelistAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: "",
			dialog 			: {
				open 	: false,
				payload	: null
			}
		}
		this.FileDownload = FileDownload
		this.PreviewComponent = import('framework/containers/PDFPreview')
	}

	handleFullScreen = () => {
		this.setState({
			dialog:{
				open:true,
				payload:{
					name 			: "Preview",
					viewType 		: "modal",
					mode 			: "read",
					component 		: this.PreviewComponent,
					closeCallback 	: this.handleClosePreview
				}
			}
		})
	}

	handleClosePreview = () => {
		this.setState({
			dialog:{
				open:false,
				payload:null
			}
		})
	}
	
	render() {
		let buttons = []
		buttons.push(
			<Button  
				key="fullscreen-pdf"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleFullScreen()}>
				<PrintIcon/>{tl(Cookies.get("lang"),"printData")}
			</Button>
		)
		buttons.push(<ReportDownloadButton
			record={this.props.record}
			model="item/itemPriceListReport"
		/>)
			
		return (
			<React.Fragment>
				{buttons}
				<Modal
					open={this.state.dialog.open}
					onCloseDialog={this.handleClosePreview}
					payload={this.state.dialog.payload}
				/>
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ItemPricelistAction)
