import React, { Component } from "react";
import axios from "axios";
import { tl } from "framework/utils/Translator";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

function parseCookies(cookieString) {
  var str = cookieString.split("; ");
  var result = {};
  for (var i = 0; i < str.length; i++) {
    var cur = str[i].split("=");
    result[cur[0]] = cur[1];
  }
  return result;
}

var cookie = parseCookies(document.cookie);
if (cookie) {
  if (!cookie.lang) {
    var expiration_date = new Date();
    expiration_date.setFullYear(expiration_date.getFullYear() + 1);
    document.cookie =
      "lang=id; path=/; expires=" + expiration_date.toUTCString();
    cookie = parseCookies(document.cookie);
  }
}

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      respond: "",
    };
    this.handleClickSend = this.handleClickSend.bind(this);
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  handleClickSend() {
    axios
      .post(this.props.Session.host + "/user/recovery", {
        email: this.state.email,
      })
      .then(() => {
        this.setState({
          respond: tl("mailSentText"),
        });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errorDescription
        ) {
          this.setState({
            respond:
              error.response.data.errorDescription ===
              "User with this email address does not exist"
                ? tl("userNotFound")
                : error.response.data.errorDescription,
          });
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid container justify="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>
                  {tl(cookie.lang, "passwordRecovery")}{" "}
                </h4>
              </CardHeader>
              <CardBody>
                <p>{tl(cookie.lang, "helperTextPasswordRecovery")} </p>
                <CustomInput
                  labelText={tl(cookie.lang, "email")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    onChange: (event) => this.handleChange(event),
                  }}
                />
                <span
                  style={{
                    display: this.state.respond === "" ? "none" : "flex",
                  }}
                >
                  {this.state.respond}
                </span>
                <Button
                  color="rose"
                  fullWidth={true}
                  onClick={() => this.handleClickSend()}
                >
                  {tl(cookie.lang, "send")}
                </Button>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(loginPageStyle)(PasswordRecovery));
