import React, { Component } from "react";
import { withStyles, ButtonBase, withWidth } from "@material-ui/core";
import ChatRoom from "./ChatRoom";
import ListRoom from "./ListRoom";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@material-ui/icons/Remove";
import _ from "lodash";
import styles from "../styles/ChatAppStyle";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import Config from "../../company/Config";
import "assets/scss/animate.css";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

const ENDPOINT = Config.host;
let socket;

class ChatAppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      roomId: "",
      roomName: "",
      roomUserId: "",
      rooms: [],
      openChat: false,
      listChatWindow: [],
      isLoading: true,
    };
  }

  handleClickRoom = (roomId, roomName, roomUserId) => {
    let count = this.state.listChatWindow.length;
    if (count !== 3) {
      if (this.foundRoom(roomId)) {
        let objIndex = this.state.listChatWindow.findIndex(
          (obj) => obj.roomId == roomId,
        );
        this.state.listChatWindow[objIndex].opened = true;
        this.forceUpdate();
      } else {
        this.setState({
          listChatWindow: [
            ...this.state.listChatWindow,
            {
              roomId: roomId,
              roomName: roomName,
              roomUserId: roomUserId,
              opened: false,
            },
          ],
        });
      }
    } else {
      if (this.foundRoom(roomId)) {
        let objIndex = this.state.listChatWindow.findIndex(
          (obj) => obj.roomId == roomId,
        );
        this.state.listChatWindow[objIndex].opened = true;
        this.forceUpdate();
      } else {
        let del = this.state.listChatWindow.shift();
        let rejected = _.reject(this.state.listChatWindow, { roomId: del });
        let newChat = [
          {
            roomId: roomId,
            roomName: roomName,
            roomUserId: roomUserId,
            opened: false,
          },
        ];
        let data = _.concat(rejected, newChat);
        this.setState({
          listChatWindow: data,
        });
      }
    }
  };

  foundRoom(roomId) {
    let found = _.find(this.state.listChatWindow, { roomId: roomId });
    if (found) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    axios({
      method: "POST",
      url: this.props.Session.host + "/user",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("accessToken"),
      },
    })
      .then((res) => {
        this.setState(
          {
            userId: res.data.id,
            username: res.data.name,
            isLoading: false,
          },
          () => {
            this.getChatRoom(this.state.userId);
          },
        );
      })
      .catch((err) => {
        console.error(err)
      });
  };

  getChatRoom = (uid) => {
    let bodyFormData = new FormData();
    bodyFormData.append("userId", uid);
    axios
      .post(ENDPOINT + "/chat/room", bodyFormData)
      .then((res) => {
        this.setState({
          rooms: res.data.rooms,
        });
      })
      .catch((err) => {
        console.error(err)
      });
  };

  handleClickChatBrowser() {
    this.setState({
      openChat: !this.state.openChat,
    });
  }

  handleClickChat(roomId) {
    let objIndex = this.state.listChatWindow.findIndex(
      (obj) => obj.roomId == roomId,
    );
    this.state.listChatWindow[objIndex].opened = true;
    this.forceUpdate();
  }

  handleMinimizeChat(roomId) {
    let objIndex = this.state.listChatWindow.findIndex(
      (obj) => obj.roomId == roomId,
    );
    this.state.listChatWindow[objIndex].opened = false;
    this.forceUpdate();
  }

  handleCloseChat(roomId) {
    let rejected = _.reject(this.state.listChatWindow, { roomId: roomId });
    this.setState({
      listChatWindow: rejected,
    });
    const options = {
      transports: ["websocket"],
    };
    socket = io(ENDPOINT + "/chat", options);
    socket.emit("disposed");
  }

  handleBack() {
    this.setState({ listChatWindow: [] });
  }

  handleLeaveChat = (roomId) => {
    this.handleCloseChat(roomId);
    let rejected = _.reject(this.state.rooms, { id: roomId });
    this.setState({
      rooms: rejected,
    });
  };

  render() {
    const { classes } = this.props;
    const media = ["xss", "xs"];
    return (
      <div style={{ position: "absolute", zIndex: 100001 }}>
        {this.state.openChat && media.includes(this.props.width) ? (
          <div className={classes.mobileContainer}>
            <div className={classes.appBarMobile}>
              <ButtonBase
                onClick={this.handleClickChatBrowser.bind(this)}
                className={classes.btnWindow}
              >
                <MinimizeIcon />
              </ButtonBase>
            </div>
            {this.state.listChatWindow.length === 0 ? (
              <div style={{ height: "calc(100% - 25px)" }}>
                <ListRoom
                  username={this.state.username}
                  uid={this.state.userId}
                  rooms={this.state.rooms}
                  onClick={this.handleClickRoom}
                />
              </div>
            ) : (
              <div style={{ height: "calc(100% - 25px)" }}>
                <ChatRoom
                  userId={this.state.userId}
                  chatRoomId={this.state.listChatWindow[0].roomId}
                  chatRoomName={this.state.listChatWindow[0].roomName}
                  chatRoomUserId={this.state.listChatWindow[0].roomUserId}
                  handleBack={this.handleBack.bind(this)}
                  mobile
                />
              </div>
            )}
          </div>
        ) : (
          //desktop mode
          <div className={classes.listBtnChat}>
            <div
              className={
                classes.chatWindow +
                ` ${this.state.openChat ? " opened" : " closed"}`
              }
            >
              <div className={classes.appBarChat}>
                {!this.state.openChat && (
                  <h6 onClick={this.handleClickChatBrowser.bind(this)}>Chat</h6>
                )}
                {this.state.openChat && (
                  <ButtonBase
                    onClick={this.handleClickChatBrowser.bind(this)}
                    className={classes.btnWindow}
                  >
                    <MinimizeIcon />
                  </ButtonBase>
                )}
              </div>
              <div style={{ height: "477px" }}>
                <ListRoom
                  username={this.state.username}
                  uid={this.state.userId}
                  rooms={this.state.rooms}
                  onClick={this.handleClickRoom}
                />
              </div>
            </div>
            {!media.includes(this.props.width) &&
              this.state.listChatWindow.map((obj, key) => (
                <div
                  key={key}
                  className={
                    classes.chatWindow +
                    ` ${obj.opened ? " opened" : " closed"}`
                  }
                >
                  <div className={classes.appBarChat}>
                    {!obj.opened && (
                      <h6 onClick={this.handleClickChat.bind(this, obj.roomId)}>
                        {obj.roomName}
                      </h6>
                    )}
                    {obj.opened ? (
                      <>
                        <ButtonBase
                          onClick={this.handleMinimizeChat.bind(
                            this,
                            obj.roomId,
                          )}
                          className={classes.btnWindow}
                        >
                          <MinimizeIcon />
                        </ButtonBase>
                        <ButtonBase
                          onClick={this.handleCloseChat.bind(this, obj.roomId)}
                          className={classes.btnWindow}
                        >
                          <CloseIcon />
                        </ButtonBase>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{ height: "477px" }}>
                    <ChatRoom
                      userId={this.state.userId}
                      chatRoomId={obj.roomId}
                      chatRoomName={obj.roomName}
                      chatRoomUserId={obj.roomUserId}
                      leaveChat={this.handleLeaveChat}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(withStyles(styles)(ChatAppContainer)));
