import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'
import FileDownload from 'js-file-download'
import axios from 'axios'
import _ from "lodash"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'


class AccountingReportGeneralLedgerAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: "",
			reportContent   : null
		}
		this.FileDownload = FileDownload
	}

	componentWillReceiveProps(nextProps){
		if (!_.isEqual(nextProps.record, this.state.reportContent)) {
			this.setState({
				reportContent:nextProps.record
			})
		}
	}

	handleDownload = () => {
		const headers = { 
			headers:{
				"Authorization"	:"Bearer "+Cookies.get("accessToken"),
			},
			responseType:"arraybuffer"
		}
		axios.post(this.props.Session.host+"/api/account/generalLedgerReportExcelize",
		{
			startDate:this.state.reportContent.startDate,
			endDate:this.state.reportContent.endDate,
			accountId:this.state.reportContent.accountId
		},
		headers
		).then((response)=>{
			FileDownload(response.data,"Buku Besar.xlsx")
		}).catch((error)=>{
			if (error.response.statusCode === 502) {
				this.props.setNotification(true,"")
			}
			
		})
	}
	
	render() {
		let buttons = []
		const {reportContent} = this.state
		buttons.push(
			<Button 
				color="success" 
				key="download-pdf"
				size="sm"
				className="rounded-button"
				// disabled={this.state.isLoading || !(reportContent && reportContent.startDate && reportContent.endDate && reportContent.accountId) }  
				onClick={()=>this.handleDownload()}
			>
				<DownloadIcon/>{tl(Cookies.get("lang"),"Download")} Excel
			</Button>
		)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingReportGeneralLedgerAction)
