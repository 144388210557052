//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import FreightIcon from "@material-ui/icons/DirectionsBoat";
import HSCodeIcon from "@material-ui/icons/Label";
import FreightConfigIcon from "@material-ui/icons/Settings";
import FreightUserIcon from "@material-ui/icons/People";
import LocationIcon from "@material-ui/icons/LocationOn";
import CurrencyIcon from "@material-ui/icons/AttachMoney"
import ExchangeIcon from "@material-ui/icons/Cached";

//activities
import HSCodeActivity from 'app/modules/Freight/activities/HSCodeActivity'
import FreightConfigurationActivity from 'app/modules/Freight/activities/FreightConfigurationActivity'
import FreightUserActivity from 'app/modules/Freight/activities/FreightUserActivity'
import FreightLocationActivity from 'app/modules/Freight/activities/FreightLocationActivity'
import FreightLocationUnloadActivity from 'app/modules/Freight/activities/FreightLocationUnloadActivity'
import FreightCurrencyActivity from 'app/modules/Freight/activities/FreightCurrencyActivity'
import FreightExchangeRateActivity from 'app/modules/Freight/activities/FreightExchangeRateActivity'

const routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Freight"),
	    menu		: "FRGT",
	    icon 		: FreightIcon,
	    state		: "FRGT",
        views		:[
			{
				path		: "/freight-location/:id?",
				name		: tl(Cookies.get("lang"),"FreightLocation"),
				menu		: "FRGT01",
				icon 		: LocationIcon,
				component 	: FreightLocationActivity,
				layout 		: "/admin"			
			},
			{
				path		: "/freight-location-unload/:id?",
				name		: tl(Cookies.get("lang"),"FreightLocationUnload"),
				menu		: "FRGT02",
				icon 		: LocationIcon,
				component 	: FreightLocationUnloadActivity,
				layout 		: "/admin"			
			},
            {
				path		: "/freight-hscode/:id?",
				name		: tl(Cookies.get("lang"),"HSCode"),
				menu		: "FRGT03",
				icon 		: HSCodeIcon,
				component 	: HSCodeActivity,
				layout 		: "/admin"			
			},
			{
				path		: "/freight-configuration",
				name		: tl(Cookies.get("lang"),"FreightConfiguration"),
				menu		: "FRGT04",
				icon 		: FreightConfigIcon,
				component 	: FreightConfigurationActivity,
				layout		: "/admin"
			},
			{
				path		: "/freight-user",
				name		: tl(Cookies.get("lang"),"FreightUser"),
				menu		: "FRGT05",
				icon 		: FreightUserIcon,
				component 	: FreightUserActivity,
				layout		: "/admin"
			},
			{
				path		: "/freight-currency",
				name		: tl(Cookies.get("lang"),"Currency"),
				menu		: "FRGTCUR",
				icon 		: CurrencyIcon,
				component 	: FreightCurrencyActivity,
				layout		: "/admin"
			},
			{
				path		: "/freight-exchange-rate",
				name		: tl(Cookies.get("lang"),"ExchangeRateDaily"),
				menu		: "FRGTXRD",
				icon 		: ExchangeIcon,
				component 	: FreightExchangeRateActivity,
				layout		: "/admin"
			},
        ]
    }
]

export default routes