import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import InstantSalesAction from "app/components/Accounting/InstantSales/InstantSalesAction"

class FinanceARInstantSales extends Component {
	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Accounting/InstantSales/InstantSalesForm")
	}

	render() {
		return (
			<Activity 
				name="FinanceARInstantSales"
				RenderedComponent={this.RenderedComponent}
				context="FinanceARInstantSales"
				formActions={InstantSalesAction}
			/>
		)
	}
}

export default FinanceARInstantSales
