import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Bakery/reports/SalesReport/SalesReportAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class InstantSalesReport extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Bakery/reports/SalesReport/SalesReport")
	}

	render() {
		return (
			<Activity 
				name="SalesReport"
				RenderedComponent={this.RenderedComponent}
				context="SalesReport"
				action="SalesReport"
				formActions={FormAction}/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(InstantSalesReport)
