import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class AccountingConfigurationFiscalActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Accounting/AccountFiscal/AccountFiscalBrowser")
	}

	render() {
		return (
			<Activity 
				name="AccountingConfigurationFiscal"
				RenderedComponent={this.RenderedComponent}
				context="AccountingConfigurationFiscal"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingConfigurationFiscalActivity)
