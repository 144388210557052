import React, { Component } from 'react'
// import Button from 'components/CustomButtons/Button.jsx'
import {withStyles} from "@material-ui/core"
import ColumnSelector from 'framework/components/ColumnSelector'
import ReportDownloadButton from "framework/components/ReportDownloadButton"


//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'


class APReportInvoiceAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
	}

	handleColumnChange = (columns) => {
		if (this.props.onColumnChange) {
			this.props.onColumnChange(columns)
		}
	}
	
	render() {
		// const {classes} = this.props
		let buttons = []
		// buttons.push(
		// 	<div className={classes.navigation}>
		// 		<ButtonBase className="navButton" onClick={()=>this.handleNav("prev")}>
		// 			<PrevIcon/>
		// 		</ButtonBase>
		// 		<div className="pageInfo">
		// 			{(this.props.pageOffset || 0) + 1} / {(this.props.maxPage || 0) < 1 ? 1 : this.state.maxPage}
		// 		</div>	
		// 		<ButtonBase className="navButton" onClick={()=>this.handleNav("next")}>
		// 			<NextIcon/>
		// 		</ButtonBase>
		// 	</div>
		// )
		buttons.push(
			<ColumnSelector
				onChange={this.handleColumnChange}
			/>
		)
		buttons.push(<ReportDownloadButton
			record={this.props.record}
			model="instant_sales/report"
		/>)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

const styles = {
	navigation:{
		display: 'flex',
		alignItems: 'center',
		marginLeft:"10px",
		flexGrow: "1",
		justifyContent: 'flex-end',
		"&>.navButton":{
			padding:"5px"
		},
		"&>.pageInfo":{
			display: 'flex',
			margin:"0px 5px",
			textAlign:"center",
			justifyContent: 'center',
			whiteSpace:"nowrap"
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(APReportInvoiceAction))
