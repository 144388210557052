const Translation = {
    id:{
        "ThemeParkReports":"Laporan Taman Bermain",
        "QueueReport":"Antrian",
        "CancelledQueueReport":"Batal Masuk Antrian",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Sederhana",
        "detailed":"Detail",
        "ticket_used":"Sudah Main",
        "ticket_unused":"Belum Main"
    },
    en:{
        "ThemeParkReports":"Theme Park Report",
        "QueueReport":"Queue",
        "CancelledQueueReport":"Cancelled Queue",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Simple",
        "detailed":"Detailed",
        "ticket_used":"Ticket Used",
        "ticket_unused":"Ticket Unused"
    }
}

export default Translation