const TranslationAsatorindo = {
    id:{
        "WarehouseReportItem":"Stok Barang",
        "Daily Stock":"Stok Harian",
        "WarehouseReportDailyStock":"Stok Harian",
        "Outstanding Giro":"Giro Belum Cair",
        "ARReportOutstandingGiro":"Giro Belum Cair",
    },
    en:{
        "WarehouseReportItem":"Item Stock",
		"Daily Stock":"Daily Stock",
		"WarehouseReportDailyStock":"Daily Stock",
        "Outstanding Giro":"Outstanding Giro",
		"ARReportOutstandingGiro":"Outstanding Giro",
    }
}

export default TranslationAsatorindo
