import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import InstantPurchaseAction from "app/components/Accounting/InstantPurchase/InstantPurchaseAction"

class FinanceAPInstantPurchase extends Component {
	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Accounting/InstantPurchase/InstantPurchaseForm")
	}

	render() {
		return (
			<Activity 
				name="InstantPurchase"
				RenderedComponent={this.RenderedComponent}
				context="InstantPurchase"
				formActions={InstantPurchaseAction}
			/>
		)
	}
}

export default FinanceAPInstantPurchase
