import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";
import NotificationButton from "framework/components/NotificationButton";

// @material-ui/icons
import gbFlag from "assets/img/ENicon.png";
import idFlag from "assets/img/IDicon.png";

// core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
import RequestApproval from "../../framework/components/RequestApproval";

if (!Cookies.get("lang")) {
  var expiration_date = new Date();
  expiration_date.setFullYear(expiration_date.getFullYear() + 10);
  document.cookie = "lang=id; path=/; expires=" + expiration_date.toUTCString();
}

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openLanguage: false,
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false, openLanguage: false });
  };

  handleClickLanguage = () => {
    this.setState({ openLanguage: !this.state.openLanguage });
  };

  handleChangeLanguage = (lang) => {
    var expiration_date = new Date();
    expiration_date.setFullYear(expiration_date.getFullYear() + 10);
    document.cookie =
      "lang=" + lang + "; path=/; expires=" + expiration_date.toUTCString();
    document.location.reload(true);
  };

  render() {
    const { classes, rtlActive } = this.props;
    const { open, openLanguage } = this.state;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <div className={wrapper}>
        <Hidden smDown>
          <RequestApproval parentClasses={classes} />
        </Hidden>
        <Hidden smDown>
          <NotificationButton parentClasses={classes} />
        </Hidden>
        <div className={managerClasses}>
          <Button
            round
            color="white"
            aria-label="Languages"
            aria-owns={open ? "lang-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickLanguage}
            className="language-button"
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              alt="language"
              src={Cookies.get("lang") === "en" ? gbFlag : idFlag}
            />
            &nbsp;▼
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : tl(Cookies.get("lang"), "languages")}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openLanguage}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openLanguage,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="lang-list"
                style={{ transformOrigin: "0 0 0", pointerEvents: "auto" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem onClick={() => this.handleChangeLanguage("en")}>
                        <span>
                          <img
                            alt="English"
                            style={{ width: "20px", height: "20px" }}
                            src={gbFlag}
                          />
                          &nbsp;{tl(Cookies.get("lang"), "English")}
                        </span>
                      </MenuItem>
                      <MenuItem onClick={() => this.handleChangeLanguage("id")}>
                        <span>
                          <img
                            alt="Indonesian"
                            style={{ width: "20px", height: "20px" }}
                            src={idFlag}
                          />
                          &nbsp;{tl(Cookies.get("lang"), "Indonesian")}
                        </span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
