import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class RepresentativeNonPayrollEmployeeActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Hr/EmployeeNonPayroll/EmployeeNonPayrollBrowser")
	}

	render() {
		return (
			<Activity 
				name="RepresentativeNonPayrollEmployee"
				RenderedComponent={this.RenderedComponent}
				context="RepresentativeNonPayrollEmployee"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(RepresentativeNonPayrollEmployeeActivity)
