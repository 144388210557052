//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

//activities
import BillingMonthlyBillingActivity from 'app/modules/Property/activities/Billing/BillingMonthlyBillingActivity'
import BillingConfigurationOwnershipBillingActivity from 'app/modules/Property/activities/BillingConfiguration/BillingConfigurationOwnershipBillingActivity'
import BillingConfigurationBillingPresetActivity from 'app/modules/Property/activities/BillingConfiguration/BillingConfigurationBillingPresetActivity'
import BillingConfigurationPaymentExplanationActivity from 'app/modules/Property/activities/BillingConfiguration/BillingConfigurationPaymentExplanationActivity'
import BillingConfigurationBillingCycleActivity from 'app/modules/Property/activities/BillingConfiguration/BillingConfigurationBillingCycleActivity'
import PropertyOwnershipActivity from 'app/modules/Property/activities/Property/PropertyOwnershipActivity'
import PropertyUnitActivity from 'app/modules/Property/activities/Property/PropertyUnitActivity'
import PropertyBlockActivity from 'app/modules/Property/activities/Property/PropertyBlockActivity'
import PropertyFloorActivity from 'app/modules/Property/activities/Property/PropertyFloorActivity'
import PropertyTypeActivity from 'app/modules/Property/activities/Property/PropertyTypeActivity'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Billing"),
	    menu		: "BILL",
	    icon 		: DashboardIcon,
	    state		: "BILL",
	    views		:[
			{
				path		: "/billing-monthly-billing/:id?",
				name		: tl(Cookies.get("lang"),"BillingMonthlyBilling"),
				menu		: "BILL01",
				icon 		: DashboardIcon,
				component 	: BillingMonthlyBillingActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Billing Configuration"),
	    menu		: "BILLCONF",
	    icon 		: DashboardIcon,
	    state		: "BILLCONF",
	    views		:[
			{
				path		: "/billing-configuration-ownership-billing/:id?",
				name		: tl(Cookies.get("lang"),"BillingConfigurationOwnershipBilling"),
				menu		: "BILLCONF01",
				icon 		: DashboardIcon,
				component 	: BillingConfigurationOwnershipBillingActivity,
				layout		: "/admin"
			},
			{
				path		: "/billing-configuration-billing-preset/:id?",
				name		: tl(Cookies.get("lang"),"BillingConfigurationBillingPreset"),
				menu		: "BILLCONF02",
				icon 		: DashboardIcon,
				component 	: BillingConfigurationBillingPresetActivity,
				layout		: "/admin"
			},
			{
				path		: "/billing-configuration-payment-explanation/:id?",
				name		: tl(Cookies.get("lang"),"BillingConfigurationPaymentExplanation"),
				menu		: "BILLCONF03",
				icon 		: DashboardIcon,
				component 	: BillingConfigurationPaymentExplanationActivity,
				layout		: "/admin"
			},
			{
				path		: "/billing-configuration-billing-cycle/:id?",
				name		: tl(Cookies.get("lang"),"BillingConfigurationBillingCycle"),
				menu		: "BILLCONF04",
				icon 		: DashboardIcon,
				component 	: BillingConfigurationBillingCycleActivity,
				layout		: "/admin"
			},
		]
	},
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Property"),
	    menu		: "PROP",
	    icon 		: DashboardIcon,
	    state		: "PROP",
	    views		:[
			{
				path		: "/property-ownership/:id?",
				name		: tl(Cookies.get("lang"),"PropertyOwnership"),
				menu		: "PROP01",
				icon 		: DashboardIcon,
				component 	: PropertyOwnershipActivity,
				layout		: "/admin"
			},
			{
				path		: "/property-unit/:id?",
				name		: tl(Cookies.get("lang"),"PropertyUnit"),
				menu		: "PROP02",
				icon 		: DashboardIcon,
				component 	: PropertyUnitActivity,
				layout		: "/admin"
			},
			{
				path		: "/property-block/:id?",
				name		: tl(Cookies.get("lang"),"PropertyBlock"),
				menu		: "PROP03",
				icon 		: DashboardIcon,
				component 	: PropertyBlockActivity,
				layout		: "/admin"
			},
			{
				path		: "/property-floor/:id?",
				name		: tl(Cookies.get("lang"),"PropertyFloor"),
				menu		: "PROP04",
				icon 		: DashboardIcon,
				component 	: PropertyFloorActivity,
				layout		: "/admin"
			},
			{
				path		: "/property-type/:id?",
				name		: tl(Cookies.get("lang"),"PropertyType"),
				menu		: "PROP05",
				icon 		: DashboardIcon,
				component 	: PropertyTypeActivity,
				layout		: "/admin"
			},
		]
	},
]
export default Routes
