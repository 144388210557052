//translation
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
// import store from "store";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShippingIcon from "@material-ui/icons/LocalShippingOutlined";

//framework modules
import DashboardActivity from "framework/modules/Dashboard/DashboardActivity";
import OrderTrackingActivity from "framework/modules/OrderTracking/OrderTrackingActivity";

//auth
import Login from "framework/pages/Login";
import ChangePasswordActivity from "framework/pages/ChangePasswordActivity";
import PasswordRecovery from "framework/pages/PasswordRecovery";
import PasswordReset from "framework/pages/PasswordReset";
import ProfilePageActivity from "framework/pages/ProfilePageActivity";

// import AccountingRoutes from 'app/modules/Accounting/Routes'
// import PropertyRoutes from 'app/modules/Property/Routes'
// import HrRoutes from 'app/modules/Hr/Routes'
// import ShopRoutes from 'app/modules/Shop/Routes'
// import BaseRoutes from 'app/modules/Base/Routes'
// import WebRoutes from 'app/modules/Web/Routes'
// import FreightRoutes from 'app/modules/Freight/Routes'
// import SaasRoutes from 'app/modules/Saas/Routes'

// import Config from "company/Config";

import AccessRightEditor from "framework/components/AccessRightEditor";

// var renderedConfig = null;
// let state = store.getState();
// let Session = state.Session;
// if (Config.saasConfig) {
//   renderedConfig = Session;
// } else {
//   renderedConfig = Config;
// }

var dashRoutes = [
  {
    collapse: true,
    invisible: true,
    name: "Pages",
    icon: DashboardIcon,
    state: "authCollapse",
    views: [
      {
        path: "/login",
        name: "Asteris Digital Lab",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/recovery",
        name: "Forget Password",
        invisible: true,
        icon: DashboardIcon,
        component: PasswordRecovery,
        layout: "/auth",
      },
      {
        path: "/reset",
        name: "Reset Password",
        invisible: true,
        icon: DashboardIcon,
        component: PasswordReset,
        layout: "/auth",
      },
      {
        path: "/change-password",
        name: "Change Password",
        invisible: true,
        icon: DashboardIcon,
        component: ChangePasswordActivity,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        invisible: true,
        icon: DashboardIcon,
        component: ProfilePageActivity,
        layout: "/admin",
      },
      {
        path: "/zzzz",
        name: "Profile",
        invisible: true,
        icon: DashboardIcon,
        component: AccessRightEditor,
        layout: "/admin",
      },
    ],
  },
  ...[
    {
      path: "/dashboard",
      name: tl(Cookies.get("lang"), "Dashboard"),
      menu: "ACCT",
      icon: DashboardIcon,
      state: "DASH",
      component: DashboardActivity,
      layout: "/admin",
    },
    {
      path: "/order-tracking",
      name: tl(Cookies.get("lang"), "orderTracking"),
      menu: "TRACK",
      icon: LocalShippingIcon,
      state: "orderTracking",
      component: OrderTrackingActivity,
      layout: "/admin",
    },
  ],
];

//for Saas route configuration, see admin component file on render method to change modules

export default dashRoutes;
