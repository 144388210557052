import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class BillingConfigurationPaymentExplanationActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Property/OwnershipBillingPresetPaymentExplanation/OwnershipBillingPresetPaymentExplanationBrowser")
	}

	render() {
		return (
			<Activity 
				name="BillingConfigurationPaymentExplanation"
				RenderedComponent={this.RenderedComponent}
				context="BillingConfigurationPaymentExplanation"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(BillingConfigurationPaymentExplanationActivity)
