import {
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = () => ({
  container: {
    // ...container,
    display: 'flex',
    justifyContent: 'center',
    width:"100%",
    zIndex: "4",
    padding:'0px 20px',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  companyLogo:{
    maxHeight:"80px"
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  card:{
    maxWidth:'400px',
    marginLeft:'auto',
    marginRight:'auto',
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  noImagePlaceholder: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.3rem",
    margin: "20px 0px",
  },
});

export default loginPageStyle;
