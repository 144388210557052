import React, { useState } from "react";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import API from "framework/utils/API";
import Button from "components/CustomButtons/Button.jsx";

//icons
import SaveIcon from "@material-ui/icons/Save";

const ModalFormActions = (props) => {
  const [isLoading, setLoading] = useState(false);

  let model = props.payload.editorProps.model;

  function handleClickSave() {
    let record = props.record;
    setLoading(true);
    API("create", model, record)
      .then((response) => {
        props.payload.recordCallback(response.data);
      })
      .catch((error) => {
        props.error(error.response.data.fields);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClickUpdate() {
    setLoading(true);
    API("update", model, props.record)
      .then((response) => {
        props.payload.recordCallback(response.data);
      })
      .catch((error) => {
        props.error(error.response.data.fields);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClickCancel() {
    props.payload.closeCallback();
  }

  let buttons = [];
  if (props.renderMode === "formCreate" || props.renderMode === "formEdit") {
    buttons.push(
      <Button key="cancel" size="sm" onClick={() => handleClickCancel()}>
        {tl(Cookies.get("lang"), "cancel")}
      </Button>,
    );
  }
  if (props.renderMode === "formCreate") {
    buttons.push(
      <Button
        color="success"
        key="save"
        size="sm"
        className="rounded-button"
        disabled={isLoading}
        onClick={() => handleClickSave()}
      >
        <SaveIcon />
        {tl(Cookies.get("lang"), "save")}
      </Button>,
    );
  }
  if (props.renderMode === "formEdit") {
    buttons.push(
      <Button
        color="success"
        key="update"
        size="sm"
        className="rounded-button"
        disabled={isLoading}
        onClick={() => handleClickUpdate()}
      >
        <SaveIcon />
        {tl(Cookies.get("lang"), "save")}
      </Button>,
    );
  }

  return <React.Fragment>{buttons}</React.Fragment>;
};

export default ModalFormActions;
