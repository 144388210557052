import React, { Component } from "react";
import { ButtonBase } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import PhotoIcon from "@material-ui/icons/Photo";

class InputMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      rows: 1,
      minRows: 1,
      maxRows: 4,
      errorDescription: this.props.errorDescription,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ errorDescription: nextProps.errorDescription });
  }

  handleImageFile = (e) => {
    console.log(e.target.files[0]);
    let file = e.target.files[0];
    let fileBase64 = "";
    this.getBase64(file, (result) => {
      fileBase64 = result.split(",");
      this.setState({
        files: {
          name: file.name,
          file: fileBase64[1],
          storage: "filesystem",
        },
      });
      console.log(this.state.files);
      this.props.handleImageFile(this.state.files);
    });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      cb(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  }

  formatBytes(bytes) {
    if (bytes < 1024) return bytes + " Bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
    else return (bytes / 1073741824).toFixed(3) + " GB";
  }

  handleChange = (event) => {
    this.props.handleChange(event);
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };

  handleSendMessage = () => {
    this.props.handleSendMessage();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.chatAction}>
        <input
          onChange={this.handleImageFile}
          id="myInput"
          type="file"
          ref={(ref) => (this.myInput = ref)}
          style={{ display: "none" }}
        />
        <ButtonBase
          onClick={() => this.myInput.click()}
          style={{ borderRadius: "50%", margin: "0px 5px" }}
        >
          <PhotoIcon style={{ color: "#3c4858" }} />
        </ButtonBase>
        <form id="myForm" className={classes.form}>
          <textarea
            rows={this.state.rows}
            defaultValue={this.state.text}
            onChange={this.handleChange}
            name="text"
            className={
              classes.inputChat +
              ` ${this.state.errorDescription ? " error" : ""}`
            }
            placeholder="Type your message here..."
          />
        </form>
        <ButtonBase
          onClick={this.handleSendMessage}
          className={classes.buttonSend}
        >
          <SendIcon style={{ color: "#3c4858" }} />
        </ButtonBase>
      </div>
    );
  }
}
export default InputMessage;
