import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import AddIcon from '@material-ui/icons/LibraryAdd'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'

import ConfirmDialog from 'framework/components/ConfirmDialog'

class CurrencyAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			previousRecord 	: JSON.parse(JSON.stringify(props.record)),
			popup 			: false,
			action 			: "",
			confirmDialog	: {
				open		: false,
				title		: "",
				content		: "",
				handleNo	: this.handleClickClosePopup,
				handleYes	: this.handleClickPopupConfirm
			}
		}
	}

	handleClickSave = () => {
		let record = this.props.record
		this.setState({isLoading:true})
		API("create","currency",record)
		.then(response=>{
			this.props.onRecordChange(response.data)
			this.props.formViewChange("formView")
			this.setState({previousRecord:JSON.parse(JSON.stringify(response.data)), isLoading:false})
		})
		.catch(error=>{
			this.setState({isLoading:false})
			this.props.error(error.response.data.fields)
		})
	}

	handleClickUpdate = () => {
		API("update","currency",this.props.record)
		.then(response=>{
			this.props.onRecordChange(response.data)
			this.props.formViewChange("formView")
			this.setState({previousRecord:JSON.parse(JSON.stringify(response.data))})
		})
		.catch(error=>{
			this.props.error(error.response.data.fields)
		})
	}

	handleClickCancel = () => {
		if (this.props.payload.viewType === "modal") {
			this.props.payload.closeCallback()
		} else {
			if (this.props.renderMode === "formView") {
				this.props.back(this.props.payload)
			} else if (this.props.renderMode === "formCreate") {
				this.props.backFrom(this.props.payload)
			} else if  (this.props.renderMode === "formEdit"){
				this.props.onRecordChange(JSON.parse(JSON.stringify(this.state.previousRecord)))
				this.props.formViewChange("formView")
			}
		}
	}

	handleClickEdit = () => {
		this.props.formViewChange("formEdit")
	}

	handleClickNew = () => {
		API("default","currency",null)
		.then(response=>{
			this.props.formViewChange("formCreate")
			this.props.onRecordChange(response.data)
		})		
	}

	handleClickOpenPopup = (action) => {
		this.setState({
			confirmDialog 	: {
				open		: true,
				title		: tl(Cookies.get("lang"),"Currency.confirmTitle."+action),
				content		: tl(Cookies.get("lang"),"Currency.confirmContent."+action),
				handleNo	: this.handleClickClosePopup,
				handleYes	: this.handleClickPopupConfirm
			},
			action			: action
		})
	}

	handleClickClosePopup = () => {
		this.setState({
			confirmDialog 	: {
				open		: false,
				title		: "",
				content		: "",
				handleNo	: this.handleClickClosePopup,
				handleYes	: this.handleClickPopupConfirm
			},
			action			: null
		})
	}

	handleClickPopupConfirm = () => {
		this.handleClickClosePopup()
		this.setState({isLoading:true})
		let route = "/api/currency/"+this.props.record.id+"/"+this.state.action
		var currentAction = this.state.action
		API("action","currency",route)
		.then(()=>{
			API("get","currency",this.props.record.id)
			.then(response=>{
				this.setState({previousRecord:JSON.parse(JSON.stringify(response.data))})
				this.props.onRecordChange(response.data)
				this.props.setNotification(true,"Currency.success."+currentAction,"success")
				setTimeout(()=>this.props.setNotification(false,"Currency.success."+currentAction,"success"),3000)
			})
		}).catch(error=>{
		}).finally(()=>{
			this.setState({isLoading:false})
		})
	}

	handleClickBack = () => {
		this.props.back(this.props.payload)
	}

	render() {
		let buttons = []
		if (this.props.renderMode === "formCreate" || this.props.renderMode === "formEdit") {
			buttons.push(
				<Button key="cancel" size="sm" onClick={()=>this.handleClickCancel()}>
					{tl(Cookies.get("lang"),"cancel")}
				</Button>
			)
		}
		if (this.props.renderMode === "formView" && can(this.props.model+".activate") && this.props.record.state === "inactive"){
			buttons.push(
				<Button 
					color="success"
					key="activate"
					size="sm"
					className="rounded-button"
					disabled={this.state.isLoading}
					onClick={()=>this.handleClickOpenPopup("action/activate")}>
					{tl(Cookies.get("lang"),"currency.activate")}
				</Button>
			)
		}
		if (this.props.renderMode === "formView" && can(this.props.model+".deactivate") && this.props.record.state === "active"){
			buttons.push(
				<Button 
					color="transparent"
					key="deactivate"
					size="sm"
					className="rounded-button negative"
					disabled={this.state.isLoading}
					onClick={()=>this.handleClickOpenPopup("action/deactivate")}>
					{tl(Cookies.get("lang"),"currency.deactivate")}
				</Button>
			)
		}
		if (this.props.renderMode === "formCreate") {
			buttons.push(
				<Button 
					color="success" 
					key="save"
					size="sm" 
					className="rounded-button"
					disabled={this.state.isLoading} 
					onClick={()=>this.handleClickSave()}>
					<SaveIcon/>{tl(Cookies.get("lang"),"save")}
				</Button>
			)
		}
		if (this.props.renderMode === "formEdit") {
			buttons.push(
				<Button 
					color="success" 
					key="update"
					size="sm"
					className="rounded-button"
					disabled={this.state.isLoading}  
					onClick={()=>this.handleClickUpdate()}>
					<SaveIcon/>{tl(Cookies.get("lang"),"save")}
				</Button>
			)
		}
		if (this.props.renderMode === "formView") {
			if (can(this.props.model+".update")) {
				buttons.push(
					<Button 
						key="editData"
						size="sm"
						className="rounded-button"
						disabled={this.state.isLoading}
						onClick={()=>this.handleClickEdit()}>
						<EditIcon/>{tl(Cookies.get("lang"),"editData")}
					</Button>
				)
			}
			if (can(this.props.model+".create")) {
				buttons.push(
					<Button 
						color="success" 
						key="createData"
						size="sm" 
						className="rounded-button"
						disabled={this.state.isLoading}
						onClick={()=>this.handleClickNew()}>
						<AddIcon/> {tl(Cookies.get("lang"),"createData")}
					</Button>
				)
			}
		}
		return (
			<React.Fragment>
				{buttons}
				<ConfirmDialog
					open={this.state.confirmDialog.open}
					title={this.state.confirmDialog.title}
					content={this.state.confirmDialog.content}					
					handleYes={this.state.confirmDialog.handleYes}
					handleNo={this.state.confirmDialog.handleNo}
				/>
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CurrencyAction)
