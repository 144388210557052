import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
// import FormAction from 'app/modules/Accounting/reports/InstantPurchaseReport/InstantPurchaseReportAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class InstantPurchaseItemPricesReportActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/InstantPurchaseItemPriceReport/InstantPurchaseItemPriceReport")
	}

	render() {
		return (
			<Activity 
				name="InstantPurchaseItemPricesReport"
				RenderedComponent={this.RenderedComponent}
				context="InstantPurchaseItemPricesReport"
				action="InstantPurchaseItemPricesReport"
				// formActions={FormAction}
            />
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(InstantPurchaseItemPricesReportActivity)
