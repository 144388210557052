const translation = {
    id:{
        "RealEstate":"Real Estate",

        //Achievement
        "Achievement":"Penghargaan",
        "Achievement.name":"Nama",
        "Achievement.profilePicture":"Foto Profil",
        "Achievement.thumbnail":"Cuplikan",
        "Achievement.description":"Deskripsi",
        "Achievement.promoted":"Dipromosikan",
        "Achievement.sequence":"Urutan",
        "Achievement.state":"Status",
        "Achievement.activate":"Aktifkan",
        "Achievement.deactivate":"Nonaktifkan",
        "Achievement.cleanUrl":"Clean URL",
        "Achievement.metaDescription":"Deskripsi Meta",
        "Achievement.confirmTitle.action/activate":"Aktikan",
        "Achievement.confirmTitle.action/deactivate":"Nonaktifkan",
        "Achievement.confirmContent.action/activate":"Apa Anda yakin akan mengatifkan penghargaan ini?",
        "Achievement.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan penghargaan ini?",
        "Achievement.success.action/activate":"Penghargaan berhasil diaktifkan",
        "Achievement.success.action/deactivate":"Penghargaan berhasil dinonaktifkan",

        //Developer
        "Developer":"Pengembang",
        "Developer.basicInfo":"Informasi Dasar",
        "Developer.locationAndContact":"Lokasi dan Kontak",
        "Developer.images":"Gambar",
        "Developer.details":"Detail",
        "Developer.administrationAndSEO":"Administrasi dan SEO",
        "Developer.name":"Nama",
        "Developer.address":"Alamat",
        "Developer.city":"Kota",
        "Developer.country":"Negara",
        "Developer.photo":"Foto",
        "Developer.photos":"Daftar Foto",
        "Developer.photos.image":"Foto",
        "Developer.photos.title":"Judul",
        "Developer.thumbnail":"Cuplikan",
        "Developer.description":"Deskripsi",
        "Developer.promoted":"Dipromosikan",
        "Developer.sequence":"Urutan",
        "Developer.cleanUrl":"Clean URL",
        "Developer.metaDescription":"Deskripsi Meta",
        "Developer.phoneNumbers":"Daftar Telepon",
        "Developer.phoneNumbers.phone":"No. Telepon",
        "Developer.socmeds":"Sosial Media",
        "Developer.socmeds.type":"Tipe",
        "Developer.socmeds.link":"Link",
        "Developer.state":"Status",
        "Developer.activate":"Aktifkan",
        "Developer.deactivate":"Nonaktifkan",
        "Developer.confirmTitle.action/activate":"Aktifkan",
        "Developer.confirmTitle.action/deactivate":"Nonaktifkan",
        "Developer.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan pengembang ini?",
        "Developer.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan pengembang ini?",
        "Developer.success.action/activate":"Pengembang berhasil diaktifkan",
        "Developer.success.action/deactivate":"Pengembang berhasil dinonaktifkan",

        //Office
        "Office":"Kantor",
        "Office.basicInfo":"Informasi Dasar",
        "Office.images":"Gambar",
        "Office.locationAndContact":"Lokasi & Kontak",
        "Office.administrationAndSEO":"Administrasi & SEO",
        "Office.name":"Nama",
        "Office.address":"Alamat",
        "Office.city":"Kota",
        "Office.country":"Negara",
        "Office.profilePicture":"Foto Profil",
        "Office.thumbnail":"Cuplikan",
        "Office.description":"Deskripsi",
        "Office.promoted":"Dipromosikan",
        "Office.sequence":"Urutan",
        "Office.cleanUrl":"Clean URL",
        "Office.phone":"Telepon",
        "Office.whatsApp":"WhatsApp",
        "Office.email":"E-mail",
        "Office.metaDescription":"Deskripsi Meta",
        "Office.state":"Status",
        "Office.socmeds":"Sosial Media",
        "Office.socmeds.type":"Tipe",
        "Office.socmeds.link":"Link",
        "Office.specializations":"Daftar Spesialisasi",
        "Office.specializations.specialization":"Spesialisasi",
        "Office.locations":"Cakupan Lokasi",
        "Office.locations.location":"Nama Lokasi",
        "Office.activate":"Aktifkan",
        "Office.deactivate":"Nonaktifkan",
        "Office.confirmTitle.action/activate":"Aktifkan",
        "Office.confirmTitle.action/deactivate":"Nonaktifkan",
        "Office.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kantor ini?",
        "Office.confirmContent.action/deactivate":"Apa Anda yakin Akan menonaktifkan Kantor ini?",
        "Office.success.action/activate":"Kantor berhasil diaktifkan",
        "Office.success.action/deactivate":"Kantor berhasil dinonaktifkan",

        //RealEstatePartner
        "RealEstatePartner":"Partner",
        "RealEstatePartner.basicInfo":"Informasi Dasar",
        "RealEstatePartner.locationAndContact":"Lokasi & Kontak",
        "RealEstatePartner.images":"Gambar",
        "RealEstatePartner.content":"Konten",
        "RealEstatePartner.administrationAndSEO":"Administrasi & SEO",
        "RealEstatePartner.name":"Nama",
        "RealEstatePartner.address":"Alamat",
        "RealEstatePartner.city":"Kota",
        "RealEstatePartner.country":"Negara",
        "RealEstatePartner.photo":"Foto",
        "RealEstatePartner.photos":"Daftar Foto",
        "RealEstatePartner.photos.image":"Foto",
        "RealEstatePartner.photos.title":"Judul",
        "RealEstatePartner.thumbnail":"Cuplikan",
        "RealEstatePartner.description":"Deskripsi",
        "RealEstatePartner.promoted":"Dipromosikan",
        "RealEstatePartner.sequence":"Urutan",
        "RealEstatePartner.cleanUrl":"Clean URL",
        "RealEstatePartner.metaDescription":"Deskripsi Meta",
        "RealEstatePartner.socmeds":"Sosial Media",
        "RealEstatePartner.socmeds.type":"Tipe",
        "RealEstatePartner.socmeds.link":"Link",
        "RealEstatePartner.phoneNumbers":"Daftar Telepon",
        "RealEstatePartner.phoneNumbers.phone":"Telepon",
        "RealEstatePartner.title":"Gelar",
        "RealEstatePartner.whatsApp":"WhatsApp",
        "RealEstatePartner.state":"Status",
        "RealEstatePartner.activate":"Aktifkan",
        "RealEstatePartner.deactivate":"Nonaktifkan",
        "RealEstatePartner.confirmTitle.action/activate":"Aktifkan",
        "RealEstatePartner.confirmTitle.action/deactivate":"Nonaktifkan",
        "RealEstatePartner.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan mitra ini?",
        "RealEstatePartner.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan mitra ini?",
        "RealEstatePartner.success.action/activate":"Mitra berhasil diaktifkan",
        "RealEstatePartner.success.action/deactivate":"Mitra berhasil dinonaktifkan",

        //Agent
        "Agent":"Agen",
        "Agent.basicInfo":"Informasi Dasar",
        "Agent.locationAndContact":"Lokasi & Kontak",
        "Agent.images":"Gambar",
        "Agent.details":"Detail",
        "Agent.administrationAndSEO":"Administrasi & SEO",
        "Agent.title":"Gelar",
        "Agent.name":"Nama",
        "Agent.address":"Alamat",
        "Agent.office":"Kantor",
        "Agent.city":"Kota",
        "Agent.country":"Negara",
        "Agent.photo":"Foto",
        "Agent.whatsApp":"WhatsApp",
        "Agent.email":"E-mail",
        "Agent.cleanUrl":"Clean URL",
        "Agent.description":"Deskripsi",
        "Agent.metaDescription":"Deskripsi Meta",
        "Agent.specializations":"Daftar Spesialisasi",
        "Agent.specializations.specialization":"Spesialisasi",
        "Agent.locations":"Cakupan Lokasi",
        "Agent.locations.location":"Nama Lokasi",
        "Agent.socmeds":"Sosial Media",
        "Agent.socmeds.type":"Tipe",
        "Agent.socmeds.link":"Link",
        "Agent.state":"Status",
        "Agent.activate":"Aktifkan",
        "Agent.deactivate":"Nonaktifkan",
        "Agent.confirmTitle.action/activate":"Aktifkan",
        "Agent.confirmTitle.action/deactivate":"Nonaktifkan",
        "Agent.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan agen ini?",
        "Agent.confirmContent.action/deactivate":"Apa Anda yakin Akan menonaktifkan agen ini?",
        "Agent.success.action/activate":"Agen berhasil diaktifkan",
        "Agent.success.action/deactivate":"Agen berhasil dinonaktifkan",
        
        //RealEstateProject
        "RealEstateProject":"Proyek Real Estate",
        "RealEstateProject.basicInfo":"Informasi Dasar",
        "RealEstateProject.location":"Lokasi",
        "RealEstateProject.images":"Gambar",
        "RealEstateProject.content":"Content",
        "RealEstateProject.administrationAndSEO":"Administrasi & SEO",
        "RealEstateProject.name":"Nama",
        "RealEstateProject.address":"Alamat",
        "RealEstateProject.city":"Kota",
        "RealEstateProject.country":"Negara",
        "RealEstateProject.developer":"Pengembang",
        "RealEstateProject.photo":"Foto",
        "RealEstateProject.photos":"Daftar Foto",
        "RealEstateProject.photos.image":"Foto",
        "RealEstateProject.photos.title":"Judul",
        "RealEstateProject.thumbnail":"Cuplikan",
        "RealEstateProject.description":"Deskripsi",
        "RealEstateProject.promoted":"Dipromosikan",
        "RealEstateProject.prime":"Utama",
        "RealEstateProject.sequence":"Urutan",
        "RealEstateProject.title":"Gelar",
        "RealEstateProject.whatsApp":"WhatsApp",
        "RealEstateProject.cleanUrl":"Clean URL",
        "RealEstateProject.metaDescription":"Deskripsi Meta",
        "RealEstateProject.state":"Status",
        "RealEstateProject.activate":"Aktifkan",
        "RealEstateProject.deactivate":"Nonaktifkan",
        "RealEstateProject.confirmTitle.action/activate":"Aktifkan",
        "RealEstateProject.confirmTitle.action/deactivate":"Nonaktifkan",
        "RealEstateProject.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan proyek ini?",
        "RealEstateProject.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan proyek ini?",
        "RealEstateProject.success.action/activate":"Proyek berhasil diaktifkan",
        "RealEstateProject.success.action/deactivate":"Proyek berhasil dinonaktifkan",

        "Property":"Properti",
        "Property.mainInfo":"Informasi Utama",
        "Property.specification":"Spesifikasi",
        "Property.ownerInfo":"Informasi Pemilik",
        "Property.otherInfo":"Informasi Lain",
        "Property.location":"Lokasi",
        "Property.searchLocation":"Cari Lokasi",
        "Property.photos":"Gambar & Video",
        "Property.administration":"Administrasi",
        "Property.name":"Nama",
        "Property.code":"Kode",
        "Property.collectionTitle":"Judul Koleksi",
        "Property.placeId":"Place ID",
        "Property.latitude":"Latitude",
        "Property.longitude":"Longitude",
        "Property.transactionType":"Tipe Transaksi",
        "Property.type":"Tipe Properti",
        "Property.type.x":"Tipe",
        "Property.price":"Harga",
        "Property.unitPrice":"Satuan Harga",
        "Property.rentTimeSpan":"Jangka Waktu Sewa",
        "Property.surfaceArea":"Luas Tanah",
        "Property.buildingArea":"Luas Bangunan",
        "Property.bedroom":"Jumlah Kamar Tidur",
        "Property.bathroom":"Jumlah Kamar Mandi",
        "Property.assistantBedroom":"Jumlah Kamar Tidur ASN",
        "Property.assistantBathroom":"Jumlah Kamar Mandi ASN",
        "Property.furniture":"Furnitur",
        "Property.direction":"Hadap",
        "Property.entranceWidth":"Lebar Muka",
        "Property.yearBuilt":"Tahun Bangun",
        "Property.garage":"Jumlah Garasi",
        "Property.garageArea":" Luas Garasi",
        "Property.carport":" Luas Carport",
        "Property.certificate":"Jenis Sertifikat",
        "Property.description":"Deskripsi",
        "Property.metaDescription":"Deskripsi Meta",
        "Property.shortDescription":"Deskripsi Pendek",
        "Property.agent":"Agen",
        "Property.colistingAgent":"Agen Colisting",
        "Property.developer":"Pengembang",
        "Property.cleanUrl":"Clean URL",
        "Property.features":"Fitur",
        "Property.waterSource":"Sumber Air",
        "Property.electricalCapacity":"Kapasitas Listrik",
        "Property.pictures":"Daftar Gambar & Video",
        "Property.videoTourLink":"Link Video Tour (Youtube)",
        "Property.pjpNumber":"No. PJP",
        "Property.pjpDate":"Tanggal PJP",
        "Property.pjpValidityPeriod":"Masa Berlaku PJP (Hari)",
        "Property.pjpExpireDate":"Tanggal PJP Berakhir",
        "Property.pjpHistory":"Riwayat PJP",
        "Property.ownerName":"Nama Pemilik",
        "Property.ownerAddress":"Alamat Pemilik",
        "Property.ownerPhone":"Telepon Pemilik",
        "PropertyPicture.description":"Deskripsi",
        "PropertyPicture.sequence":"Urutan",
        "PropertyPicture.thumbnail":"Cuplikan",
        "PropertyPicture.externalLink":"Link Eksternal",
        "Property.state":"Status",
        "Property.available":"Tersedia",
        "Property.sold":"Terjual",
        "Property.cancelled":"Batalkan",
        "Property.listingDate":"Tanggal Listing",
        "Property.confirmTitle.action/available":"Tersedia",
        "Property.confirmTitle.action/sold":"Terjual",
        "Property.confirmTitle.action/cancelled":"Batalkan",
        "Property.confirmContent.action/available":"Ubah properti ini menjadi tersedia?",
        "Property.confirmContent.action/sold":"Ubah properti ini menjadi terjual",
        "Property.confirmContent.action/cancelled":"Apa Anda yakin akan membatalkan properti ini?",
        "Property.success.action/available":"Properti telah diubah menjadi tersedia",
        "Property.success.action/sold":"Properti telah diubah menjadi terjual",
        "Property.success.action/cancelled":"Properti telah dibatalkan",
        

        //PropertyFeature
        "PropertyFeature":"Fitur Properti",
        "PropertyFeature.name":"Nama",
        "PropertyFeature.icon":"Ikon",
        "PropertyFeature.code":"Kode",
        "PropertyFeature.sequence":"Urutan",
        "PropertyFeature.state":"Status",
        "PropertyFeature.activate":"Aktifkan",
        "PropertyFeature.deactivate":"Nonaktifkan",
        "PropertyFeature.confirmTitle.action/activate":"Aktifkan",
        "PropertyFeature.confirmTitle.action/deactivate":"Nonaktifkan",
        "PropertyFeature.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan fitur ini?",
        "PropertyFeature.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan fitur ini?",
        "PropertyFeature.success.action/activate":"Fitur Properti berhasil diaktifkan",
        "PropertyFeature.success.action/deactivate":"Fitur Properti berhasil dinonaktifkan",

        //Property Collection
        "PropertyCollection":"Koleksi Properti",
        "PropertyCollection.title":"Judul",
        "PropertyCollection.state":"Status",
        "PropertyCollection.cleanUrl":"Clean URL",
        "PropertyCollection.category":"Kategori",
        "PropertyCollection.description":"Deskripsi",
        "PropertyCollection.content":"Konten",
        "PropertyCollection.metaDescription":"Deskripsi Meta",
        "PropertyCollection.menu":"Menu",
        "PropertyCollection.sequence":"Urutan",
        "PropertyCollection.lines":"Daftar Properti",
        "PropertyCollection.line.property":"Properti",
        "PropertyCollection.line.sequence":"Urutan",
        "PropertyCollection.activate":"Aktifkan",
        "PropertyCollection.deactivate":"Nonaktifkan",
        "PropertyCollection.confirmTitle.action/activate":"Aktifkan",
        "PropertyCollection.confirmTitle.action/deactivate":"Nonaktifkan",
        "PropertyCollection.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan koleksi ini?",
        "PropertyCollection.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan fitur ini??",
        "PropertyCollection.success.action/activate":"Koleksi Properti berhasil diaktifkan",
        "PropertyCollection.success.action/deactivate":"Koleksi Properti berhasil dinonaktifkan",

        //Top Listing
        "TopListing":"Top Listing",
        "TopListing.name":"Nama",
        "TopListing.code":"Kode",
        "TopListing.lines":"Daftar Properti",
        "TopListing.line.property":"Properti",
        "TopListing.line.sequence":"Urutan",
        "TopListing.state":"Status",
        "TopListing.activate":"Aktifkan",
        "TopListing.deactivate":"Nonaktifkan",
        "TopListing.confirmTitle.action/activate":"Aktifkan",
        "TopListing.confirmTitle.action/deactivate":"Nonaktifkan",
        "TopListing.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan daftar properti ini?",
        "TopListing.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan daftar properti ini??",
        "TopListing.success.action/activate":"Daftar properti telah berhasil diaktifkan",
        "TopListing.success.action/deactivate":"Daftar properti telah berhasil dinonaktifkan",

        //Top Listing
        "TopAgent":"Top Agen",
        "TopAgent.name":"Nama",
        "TopAgent.code":"Kode",
        "TopAgent.lines":"Daftar Agen",
        "TopAgent.line.agent":"Agen",
        "TopAgent.line.sequence":"Urutan",
        "TopAgent.state":"Status",
        "TopAgent.activate":"Aktifkan",
        "TopAgent.deactivate":"Nonaktifkan",
        "TopAgent.confirmTitle.action/activate":"Aktifkan",
        "TopAgent.confirmTitle.action/deactivate":"Nonaktifkan",
        "TopAgent.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan daftar agen ini?",
        "TopAgent.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan daftar agen ini?",
        "TopAgent.success.action/activate":"Daftar agen telah berhasil diaktifkan",
        "TopAgent.success.action/deactivate":"Daftar agen telah berhasil dinonaktifkan",

        //Top Listing
        "PredefinedLocation":"Lokasi terdefinisi",
        "PredefinedLocation.name":"Nama",
        "PredefinedLocation.code":"Kode",
        "PredefinedLocation.state":"Status",
        "PredefinedLocation.activate":"Aktifkan",
        "PredefinedLocation.deactivate":"Nonaktifkan",
        "PredefinedLocation.confirmTitle.action/activate":"Aktifkan",
        "PredefinedLocation.confirmTitle.action/deactivate":"Nonaktifkan",
        "PredefinedLocation.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan lokasi ini?",
        "PredefinedLocation.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan lokasi ini?",
        "PredefinedLocation.success.action/activate":"Lokasi telah berhasil diaktifkan",
        "PredefinedLocation.success.action/deactivate":"Lokasi telah berhasil dinonaktifkan",

        //Property Collection category
        "CollectionCategory":"Kategori Koleksi",
        "CollectionCategory.name":"Nama",
        "CollectionCategory.parentId":"Induk",
        "CollectionCategory.code":"Kode",
        "CollectionCategory.cleanUrl":"Clean URL",
        "CollectionCategory.sequence":"Urutan",
        "CollectionCategory.state":"Status",
        "CollectionCategory.parent":"Induk",
        "CollectionCategory.image":"Gambar",
        "CollectionCategory.thumbnail":"Cuplikan",
        "CollectionCategory.activate":"Aktifkan",
        "CollectionCategory.deactivate":"Nonaktifkan",
        "CollectionCategory.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kategori koleksi ini?",
        "CollectionCategory.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan kategori koleksi ini?",
        "CollectionCategory.success.action/activate":"Kategori koleksi telah berhasil diaktifkan",
        "CollectionCategory.success.action/deactivate":"Kategori koleksi telah berhasil dinonaktifkan",
        
        //referral
        "Referral":"Referal",
        "Referral.code":"Kode",
        "Referral.name":"Nama",
        "Referral.type":"Tipe",
        "Referral.adType":"Tipe Iklan",
        "Referral.site":"Website / Aplikasi",
        "Referral.partner":"Partner",
        "Referral.description":"Deskripsi",
        "Referral.state":"Status",
        "Referral.activate":"Aktifkan",
        "Referral.deactivate":"Nonaktifkan",
        "Referral.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kode referal ini?",
        "Referral.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan kode referal ini?",
        "Referral.success.action/activate":"Kode referal telah berhasil diaktifkan",
        "Referral.success.action/deactivate":"Kode referal telah berhasil dinonaktifkan",

        "ReferralVisit":"Referral Visit",
        "ReferralVisit.dateTime":"Date & Time",
        "ReferralVisit.url":"URL",
        "ReferralVisit.referral":"Referral",
        "ReferralVisit.previousVisit":"Previous Visit",
        "ReferralVisit.conversionValue":"Conversion Value",
        "ReferralVisit.conversionQuantity":"Conversion Quantity",

        //Site
        "Site":"Situs",
        "Site.name":"Nama",
        "Site.url":"URL",
        "Site.activate":"Aktifkan",
        "Site.deactivate":"Nonaktifkan",
        "Site.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan situs ini?",
        "Site.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan situs ini?",
        "Site.success.action/activate":"Situs telah berhasil diaktifkan",
        "Site.success.action/deactivate":"Situs telah berhasil dinonaktifkan",

        //options & states
        "available":"Tersedia",
        "sold":"Terjual",
        "cancelled":"Dibatalkan",
        "rent":"Sewa",
        "sell":"Jual",
        "house":"Rumah", 
        "apartment":"Apartemen", 
        "condo":"Kondominium", 
        "land":"Tanah", 
        "villa":"Villa", 
        "shop_house":"Ruko", 
        "office":"Kantor", 
        "factory":"Pabrik", 
        "warehouse":"Gudang", 
        "business_space":"Tempat Bisnis",
        "shm":"SHM",
        "hgb":"HGB",
        "mr":"Tn.",
        "mrs":"Ny.",
        "pdam":"PDAM",
        "well":"Sumur",
        "other":"Lainnya",
        //socmeds
        "facebook":"Facebook",
        "twitter":"Twitter",
        "instagram":"Instagram",
        "linkedin":"LinkedIn",
        "youtube":"YouTube",
        //rent time span
        "year":"Tahunan",
        "month":"Bulanan",
        "week":"Mingguan",
        "day":"Harian",
        "m2":"m²",
        "unit":"unit",
        "north":"Utara",
        "northeast":"Timur Laut",
        "east":"Timur", 
        "southeast":"Tenggara",
        "south":"Selatan", 
        "southwest":"Barat Daya",
        "west":"Barat",
        "northwest":"Barat Laut",
        "hook":"Hook",
        "furnished":"Dengan Furnitur",
        "semi_furnished":"Semi Furnitur",
        "non_furnished":"Tidak ada Furnitur",

    },
    en:{
        "RealEstate":"Real Estate",

        //Achievement
        "Achievement":"Achievement",
        "Achievement.name":"Name",
        "Achievement.profilePicture":"Profile Picture",
        "Achievement.thumbnail":"Thumbnail",
        "Achievement.description":"Description",
        "Achievement.promoted":"Promoted",
        "Achievement.sequence":"Sequence",
        "Achievement.cleanUrl":"Clean URL",
        "Achievement.metaDescription":"Meta Description",
        "Achievement.state":"Status",
        "Achievement.activate":"Activate",
        "Achievement.deactivate":"Deactivate",
        "Achievement.confirmTitle.action/activate":"Activate",
        "Achievement.confirmTitle.action/deactivate":"Deactivate",
        "Achievement.confirmContent.action/activate":"Are you sure want to activate this achievement?",
        "Achievement.confirmContent.action/deactivate":"Are you sure want to deactivate this achievement?",
        "Achievement.success.action/activate":"Achievement has successfully activated",
        "Achievement.success.action/deactivate":"Achievement has successfully deactivated",

        //Developer
        "Developer":"Developer",
        "Developer.name":"Name",
        "Developer.address":"Address",
        "Developer.city":"City",
        "Developer.country":"Country",
        "Developer.photo":"Photo",
        "Developer.photos":"Photos",
        "Developer.photos.title":"Title",
        "Developer.photos.image":"Photo",
        "Developer.thumbnail":"Thumbnail",
        "Developer.description":"Description",
        "Developer.promoted":"Promoted",
        "Developer.sequence":"Sequence",
        "Developer.cleanUrl":"Clean URL",
        "Developer.metaDescription":"Meta Description",
        "Developer.socmeds":"Social Media",
        "Developer.socmeds.type":"Type",
        "Developer.socmeds.link":"Link",
        "Developer.phoneNumbers":"Phone Numbers",
        "Developer.PhoneNumbers.phone":"Phone",
        "Developer.state":"Status",
        "Developer.activate":"Activate",
        "Developer.deactivate":"Deactivate",
        "Developer.confirmTitle.action/activate":"Activate",
        "Developer.confirmTitle.action/deactivate":"Deactivate",
        "Developer.confirmContent.action/activate":"Are you sure want to activate this developer?",
        "Developer.confirmContent.action/deactivate":"Are you sure want to deactivate this developer?",
        "Developer.success.action/activate":"Developer has successfully activated",
        "Developer.success.action/deactivate":"Developer has successfully deactivated",

        //Office
        "Office":"Office",
        "Office.basicInfo":"Basic Information",
        "Office.images":"Images",
        "Office.locationAndContact":"Location & Contact",
        "Office.administrationAndSEO":"Administration & SEO",
        "Office.name":"Name",
        "Office.address":"Address",
        "Office.city":"City",
        "Office.country":"Country",
        "Office.phone":"Phone",
        "Office.whatsApp":"WhatsApp",
        "Office.email":"E-mail",
        "Office.profilePicture":"Profile Picture",
        "Office.thumbnail":"Thumbnail",
        "Office.description":"Description",
        "Office.promoted":"Promoted",
        "Office.sequence":"Sequence",
        "Office.cleanUrl":"Clean URL",
        "Office.metaDescription":"Meta Description",
        "Office.socmeds":"Social Media",
        "Office.socmeds.type":"Type",
        "Office.socmeds.link":"Link",
        "Office.specializations":"Specializations",
        "Office.specializations.specialization":"Specialization",
        "Office.locations":"Location Scope",
        "Office.locations.location":"Location Name",
        "Office.state":"Status",
        "Office.activate":"Activate",
        "Office.deactivate":"Deactivate",
        "Office.confirmTitle.action/activate":"Activate",
        "Office.confirmTitle.action/deactivate":"Deactivate",
        "Office.confirmContent.action/activate":"Are you sure want to activate this office?",
        "Office.confirmContent.action/deactivate":"Are you sure want to deactivate this office?",
        "Office.success.action/activate":"Office has successfully activated",
        "Office.success.action/deactivate":"Office has successfully deactivated",

        //Agent
        "Agent":"Agent",
        "Agent.basicInfo":"Basic Information",
        "Agent.locationAndContact":"Locations & Contact",
        "Agent.images":"Images",
        "Agent.details":"Details",
        "Agent.administrationAndSEO":"Administration & SEO",
        "Agent.title":"Title",
        "Agent.name":"Name",
        "Agent.address":"Address",
        "Agent.office":"Office",
        "Agent.city":"City",
        "Agent.country":"Country",
        "Agent.photo":"Photo",
        "Agent.whatsApp":"WhatsApp",
        "Agent.email":"E-mail",
        "Agent.cleanUrl":"Clean URL",
        "Agent.description":"Description",
        "Agent.metaDescription":"Meta Description",
        "Agent.specializations":"Specializations",
        "Agent.specializations.specialization":"Specialization",
        "Agent.locations":"Location Scope",
        "Agent.locations.location":"Location Name",
        "Agent.socmeds":"Social Media",
        "Agent.socmeds.type":"Type",
        "Agent.socmeds.link":"Link",
        "Agent.state":"Status",
        "Agent.activate":"Activate",
        "Agent.deactivate":"Deactivate",
        "Agent.confirmTitle.action/activate":"Activate",
        "Agent.confirmTitle.action/deactivate":"Deactivate",
        "Agent.confirmContent.action/activate":"Are you sure want to activate this agent?",
        "Agent.confirmContent.action/deactivate":"Are you sure want to deactivate this agent?",
        "Agent.success.action/activate":"Agen successfully activated",
        "Agent.success.action/deactivate":"Agen successfully deactivated",

        //RealEstatePartner
        "RealEstatePartner":"Partner",
        "RealEstatePartner.basicInfo":"Basic Information",
        "RealEstatePartner.locationAndContact":"Location & Contact",
        "RealEstatePartner.images":"Images",
        "RealEstatePartner.content":"Content",
        "RealEstatePartner.administrationAndSEO":"Administration & SEO",
        "RealEstatePartner.name":"Name",
        "RealEstatePartner.address":"Address",
        "RealEstatePartner.city":"City",
        "RealEstatePartner.country":"Country",
        "RealEstatePartner.photo":"Photo",
        "RealEstatePartner.photos":"Photos",
        "RealEstatePartner.photos.title":"Title",
        "RealEstatePartner.photos.image":"Photo",
        "RealEstatePartner.thumbnail":"Thumbnail",
        "RealEstatePartner.description":"Description",
        "RealEstatePartner.promoted":"Promoted",
        "RealEstatePartner.sequence":"Sequence",
        "RealEstatePartner.title":"Title",
        "RealEstatePartner.whatsApp":"WhatsApp",
        "RealEstatePartner.cleanUrl":"Clean URL",
        "RealEstatePartner.metaDescription":"Meta Description",
        "RealEstatePartner.socmeds":"Social Media",
        "RealEstatePartner.socmeds.type":"Type",
        "RealEstatePartner.socmeds.link":"Link",
        "RealEstatePartner.phoneNumbers":"Phone Numbers",
        "RealEstatePartner.phoneNumbers.phone":"Phone",
        "RealEstatePartner.state":"Status",
        "RealEstatePartner.activate":"Activate",
        "RealEstatePartner.deactivate":"Deactivate",
        "RealEstatePartner.confirmTitle.action/activate":"Activate",
        "RealEstatePartner.confirmTitle.action/deactivate":"Deactivate",
        "RealEstatePartner.confirmContent.action/activate":"Are you sure want to activate this partner?",
        "RealEstatePartner.confirmContent.action/deactivate":"Are you sure want to deactivate this partner?",
        "RealEstatePartner.success.action/activate":"Partner has successfully activated",
        "RealEstatePartner.success.action/deactivate":"Partner has successfully deactivated",

        //RealEstateProject
        "RealEstateProject":"Real Estate Project",
        "RealEstateProject.basicInfo":"Basic Information",
        "RealEstateProject.location":"Location",
        "RealEstateProject.images":"Images",
        "RealEstateProject.content":"Content",
        "RealEstateProject.administrationAndSEO":"Administration & SEO",
        "RealEstateProject.name":"Name",
        "RealEstateProject.address":"Address",
        "RealEstateProject.city":"City",
        "RealEstateProject.developer":"Pengembang",
        "RealEstateProject.country":"Country",
        "RealEstateProject.photo":"Photo",
        "RealEstateProject.photos":"Photos",
        "RealEstateProject.photos.title":"Title",
        "RealEstateProject.photos.image":"Photo",
        "RealEstateProject.thumbnail":"Thumbnail",
        "RealEstateProject.description":"Description",
        "RealEstateProject.promoted":"Promoted",
        "RealEstateProject.prime":"Primary",
        "RealEstateProject.sequence":"Sequence",
        "RealEstateProject.cleanUrl":"Clean URL",
        "RealEstateProject.metaDescription":"Meta Description",
        "RealEstateProject.state":"Status",
        "RealEstateProject.activate":"Activate",
        "RealEstateProject.deactivate":"Deactivate",
        "RealEstateProject.confirmTitle.action/activate":"Activate",
        "RealEstateProject.confirmTitle.action/deactivate":"Deactivate",
        "RealEstateProject.confirmContent.action/activate":"Are you sure want to activate this project?",
        "RealEstateProject.confirmContent.action/deactivate":"Are you sure want to deactivate this project?",
        "RealEstateProject.success.action/activate":"Project has successfully activated",
        "RealEstateProject.success.action/deactivate":"Project has successfully deactivated",
    
        "Property":"Property",
        "Property.mainInfo":"Main Information",
        "Property.specification":"Specifications",
        "Property.ownerInfo":"Owner Information",
        "Property.otherInfo":"Other Information",
        "Property.location":"Location",
        "Property.searchLocation":"Search Location",
        "Property.photos":"Images & Videos",
        "Property.administration":"Administration",
        "Property.name":"Name",
        "Property.code":"Code",
        "Property.collectionTitle":"Collection Title",
        "Property.placeId":"Place ID",
        "Property.latitude":"Latitude",
        "Property.longitude":"Longitude",
        "Property.transactionType":"Transaction Type",
        "Property.type":"Property Type",
        "Property.type.x":"Type",
        "Property.price":"Price",
        "Property.unitPrice":"Unit Price",
        "Property.rentTimeSpan":"Rent Timespan",
        "Property.surfaceArea":"Surface Area",
        "Property.buildingArea":"Building Area",
        "Property.bedroom":"Bedroom",
        "Property.bathroom":"Bathroom",
        "Property.assistantBedroom":"Assistant Bedroom",
        "Property.assistantBathroom":"Assistanr Bathroom",
        "Property.furniture":"Furniture",
        "Property.direction":"Direction",
        "Property.entranceWidth":"Entrance Width",
        "Property.yearBuilt":"Year Built",
        "Property.garage":"Garage",
        "Property.garageArea":"Garage Area",
        "Property.carport":"Carport",
        "Property.certificate":"Certificate Type",
        "Property.description":"Description",
        "Property.metaDescription":"Meta Deskription",
        "Property.shortDescription":"Short Description",
        "Property.agent":"Agent",
        "Property.colistiongAgent":"Colistiong Agent",
        "Property.developer":"Developer",
        "Property.cleanUrl":"Clean URL",
        "Property.features":"Features",
        "Property.waterSource":"Water Source",
        "Property.electricalCapacity":"Electrical Capacity",
        "Property.pictures":"Images & Videos",
        "Property.videoTourLink":"Video Tour Link (Youtube)",
        "PropertyPicture.description":"Description",
        "PropertyPicture.sequence":"Sequence",
        "PropertyPicture.thumbnail":"Thumbnail",
        "PropertyPicture.externalLink":"External Link",
        "Property.pjpNumber":"PJP Number",
        "Property.pjpDate":"PJP Date",
        "Property.pjpValidityPeriod":"PJP Validity Period (Days)",
        "Property.pjpExpireDate":"PJP Expiry Date",
        "Property.pjpHistory":"PJP History",
        "Property.ownerName":"Owner Name",
        "Property.ownerAddress":"Owner Address",
        "Property.ownerPhone":"Owner Phone",
        "Property.state":"Status",
        "Property.available":"Available",
        "Property.sold":"Sold",
        "Property.cancelled":"Cancel",
        "Property.listingDate":"Listing Date",
        "Property.confirmTitle.action/available":"Available",
        "Property.confirmTitle.action/sold":"Sold",
        "Property.confirmTitle.action/cancelled":"Cancel",
        "Property.confirmContent.action/available":"Set this property as available?",
        "Property.confirmContent.action/sold":"Set this property as sold?",
        "Property.confirmContent.action/cancelled":"Are you sure want to cancel this property?",
        "Property.success.action/available":"Property has been set to available",
        "Property.success.action/sold":"Property has been set to sold",
        "Property.success.action/cancelled":"Property has successfully cancelled",

        //PropertyFeature
        "PropertyFeature":"Property Feature",
        "PropertyFeature.name":"Name",
        "PropertyFeature.icon":"Icon",
        "PropertyFeature.code":"Code",
        "PropertyFeature.sequence":"Sequence",
        "PropertyFeature.state":"Status",
        "PropertyFeature.activate":"Activate",
        "PropertyFeature.deactivate":"Deactivate",
        "PropertyFeature.confirmTitle.action/activate":"Activate",
        "PropertyFeature.confirmTitle.action/deactivate":"Deactivate",
        "PropertyFeature.confirmContent.action/activate":"Are you sure want to activate this feature?",
        "PropertyFeature.confirmContent.action/deactivate":"Are you sure want to deactivate this feature?",
        "PropertyFeature.success.action/activate":"Property feature has successfully activated",
        "PropertyFeature.success.action/deactivate":"Property feature has successfully deactivated",

        //Property Collection
        "PropertyCollection":"Property Collection",
        "PropertyCollection.title":"Title",
        "PropertyCollection.state":"Status",
        "PropertyCollection.cleanUrl":"Clean URL",
        "PropertyCollection.category":"Category",
        "PropertyCollection.description":"Description",
        "PropertyCollection.content":"Content",
        "PropertyCollection.metaDescription":"Meta Description",
        "PropertyCollection.menu":"Menu",
        "PropertyCollection.sequence":"Sequence",
        "PropertyCollection.activate":"Activate",
        "PropertyCollection.deactivate":"Deactivate",
        "PropertyCollection.lines":"Property List",
        "PropertyCollection.line.property":"Property",
        "PropertyCollection.line.sequence":"Sequence",
        "PropertyCollection.confirmTitle.action/activate":"Activate",
        "PropertyCollection.confirmTitle.action/deactivate":"Deactivate",
        "PropertyCollection.confirmContent.action/activate":"Are you sure want to activate this collection?",
        "PropertyCollection.confirmContent.action/deactivate":"Are you sure want to deactivate this collection?",
        "PropertyCollection.success.action/activate":"Property collection has successfully activated",
        "PropertyCollection.success.action/deactivate":"Property collection has successfully deactivated",

        //Top Listing
        "TopListing":"Top Listing",
        "TopListing.name":"Name",
        "TopListing.code":"Code",
        "TopListing.lines":"List",
        "TopListing.line.property":"Property",
        "TopListing.line.sequence":"Sequence",
        "TopListing.state":"Status",
        "TopListing.activate":"Activate",
        "TopListing.deactivate":"Deactivate",
        "TopListing.confirmTitle.action/activate":"Activate",
        "TopListing.confirmTitle.action/deactivate":"Deactivate",
        "TopListing.confirmContent.action/activate":"Are you sure want to activate this property list?",
        "TopListing.confirmContent.action/deactivate":"Are you sure want to deactivate this property list?",
        "TopListing.success.action/activate":"Property list has successfully activated",
        "TopListing.success.action/deactivate":"Property list has successfully deactivated",

        //Top Listing
        "TopAgent":"Top Agent",
        "TopAgent.name":"Name",
        "TopAgent.code":"Code",
        "TopAgent.lines":"List",
        "TopAgent.line.agent":"Agent",
        "TopAgent.line.sequence":"Sequence",
        "TopAgent.state":"Status",
        "TopAgent.activate":"Activate",
        "TopAgent.deactivate":"Deactivate",
        "TopAgent.confirmTitle.action/activate":"Activate",
        "TopAgent.confirmTitle.action/deactivate":"Deactivate",
        "TopAgent.confirmContent.action/activate":"Are you sure want to activate this agent list?",
        "TopAgent.confirmContent.action/deactivate":"Are you sure want to deactivate this agent list?",
        "TopAgent.success.action/activate":"Agent list has successfully activated",
        "TopAgent.success.action/deactivate":"Agent list has successfully deactivated",

        //Predefined Location
        "PredefinedLocation":"Predefined Location",
        "PredefinedLocation.name":"Name",
        "PredefinedLocation.code":"Code",
        "PredefinedLocation.state":"Status",
        "PredefinedLocation.activate":"Activate",
        "PredefinedLocation.deactivate":"Deactivate",
        "PredefinedLocation.confirmTitle.action/activate":"Activate",
        "PredefinedLocation.confirmTitle.action/deactivate":"Deactivate",
        "PredefinedLocation.confirmContent.action/activate":"Are you sure want to activate this location?",
        "PredefinedLocation.confirmContent.action/deactivate":"Are you sure want to deactivate this location?",
        "PredefinedLocation.success.action/activate":"location has successfully activated",
        "PredefinedLocation.success.action/deactivate":"location list has successfully deactivated",

        "CollectionCategory":"Collection Category",
        "CollectionCategory.name":"Name",
        "CollectionCategory.parentId":"Parent",
        "CollectionCategory.code":"Code",
        "CollectionCategory.cleanUrl":"Clean URL",
        "CollectionCategory.sequence":"Sequence",
        "CollectionCategory.parent":"Parent",
        "CollectionCategory.state":"State",
        "CollectionCategory.image":"Image",
        "CollectionCategory.thumbnail":"Thumbnail",
        "CollectionCategory.activate":"Activate",
        "CollectionCategory.deactivate":"Deactivate",
        "CollectionCategory.confirmContent.action/activate":"Are you sure want to activate this collection category?",
        "CollectionCategory.confirmContent.action/deactivate":"Are you sure want to deactivate this collection category?",
        "CollectionCategory.success.action/activate":"Collection category has successfully activated",
        "CollectionCategory.success.action/deactivate":"Collection category has successfully deactivated",

        //referral
        "Referral":"Referral",
        "Referral.code":"Code",
        "Referral.name":"Name",
        "Referral.type":"Type",
        "Referral.adType":"Ad type",
        "Referral.site":"Website / App",
        "Referral.partner":"Partner",
        "Referral.description":"Description",
        "Referral.state":"Status",
        "Referral.activate":"Activate",
        "Referral.deactivate":"Deactivate",
        "Referral.confirmContent.action/activate":"Are you sure want to activate this referral code?",
        "Referral.confirmContent.action/deactivate":"Are you sure want to deactivate this referral code?",
        "Referral.success.action/activate":"Referal code has successfully activated",
        "Referral.success.action/deactivate":"Referal code has successfully deactivated",


        "ReferralVisit":"Referral Visit",
        "ReferralVisit.dateTime":"Date & Time",
        "ReferralVisit.url":"URL",
        "ReferralVisit.referral":"Referral",
        "ReferralVisit.previousVisit":"Previous Visit",
        "ReferralVisit.conversionValue":"Conversion Value",
        "ReferralVisit.conversionQuantity":"Conversion Quantity",

        //Site
        "Site":"Site",
        "Site.name":"Name",
        "Site.url":"URL",
        "Site.activate":"Activate",
        "Site.deactivate":"Deactivate",
        "Site.confirmContent.action/activate":"Are you sure want to activate this site?",
        "Site.confirmContent.action/deactivate":"Are you sure want to deactivate this site?",
        "Site.success.action/activate":"Site has successfully activated",
        "Site.success.action/deactivate":"Site has successfully deactivated",

        //options & states
        "available":"Available",
        "sold":"Sold",
        "cancelled":"Cancelled",
        "rent":"Rent",
        "sell":"Sell",
        "house":"House", 
        "apartment":"Apartment", 
        "condo":"Condominium", 
        "land":"Land", 
        "villa":"Villa", 
        "shop_house":"Shop House", 
        "office":"Office", 
        "factory":"Factory", 
        "warehouse":"Warehouse", 
        "business_space":"Business Space",
        "shm":"SHM",
        "hgb":"HGB",
        "mr":"Mr.",
        "mrs":"Mrs.",
        "pdam":"PDAM",
        "well":"Well",
        "other":"Other",
        //socmeds
        "facebook":"Facebook",
        "twitter":"Twitter",
        "instagram":"Instagram",
        "linkedin":"LinkedIn",
        "youtube":"YouTube",
        //rent time span
        "year":"Annual",
        "month":"Monthly",
        "week":"Weekly",
        "day":"Daily",
        "m2":"m²",
        "unit":"unit",
        "north":"North",
        "northeast":"Northeast",
        "east":"East", 
        "southeast":"Southeast",
        "south":"South", 
        "southwest":"Southwest",
        "west":"West",
        "northwest":"Northwest",
        "hook":"Hook",
        "furnished":"Furnished",
        "semi_furnished":"Semi-furnished",
        "non_furnished":"Non-furnished",
    }
}

export default translation