import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Hr/reports/EmployeeStatisticReport/HumanCapitalReportEmploymentStatisticAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class HumanCapitalReportEmploymentStatisticActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Hr/reports/EmployeeStatisticReport/HumanCapitalReportEmploymentStatistic")
	}

	render() {
		return (
			<Activity 
				name="HumanCapitalReportEmploymentStatistic"
				RenderedComponent={this.RenderedComponent}
				context="HumanCapitalReportEmploymentStatistic"
				action="HumanCapitalReportEmploymentStatistic"
				formActions={FormAction}			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(HumanCapitalReportEmploymentStatisticActivity)
