import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
//import moment from 'moment'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import routes from "routes.js";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

import register from "assets/img/register.jpeg";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import Config from "company/Config";

//redux
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

import DebugTool from "framework/inputs/DebugTool";

class Pages extends React.Component {
  constructor(props) {
    super(props);
    let accessToken = Cookies.get("accessToken");
    let refreshToken = Cookies.get("refreshToken");
    this.defaultPage = null;
    if (Config.saasConfig) {
      this.defaultPage = props.Session.defaultPage;
    } else {
      this.defaultPage = Config.defaultPage;
    }

    if (accessToken && refreshToken) {
      this.props.history.push(this.defaultPage);
    } else if (!accessToken && refreshToken) {
      axios
        .post(props.Session.host + "/api/oauth/token", {
          refresh_token: Cookies.get("refreshToken"),
        })
        .then((response) => {
          Cookies.set("accessToken", response.data.accessToken, {
            path: "/",
            expires: moment.unix(response.data.atExpiredAt).toDate(),
          });
          Cookies.set("refreshToken", response.data.refreshToken, {
            path: "/",
            expires: moment.unix(response.data.rtExpiredAt).toDate(),
          });
        })
        .then(() => {
          this.props.history.push(this.defaultPage);
        });
    }
  }

  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            // match={this.props.match}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBgImage = (renderedConfig) => {
    if (window.location.pathname.indexOf("/auth/login") !== -1) {
      return renderedConfig.loginBackground;
    } else if (window.location.pathname.indexOf("/auth/recovery") !== -1) {
      return register;
    } else {
      return register;
    }
  };

  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {
    const { classes, ...rest } = this.props;
    let renderedConfig = {
      loginBackground: Config.saasConfig
        ? this.props.Session.loginBackground || null
        : Config.loginBackground || null,
    };

    return (
      <div>
        {Config.debug && <DebugTool />}
        <AuthNavbar brandText={this.getActiveRoute(routes)} {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage}
            style={{
              backgroundImage: "url(" + this.getBgImage(renderedConfig) + ")",
            }}
          >
            <Switch>{this.getRoutes(routes)}</Switch>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(pagesStyle)(Pages)),
);
