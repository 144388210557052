import store from "store";

export function checkAccessRights(accessRight) {
  let state = store.getState();
  let allAccessRights = state.Session.accessRights;
  if (allAccessRights.indexOf(accessRight)) {
    return true;
  } else {
    return false;
  }
}

export function getAccessRights(model) {
  let state = store.getState();
  let accessRights = state.Session.accessRights;
  let modelAccessRights = [];
  accessRights.map((rights) => {
    var splitted = rights.split(".");
    if (splitted[0] === model) {
      modelAccessRights.push(rights);
    }
  });
  return modelAccessRights;
}

export function can(toDo) {
  if (store.getState().Session.accessRights.indexOf(toDo) > -1) {
    return true;
  }
  return false;
}
