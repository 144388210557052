import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import AsterisLogo from "assets/img/logos/logo.svg";

const styles = {
  page: {
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class PageNotFound extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.page}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={AsterisLogo}
            style={{
              height: "45px",
              width: "auto",
              margin: "10px 0px",
              justifyContent: "center",
            }}
          />
          <div style={{ textAlign: "center" }}>
            {tl(Cookies.get("lang"), "domainNotFound")}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(PageNotFound);
