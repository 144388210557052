const Translations = {
	id:{
		"shop":"shop",
		//Item Category
		"AccountingConfigurationItemCategory":"Kategori Barang",
		"ItemCategory":"Kategori Barang",
		"item_category":"Kategori Barang",
		"item_category.activate":"Aktifkan",
		"ItemCategory.confirmTitle.action/activate":"Aktifkan",
		"ItemCategory.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Kategori Barang ini?",
		"ItemCategory.success.activate":"Kategori Barang telah berhasil diaktifkan",
		"item_category.deactivate":"Nonaktifkan",
		"ItemCategory.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemCategory.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Kategori Barang ini?",
		"ItemCategory.success.deactivate":"Kategori Barang telah berhasil dinonaktifkan",
		"ItemCategory.code":"Kode",
		"ItemCategory.name":"Nama",
		"ItemCategory.cleanUrl":"Clean URL",
		"ItemCategory.parentId":"Kategori Induk",
		"ItemCategory.parent":"Kategori Induk",
		"ItemCategory.image":"Gambar",
		"ItemCategory.thumbnail":"Cuplikan",
		"ItemCategory.state":"Status",
		"ItemCategory.hideOnReport":"Sembunyikan Dari Laporan",
		"ItemCategory.saleableOnline":"Dapat Dijual Online",
		//Item attachment
		"ItemAttachment.title":"Judul",
		"ItemAttachment.attachment":"Lampiran",
		//Tag
		"AccountingConfigurationTag":"Tag",
		"Tag":"Tag",
		"tag":"Tag",
		"tag.activate":"Aktifkan",
		"Tag.confirmTitle.activate":"Aktifkan",
		"Tag.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Tag ini?",
		"Tag.success.activate":"Tag telah berhasil diaktifkan",
		"tag.deactivate":"Nonaktifkan",
		"Tag.confirmTitle.deactivate":"Nonaktifkan",
		"Tag.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Tag ini?",
		"Tag.success.deactivate":"Tag telah berhasil dinonaktifkan",
		"Tag.name":"Nama",
		"Tag.image":"Gambar",
		"Tag.thumbnail":"Cuplikan",
		"Tag.state":"Status",
		//Shopping Cart
		"ShoppingCart":"Keranjang Belanja",
		"shopping_cart":"Keranjang Belanja",
		"shopping_cart.cancel":"Batalkan",
		"ShoppingCart.confirmTitle.cancel":"Batalkan",
		"ShoppingCart.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Keranjang Belanja ini?",
		"ShoppingCart.success.cancel":"Keranjang Belanja telah berhasil dibatalkan",
		"ShoppingCart.name":"Nama",
		"ShoppingCart.date":"Tanggal",
		"ShoppingCart.currencyId":"Mata Uang",
		"ShoppingCart.currency":"Mata Uang",
		"ShoppingCart.temporarySessionID":"Sesi Sementara",
		"ShoppingCart.lines":"Daftar Barang",
		"ShoppingCart.userId":"Pelanggan",
		"ShoppingCart.user":"Pelanggan",
		"ShoppingCart.state":"Status",
		//Shopping Cart Line
		"ShoppingCartLine":"Item Keranjang Belanja",
		"shopping_cart_line":"Item Keranjang Belanja",
		"ShoppingCartLine.name":"Nama",
		"ShoppingCartLine.itemId":"Barang",
		"ShoppingCartLine.item":"Barang",
		"ShoppingCartLine.additionalCharges":"Biaya Tambahan",
		"ShoppingCartLine.quantity":"Jumlah",
		"ShoppingCartLine.price":"Harga",
		"ShoppingCartLine.total":"Total",
		//Slideshow
		"SlideShow":"Slideshow",
		"slide_show":"Slideshow",
		"slide_show.activate":"Aktifkan",
		"SlideShow.confirmTitle.action/activate":"Aktifkan",
		"SlideShow.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Slideshow ini?",
		"SlideShow.success.action/activate":"Slideshow telah berhasil diaktifkan",
		"slide_show.deactivate":"Nonaktifkan",
		"SlideShow.confirmTitle.action/deactivate":"Nonaktifkan",
		"SlideShow.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Slideshow ini?",
		"SlideShow.success.action/deactivate":"Slideshow telah berhasil dinonaktifkan",
		"SlideShow.code":"Kode",
		"SlideShow.name":"Nama",
		"SlideShow.lines":"Daftar Slide",
		"SlideShow.state":"Status",
		//Shopping Cart Line
		"SlideShowLine":"Item Keranjang Belanja",
		"slide_show_line":"Item Keranjang Belanja",
		"SlideShowLine.title":"Judul",
		"SlideShowLine.description":"Deskripsi",
		"SlideShowLine.url":"URL",
		"SlideShowLine.itemId":"Barang",
		"SlideShowLine.item":"Barang",
		"SlideShowLine.image":"Gambar",
		"SlideShowLine.sequence":"Urutan",
		"SlideShowLine.thumbnail":"Cuplikan",
		//Online Shop Policy
		"ShopPolicy":"Kebijakan Toko Online",
		"shop_policy":"Kebijakan Toko Online",
		"ShopPolicy.name":"Nama",
		"ShopPolicy.value":"Nilai",
		//Slideshow
		"highlight.activate":"Aktifkan",
		"Highlight.confirmTitle.action/activate":"Aktifkan",
		"Highlight.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Slideshow ini?",
		"Highlight.success.action/activate":"Slideshow telah berhasil diaktifkan",
		"highlight.deactivate":"Nonaktifkan",
		"Highlight.confirmTitle.action/deactivate":"Nonaktifkan",
		"Highlight.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Slideshow ini?",
		"Highlight.success.action/deactivate":"Slideshow telah berhasil dinonaktifkan",
		"Highlight.name":"Nama",
		"Highlight.code":"Kode",
		"Highlight.lines":"Daftar Barang",
		"Highlight.state":"Status",
		//Shopping Cart Line
		"HighlightLine":"Item Keranjang Belanja",
		"highlight_line":"Item Keranjang Belanja",
		"HighlightLine.itemId":"Barang",
		"HighlightLine.item":"Barang",
		"HighlightLine.sequence":"Urutan",
		//Item Option
		"ItemOption":"Pilihan Barang",
		"item_option":"Pilihan Barang",
		"ItemOption.name":"Nama",
		"ItemOption.state":"Status",
		"ItemOption.activate":"Aktifkan",
		"ItemOption.confirmTitle.action/activate":"Aktifkan",
		"ItemOption.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/activate":"Piilihan Barang telah berhasil diaktifkan",
		"ItemOption.deactivate":"Nonaktifkan",
		"ItemOption.confirmTitle.action/deactivate":"Nonaktifkan",
		"ItemOption.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Pilihan Barang ini?",
		"ItemOption.success.action/deactivate":"Pilihan Barang telah berhasil dinonaktifkan",
		"ItemOptionLine.option":"Pilihan",
		"ItemOptionLine.sequence":"Urutan",
		"ItemOptionLine.price":"Harga",

		//sales order
		"SalesOrder.notes":"Catatan",
		
		//MENUS
		"Shop":"Toko",
		"ShopConfiguration":"Konfigurasi Toko",
		"ShopItem":"Barang Toko Online",
		"ShopItem.defaultPrice":"Harga",
		"ShopItem.variantPrices":"Varian Harga",
		"Shopping Cart":"Keranjang Belanja",
		"ShopShoppingCart":"Keranjang Belanja",
		"Highlight":"Sorotan",
		"ShopHighlight":"Sorotan",
		"Slideshow":"Slideshow",
		"ShopSlideshow":"Slideshow",
		"Shop Policy":"Kebijakan Toko",
		"ShopShopPolicy":"Kebijakan Toko",
		"Item.metaDescription":"Deskripsi Meta",
		"Item.viewCount":"Jumlah View",
		//Item Attribute
		"ItemAttribute":"Atribut Barang",
		"item_attribute":"Atribut Barang",
		"ItemAttribute.name":"Atribut",
		"ItemAttribute.value":"Nilai",
		"ItemAttribute.itemId":"Barang",
		"ItemAttribute.item":"Barang",
		"ItemAttribute.sequence":"Urutan",
		"ItemAttribute.measurementUnit":"Satuan",

		//SERVICE GIG
		"Service" : "Layanan",
		"gig_admin":"Layanan",
		"Gig.name":"Nama Layanan",
		"Gig.state":"Status",
		"Gig.qty":"Jml",
		"Gig.subtotal":"Subtotal",

		"Gig.overview":"Ikhtisar",
		"Gig.pricing":"Harga",
		"Gig.desc":"Deskripsi",
		"Gig.requirements":"Kebutuhan",
		"Gig.gallery":"Galeri",

		"Gig.title":"Nama layanan",
		"Gig.slugURL":"Slug URL",
		"Gig.category":"Kategori",
		"Gig.serviceType":"Jenis Layanan",
		"Gig.metadata":"Metadata",
		"Gig.searchTags":"Cari Tag",
		"Gig.tagNotice":"Maks. 5 tag, Gunakan huruf dan angka saja",
		
		"Gig.scopePrice":"Cakupan harga",
		"Gig.package":"Paket",
		"Gig.basic":"Dasar",
		"Gig.standard":"Standar",
		"Gig.premium":"Premium",
		"Gig.nameOfPackage":"Nama Paket",
		"Gig.describePackage":"Deskripsi",
		"Gig.duration":"Durasi",
		"Gig.price":"Harga",

		"Gig.brieflyDescribe":"Paparkan tentang jasa layanan anda",
		"Gig.FAQ":"Pertanyaan umum (FAQ)",
		"Gig.addQnA":"Tambahkan pertanyaan & jawaban untuk pelanggan anda",
		"Gig.addFAQ":"Tambah Pertanyaan",
		"Gig.FAQQuestion":"Pertanyaan",
		"Gig.FAQAnswer":"Jawaban",
		"Gig.questionPlaceholder":"Tulis Pertanyaan (cth: 'Apakah anda menerjemahkan bahasa inggris?')",
		"Gig.answerPlaceholder":"Tulis Jawaban (cth:'Ya, saya bisa menerjemahkan bahasa inggris.')",
		"Gig.char":"Karakter",
		"Gig.FAQCancel":"Batal",
		"Gig.FAQAdd":"Tambah",
		"Gig.FAQDelete":"Hapus",
		"Gig.FAQUpdate":"Perbarui",

		"Gig.requirementTitle":"Beritahu pelangganmu apa saya yang dibutuhkan untuk memulai",
		"Gig.requirementSubtitle":"Susun instruksi dalam bentuk teks bebas, pilihan ganda, ataupun penggunggah berkas",
		"Gig.addRequirement":"Tambah Kebutuhan baru",
		"Gig.requirementDescription": "Deskripsi",
		"Gig.requirementAnswerType": "Tipe Jawaban",
		"Gig.requirementIsMandatory": "Wajib diisi",
		"Gig.addOptionalAnswer":"Tambah Jawaban Opsional",
		"Gig.allowMoreAnswers":"Bolehkan jawaban lebih dari satu untuk pertanyaan ini",
		"Gig.willSeeCheckboxes":"(pelanggan anda akan melihat kotak centang)",
		"Gig.requirement":"KEBUTUHAN",
		"Gig.requirementDescPlaceholder":"Contoh: Spesifikasi, dimensi, pedoman merk, atau bahan latar belakang",

		"Gig.galleryTitle":"Rancang galeri layanan anda",
		"Gig.gallerySubtitle":"Tambahkan konten yang berkesan untuk bersaing dengan kompetitor anda",
		"Gig.galleryPhotos":"Gambar layanan",
		"Gig.galleryVideos":"Video layanan",
		"Gig.galleryPhotosDesc":"Unggah gambar yang berkaitan dengan layanan anda",
		"Gig.galleryVideosDesc":"Tambah video yang relevan dan berkualitas tinggi.",
		"Gig.galleryPDF":"Berkas PDF layanan",
		"Gig.galleryPDFDesc":"Kami hanya merekomendasikan anda untuk menambahkan berkas PDF bila membutuhkan penyelasan lebih lanjut mengenai layanan anda",
		"Gig.browserUnsupportedVideoTag":"Peramban anda tidak mendukung pratinjau video.",

		"Gig.untitled":"Tidak ada judul",
		"Gig.mandatory":"Dibutuhkan",
		"Gig.addExtraServices":"Tambah Layanan Tambahan",
		"Gig.extraFastDelivery":"Layanan Ekstra Cepat",
		"Gig.forAnExtra":"untuk tambahan",
		"Gig.andAdditional":"Akan dikirimkan dalam",
		"Gig.iWillDeliver":"Akan dikirimkan dalam",
		"Gig.revision":"Revisi",
		"Gig.revisionExtraDays":"Waktu Tambahan Revisi",
		"Gig.addGigExtra":"Layanan Tambahan Baru",
		"Gig.saveAndContinue":"Simpan & Lanjut",
		"Gig.days":"%s hari",



		//state changes
		"Gig.approve":"Setujui",
		"Gig.activate":"Aktifkan",
		"Gig.deactivate":"Nonaktifkan",
		"Gig.requires_modification":"Butuh Perubahan",
		"Gig.deny":"Tolak",
		"Gig.pause":"Hentikan Sementara",
		"Gig.reason":"Alasan",
		//editing
		"Gig.cancel":"Batal",
		"Gig.add":"Tambah",
		"Gig.delete":"Hapus",
		"Gig.update":"Perbarui",
		"Gig.browse":"Buka Folder",

		"Gig.confirmTitle.action/approve":"Setujui",
		"Gig.confirmTitle.action/activate":"Aktifkan",
		"Gig.confirmTitle.action/deactivate":"Nonaktifkan",
		"Gig.confirmTitle.action/requires_modification":"Butuh Perubahan",
		"Gig.confirmTitle.action/denied":"Tolak",
		"Gig.confirmTitle.action/paused":"Hentikan Sementara",

		"Gig.confirmContent.action/approve":"Apa Anda yakin akan menyetujui layanan ini?",
		"Gig.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan layanan ini?",
		"Gig.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan layanan ini?",
		"Gig.confirmContent.action/requires_modification":"Apa Anda yakin akan memberi tahu bahwa layanan ini membutuhkan perubahan kepada penyedia layanan?",
		"Gig.confirmContent.action/denied":"Apa Anda yakin akan menolak layanan ini",
		"Gig.confirmContent.action/paused":"Apa Anda yakin akan menghentikan sementara layanan ini?",

		"Gig.success.action/approve":"Layanan ini sudah berhasil disetujui",
		"Gig.success.action/activate":"Layanan ini sudah berhasil diaktikan",
		"Gig.success.action/deactivate":"Layanan ini sudah berhasil dinonaktifkan",
		"Gig.success.action/requires_modification":"Layanan ini sudah di set sebagai 'Butuh Perubahan'",
		"Gig.success.action/denied":"Layanan ini berhasil ditolak",
		"Gig.success.action/paused":"Layanan ini berhasil dihentikan sementara",
		
		//Service Prices
		"ServicePrice":"Harga",
		"price":"Harga",
		"ServicePrice.name":"Nama",
		"ServicePrice.value":"Nilai",

		//Service Content
		"ServiceContent":"Opsi Layanan",
		"content":"Konten",
		"ServiceContent.attributeName":"Nama Atribut",
		"ServiceContent.attributeType":"Jenis Atribut",
		"ServiceContent.title":"Judul",
		"ServiceContent.comment":"Komentar",
		"ServiceContent.expectedDuration":"Durasi yang diharapkan",
		"ServiceContent.editType":"Jenis Editor",
		"ServiceContent.description":"Deskripsi",
		"ServiceContent.subCategory":"Sub-kategori",
		"ServiceContent.type":"Tipe",

		//Sub Category Contents
		"ServiceSubCategoryContents":"Konfigurasi Kategori",
		"ServiceSubCategoryContents.category":"Kategori",
		"ServiceSubCategoryContents.subCategory":"Sub-Kategori",
		"ServiceSubCategoryContents.serviceType":"Tipe Layanan",
		"ServiceSubCategoryContents.contents":"Opsi Paket",
		"ServiceSubCategoryContents.packages":"Paket",
		"ServiceSubCategoryContents.sequence":"Urutan",
		"ServiceSubCategoryContents.maxQuantity":"Jumlah Maks",
		"ServiceSubCategoryContents.revisable":"Dapat direvisi?",
		"ServiceSubCategoryContents.activate":"Aktifkan",
		"ServiceSubCategoryContents.deactivate":"Nonaktifkan",
		"CatContentsPackageLine.name":"Nama Paket",
		"CatContentsPackageLine.sequence":"Urutan",
		"CatContentsPackageLine.minPrice":"Min. Harga",
		"CatContentsPackageLine.maxPrice":"Maks. Harga",
		"CatContentsPackageLine.roundTo":"Dibulatkan ke",

		//service type
		"ServiceType":"Tipe Layanan",
		"ServiceType.name":"Nama",
		"ServiceType.category":"Kategori",
		"ServiceType.subCategory":"Sub-kategori",

		//Service Medatadas
		"ServiceMetadata":"Metadata",
		"ServiceMetadata.name":"Nama",
		"ServiceMetadata.title":"Judul",
		"ServiceMetadata.description":"Deskripsi",
		"ServiceMetadata.category":"Kategori",
		"ServiceMetadata.subCategoryId":"Sub-Kategori",
		"ServiceMetadata.isMandatory":"Wajib?",
		"ServiceMetadataLines.name":"Nama",
		"ServiceMetadataLines.value":"Nilai",
		"ServiceMetadataLines.alias":"Alias",
		"ServiceMetadata.lines":"Daftar Metadata",

		"ServiceMetadata.activate":"Aktifkan",
		"ServiceMetadata.deactivate":"Nonaktifkan",

		// Service Occupation
		"ServiceOccupation":"Pekerjaan",
		"ServiceOccupation.name":"Nama Pekerjaan",
		"ServiceOccupation.expertises":"Keahlian",
		"ServiceOccupationExpertises.name":"Nama Keahlian",

		// Service Order
		"ServiceOrder":"Pesanan Jasa",
		"order_admin":"Pesanan Jasa",
		"ServiceOrder.name":"No. Order",
		"ServiceOrder.total":"Total",
		"ServiceOrder.state":"Status",
		"ServiceOrder.seller":"Penyedia Jasa",
		"ServiceOrder.buyer":"Pelanggan",
		"ServiceOrder.orderNo":"No. Order",
		"ServiceOrder.viewInvoice":"Lihat Faktur",
		"ServiceOrder.orderRequirements":"Persyaratan Pesanan",
		"ServiceOrder.youFilledRequirement":"Telah diisi pelanggan",
		"ServiceOrder.seeRequirement":"Lihat Persyaratan",
		"ServiceOrder.orderStatus":"Status Pesanan",
		"ServiceOrder.orderHasBeenDelivered":"Pesanan sudah dikirimkan",
		"ServiceOrder.messageFromSeller":"Pesan dari Penyedia Jasa",
		"ServiceOrder.noMessage":"Tidak ada pesan",
		"ServiceOrder.noDescription":"Tidak ada deskripsi",
		"ServiceOrder.cancellationReason":"Alasan pembatalan",
		"ServiceOrder.deliveryDue":"Batas Waktu Pengiriman",

		// Order Request
		"OrderRequest":"Permintaan Order",
		"OrderRequest.username":"Nama Pengguna",
		"OrderRequest.user":"Pengguna",
		"OrderRequest.date":"Tanggal",
		"OrderRequest.description":"Deskripsi",
		"OrderRequest.category":"Kategori",
		"OrderRequest.subCategory":"Sub kategori",
		"OrderRequest.duration":"Durasi",
		"OrderRequest.budget":"Budget",
		"OrderRequest.offer":"Penawaran",
		"OrderRequest.offers":"Penawaran",
		"OrderRequest.attachment":"Lampiran",
		"OrderRequest.state":"Status",
		"OrderRequest.approve":"Setujui",
		"OrderRequest.reject":"Tolak",
		"OrderRequest.confirmTitle.action/approve":"Setujui",
		"OrderRequest.confirmContent.action/approve":"Apakah Anda yakin akan menyetujui permintaan order ini?",
		"OrderRequest.success.action/approve":"Permintaan order berhasil disetujui",
		"OrderRequest.confirmTitle.action/reject":"Tolak",
		"OrderRequest.confirmContent.action/reject":"Apakah Anda yakin akan menolak permintaan order ini?",
		"OrderRequest.success.action/reject":"Permintaan order berhasil ditolak",
		
		//RATING TEMPLATE
		"RatingTemplate":"Template Penilaian",
		"RatingTemplate.name":"Nama",
		"RatingTemplate.category":"Kategori",
		"RatingTemplate.subCategory":"Subkategori",
		"RatingTemplate.serviceType":"Tipe Layanan",
		"RatingTemplate.state":"Status",
		"RatingTemplate.ratings":"Template Penilaian",
		"RatingTemplate.reviews":"Template Ulasan",
		"RatingTemplateLine.name":"Nama",
		"RatingTemplateLine.label":"Label",
		"RatingTemplateLine.position":"Posisi",
		"RatingTemplate.activate":"Aktifkan",
		"RatingTemplate.deactivate":"Nonaktifkan",
		"RatingTemplate.confirmTitle.action/activate":"Aktifkan",
		"RatingTemplate.confirmTitle.action/deactivate":"Nonaktifkan",
		"RatingTemplate.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan Template Penilaian ini?",
		"RatingTemplate.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan Template Penilaian ini?",
		"RatingTemplate.success.action/activate":"Template Penilaian sukses diaktifkan",
		"RatingTemplate.success.action/deactivate":"Template Penilaian sukses dinonaktifkan",

		//AccountingInventoryValuationReport
		

		//Marketplace
		"Marketplace":"Marketplace",
		"Marketplace.name":"Nama",
		"Marketplace.url":"URL",
		"Marketplace.icon":"Icon",
		"MarketplaceLine.marketplace":"Marketplace",
		"MarketplaceLine.url":"URL",
		"MarketplaceLine.sequence":"Urutan",
		"Marketplace.backgroundColor":"Warna Latar",
		"Marketplace.textColor":"Warna Teks",

		//Variant & choices
		"Variant":"Varian",
		"Variant.name":"Nama",
		"Variant.code":"Kode",
		"Variant.itemCategory":"Kategori Barang",
		"Variant.choices":"Daftar Pilihan",
		"Variant.state":"Status",
		"ChoicesLine.name":"Nama",
		"ChoicesLine.code":"Kode",
		"ChoicesLine.color":"Warna",
		"ChoicesLine.image":"Gambar",
		"ChoicesLine.sequence":"Urutan",
		"VariantLine.variant":"Varian",
		"VariantLine.choices":"Pilihan",
		"VariantLine.sequence":"Urutan",

		// Item Import
		"ShopItemImport":"Impor Data Barang",
		"ShopItemImport.file":"Berkas Excel (.xlsx)",
		"ShopItemImport.upload":"Unggah",
		"ShopItemImport.uploadSuccess":"Berkas telah berhasil diunggah",
		"ShopItemImport.uploadFailed":"Berkas gagal diunggah, silakan periksa kembali koneksi Anda",

		//Courier
		"Courier":"Kurir",
		"country":"Kurir",
		"Courier.activate":"Aktifkan",
		"Courier.confirmTitle.action/activate":"Aktifkan",
		"Courier.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kurir ini?",
		"Courier.success.action/activate":"Kurir berhasil diaktifkan",
		"Courier.deactivate":"Nonaktifkan",
		"Courier.confirmTitle.action/deactivate":"Nonaktifkan",
		"Courier.confirmContent.action/deactivate":"Apa Anda yakin akan mengaktifkan kurir ini?",
		"Courier.success.action/deactivate":"Kurir berhasil dinonaktifkan",
		"Courier.name":"Nama",
		"Courier.trackable":"Dapat Dilacak",
		"Courier.urlPrefix":"Prefiks URL",
		"Courier.urlSuffix":"Sufiks URL",
		"Courier.state":"Status",

		//OPTIONS
		"active":"Aktif",
		"cancelled":"Dibatalkan",
		"inactive":"Nonaktif",
		"complete":"Selesai",
		"draft":"Draf",
		"denied":"Ditolak",
		"pending_approval":"Menunggu Persetujuan",
		"requires_modification":"Butuh Perubahan",
		"paused":"Dijeda",
		"package":"Paket",
		"additional_service":"Layanan Tambahan",
		"checkbox":"Checkbox",
		"number":"Number",
		"dropdown":"Dropdown",
		"modification":"Modifikasi",
		"quantity":"Kuantitas",
		"awaiting_for_payment":"Menunggu Pembayaran",
		"in_progress":"Dikerjakan",
		"delivered.commission":"Pesanan Tekirim",
		"revision":"Revisi"
		
	},
	en:{
		"shop":"shop",
		//Item Category
		"AccountingConfigurationItemCategory":"Item Category",
		"ItemCategory":"Item Category",
		"item_category":"Item Category",
		"item_category.activate":"Activate",
		"ItemCategory.confirmTitle.action/activate":"Activate",
		"ItemCategory.confirmContent.action/activate":"Are you sure you want to activate this Item Category?",
		"ItemCategory.success.activate":"Item Category is successfully activated",
		"item_category.deactivate":"Deactivate",
		"ItemCategory.confirmTitle.action/deactivate":"Deactivate",
		"ItemCategory.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Category?",
		"ItemCategory.success.deactivate":"Item Category is successfully deactivated",
		"ItemCategory.code":"Code",
		"ItemCategory.name":"Name",
		"ItemCategory.cleanUrl":"Cleam URL",
		"ItemCategory.parentId":"Parent Category",
		"ItemCategory.parent":"Parent Category",
		"ItemCategory.image":"Image",
		"ItemCategory.thumbnail":"Thumbnail",
		"ItemCategory.state":"State",
		"ItemCategory.hideOnReport":"Hide On Report",
		"ItemCategory.saleableOnline":"Saleable Online",
		//Item attachment
		"ItemAttachment.title":"Title",
		"ItemAttachment.attachment":"Attachment",
		//Tag
		"AccountingConfigurationTag":"Tag",
		"Tag":"Tag",
		"tag":"Tag",
		"tag.activate":"Activate",
		"Tag.confirmTitle.activate":"Activate",
		"Tag.confirmContent.activate":"Are you sure you want to activate this Tag?",
		"Tag.success.activate":"Tag is successfully activated",
		"tag.deactivate":"Deactivate",
		"Tag.confirmTitle.deactivate":"Deactivate",
		"Tag.confirmContent.deactivate":"Are you sure you want to deactivate this Tag?",
		"Tag.success.deactivate":"Tag is successfully deactivated",
		"Tag.name":"Name",
		"Tag.image":"Image",
		"Tag.thumbnail":"Thumbnail",
		"Tag.state":"State",
		//Shopping Cart
		"ShoppingCart":"Shopping Cart",
		"shopping_cart":"Shopping Cart",
		"shopping_cart.cancel":"Cancel",
		"ShoppingCart.confirmTitle.cancel":"Cancel",
		"ShoppingCart.confirmContent.cancel":"Are you sure you want to cancel this Shopping Cart?",
		"ShoppingCart.success.cancel":"Shopping Cart is successfully cancelled",
		"ShoppingCart.name":"Name",
		"ShoppingCart.date":"Date",
		"ShoppingCart.currencyId":"Currency",
		"ShoppingCart.currency":"Currency",
		"ShoppingCart.temporarySessionID":"Temporary Session",
		"ShoppingCart.lines":"Items",
		"ShoppingCart.userId":"Customer",
		"ShoppingCart.user":"Customer",
		"ShoppingCart.state":"State",
		//Shopping Cart Line
		"ShoppingCartLine":"Shopping Cart Line",
		"shopping_cart_line":"Shopping Cart Line",
		"ShoppingCartLine.name":"Name",
		"ShoppingCartLine.itemId":"Item",
		"ShoppingCartLine.item":"Item",
		"ShoppingCartLine.additionalCharges":"Additional Charges",
		"ShoppingCartLine.quantity":"Quantity",
		"ShoppingCartLine.price":"Price",
		"ShoppingCartLine.total":"Total",
		//Slideshow
		"SlideShow":"Slideshow",
		"slide_show":"Slideshow",
		"slide_show.activate":"Activate",
		"SlideShow.confirmTitle.action/activate":"Activate",
		"SlideShow.confirmContent.action/activate":"Are you sure you want to activate this Slideshow?",
		"SlideShow.success.action/activate":"Slideshow is successfully activated",
		"slide_show.deactivate":"Deactivate",
		"SlideShow.confirmTitle.action/deactivate":"Deactivate",
		"SlideShow.confirmContent.action/deactivate":"Are you sure you want to deactivate this Slideshow?",
		"SlideShow.success.action/deactivate":"Slideshow is successfully deactivated",
		"SlideShow.code":"Code",
		"SlideShow.name":"Name",
		"SlideShow.lines":"Slides",
		"SlideShow.state":"State",
		//Shopping Cart Line
		"SlideShowLine":"Shopping Cart Line",
		"slide_show_line":"Shopping Cart Line",
		"SlideShowLine.title":"Judul",
		"SlideShowLine.description":"Deskripsi",
		"SlideShowLine.url":"URL",
		"SlideShowLine.itemId":"Item",
		"SlideShowLine.item":"Item",
		"SlideShowLine.image":"Image",
		"SlideShowLine.sequence":"Sequence",
		"SlideShowLine.thumbnail":"Thumbnail",
		//Online Shop Policy
		"ShopPolicy":"Online Shop Policy",
		"shop_policy":"Online Shop Policy",
		"ShopPolicy.name":"Name",
		"ShopPolicy.value":"Value",
		//Slideshow
		"highlight":"Slideshow",
		"highlight.activate":"Activate",
		"Highlight.confirmTitle.action/activate":"Activate",
		"Highlight.confirmContent.action/activate":"Are you sure you want to activate this Slideshow?",
		"Highlight.success.action/activate":"Slideshow is successfully activated",
		"highlight.deactivate":"Deactivate",
		"Highlight.confirmTitle.action/deactivate":"Deactivate",
		"Highlight.confirmContent.action/deactivate":"Are you sure you want to deactivate this Slideshow?",
		"Highlight.success.action/deactivate":"Slideshow is successfully deactivated",
		"Highlight.name":"Name",
		"Highlight.code":"Code",
		"Highlight.lines":"Items",
		"Highlight.state":"State",
		//Shopping Cart Line
		"HighlightLine":"Shopping Cart Line",
		"highlight_line":"Shopping Cart Line",
		"HighlightLine.itemId":"Item",
		"HighlightLine.item":"Item",
		"HighlightLine.sequence":"Sequence",

		//itemOption
		"ItemOption":"Item Option",
		"item_option":"Item Option",
		"ItemOption.name":"Name",
		"ItemOption.state":"State",
		"ItemOption.activate":"Activate",
		"ItemOption.confirmTitle.action/activate":"Activate",
		"ItemOption.confirmContent.action/activate":"Are you sure you want to activate this Item Option?",
		"ItemOption.success.action/activate":"Item Option is successfully activated",
		"ItemOption.deactivate":"Deactivate",
		"ItemOption.confirmTitle.action/deactivate":"Deactivate",
		"ItemOption.confirmContent.action/deactivate":"Are you sure you want to deactivate this Item Option?",
		"ItemOption.success.action/deactivate":"Item Option is successfully deactivated",
		"ItemOptionLine.option":"Option",
		"ItemOptionLine.sequence":"Sequence",
		"ItemOptionLine.price":"Price",

		//sales order
		"SalesOrder.notes":"Note",

		//MENUS
		"Shop":"Shop",
		"ShopConfiguration":"Shop Configuration",
		"ShopItem":"Online Shop item",
		"ShopItem.defaultPrice":"Price",
		"ShopItem.variantPrices":"Price Variants",
		"Shopping Cart":"Shopping Cart",
		"ShopShoppingCart":"Shopping Cart",
		"Highlight":"Highlight",
		"ShopHighlight":"Highlight",
		"Slideshow":"Slideshow",
		"ShopSlideshow":"Slideshow",
		"Shop Policy":"Shop Policy",
		"ShopShopPolicy":"Shop Policy",
		"Item.metaDescription":"Meta Description",
		"Item.viewCount":"View Count",
		
		//Item Attribute
		"ItemAttribute":"Item Attribute",
		"item_attribute":"Item Attribute",
		"ItemAttribute.name":"Attribute",
		"ItemAttribute.value":"Value",
		"ItemAttribute.itemId":"Item",
		"ItemAttribute.item":"Item",
		"ItemAttribute.sequence":"Sequence",
		"ItemAttribute.measurementUnit":"Unit",

		//SERVICE GIG
		"Service" : "Service",
		"gig_admin":"Services",
		"Gig.name":"Service Name",
		"Gig.state":"Status",
		"Gig.qty":"Qty",
		"Gig.subtotal":"Subtotal",

		"Gig.overview":"Overview",
		"Gig.pricing":"Pricing",
		"Gig.desc":"Description",
		"Gig.requirements":"Requirements",
		"Gig.gallery":"Gallery",

		"Gig.title":"Service Gig",
		"Gig.slugURL":"Slug URL",
		"Gig.category":"Category",
		"Gig.serviceType":"Service Type",
		"Gig.metadata":"Metadata",
		"Gig.searchTags":"Search Tags",
		"Gig.tagNotice":"Max. 5 tag, Use letters and numbers only",
		
		"Gig.scopePrice":"Price",
		"Gig.package":"Package",
		"Gig.basic":"Basic",
		"Gig.standard":"Standard",
		"Gig.premium":"Premium",
		"Gig.nameOfPackage":"Package Name",
		"Gig.describePackage":"Describe Package",
		"Gig.duration":"Duration",
		"Gig.price":"Price",

		"Gig.brieflyDescribe":"Briefly describe your gig",
		"Gig.FAQ":"Frequently Asked Questions",
		"Gig.addQnA":"Add questions and answers for your buyers",
		"Gig.addFAQ":"Add FAQ",
		"Gig.FAQQuestion":"Question",
		"Gig.FAQAnswer":"Answer",
		"Gig.questionPlaceholder":"Add a Question: i.e Do you translate English as well?",
		"Gig.answerPlaceholder":"Add an Answer: i.e Yes, I also translate from English to Indonesia?",
		"Gig.char":"Character(s)",
		"Gig.FAQCancel":"Cancel",
		"Gig.FAQAdd":"Add",
		"Gig.FAQDelete":"Delete",
		"Gig.FAQUpdate":"Update",

		"Gig.requirementTitle":"Tell your buyer what you need to get started.",
		"Gig.requirementSubtitle":"Structure your Buyer Instructions as free text, a multiple choice question or file upload.",
		"Gig.addRequirement":"Add Another Requirement",
		"Gig.requirementDescription": "Description",
		"Gig.requirementAnswerType": "Answer Type",
		"Gig.requirementIsMandatory": "Requirement is mandatory",
		"Gig.addOptionalAnswer":"Add Optional Answer",
		"Gig.allowMoreAnswers":"Allow more than one answer to this questiion",
		"Gig.willSeeCheckboxes":"(your buyer will see checkboxes)",
		"Gig.requirement":"REQUIREMENT",
		"Gig.requirementDescPlaceholder":"For example : Spesifications, dimensions, brand guidelines, or background materials.",

		"Gig.galleryTitle":"Build Your Gig Gallery",
		"Gig.gallerySubtitle":"Add memorable content to your gallery to set yourself apart from competitors.",
		"Gig.galleryPhotos":"Gig Photos",
		"Gig.galleryVideos":"Gig Videos",
		"Gig.galleryPhotosDesc":"Upload photos that describe or are related to your Gig.",
		"Gig.galleryVideosDesc":"Add a relevant, high quality video that best showcases your Gig.",
		"Gig.galleryPDF":"Gig PDFs",
		"Gig.galleryPDFDesc":"We only recommend adding a PDF file if it further clarifies the service you will be providing.",
		"Gig.browserUnsupportedVideoTag":"Your browser isn't supported to our video previews",

		"Gig.untitled":"No Title",
		"Gig.mandatory":"Mandatory",
		"Gig.addExtraService":"Add Extra Service",
		"Gig.extraFastDelivery":"Extra Fast Delivery",
		"Gig.forAnExtra":"for an extra",
		"Gig.andAdditional":"Delivered in",
		"Gig.iWillDeliver":"Delivered in",
		"Gig.revision":"Revision",
		"Gig.revisionExtraDays":"Revision Extra Days",
		"Gig.addGigExtra":"Add Gig Extra",
		"Gig.saveAndContinue":"Save & Continue",
		"Gig.days":"%s day(s)",

		//state changes
		"Gig.approve":"Approve",
		"Gig.activate":"Activate",
		"Gig.deactivate":"Deactivate",
		"Gig.requires_modification":"Need Modification",
		"Gig.deny":"Deny",
		"Gig.pause":"Pause",
		//editing
		"Gig.cancel":"Cancel",
		"Gig.add":"Add",
		"Gig.delete":"Delete",
		"Gig.update":"Update",
		"Gig.browse":"Browse",

		"Gig.confirmTitle.action/approve":"Approve",
		"Gig.confirmTitle.action/activate":"Activate",
		"Gig.confirmTitle.action/deactivate":"Deactivate",
		"Gig.confirmTitle.action/requires_modification":"Need Modification",
		"Gig.confirmTitle.action/denied":"Deny",
		"Gig.confirmTitle.action/paused":"Pause",

		"Gig.confirmContent.action/approve":"Are you sure want to approve this service?",
		"Gig.confirmContent.action/activate":"Are you sure want to activate this service?",
		"Gig.confirmContent.action/deactivate":"Are you sure want to deactivate this service?",
		"Gig.confirmContent.action/requires_modification":"Are you sure want to notify the provider that this service needs modification?",
		"Gig.confirmContent.action/denied":"Are you sure want to deny this service?",
		"Gig.confirmContent.action/paused":"Are you sure want to pause this service?",

		"Gig.success.action/approve":"This service is successfully approved.",
		"Gig.success.action/activate":"This service is successfully activated.",
		"Gig.success.action/deactivate":"This service is successfully deactivated.",
		"Gig.success.action/requires_modification":"This service is successfully set as 'Need Modification'.",
		"Gig.success.action/denied":"This service is successfully denied.",
		"Gig.success.action/paused":"This service is successfully paused.",

		//Service Prices
		"ServicePrice":"Price",
		"price":"Price",
		"ServicePrice.name":"Name",
		"ServicePrice.value":"Value",

		//Service Content
		"ServiceContent":"Service Options",
		"content":"Option",
		"ServiceContent.attributeName":"Attribute Name",
		"ServiceContent.attributeType":"Attribute Type",
		"ServiceContent.title":"Title",
		"ServiceContent.comment":"Comment",
		"ServiceContent.expectedDuration":"Expected Duration",
		"ServiceContent.editType":"Editor Type",
		"ServiceContent.description":"Description",
		"ServiceContent.subCategory":"Sub-category",
		"ServiceContent.type":"Type",

		//Sub Category Contents
		"ServiceSubCategoryContents":"Category Configuration",
		"ServiceSubCategoryContents.category":"Category",
		"ServiceSubCategoryContents.subCategory":"Sub-Category",
		"ServiceSubCategoryContents.serviceType":"Service Type",
		"ServiceSubCategoryContents.contents":"Package Options",
		"ServiceSubCategoryContents.packages":"Packages",
		"ServiceSubCategoryContents.sequence":"Sequence",
		"ServiceSubCategoryContents.maxQuantity":"Max Qty",
		"ServiceSubCategoryContents.revisable":"Revisable?",
		"ServiceSubCategoryContents.activate":"Activate",
		"ServiceSubCategoryContents.deactivate":"Deactivate",
		"CatContentsPackageLine.name":"Package Name",
		"CatContentsPackageLine.sequence":"Sequence",
		"CatContentsPackageLine.minPrice":"Min Price",
		"CatContentsPackageLine.maxPrice":"Max Price",
		"CatContentsPackageLine.roundTo":"Round to",

		//service type
		"ServiceType":"Service Types",
		"ServiceType.name":"Name",
		"ServiceType.category":"Category",
		"ServiceType.subCategory":"Sub-Category",

		//Service Medatadas
		"ServiceMetadata":"Metadata",
		"ServiceMetadata.name":"Name",
		"ServiceMetadata.title":"Title",
		"ServiceMetadata.description":"Description",
		"ServiceMetadata.category":"Categroy",
		"ServiceMetadata.subCategoryId":"Sub-Category",
		"ServiceMetadata.isMandatory":"Mandatory ?",
		"ServiceMetadataLines.name":"Name",
		"ServiceMetadataLines.value":"Value",
		"ServiceMetadataLines.alias":"Alias",
		"ServiceMetadata.lines":"Metadata List",

		"ServiceMetadata.activate":"Activate",
		"ServiceMetadata.deactivate":"Deactivate",

		// Service Occupation
		"ServiceOccupation":"Occupation",
		"ServiceOccupation.name":"Occupation Name",
		"ServiceOccupation.expertises":"Expertises",
		"ServiceOccupationExpertises.name":"Name",

		// Service Order
		"ServiceOrder":"Service Order",
		"order_admin":"Service Order",
		"ServiceOrder.name":"Order No.",
		"ServiceOrder.total":"Total",
		"ServiceOrder.state":"State",
		"ServiceOrder.seller":"Seller",
		"ServiceOrder.buyer":"Buyer",
		"ServiceOrder.orderNo":"Order No.",
		"ServiceOrder.viewInvoice":"View Invoice",
		"ServiceOrder.orderStatus":"Order Status",
		"ServiceOrder.orderHasBeenDelivered":"Order has been delivered",
		"ServiceOrder.messageFromSeller":"Message from Seller",
		"ServiceOrder.noMessage":"No Message",
		"ServiceOrder.noDescription":"No Description",

		// Order Request
		"OrderRequest":"Order Request",
		"OrderRequest.username":"Username",
		"OrderRequest.user":"User",
		"OrderRequest.date":"Date",
		"OrderRequest.description":"Description",
		"OrderRequest.category":"Category",
		"OrderRequest.subCategory":"Subcategory",
		"OrderRequest.duration":"Duration",
		"OrderRequest.budget":"Budget",
		"OrderRequest.offer":"Offer",
		"OrderRequest.offers":"Offers",
		"OrderRequest.attachment":"Attachment",
		"OrderRequest.state":"Status",
		"OrderRequest.approve":"Approve",
		"OrderRequest.reject":"Reject",
		"OrderRequest.confirmTitle.action/approve":"Approve",
		"OrderRequest.confirmContent.action/approve":"Are you sure want to approve this order request?",
		"OrderRequest.success.action/approve":"Order request sucessfully approved",
		"OrderRequest.confirmTitle.action/reject":"Reject",
		"OrderRequest.confirmContent.action/reject":"Are you sure want to reject this order request?",
		"OrderRequest.success.action/reject":"Order request sucessfully rejected",

		//RATING TEMPLATE
		"RatingTemplate":"Rating Template",
		"RatingTemplate.name":"Name",
		"RatingTemplate.category":"Category",
		"RatingTemplate.subCategory":"Subcategory",
		"RatingTemplate.serviceType":"Service Type",
		"RatingTemplate.state":"Status",
		"RatingTemplate.ratings":"Rating Template",
		"RatingTemplate.reviews":"Reviews Template",
		"RatingTemplateLine.name":"Name",
		"RatingTemplateLine.label":"Label",
		"RatingTemplateLine.position":"Position",
		"RatingTemplate.activate":"Activate",
		"RatingTemplate.deactivate":"Deactivate",
		"RatingTemplate.confirmTitle.action/activate":"Activate",
		"RatingTemplate.confirmTitle.action/deactivate":"Deactivate",
		"RatingTemplate.confirmContent.action/activate":"Are you sure want to activate this Rating Template?",
		"RatingTemplate.confirmContent.action/deactivate":"Are you sure want to deactivate this Rating Template?",
		"RatingTemplate.success.action/activate":"Rating Template was successfully activated",
		"RatingTemplate.success.action/deactivate":"Rating Template was successfully deactivated",
		

		//Marketplace
		"Marketplace":"Marketplace",
		"Marketplace.name":"Name",
		"Marketplace.url":"URL",
		"Marketplace.icon":"Icon",
		"MarketplaceLine.marketplace":"Marketplace",
		"MarketplaceLine.url":"URL",
		"MarketplaceLine.sequence":"Sequence",
		"Marketplace.backgroundColor":"Background Color",
		"Marketplace.textColor":"Text Color",

		//Variant & choices
		"Variant":"Variant",
		"Variant.name":"Name",
		"Variant.code":"Code",
		"Variant.itemCategory":"Item Category",
		"Variant.choices":"Choices",
		"Variant.state":"Status",
		"ChoicesLine.name":"Name",
		"ChoicesLine.code":"Code",
		"ChoicesLine.color":"Color",
		"ChoicesLine.image":"Image",
		"ChoicesLine.sequence":"Sequence",
		"VariantLine.variant":"Variant",
		"VariantLine.choices":"Choices",
		"VariantLine.sequence":"sequence",

		// Item Import
		"ShopItemImport":"Item Import",
		"ShopItemImport.file":"Excel File (.xlsx)",
		"ShopItemImport.upload":"Upload",
		"ShopItemImport.uploadSuccess":"File has been uploaded",
		"ShopItemImport.uploadFailed":"Uplaod Failed, please check your internet connection",

		//Courier
		"Courier":"Courier",
		"country":"Courier",
		"Courier.activate":"Activate",
		"Courier.confirmTitle.action/activate":"Activate",
		"Courier.confirmContent.action/activate":"Are you sure you want to activate this Courier?",
		"Courier.success.action/activate":"Courier is successfully activated",
		"Courier.deactivate":"Deactivate",
		"Courier.confirmTitle.action/deactivate":"Deactivate",
		"Courier.confirmContent.action/deactivate":"Are you sure you want to deactivate this Courier?",
		"Courier.success.action/deactivate":"Courier is successfully deactivated",
		"Courier.name":"Name",
		"Courier.trackable":"Trackable",
		"Courier.urlPrefix":"URL Prefix",
		"Courier.urlSuffix":"URL Suffix",
		"Courier.state":"State",

		//OPTIONS
		"active":"Active",
		"cancelled":"Cancelled",
		"inactive":"Inactive",
		"complete":"Complete",
		"draft":"Draft",
		"denied":"Deny",
		"pending_approval":"Waiting Approval",
		"requires_modification":"Need Modification",
		"paused":"Paused",
		"package":"Package",
		"additional_service":"Additional Service",
		"checkbox":"Checkbox",
		"number":"Number",
		"dropdown":"Dropdown",
		"modification":"Modification",
		"quantity":"Quantity",
		"awaiting_for_payment":"Wating for Payment",
		"in_progress":"In Progress",
		"delivered.commission":"Order Sent",
		"revision":"Revision"
	}
}

export default Translations
