const Translations = {

    id: {
        "Freight": "Kargo",
        //HSCode
        "HSCode":"HS Code",
        "HSCode.name":"Nama",
        "HSCode.importDutyType":"Tipe Bea Masuk",
        "HSCode.importDuty":"Bea Masuk",
        "HSCode.vat":"PPN",
        "HSCode.incomeTax":"PPh",
        "HSCode.prohibited":"Dilarang?",
        "HSCode.parentEnglishDescription":"Deskripsi Induk B.Inggris",
        "HSCode.parentIndonesianDescription":"Deskripsi Induk B.Indonesia",
        "HSCode.englishDescription":"Deskripsi B.Inggris",
        "HSCode.indonesianDescription":"Deskripsi B.Indonesia",
        "HSCode.keyword":"Kata Kunci",
        "HSCode.unitId":"ID Satuan",
        "HSCode.unit":"Satuan",
        "HSCode.state":"Status",
        "HSCode.parentId":"ID Induk",
        "HSCode.parent":"Induk",

        // Freight Configuration
        "FreightConfiguration":"Konfigurasi Kargo",
        "FreightConfig.location":"Negara",
        "FreightConfig.unloadLocation":"Pelabuhan",
        "FreightConfig.containerType":"Tipe Kontainer",
        "FreightConfig.fcl":"Konfigurasi FCL",
        "FreightConfig.fcl.name":"Nama",
        "FreightConfig.fcl.code":"Kode",
        "FreightConfig.fcl.information":"Informasi",
        "FreightConfig.fcl.price":"Harga",
        "FreightConfig.fcl.currency":"Mata Uang",
        "FreightConfig.fcl.sequence":"Urutan",
        
        "FreightConfig.lclsea.cbmUpperLimit":"Batas Atas CBM",
        "FreightConfig.lclsea.cbmLowerLimit":"Batas Bawah CBM",
        "FreightConfig.lclsea.prohibited":"Dilarang?",
        "FreightConfig.lclsea.location":"Negara",
        "FreightConfig.lclsea.price":"Price",
        "FreightConfig.lclair.location":"Negara",
        "FreightConfig.lclair.price":"Harga",

        //Freight User
        "FreightUser":"Pengguna Kargo",
        "freightuser":"Pengguna Kargo",
        "FreightUser.name":"Nama",
        "FreightUser.email":"E-mail",
        "FreightUser.mobile":"Telepon/HP",
        "FreightUser.state":"Status",
        "FreightUser.accessCode":"Kode Akses",
        "FreightUser.copyCode.success":"Access code berhasil disalin ke clipboard",

        //Freight location
        "FreightLocation":"Negara",
        "FreightLocation.name":"Nama",
        "FreightLocation.latitude":"Latitude",
        "FreightLocation.longitude":"Longitude",
        "FreightLocation.option":"Pilihan",
        "FreightLocation.state":"Status",
        "FreightLocation.isPort":"Pelabuhan?",
        "FreightLocation.parent":"Lokasi Induk",
        "FreightLocation.isSelectableForOceanFCL": "Dapat Dipilih untuk FCL Laut",
        "FreightLocation.isSelectableForOceanLCL":"Dapat Dipilih untuk LCL Laut",
        "FreightLocation.isSelectableForAirFreight":"Dapat Dipilih untuk Kargo Udara",

        //Freight location Unload
        "FreightLocationUnload":"Pelabuhan",
        "FreightLocationUnload.name":"Nama",
        "FreightLocationUnload.latitude":"Latitude",
        "FreightLocationUnload.longitude":"Longitude",
        "FreightLocationUnload.freightLocation":"Negara",
        "FreightLocationUnload.state":"Status",
        "FreightLocationUnload.isSelectableForOceanFCL": "Dapat Dipilih untuk FCL Laut",
        "FreightLocationUnload.isSelectableForOceanLCL":"Dapat Dipilih untuk LCL Laut",
        "FreightLocationUnload.isSelectableForAirFreight":"Dapat Dipilih untuk Kargo Udara",
        
        //Currency
        "FreightCurrency":"Mata Uang",
		"Currency":"Mata Uang",
		"currency":"Mata Uang",
		"currency.activate":"Aktifkan",
		"Currency.confirmTitle.action/activate":"Aktifkan",
		"Currency.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Mata Uang ini?",
		"Currency.success.activate":"Mata Uang telah berhasil diaktifkan",
		"currency.deactivate":"Nonaktifkan",
		"Currency.confirmTitle.action/deactivate":"Nonaktifkan",
		"Currency.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Mata Uang ini?",
		"Currency.success.deactivate":"Mata Uang telah berhasil dinonaktifkan",
		"Basic Information":"Informasi Dasar",
		"Currency.name":"Nama",
		"Currency.code":"Kode",
		"Currency.symbol":"Simbol",
		"Accounting":"Akunting",
		"Currency.defaultPayableAccountId":"Akun Utang",
		"Currency.defaultPayableAccount":"Akun Utang",
		"Currency.defaultReceivableAccountId":"Akun Piutang",
		"Currency.defaultReceivableAccount":"Akun Piutang",
		"Currency.advanceReceiveAccountId":"Akun Penerimaan Di Muka",
		"Currency.advanceReceiveAccount":"Akun Penerimaan Di Muka",
		"Currency.advancePaymentAccountId":"Akun Pembayaran Di Muka",
		"Currency.advancePaymentAccount":"Akun Pembayaran Di Muka",
		"Currency.salesDiscountAccountId":"Akun Potongan Penjualan",
		"Currency.salesDiscountAccount":"Akun Potongan Penjualan",
		"Currency.realizedGainOrLossAccountId":"Laba/Rugi Selisih Kurs Terealisasi",
		"Currency.realizedGainOrLossAccount":"Laba/Rugi Selisih Kurs Terealisasi",
		"Currency.unrealizedGainOrLossAccountId":"Laba/Rugi Selisih Kurs Belum Terealisasi",
		"Currency.unrealizedGainOrLossAccount":"Laba/Rugi Selisih Kurs Belum Terealisasi",
        "Currency.state":"Status",
        
		//Daily Exchange Rate
		"ExchangeRateDaily":"Nilai Tukar Harian",
		"exchange_rate_daily":"Nilai Tukar Harian",
		"ExchangeRateDaily.date":"Tanggal",
		"ExchangeRateDaily.periodId":"Periode",
		"ExchangeRateDaily.period":"Periode",
		"ExchangeRateDaily.currencyFromId":"Kurs Asal",
		"ExchangeRateDaily.currencyFrom":"Kurs Asal",
		"ExchangeRateDaily.currencyToId":"Kurs Tujuan",
		"ExchangeRateDaily.currencyTo":"Kurs Tujuan",
		"ExchangeRateDaily.rate":"Nilai Tukar",
        "ExchangeRateDaily.description":"Deskripsi",

        //options
        "percentage":"Persentase",
        "per_quantity":"Per Kuantitas",
        "fcl":"FCL",
        "fcl_unload":"FCL (Pelabuhan)",
        "lcl_sea":"LCL Laut",
        "lcl_air":"LCL Udara",
        "20HQ":"20\"HQ",
        "40HQ":"40\"HQ",
    },
    en: {
        "Freight":"Freight",
        //HSCode
        "HSCode":"HS Code",
        "HSCode.name":"Name",
        "HSCode.importDutyType":"Import Duty Type",
        "HSCode.importDuty":"Import Duty",
        "HSCode.vat":"Vat",
        "HSCode.incomeTax":"Income Tax",
        "HSCode.prohibited":"Prohibited",
        "HSCode.parentEnglishDescription":"Parent English Description",
        "HSCode.parentIndonesianDescription":"Parent English Description",
        "HSCode.englishDescription":"English Description",
        "HSCode.indonesianDescription":"Indonesian Description",
        "HSCode.keyword":"Keyword",
        "HSCode.unitId":"Unit ID",
        "HSCode.unit":"Unit",
        "HSCode.state":"State",
        "HSCode.parentId":"Parent ID",
        "HSCode.parent":"Parent",

        // Freight Configuration
        "FreightConfiguration":"Freight Configuration",
        "FreightConfig.location":"Country",
        "FreightConfig.unloadLocation":"Port",
        "FreightConfig.containerType":"Container Type",
        "FreightConfig.fcl":"FCL Configuration",
        "FreightConfig.fcl.name":"Name",
        "FreightConfig.fcl.code":"Code",
        "FreightConfig.fcl.information":"Information",
        "FreightConfig.fcl.price":"Price",
        "FreightConfig.fcl.currency":"Currency",
        "FreightConfig.fcl.sequence":"Sequence",
        
        "FreightConfig.lclsea.cbmUpperLimit":"CBM Upper Limit",
        "FreightConfig.lclsea.cbmLowerLimit":"CBM Lower Limit",
        "FreightConfig.lclsea.prohibited":"CBM Upper Limit?",
        "FreightConfig.lclsea.location":"Country",
        "FreightConfig.lclsea.price":"Price",
        "FreightConfig.lclair.location":"Country",
        "FreightConfig.lclair.price":"Price",

        //Freight User
        "FreightUser":"Freight User",
        "freightuser":"Freight User",
        "FreightUser.name":"Name",
        "FreightUser.email":"E-mail",
        "FreightUser.mobile":"Phone",
        "FreightUser.state":"Status",
        "FreightUser.accessCode":"Access Code",
        "FreightUser.copyCode.success":"Access code has been copied",

        //Freight location
        "FreightLocation":"Country",
        "FreightLocation.name":"Name",
        "FreightLocation.latitude":"Latitude",
        "FreightLocation.longitude":"Longitude",
        "FreightLocation.option":"Option",
        "FreightLocation.state":"Status",
        "FreightLocation.isPort":"Port?",
        "FreightLocation.parent":"Parent Location",
        "FreightLocation.isSelectableForOceanFCL": "Selectable for Ocean FCL",
        "FreightLocation.isSelectableForOceanLCL":"Selectable for Ocean LCL",
        "FreightLocation.isSelectableForAirFreight":"Selectable for Air Freight",

        //Freight location Unload
        "FreightLocationUnload":"Port",
        "FreightLocationUnload.name":"Name",
        "FreightLocationUnload.latitude":"Latitude",
        "FreightLocationUnload.longitude":"Longitude",
        "FreightLocationUnload.freightLocation":"Country",
        "FreightLocationUnload.state":"Status",
        "FreightLocationUnload.isSelectableForOceanFCL": "Selectable for Ocean FCL",
        "FreightLocationUnload.isSelectableForOceanLCL":"Selectable for Ocean LCL",
        "FreightLocationUnload.isSelectableForAirFreight":"Selectable for Air Freight",        
        
        //Currency
        "FreightCurrency":"Currency",
		"Currency":"Currency",
		"currency":"Currency",
		"currency.activate":"Activate",
		"Currency.confirmTitle.action/activate":"Activate",
		"Currency.confirmContent.action/activate":"Are you sure you want to activate this Currency?",
		"Currency.success.activate":"Currency is successfully activated",
		"currency.deactivate":"Deactivate",
		"Currency.confirmTitle.action/deactivate":"Deactivate",
		"Currency.confirmContent.action/deactivate":"Are you sure you want to deactivate this Currency?",
		"Currency.success.deactivate":"Currency is successfully deactivated",
		"Basic Information":"Basic Information",
		"Currency.name":"Name",
		"Currency.code":"Code",
		"Currency.symbol":"Symbol",
		"Accounting":"Accounting",
		"Currency.defaultPayableAccountId":"Payable Account",
		"Currency.defaultPayableAccount":"Payable Account",
		"Currency.defaultReceivableAccountId":"Receivable Account",
		"Currency.defaultReceivableAccount":"Receivable Account",
		"Currency.advanceReceiveAccountId":"Advance Receive Account",
		"Currency.advanceReceiveAccount":"Advance Receive Account",
		"Currency.advancePaymentAccountId":"Advance Payment Account",
		"Currency.advancePaymentAccount":"Advance Payment Account",
		"Currency.salesDiscountAccountId":"Sales Discount Account",
		"Currency.salesDiscountAccount":"Sales Discount Account",
		"Currency.realizedGainOrLossAccountId":"Realized Gain Account",
		"Currency.realizedGainOrLossAccount":"Realized Gain Account",
		"Currency.unrealizedGainOrLossAccountId":"Unrealized Gain Or Loss Account",
		"Currency.unrealizedGainOrLossAccount":"Unrealized Gain Or Loss Account",
		"Currency.state":"State",
		//Daily Exchange Rate
		"ExchangeRateDaily":"Daily Exchange Rate",
		"exchange_rate_daily":"Daily Exchange Rate",
		"ExchangeRateDaily.date":"Date",
		"ExchangeRateDaily.periodId":"Period",
		"ExchangeRateDaily.period":"Period",
		"ExchangeRateDaily.currencyFromId":"From Currency",
		"ExchangeRateDaily.currencyFrom":"From Currency",
		"ExchangeRateDaily.currencyToId":"To Currency",
		"ExchangeRateDaily.currencyTo":"To Currency",
		"ExchangeRateDaily.rate":"Rate",
		"ExchangeRateDaily.description":"Description",

        //options
        "percentage":"Percentage",
        "per_quantity":"Per Quantity",
        "fcl":"FCL",
        "fcl_unload":"FCL (Port)",
        "lcl_sea":"LCL Sea",
        "lcl_air":"LCL Air",
        "20HQ":"20\"HQ",
        "40HQ":"40\"HQ",
        "destination":"Destination",
        "origin":"Origin",
        "both":"both",
    },
}

export default Translations