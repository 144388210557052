import React, { Component } from "react";
import { withSnackbar } from "notistack";
import {
  withStyles,
  Badge,
  Dialog,
  DialogContent,
  ButtonBase,
  CircularProgress,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import Cookies from "js-cookie";
import API from "framework/utils/API";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import { tl, tlStrict } from "framework/utils/Translator";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { connect } from "react-redux";

//icons
import ListIcon from "@material-ui/icons/PlaylistAddCheck";
import CloseIcon from "@material-ui/icons/Close";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import PrevIcon from "@material-ui/icons/KeyboardArrowLeft";
import CheckIconOutlined from "@material-ui/icons/CheckCircleOutline";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import UserIcon from "@material-ui/icons/Person";
import ConfirmIcon from "@material-ui/icons/Check";
import RejectIcon from "@material-ui/icons/Close";
import RefreshButton from "@material-ui/icons/Refresh";

//styles
import RequestApprovalStyle from "../styles/RequestApprovalStyle";
import "assets/scss/animate.css";
import parse from "html-react-parser";

class RequestApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
      maxPage: 0,
      pageOffset: 0,
      isOpen: false,
      isLoading: false,
      isApprovalLoading:false,
      records: [],
    };
    this.headers = {
      headers: {
        Authorization: "Bearer " + Cookies.get("accessToken"),
      },
    };

    this.state.pageIndex = 0;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = (withLoading) => {
    if (withLoading) {
      this.setState({
        isLoading: true,
      });
    }
    let model = "request_approval";
    let reqBody = {
      sorts: this.state.sorts,
      filters: [],
      limit: 20,
      offset: this.state.pageOffset * 20,
    };

    if (this.state.pageIndex === 0) {
      reqBody.filters = [
        { field: "state", operator: "=", value: "requested" },
        { field: "processed", operator: "=", value: "false" },
      ];
    } else if (this.state.pageIndex === 1) {
      model = "request";
    } else {
      reqBody.filters = [{ field: "state", operator: "=", value: "approved" }];
    }

    API("browse", model, reqBody).then((response) => {
      if (this.state.pageIndex === 1) {
        this.setState({
          records: [
            {
              id: 1,
              number: tl("RequestApproval.yourRequests"),
              processed: "false",
              requests: [...response.data.records],
            },
          ],
          maxPage: response.data.maxPage,
          isLoading: false,
        });
      } else {
        this.setState({
          records: response.data.records,
          maxPage: response.data.maxPage,
          isLoading: false,
        });
      }
    });
  };

  handleTogglePane = () => {
    if (!this.state.isOpen) {
      this.setState({ isLoading: true }, () => this.getData());
    }

    this.setState({
      isOpen: !this.state.isOpen,
      animated: true,
    });
    setTimeout(() => {
      this.setState({
        animated: false,
      });
    }, 1000);
  };

  handleTabChange = (index) => {
    this.setState(
      {
        pageIndex: index,
        isLoading: true,
      },
      () => this.getData(),
    );
  };

  handleNav = (direction) => {
    let offset = this.state.pageOffset;
    if (direction === "prev" && offset > 0) {
      offset--;
    } else if (direction === "next" && offset < this.state.maxPage - 1) {
      offset++;
    }
    this.setState(
      {
        pageOffset: offset,
        isLoading: true,
      },
      () => this.getData(),
    );
  };

  handleCheck = (id, reqIndex) => {
    this.setState({
      records: this.state.records.map((record) => {
        if (record.id === id) {
          let newRecord = {
            ...record,
            requests: record.requests.map((req, idx) => {
              if (idx === reqIndex) {
                return {
                  ...req,
                  approved: !req.approved,
                };
              }
              return req;
            }),
          };
          return newRecord;
        }
        return record;
      }),
    });
  };

  handleConfirmApproval = (requestId, action, recordId) => {
    this.setState({
      isApprovalLoading: true,
    });
    API("custom_action", "request/action", {
      id: requestId,
      action,
    })
      .then(() => {
        let newRecords = this.state.records.map((oldRec) => {
          if (oldRec.id !== recordId) {
            return oldRec;
          } else {
            return {
              ...oldRec,
              requests: oldRec.requests.filter((req) => req.id != requestId),
            };  
          }
        });
        this.props.setNotification(
          true,
          "RequestApproval.complete." + action,
          "success",
        );
        setTimeout(()=>{
          this.setState({
            isApprovalLoading: false,
            records: newRecords,
          })
        }, 300)
        setTimeout(() => {  
          this.props.setNotification(
            false,
            "RequestApproval.complete." + action,
            "success",
          );
        }, 3000);
      })
      .catch((error) => {
        if (error) {
          setTimeout(()=>{
            this.setState({
              isApprovalLoading: false,
            });
          }, 300)
        }
      });
  };

  getDescription = (request) => {
    let desc = tlStrict("RequestApproval." + request.requestCode) || "";
    if (desc) {
      let data = JSON.parse(request.requestData);
      if (request.itemName) {
        desc = desc.replace("%s", request.itemName);
      } else {
        desc = desc.replace("%s", "barang");
      }

      if (request.itemPrice) {
        desc = desc.replace(
          "%oldPrice",
          request.itemPrice.toLocaleString("en-US"),
        );
      }

      if (data.price) {
        desc = desc.replace("%newPrice", data.price.toLocaleString("en-US"));
      }

      return desc;
    } else {
      return request.description;
    }
  };

  parseHtml = (request) => {
    try {
      return parse(this.getDescription(request));
    } catch (e) {
      return "-";
    }
  };

  render() {
    const { parentClasses, classes } = this.props;
    return (
      <>
        <div style={{ display: "inline-block" }}>
          <div>
            <Button
              justIcon
              simple
              size="xs"
              color="transparent"
              className={parentClasses.buttonLink + " " + classes.mainButton}
              onClick={() => this.handleTogglePane()}
            >
              <Badge
                color="primary"
                badgeContent={this.state.count || 0}
                classes={{ badge: classes.customBadge }}
              >
                <ListIcon
                  className={
                    ` ${
                      this.state.animated ? "animated rubberBand infinite" : ""
                    } ` +
                    parentClasses.headerLinksSvg +
                    " " +
                    parentClasses.links
                  }
                />
              </Badge>
            </Button>
            <div
              className={classes.notifPane + ` ${!this.state.isOpen && "hide"}`}
            >
              <div className={classes.paneContent}>
                <div className={classes.paneHeading}>
                  <div className="mainHeading">
                    <div className={classes.paneTitle}>
                      {tl("RequestApproval")}
                    </div>
                    <CloseIcon
                      className={classes.paneClose}
                      onClick={() => this.handleTogglePane()}
                    />
                  </div>
                  <div className="tabs">
                    <div
                      className={
                        "tab " + (this.state.pageIndex === 0 ? "active" : "")
                      }
                      onClick={() => this.handleTabChange(0)}
                    >
                      {tl("RequestApproval.active")}
                    </div>
                    <div
                      className={
                        "tab " + (this.state.pageIndex === 1 ? "active" : "")
                      }
                      onClick={() => this.handleTabChange(1)}
                    >
                      {tl("RequestApproval.waiting")}
                    </div>
                    <div
                      className={
                        "tab " + (this.state.pageIndex === 2 ? "active" : "")
                      }
                      onClick={() => this.handleTabChange(2)}
                    >
                      {tl("RequestApproval.history")}
                    </div>
                  </div>
                </div>

                <div className={classes.notificationItems}>
                  {this.state.isLoading && (
                    <div className={classes.loadingPane}>
                      <div className={classes.paneSpinner}>
                        <CircularProgress size={20} />
                      </div>
                      {tl(Cookies.get("lang"), "waitASec")}...
                    </div>
                  )}
                  {!this.state.isLoading &&
                    this.state.records.length > 0 &&
                    this.state.records.map((record, recIndex) => {
                      let isSameDay =
                        record.startDate && record.endDate
                          ? record.startDate.split("T")[0] ===
                            record.endDate.split("T")[0]
                          : false;
                      return (
                        <div className={classes.requestCard} key={recIndex}>
                          <div className="heading">
                            <div className="mainHeader">
                              <div className="title">{record.number}</div>
                              {this.state.pageIndex !== 1 && (
                                <div className="subtitle">
                                  {isSameDay
                                    ? moment(record.startDate).format(
                                        "DD/MM/YYYY",
                                      )
                                    : moment(record.startDate).format(
                                        "DD/MM/YYYY",
                                      ) +
                                      " - " +
                                      moment(record.endDate).format(
                                        "DD/MM/YYYY",
                                      )}
                                </div>
                              )}
                            </div>
                            {this.state.pageIndex !== 1 && (
                              <div>
                                {record.state === "approved" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {tl(
                                      record.processed
                                        ? "RequestApproval.processed"
                                        : "RequestApproval.pending",
                                    )}
                                    {record.processed && (
                                      <CheckIcon
                                        style={{
                                          color: "#4caf50",
                                          marginLeft: "10px",
                                          fontSize: "1.2em",
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="card">
                            {[...(record.requests || [])].map(
                              (request, reqIndex) => (
                                <div className="list" key={reqIndex}>
                                  <div className="details">
                                    <div className="desc">
                                      {request ? this.parseHtml(request) : ""}
                                    </div>
                                    <div className="user">
                                      <UserIcon
                                        style={{
                                          fontSize: "1em",
                                          marginRight: "7px",
                                        }}
                                      />
                                      {request.requester
                                        ? request.requester.username
                                        : "-"}
                                    </div>
                                    {this.state.pageIndex !== 1 && (
                                      <>
                                        {record.state === "approved" ? (
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                            }}
                                          >
                                            {request.state === "approved" ? (
                                              <CheckIcon
                                                style={{
                                                  color: "#4caf50",
                                                  marginRight: "10px",
                                                }}
                                              />
                                            ) : (
                                              <CancelIcon
                                                style={{
                                                  color: "#d32f2f",
                                                  marginRight: "10px",
                                                }}
                                              />
                                            )}
                                            {tl(request.state)}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <Button
                                              disabled={this.state.isApprovalLoading}
                                              size="sm"
                                              color="transparent"
                                              onClick={() =>
                                                this.handleConfirmApproval(
                                                  request.id,
                                                  "reject",
                                                  record.id,
                                                )
                                              }
                                            >
                                              <RejectIcon />
                                              Tolak
                                            </Button>
                                            <Button
                                              disabled={this.state.isApprovalLoading}
                                              size="sm"
                                              color="success"
                                              onClick={() =>
                                                this.handleConfirmApproval(
                                                  request.id,
                                                  "confirm",
                                                  record.id,
                                                )
                                              }
                                            >
                                              <ConfirmIcon />
                                              Konfirmasi
                                            </Button>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {!this.state.isLoading && this.state.records.length === 0 && (
                    <div className={classes.noNotification}>
                      <CheckIconOutlined
                        style={{ color: "#ddd", fontSize: "2em" }}
                      />
                      <h4>{tl("RequestApproval.allClear")}</h4>
                      <p>{tl("RequestApproval.noRequest")}</p>
                    </div>
                  )}
                </div>
                <div className={classes.paneBottom}>
                  <div className="navigation">
                    <ButtonBase
                      className="navButton"
                      onClick={() => this.handleNav("prev")}
                    >
                      <PrevIcon />
                    </ButtonBase>
                    <div className="pageInfo">
                      {this.state.pageOffset + 1} /{" "}
                      {this.state.maxPage < 1 ? 1 : this.state.maxPage}
                    </div>
                    <ButtonBase
                      className="navButton"
                      onClick={() => this.handleNav("next")}
                    >
                      <NextIcon />
                    </ButtonBase>
                  </div>
                  <ButtonBase
                    className="refresh"
                    onClick={() => this.getData(true)}
                  >
                    <RefreshButton />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.expand}
          onClose={() => this.handleCloseModal()}
          maxWidth="md"
          fullWidth
        >
          <DialogContent style={{ padding: "10px" }}></DialogContent>
        </Dialog>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(withStyles(RequestApprovalStyle)(withRouter(RequestApproval))));
