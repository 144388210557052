import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import { tl } from "framework/utils/Translator";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

class SpotlightSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false,
      cursorIndex: 0,
    };
    this.allowedRoutes = props.Session.menus;
    this.routes = [];
    this.filteredRoutes = [];
    props.routes.map((route) => {
      if (route.name !== "Pages") {
        if (route.collapse) {
          route.views.map((childRoute) => {
            if (this.allowedRoutes.includes(childRoute.menu)) {
              this.routes.push({ ...childRoute, from: route.name });
            }
          });
        } else if (this.allowedRoutes.includes(route.menu)) {
          this.routes.push(route);
        }
      }
    });

    this.searchRef = React.createRef();
    this.isAltPressed = false;
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  };

  handleKeyDown = (e) => {
    if (this.state.show && [38, 33, 34, 40].includes(e.keyCode)) {
      let cursorIndex = this.state.cursorIndex;
      if (e.keyCode === 38 && this.state.cursorIndex > 0) {
        cursorIndex = this.state.cursorIndex - 1;
      } else if (
        e.keyCode === 40 &&
        cursorIndex < this.filteredRoutes.length - 1
      ) {
        cursorIndex = this.state.cursorIndex + 1;
      }
      this.setState(
        {
          cursorIndex,
        },
        () => {
          let cursor = document.querySelector(".cursorActive");
          let ctr = document.querySelector("#spotlightListCtr");
          if (cursor && ctr) {
            // var ctrTop = ctr.scrollTop;
            var ctrHeight = ctr.offsetHeight;
            var rect = cursor.getBoundingClientRect();
            // var elemTop = rect.top;
            var elemBottom = rect.bottom;
            var isVisible =
              elemBottom + ctrHeight + this.headerHeight < ctrHeight;
            if (!isVisible) {
              cursor.scrollIntoView({ block: "nearest" });
            }
          }
        },
      );
    }
    if (e.keyCode === 18) {
      this.isAltPressed = true;
      setTimeout(() => (this.isAltPressed = false), 2000);
    } else if (e.keyCode === 77 && this.isAltPressed) {
      this.setState({
        show: !this.state.show,
        cursorIndex: !this.state.show ? 0 : this.state.cursorIndex,
        search: this.searchRef ? this.searchRef.current.value : "",
      });
      if (this.state.show) {
        this.searchRef.current.focus();
      } else {
        this.searchRef.current.blur();
      }
    } else if (e.keyCode === 13 && e.shiftKey && this.state.show) {
      this.handleClick(
        this.filteredRoutes[this.state.cursorIndex],
        this.state.cursorIndex,
      );
    }
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 18) {
      this.isAltPressed = false;
    }
  };

  handleChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleClick = (route, index) => {
    console.log(this.props);
    this.props.history.push("/admin" + route.path.replace("/:id", ""));
    this.setState({
      show: false,
      cursorIndex: index,
    });
    this.searchRef.current.blur();
  };

  handleClickOutside = () => {
    this.setState(
      {
        show: false,
        search: "",
        cursorIndex: 0,
      },
      () => {
        this.isAltPressed = false;
      },
    );
  };

  render() {
    const { classes } = this.props;
    let renderedRoutes = [];
    if (this.state.search) {
      renderedRoutes = this.routes.filter((route) => {
        return route.name
          .toLowerCase()
          .includes(this.state.search.toLowerCase());
      });
    }
    this.filteredRoutes = renderedRoutes;
    return (
      <div
        className={classes.container + (this.state.show ? " show" : "")}
        onClick={() => this.handleClickOutside()}
      >
        <div className="searchBox" onClick={(e) => e.stopPropagation()}>
          <div className="searchInput">
            <SearchIcon className="icon" />
            <input
              ref={this.searchRef}
              type="search"
              placeholder={tl("search")}
              onChange={this.handleChangeSearch}
            />
          </div>
          <div className="suggestion" id="spotlightListCtr">
            {renderedRoutes.map((route, index) => (
              <div
                key={index}
                className={
                  "item " +
                  (this.state.cursorIndex === index ? "cursorActive" : "")
                }
                onClick={() =>
                  this.handleClick(route, index === renderedRoutes.length - 1)
                }
              >
                {React.createElement(route.icon, { className: "icon" })}
                <div className="label">
                  {route.name}
                  {route.from && (
                    <span style={{ opacity: "0.6" }}>
                      &nbsp;{Cookies.get("lang") === "id" ? "di" : "on"}&nbsp;
                      {route.from}
                    </span>
                  )}
                </div>
                {this.state.cursorIndex === index && <kbd>shift + enter</kbd>}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    pointerEvents: "none",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    zIndex: "10000",
    backgroundColor: "rgba(0,0,0,0)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "0.3s ease-out",
    "@media(max-width:560px)": {
      "&.show": {
        "&>.searchBox": {
          width: "80vw",
          marginTop: "80px !important",
        },
      },
    },
    "&.show": {
      pointerEvents: "all",
      backgroundColor: "rgba(0,0,0,0.2)",
      "&>.searchBox": {
        pointerEvents: "all",
        marginTop: "150px",
        opacity: "1",
      },
    },
    "&>.searchBox": {
      transition: "0.5s ease-out",
      opacity: "0",
      pointerEvents: "none",
      width: "40vw",
      minWidth: "300px",
      marginTop: "-450px",
      boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
      borderRadius: "10px",
      overflow: "hidden",
      "&>.searchInput": {
        padding: "0px 10px",
        height: "50px",
        backgroundColor: "rgba(255,255,255,0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
        "&>.icon": {
          marginRight: "5px",
        },
        "&>input": {
          border: "none",
          fontSize: "1.2em",
          fontFamily: "Quicksand",
        },
      },
      "&>.suggestion": {
        backgroundColor: "#fff",
        maxHeight: "400px",
        overflowY: "auto",
        "&>.item": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "45px",
          "&:hover": {
            backgroundColor: "#f8f8f8",
          },
          "&.cursorActive": {
            backgroundColor: "#ddd",
            "&:hover": {
              backgroundColor: "#c8c8c8",
            },
          },
          "&>.icon": {
            margin: "0px 10px",
          },
          "&>.label": {
            flexGrow: "1",
            fontSize: "1.2em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "&>kbd": {
            fontSize: "0.8em",
            paddingRight: "10px",
            "@media(max-width:560px)": {
              display: "none",
            },
          },
        },
      },
    },
  },
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(SpotlightSearch)),
);
