import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import axios from 'axios'
import _ from 'lodash'
import FileDownload from 'js-file-download'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'
import RefreshIcon from '@material-ui/icons/Refresh'

class AccountingReportProfitandLossAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: "",
			reportContent 	: null,
		}
	}

	componentWillReceiveProps(nextProps){
		if (!_.isEqual(this.state.reportContent, nextProps.record)) {
			this.setState({
				reportContent:nextProps.record
			})
		}
	}

	handleDownloadExcel = () => {
		this.setState({isLoading:true})
		const headers = { 
			headers:{
				"Authorization"	:"Bearer "+Cookies.get("accessToken"),
			},
			responseType:"arraybuffer"
		}
		axios.post(this.props.Session.host+"/api/account/accountingReportExcelize",
		{
			...this.state.reportContent
		},
		headers
		).then((response)=>{
			FileDownload(response.data,"Neraca.xlsx")
		}).catch(error=>{
			if (error) {
				if (error.response.status === 502) {
					console.error("Not connected to server")
				}
				if (this.props.error) {
					// this.props.error(error.response)
				}
			}
		}).finally(()=>{
			this.setState({isLoading:false})
		})
	}

	handleRefresh = () => {
		if (this.props.onRefreshClicked) {
			this.props.onRefreshClicked()
		}
	}

	
	render() {
		let buttons = []
		buttons.push(
			<Button 
				color="success" 
				key="download-pdf"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleDownloadExcel()}>
				<DownloadIcon/>{tl(Cookies.get("lang"),"download")} {tl(Cookies.get("lang"),"excel")}
			</Button>
		)
		// buttons.push(
		// 	<Button 
		// 		color="success" 
		// 		key="download-pdf"
		// 		size="sm"
		// 		className="rounded-button"
		// 		disabled={this.state.isLoading}  
		// 		onClick={()=>this.handleRefresh()}>
		// 		<RefreshIcon/>{tl(Cookies.get("lang"),"refreshData")}
		// 	</Button>
		// )
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingReportProfitandLossAction)
