import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import axios from 'axios'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import _ from 'lodash'
import FileDownload from 'js-file-download'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'
import RefreshIcon from '@material-ui/icons/Refresh'

class AccountingReportBalanceSheetAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: "",
			reportContent 	: null
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!_.isEqual(nextProps.record,this.state.reportContent)) {
			this.setState({reportContent:nextProps.record})
		}
	}

	handleDownloadExcel = () => {
		const headers = { 
			headers:{
				"Authorization"	:"Bearer "+Cookies.get("accessToken"),
			},
			responseType:"arraybuffer"
		}
		this.setState({isLoading:true})
		axios.post(this.props.Session.host+"/api/account/accountingReportExcelize",
		{
			...this.state.reportContent
		},
		headers
		).then((response)=>{
			FileDownload(response.data,"Neraca.xlsx")
			setTimeout(()=>{
				this.setState({isLoading:false})
			}, 1000)
		}).catch((error)=>{
			this.setState({isLoading:false})
		})
	}

	handleRefresh = () => {
		if (this.props.onRefreshClicked) {
			this.props.onRefreshClicked()
		}	
	}
	
	render() {
		let buttons = []
		buttons.push(
			<Button 
				color="success" 
				key="download-excel"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleDownloadExcel()}>
				<DownloadIcon/>{tl(Cookies.get("lang"),"download")} {tl(Cookies.get("lang"),"excel")}
			</Button>
		)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingReportBalanceSheetAction)
