const Translations = {
	id:{
		"base":"base",
		//Company
		"Company":"Perusahaan",
		"company":"Perusahaan",
		"company.activate":"Aktifkan",
		"Company.confirmTitle.action/activate":"Aktifkan",
		"Company.confirmContent.action/activate":"Apakah Anda yakin ingin mengaktifkan Perusahaan ini?",
		"Company.success.action/activate":"Perusahaan telah berhasil diaktifkan",
		"company.deactivate":"Nonaktifkan",
		"Company.confirmTitle.action/deactivate":"Nonaktifkan",
		"Company.confirmContent.action/deactivate":"Apakah Anda yakin ingin menonaktifkan Perusahaan ini?",
		"Company.success.action/deactivate":"Perusahaan telah berhasil dinonaktifkan",
		"Company.code":"Kode",
		"Company.name":"Nama",
		"Company.email":"Email",
		"Company.contact":"Nomor Kontak",
		"Company.address":"Alamat",
		"Company.cityId":"Kota",
		"Company.city":"Kota",
		"Company.provinceId":"Provinsi",
		"Company.province":"Provinsi",
		"Company.countryId":"Negara",
		"Company.country":"Negara",
		"Company.zip":"Kode Pos",
		"Company.imageId":"Gambar",
		"Company.image":"Gambar",
		"Company.default":"Utama",
		"Company.state":"Status",
		//MENUS
		"Company Configuration":"Konfigurasi Perusahaan",
		"Company":"Perusahaan",
		"CompanyConfigurationCompany":"Perusahaan",
		"customerAccount":"Akun Pelanggan",
		"employeeAccount":"Akun Pegawai",
		"supplierAccount":"Akun Pemasok",
		"defaultUserGroup":"Grup Pengguna Standar",

		//default user groups
		"DefaultUserGroup.defaultCustomerUserGroup":"Grup Pengguna Standar untuk Pelanggan",
		"DefaultUserGroup.defaultEmployeeUserGroup":"Grup Pengguna Standar untuk Pegawai",
		"DefaultUserGroup.defaultSupplierUserGroup":"Grup Pengguna Standar untuk Pemasok",

		//User
		"User.password":"Kata Sandi",
		"User.passwordConfirm":"Konfirmasi Kata Sandi",

		//Register & Custom Template
		"RegisterTemplate":"Template Registrasi",
		"CustomTemplate":"Template Kustom",
		"Template.name":"Nama",
		"Template.cleanUrl":"Clean URL",
		"Template.state":"Status",
		"Template.name":"Nama",
		"Template.state":"Status",
		"Template.lines":"Template",
		"TemplateLine.position":"Posisi",
		"TemplateLine.name":"Nama",
		"TemplateLine.label":"Label",
		"TemplateLine.placeholder":"Placeholder",
		"TemplateLine.type":"Tipe Isian",
		"TemplateLine.selections":"Pilihan",
		"TemplateLine.required":"Wajib diisi?",
		"TemplateLine.multipleSelection":"Pil. Ganda?",

		"Template.activate":"Aktifkan",
		"Template.deactivate":"Nonaktifkan",
		"Template.confirmTitle.action/activate":"Pengaktifan Template",
		"Template.confirmTitle.action/deactivate":"Nonaktikan Template",
		"Template.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan template ini?",
		"Template.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan template ini?",
		"Template.success.action/activate":"Template sudah berhasil diaktifkan",
		"Template.success.action/deactivate":"Template sudah berhasil dinonaktifkan",

		//OPTIONS
		"active":"Aktif",
		"inactive":"Nonaktif",
		"string":"Teks",
		"integer":"Angka Bulat",
		"float":"Angka Pecahan",
		"selection":"Pilihan",
		"boolean":"Benar / Salah"
	},
	en:{
		"base":"base",
		//Company
		"Company":"Company",
		"company":"Company",
		"company.activate":"Activate",
		"Company.confirmTitle.action/activate":"Activate",
		"Company.confirmContent.action/activate":"Are you sure you want to activate this Company?",
		"Company.success.action/activate":"Company is successfully activated",
		"company.deactivate":"Deactivate",
		"Company.confirmTitle.action/deactivate":"Deactivate",
		"Company.confirmContent.action/deactivate":"Are you sure you want to deactivate this Company?",
		"Company.success.action/deactivate":"Company is successfully deactivated",
		"Company.code":"Code",
		"Company.name":"Name",
		"Company.email":"Email",
		"Company.contact":"Contact Number",
		"Company.address":"Address",
		"Company.cityId":"City",
		"Company.city":"City",
		"Company.provinceId":"Province",
		"Company.province":"Province",
		"Company.countryId":"Country",
		"Company.country":"Country",
		"Company.zip":"Zip",
		"Company.imageId":"Image",
		"Company.image":"Image",
		"Company.default":"Default",
		"Company.state":"State",
		//MENUS
		"Company Configuration":"Company Configuration",
		"Company":"Company",
		"CompanyConfigurationCompany":"Company",
		"customerAccount":"Customer Account",
		"employeeAccount":"Employee Account",
		"supplierAccount":"Supplier Account",
		"defaultUserGroup":"Default User group",

		//default user groups
		"DefaultUserGroup.defaultCustomerUserGroup":"Default Customer User Group",
		"DefaultUserGroup.defaultEmployeeUserGroup":"Default Employee User Group",
		"DefaultUserGroup.defaultSupplierUserGroup":"Default Supplier User Group",

		//User
		"User.password":"Password",
		"User.passwordConfirm":"Confirm Password",

		//Register & Custom Template
		"RegisterTemplate":"Register Template",
		"CustomTemplate":"Register Template",
		"Template.name":"Name",
		"Template.cleanUrl":"clean URL",
		"Template.state":"Status",
		"Template.lines":"Template",
		"TemplateLine.position":"Position",
		"TemplateLine.name":"Name",
		"TemplateLine.label":"Label",
		"TemplateLine.placeholder":"Placeholder",
		"TemplateLine.type":"Type",
		"TemplateLine.selections":"Selections",
		"TemplateLine.required":"Required?",
		"TemplateLine.multipleSelection":"Multiple Selection?",

		"Template.activate":"Activate",
		"Template.deactivate":"Deactivate",
		"Template.confirmTitle.action/activate":"Template Activation",
		"Template.confirmTitle.action/deactivate":"Template Deactivation",
		"Template.confirmContent.action/activate":"Are you sure want to activate this template?",
		"Template.confirmContent.action/deactivate":"Are you sure want to deactivate this template?",
		"Template.success.action/activate":"Template sucessfully activated",
		"Template.success.action/deactivate":"Template successfully deactivated",

		//OPTIONS
		"active":"Active",
		"inactive":"Inactive",
	}
}

export default Translations
