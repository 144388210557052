import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/DetailedVoucherReport/DetailedVoucherReportAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class ARDetailedVoucherReport extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/DetailedVoucherReport/DetailedVoucherReport")
        this.compName = "APDetailedReportActivity"
	}

	render() {
		return (
			<Activity 
				name={this.compName}
				RenderedComponent={this.RenderedComponent}
				context={this.compName}
				action={this.compName}
				formActions={FormAction}
            />
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(ARDetailedVoucherReport)
