import React, { Component } from 'react'
import ReportDownloadButton from "framework/components/ReportDownloadButton"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class TrialBalanceAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
	}

	render() {
		let buttons = []
		buttons.push(<ReportDownloadButton
			record={this.props.record}
			model="accounting/trial_balance"
		/>)
		return (
			<React.Fragment>	
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(TrialBalanceAction)

