const NotificationButtonStyle = {
  customBadge: {
    backgroundColor: "#e50f11",
    color: "#fff",
  },
  mainButton: {
    color: "#747474",
    marginRight: "10px",
    "@media(max-width:560px)": {
      marginRight: "0px",
    },
  },
  notificationPane: {
    position: "fixed",
    zIndex: "10000 !important",
    top: "60px",
    right: "137px",
    opacity: 1,
    height: "500px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px 10px",
    pointerEvents: "auto",
  },
  notifPane: {
    position: "absolute",
    zIndex: "10000 !important",
    marginTop: "0px",
    top: "50px",
    right: 0,
    opacity: 1,
    height: "500px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px 10px",
    pointerEvents: "auto",
    "&.hide": {
      opacity: "0",
      top: "-600px",
    },
  },
  paneHide: {
    position: "fixed",
    zIndex: "10000 !important",
    top: "-560px",
    opacity: 0,
    right: "137px",
    height: "500px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px",
  },
  mobilePane: {
    position: "fixed",
    zIndex: "10000 !important",
    top: "60px",
    right: "0px",
    opacity: 1,
    height: "500px",
    width: "100vw",
    backgroundColor: "#fff",
    borderRadius: "7px",
    boxShadow: "0px 10px 15px rgba(0,0,0,0.2)",
    transition: "0.3s ease-in",
    padding: "20px",
    pointerEvents: "auto",
  },
  paneContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paneHeading: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
  },
  paneTitle: {
    padding: "0px",
    margin: "0px",
    fontWeight: "bold",
    fontSize: "1.2em",
    lineHeight: "1.6",
    flexGrow: "1",
    marginLeft: "10px",
  },
  paneClose: {
    marginLeft: "auto",
    color: "#ccc",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  },
  notificationItems: {
    width: "100%",
    height: "390px",
    overflowY: "scroll",
    paddingRight: "15px",
    "&::-webkit-scrollbar": {
      width: "10px",
      marginRight: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "10px",
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#ccc",
      },
    },
  },
  notifItem: {
    width: "100%",
    display: "flex",
    paddingBottom: "5px",
    borderBottom: "1px solid #ddd",
    "&:last-of-type": {
      borderBottom: "none",
    },
    "&.clickable": {
      userSelect: "none",
      cursor: "pointer",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
  },
  notifIcon: {
    minWidth: "40px",
    marginTop: "7px",
    textAlign: "center",
  },
  notifContent: {
    flexGrow: "1",
  },
  notifHeading: {
    display: "flex",
    alignItems: "center",
  },
  notifTitle: {
    fontSize: "1em",
    fontWeight: "bold",
  },
  notifTitleRead: {
    fontSize: "1em",
  },
  notifTime: {
    marginTop: "10px",
    width: "100%",
    textAlign: "right",
    fontSize: "0.8em",
    marginLeft: "auto",
    color: "#888",
    padding: "0px 10px",
  },
  notifText: {
    lineHeight: "1.2",
    paddingRight: "20px",
  },
  notifAction: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  paneBottom: {
    marginTop: "10px",
    width: "100%",
  },
  bottomButton: {
    fontWeight: "bold",
  },
  notifPushContainer: {
    maxHeight: "auto",
    position: "fixed",
    bottom: "0px",
    right: "0px",
    transition: "0.3s ease-in-out",
    "&.closed": {
      right: "-340px",
      transition: "0.3s ease-in-out",
    },
  },
  notifPush: {
    width: "300px",
    backgroundColor: "#fff",
    padding: "10px 10px 5px 10px",
    borderRadius: "5px",
    pointerEvents: "auto",
    boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
  },
  notifPushHeader: {
    width: "100%",
    display: "flex",
  },
  notifPushTitle: {
    flexGrow: "1",
    fontWeight: "bold",
    lineHeight: "1.7",
  },
  notifPushClose: {
    width: "20px",
    height: "20px",
    color: "#ccc",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#aaa",
    },
  },
  notifPushBody: {
    margin: "0px 0px 10px 0px",
  },
  notifPushActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  notifPushIcon: {
    marginRight: "10px",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  miniIcon: {
    width: "10px",
  },
  infiniteLoader: {
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingLabel: {
    margin: "0px 10px",
  },
  loadingPane: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  noNotification: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  paneSpinner: {
    marginBottom: "10px",
  },
};

export default NotificationButtonStyle;
