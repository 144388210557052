import React, { Component } from "react";
import { sortBy, find } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { tl } from "framework/utils/Translator";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UnheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const menuCategories = [
  {
    id: 1,
    name: "Finance",
    orderNum: 1,
    state: "active",
  },
  {
    id: 2,
    name: "Sales",
    orderNum: 2,
    state: "active",
  },
  {
    id: 3,
    name: "Purchase",
    orderNum: 3,
    state: "active",
  },
  {
    id: 4,
    name: "Administraion",
    orderNum: 4,
    state: "active",
  },
];

const menus = [
  {
    id: 1,
    name: "Invoice",
    categoryId: 1,
    orderNum: 1,
  },
  {
    id: 2,
    name: "Sales Order",
    categoryId: 2,
    orderNum: 1,
  },
  {
    id: 3,
    name: "Purchase Order",
    categoryId: 3,
    orderNum: 1,
  },
  {
    id: 4,
    name: "Instant Sales",
    categoryId: 2,
    orderNum: 3,
  },
];

const styles = {
  container: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
    overflow: "hidden",
  },
  content: {
    maxHeight: "400px",
    overflow: "auto",
  },
  title: {
    width: "100%",
    padding: "5px 10px",
    backgroundColor: "#eee",
    fontWeight: "bold",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  group: {
    width: "100%",
    padding: "3px 10px",
    backgroundColor: "#f8f8f8",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  item: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "3px 10px 3px 30px",
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: "#b3e5fc",
    },
  },
  itemDisabled: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "3px 10px 3px 30px",
    overflow: "hidden",
  },
  itemIcon: {
    color: "#aaa",
    fontSize: "1rem",
  },
  itemText: {
    marginLeft: "10px",
    flexGrow: "1",
    userSelect: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "@media (max-width:560px)": {
    item: {
      padding: "5px 10px 5px 30px",
    },
    itemDisabled: {
      padding: "5px 10px 5px 30px",
    },
  },
};

class AccessRightEditor extends Component {
  state = {
    accessRights: [],
    menus: [],
  };

  toggleMenu = (menu) => {
    if (find(this.state.menus, ["id", menu.id])) {
      this.setState({
        menus: this.state.menus.filter((tmenu) => {
          if (tmenu.id !== menu.id) {
            return tmenu;
          }
        }),
      });
    } else {
      this.setState({
        menus: [...this.state.menus, menu],
      });
    }
  };

  render() {
    const { classes } = this.props;
    let sortedMnCategory = sortBy(menuCategories, ["orderNum"]);
    return (
      <div style={{ marginTop: "40px" }}>
        <div className={classes.container}>
          <div className={classes.title}>Menu Categories</div>
          <div className={classes.content}>
            {sortedMnCategory.map((category, categoryIndex) => {
              let categoryMenus = menus.filter((menu) => {
                if (menu.categoryId === category.id) {
                  return menu;
                }
              });
              return (
                <div>
                  <div className={classes.group}>
                    {categoryIndex + 1}. {category.name}
                  </div>
                  {categoryMenus.length > 0 &&
                    categoryMenus.map((menu) => {
                      return (
                        <div
                          className={classes.item}
                          onClick={() => this.toggleMenu(menu)}
                        >
                          {find(this.state.menus, ["id", menu.id]) ? (
                            <CheckedIcon className={classes.itemIcon} />
                          ) : (
                            <UnheckedIcon className={classes.itemIcon} />
                          )}{" "}
                          <div className={classes.itemText}>{menu.name}</div>
                        </div>
                      );
                    })}
                  {categoryMenus.length === 0 && (
                    <div className={classes.itemDisabled}>
                      <UnheckedIcon
                        className={classes.itemIcon}
                        style={{ visibility: "hidden" }}
                      />
                      <div
                        className={classes.itemText}
                        style={{ opacity: "0.5" }}
                      >
                        {tl("none")}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AccessRightEditor);
