import React, { Component } from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";
import _ from "lodash";

class DebugTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      host: this.props.Session.host,
    };
    this.delayedCallback = _.debounce(this.applyChange, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.host !== nextProps.Session.host) {
      this.setState({
        host: nextProps.Session.host,
      });
    }
  }

  applyChange = (value) => {
    this.props.setHost(value);
    Cookies.set("debugHost", value, { path: "/", expires: null });
  };

  handleChange = (event) => {
    let { value } = event.target ? event.target : "";
    event.persist();
    this.delayedCallback(value);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="debug-tool"
          style={{
            position: "fixed",
            bottom: "0",
            left: "0",
            zIndex: "99999",
            padding: "5px",
            margin: "5px",
            backgroundColor: "white",
          }}
        >
          <input
            type="text"
            onChange={(event) => this.handleChange(event)}
            defaultValue={this.state.host}
          ></input>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebugTool);
