import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'
import FileDownload from 'js-file-download'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'

class PurchasingReportOutstandingPurchaseOrderAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
		this.FileDownload = FileDownload
	}

	handleDownload = () => {
		let record = this.props.record
		this.setState({isLoading:true})
		API("download","report/outstanding_purchase_order_report",record)
		.then(response=>{
			this.FileDownload(response.data, 'report.pdf')
			setTimeout(()=>{
				this.setState({isLoading:false})
			}, 1000)
		})
		.catch(error=>{
			this.setState({isLoading:false})
			if(error.response){
				this.props.error(error.response.data.fields)
			} else {
				this.props.setNotification(true,"serverError","danger")
				setTimeout(()=>this.props.setNotification(false,"serverError","danger"),3000)
			}
		})
	}
	
	render() {
		let buttons = []
		buttons.push(
			<Button 
				color="success" 
				key="download-pdf"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleDownload()}>
				<DownloadIcon/>{tl(Cookies.get("lang"),"Download")}
			</Button>
		)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(PurchasingReportOutstandingPurchaseOrderAction)
