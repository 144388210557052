import React, { Component } from "react";
import { tl, formatDateLocale } from "framework/utils/Translator";
import Cookies from "js-cookie";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import ReportIcon from "@material-ui/icons/Report";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";
import "assets/scss/custom-datepicker-style.css";
import _ from "lodash";

export default class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : "",
      field: props.field,
      dateValue: null, //only for filtering purpose
      popupOpen: false,
    };
    if (props.value) {
      let dateString = props.value.split("T")[0];
      let parsedDate = moment(dateString).format("YYYY-MM-DD");
      if (parsedDate === "Invalid date") {
        this.state.value = "";
      } else {
        this.state.value = parsedDate;
      }
    } else {
      let newDate = moment().format('YYYY-MM-DD')
      this.state.value = newDate
    }
    this.dateInput = React.createRef();

    if (this.props.renderMode === "gridRenderer") {
      let currentRowIndex = props.rowIndex;
      if (
        typeof props.cellErrors === "object" &&
        props.cellErrors !== null &&
        currentRowIndex in props.cellErrors
      ) {
        if (props.field in props.cellErrors[currentRowIndex]) {
          let errorText = _.get(props.cellErrors[currentRowIndex], props.field);
          if (props.value && errorText === "cannot be blank") {
            this.state._error = "";
          } else {
            this.state._error = tl(Cookies.get("lang"), errorText);
          }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.disableExternalChanges) {
      if (
        !nextProps.value ||
        moment(nextProps.value).format("YYYY-MM-DD") === "Invalid date"
      ) {
        this.setState({ value: "" });
      } else {
        this.setState({ value: moment(nextProps.value).format("YYYY-MM-DD") });
      }
    }
  }

  parseDate8601 = (date) => {
    if (date === "Invalid date") {
      return "";
    } else {
      return date + "T00:00:00Z";
    }
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    console.log("selected", e.target.value);
    if (
      ["formCreate", "formEdit"].includes(this.props.renderMode) &&
      this.props.onChange
    ) {
      this.props.onChange(
        this.props.field,
        this.parseDate8601(moment(e.target.value).format("YYYY-MM-DD")),
      );
    }
  };

  onKeyDown(event) {
    if (
      event.keyCode === 37 ||
      event.keyCode === 38 ||
      event.keyCode === 39 ||
      event.keyCode === 40
    ) {
      event.stopPropagation();
    }
  }

  afterGuiAttached() {
    if (this.props.renderMode === "gridEditor") {
      if (this.dateInput) this.dateInput.current.focus();
    }
  }

  getValue() {
    var dateToSet = moment(this.dateInput.current.value).format("YYYY-MM-DD");
    if (dateToSet === "Invalid date") {
      return "";
    } else {
      return dateToSet + "T00:00:00Z";
    }
  }

  parseDateToYMD = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  isPopup() {
    return true;
  }

  handleChangeFilter = (event) => {
    this.setState({ dateValue: event.target.value });
  };

  applyFilter = () => {
    this.props.onChange(this.state.dateValue);
  };

  handleTogglePopup = () => {
    this.setState({ popupOpen: !this.state.popupOpen });
  };

  handleChangeTest = (event) => {
    let dateToSet = moment(event.target.value).format("YYYY-MM-DD");
    if (dateToSet === "Invalid date") {
      this.props.onChange(this.props.field, "");
    } else {
      this.props.onChange(this.props.field, dateToSet + "T00:00:00Z");
    }
  };

  handleEnterPressed = (e) => {
    if (e.keyCode === 13) {
      if (this.props.onEnterPressed && this.props.renderMode !== "formView") {
        this.props.onEnterPressed(this.state.value);
      }
    }
  };

  render() {
    var labelText = null;
    if (this.props.label) {
      labelText = this.props.label;
    } else {
      labelText = tl(Cookies.get("lang"), this.props.field);
    }
    var InputComponent = null;
    if (["formCreate", "formEdit"].includes(this.props.renderMode)) {
      InputComponent = (
        <div>
          {!this.props.disableLabel && (
            <InputLabel
              shrink
              className={`input-label ${this.props.white ? "white" : ""}`}
            >
              {labelText}
              {this.props.required ? "*" : ""}
            </InputLabel>
          )}
          <div
            className={`date-input-container ${
              this.props.renderMode !== "formView" &&
              this.props.editable === false &&
              this.props.washedOff !== false &&
              "input-disabled"
            }`}
          >
            <input
              type="date"
              className="date-input basic-inner-input-base"
              value={this.state.value ? this.state.value : ""}
              onChange={this.handleChange}
              disabled={this.props.editable === false}
              ref={ref=>{
                if (this.props.innerRef) {
                  this.props.innerRef(ref)
                }
              }}
              {...(this.props.onEnterPressed
                ? {
                    onKeyDown: this.handleEnterPressed,
                  }
                : {})}
            />
            <Tooltip
              style={{ display: this.props.error ? "inline-block" : "none" }}
              title={this.props.error ? this.props.error : ""}
            >
              <ReportIcon className="basic-input-error-icon date-input-error-icon" />
            </Tooltip>
          </div>
        </div>
      );
    } else if (this.props.renderMode === "formView") {
      InputComponent = (
        <div>
          {!this.props.disableLabel && (
            <InputLabel
              shrink
              className={`input-label ${this.props.white ? "white" : ""}`}
            >
              {labelText}
              {this.props.required ? "*" : ""}
            </InputLabel>
          )}
          <div className="basic-input-container">
            <InputBase
              disabled={true}
              className="basic-input-base"
              fullWidth={true}
              value={
                this.state.value
                  ? formatDateLocale(Cookies.get("lang"), this.props.value)
                  : ""
              }
              inputProps={{
                type: "text",
                className: "basic-inner-input-base",
              }}
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridEditor") {
      InputComponent = (
        <div>
          <div className="basic-input-container">
            <input
              className="grid-date-input"
              defaultValue={
                this.props.value ? this.parseDateToYMD(this.props.value) : ""
              }
              ref={this.dateInput}
              onChange={this.handleChange}
              type="date"
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridRenderer") {
      InputComponent = (
        <div className="grid-renderer-container">
          <div
            className={`grid-renderer-content ${
              this.props.value === null && this.props.required === true
                ? "value-null"
                : ""
            }`}
          >
            {this.props.value
              ? formatDateLocale(Cookies.get("lang"), this.props.value)
              : ""}
          </div>
          <Tooltip
            style={{ display: this.state._error ? "inline-block" : "none" }}
            title={this.state._error ? this.state._error : ""}
          >
            <ReportIcon className="basic-grid-error-icon" />
          </Tooltip>
        </div>
      );
    } else if (this.props.renderMode === "browserFilter") {
      InputComponent = (
        <div>
          <div
            className="basic-input-container"
            onClick={() => this.handleTogglePopup()}
            onBlur={() => this.handleTogglePopup()}
          >
            <div className="date-range-value">
              {this.props.value
                ? formatDateLocale(Cookies.get("lang"), this.props.value)
                : ""}
            </div>
          </div>
          <div
            className={`browser-filter-popup-container ${
              this.state.popupOpen ? "open" : ""
            }`}
          >
            <input
              className="grid-date-input"
              style={{ width: "100%" }}
              defaultValue={this.parseDateToYMD(this.props.value)}
              onChange={(event) => this.handleChangeFilter(event)}
              type="date"
            />
            <div className="browser-filter-popup-buttons">
              <Button size="sm" onClick={() => this.applyFilter()}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return InputComponent;
  }
}
