import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/GeneralLedger/AccountingReportGeneralLedgerAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class AccountingReportGeneralLedgerActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/GeneralLedger/AccountingReportGeneralLedger")
	}

	render() {
		return (
			<Activity 
				name="AccountingReportGeneralLedger"
				RenderedComponent={this.RenderedComponent}
				context="AccountingReportGeneralLedger"
				action="AccountingReportGeneralLedger"
				formActions={FormAction}			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingReportGeneralLedgerActivity)
