import React, { Component } from "react";
import { ButtonBase } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.files,
    };
  }

  handleSendImage() {
    this.props.sendImage();
  }

  closePreview() {
    this.props.closePreview();
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          width: "100%",
          borderTop: "1px solid #f5f5f5",
          position: "absolute",
          bottom: "0px",
        }}
      >
        <div style={{ display: "flex", height: "30px", alignItems: "center" }}>
          <ButtonBase onClick={this.closePreview.bind(this)}>
            <CloseIcon />
          </ButtonBase>
          <p style={{ margin: "0px" }}>preview image</p>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "250px",
          }}
        >
          <img
            style={{
              maxWidth: "350px",
              maxHeight: "250px",
              margin: "auto",
              display: "block",
            }}
            src={"data:image/*;base64," + this.state.files.file}
          />
        </div>
        <ButtonBase
          onClick={this.handleSendImage.bind(this)}
          className={classes.btnSendImage}
        >
          <SendIcon style={{ color: "#3c4858" }} />
        </ButtonBase>
      </div>
    );
  }
}
export default ImagePreview;
