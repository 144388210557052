//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

//activities
import CompanyConfigurationCompanyActivity from 'app/modules/Base/activities/CompanyConfiguration/CompanyConfigurationCompanyActivity'
import SupplierAccountActivity from 'app/modules/Base/activities/User/SupplierAccountActivity'
import CustomerAccountActivity from 'app/modules/Base/activities/User/CustomerAccountActivity'
import EmployeeAccountActivity from 'app/modules/Base/activities/User/EmployeeAccountActivity'
import DefaultUserGroupActivity from 'app/modules/Base/activities/DefaultUserGroup/DefaultUserGroupActivity'
import RegisterTemplateActivity from "app/modules/Base/activities/RegisterTemplate/RegisterTemplateActivity";
import CustomTemplateActivity from "app/modules/Base/activities/CustomTemplate/CustomTemplateActivity";

// @material-ui/icons
import AccountIcon from "@material-ui/icons/AccountCircle"
import CompanyIcon from "@material-ui/icons/LocationCity"
import GroupIcon from "@material-ui/icons/Group"
import FormIcon from "@material-ui/icons/Assignment"

const lang = Cookies.get("lang")
var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Company Configuration"),
	    menu		: "COMP",
	    icon 		: CompanyIcon,
	    state		: "COMP",
	    views		:[
			{
				path		: "/company-configuration-company/:id?",
				name		: tl(Cookies.get("lang"),"CompanyConfigurationCompany"),
				menu		: "COMP01",
				icon 		: CompanyIcon,
				component 	: CompanyConfigurationCompanyActivity,
				layout		: "/admin"
			},
			{
				path		: "/employee-accounts/:id?",
				name		: tl(lang,"employeeAccount"),
				menu		: "COMP02",
				icon 		: AccountIcon,
				component 	: EmployeeAccountActivity,
				layout		: "/admin"
			},
			{
				path		: "/customer-accounts/:id?",
				name		: tl(lang,"customerAccount"),
				menu		: "COMP03",
				icon 		: AccountIcon,
				component 	: CustomerAccountActivity,
				layout		: "/admin"
			},
			{
				path		: "/supplier-accounts/:id?",
				name		: tl(lang,"supplierAccount"),
				menu		: "COMP04",
				icon 		: AccountIcon,
				component 	: SupplierAccountActivity,
				layout		: "/admin"
			},
			{
				path		: "/default-user-group",
				name		: tl(lang,"defaultUserGroup"),
				menu		: "COMP05",
				icon 		: GroupIcon,
				component 	: DefaultUserGroupActivity,
				layout		: "/admin"
			},
			{
				path		: "/register-template/:id?",
				name		: tl("RegisterTemplate"),
				menu		: "COMPREGTEMP",
				icon 		: FormIcon,
				component 	: RegisterTemplateActivity,
				layout		: "/admin"
			},
			{
				path		: "/custom-template/:id?",
				name		: tl("CustomTemplate"),
				menu		: "COMPREGTEMP",
				icon 		: FormIcon,
				component 	: CustomTemplateActivity,
				layout		: "/admin"
			},

		]
	},
]
export default Routes
