import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class SupplierAccountActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Base/User/UserBrowser")
	}

	render() {
		return (
			<Activity 
				name="supplierAccount"
				RenderedComponent={this.RenderedComponent}
				context="isSupplier"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(SupplierAccountActivity)