const Translations = {
    id : {
        //Menu folders
        "Web":"Web",
        "Web Configuration":"Konfigurasi Web",

        //blog
        BlogPostActivity : "Postingan Blog",
        Post: "Postingan",
        unposted:"Belum Diposting",
        "Blog.Title" : "Judul",
        uploadImage : "Unggah Gambar",
        pasteLink : "Tempel link disini...",
        maxFile : "Maks, ukuran file yang diterima adalah 2MB",
        postTitle : "Judul Postingan",
        publishDate : "Tanggal Terbit",
        writer : "Penulis",
        "BlogPost.confirmContent.delete" : "Apakah anda yakin ingin menghapus postingan ini?",
        "Blog.cleanUrl" : 'URL sederhana',
        "Blog.category" : 'Kategori',
        "Item.thumbnail" : 'Thumbnail',
        shortDescription : 'Deskripsi Singkat',
        metaDescription : 'Deskripsi Meta',
        headerImage : 'Gambar Header',
        "BlogPost.viewCount":"Jumlah View",
        "BlogPost.writer":"Penulis",
        "BlogPost.state":"Status",
        "BlogPost.draft":"Draf",
        "BlogPost.publish":"Publikasi",
        "BlogPost.unpublish":"Batalkan Publikasi",
        "BlogPost.confirmTitle.action/draft":"Draf",
        "BlogPost.confirmTitle.action/publish":"Dipublikasi",
        "BlogPost.confirmTitle.action/unpublish":"Publikasi Dibatalkan",
        "BlogPost.confirmContent.action/draft":"Apa Anda yakin akan mengubah status post ini ke draf?",
        "BlogPost.confirmContent.action/publish":"Apa anda yakin akan mempublikasikan post ini?",
        "BlogPost.confirmContent.action/unpublish":"Apa Anda yakin akan membatalkan publikasi post ini?",
        "BlogPost.success.action/draft":"Post berhasil dikembalikan ke draf",
        "BlogPost.success.action/publish":"Post berhasil dipublikasikan",
        "BlogPost.success.action/unpublish":"Post telah berhasil dibatalkan publikasinya",
        "Blog.state" : "Status",

        //contact submission
        "ContactSubmission":"Kontak",
        "contact_submission":"Kontak",
        "ContactSubmission.name":"Nama",
        "ContactSubmission.email":"E-mail",
        "ContactSubmission.mobile":"No. Telp",
        "ContactSubmission.message":"Pesan",
        "ContactSubmission.contactSubmissionTopic":"Topik Pengajuan Kontak",
        "ContactSubmission.additionalInfo":"Info Tambahan",
        "ContactSubmission.state":"Status",

        //contact submission topic
        "ContactSubmissionTopic":"Topik Kontak",
        "cantact_submission_topic":"Topik Kontak",
        "ContactSubmissionTopic.name":"Nama Topik",
        "ContactSubmissionTopic.sequence":"Urutan",
        "ContactSubmissionTopic.state":"status",

        //Newsletter
		"Newsletter":"Buletin",
		"Newsletter.name":"No. Buletin",
		"Newsletter.title":"Judul",
		"Newsletter.emailTitle":"Judul E-mail",
		"Newsletter.date":"Tanggal",
		"Newsletter.content":"Konten",
		"Newsletter.emailContent":"Konten E-mail",
		"Newsletter.attachments":"Lampiran",
		"Newsletter.attachment":"Berkas",
		"Newsletter.teaser":"Teaser",
        "Newsletter.sendAfterDays":"Dikirim dalam (hari)",
        "Newsletter.shortDescription":"Deskripsi Pendek",
        "Newsletter.state":"Status",
        "Newsletter.publish":"Publikasikan",
        "Newsletter.unpublish":"Batalkan Publikasi",
        "Newsletter.confirmTitle.action/publish":"Publikasi",
        "Newsletter.confirmContent.action/publish":"Apa Anda yakin akan mempublikasikan buletin ini?",
        "Newsletter.success.action/publish":"Buletin sukses dipublikasi",
        "Newsletter.confirmTitle.action/unpublish":"Batalkan Publikasi",
        "Newsletter.confirmContent.action/unpublish":"Apa Anda yakin akan membatalkan publikasi buletin ini?",
        "Newsletter.success.action/unpublish":"Publikasi buletin sukses dibatalkan",
        "Newsletter.readerList":"Telah dibaca",
        "Newsletter.header":"Header",
        "Newsletter.thumbnail":"Thumbnail",
        "Newsletter.noOneHasRead":"Buletin ini belum memiliki pembaca, pastikan anda telah mempublikasinya terlebih dahulu",
        
        //Participants
        "Participant":"Subscriber",
        "participant":"subscriber",
        "Participant.title":"Gelar",
        "Participant.name":"Nama Lengkap",
        "Participant.email":"E-mail",
        "Participant.joinDate":"Tanggal Daftar",
        "Participant.mobile":"Telepon/HP",
        "Participant.whatsApp":"No. WhatsApp",
        "Participant.street":"Alamat",
        "Participant.birthPlace":"Tempat Lahir",
        "Participant.birthDate":"Tanggal Lahir",
        "Participant.hobby":"Hobi",
        "Participant.state":"Status",
        "Participant.facebook":"Facebook",
        "Participant.instagram":"Instagram",
        "Participant.lastEducation":"Pendidikan Terakhir",
        "Participant.workExperience":"Pengalaman Bekerja",
        "Participant.everJoinedBroker":"Pernah Ikut Broker?",
        "Participant.brokerBefore":"Broker Sebelummnya",
        "Participant.attachment":"Bukti Pembayaran",
        "Participant.campaign":"Kampanye",
        "Participant.socmedContent":"Konten Sosial Media",
        "Participant.screeningState":"Status Skrining",
        "Participant.payment_confirmed":"Konfirmasi Bayar",
        "Participant.unpaid":"Belum Bayar",
        "Participant.ongoing_training":"Pelatihan Berlangsung",
        "Participant.accepted":"Terima",
        "Participant.rejected":"Tolak",
        "Participant.resigned":"Mengundurkan Diri",
        "Participant.cancelled":"Batalkan",
        "Participant.subscribe":"Berlangganan",
        "Participant.payment_claimed":"Klaim Bayar",
        "Participant.activate":"Aktifkan",
        "Participant.deactivate":"Nonaktifkan",
        "Participant.confirmTitle.action/payment_confirmed":"Konfirmasi Bayar",
        "Participant.confirmTitle.action/unpaid":"Belum Bayar",
        "Participant.confirmTitle.action/ongoing_training":"Pelatihan Berlangsung",
        "Participant.confirmTitle.action/accepted":"Penerimaan",
        "Participant.confirmTitle.action/rejected":"Penolakan",
        "Participant.confirmTitle.action/resigned":"Pengunduran Diri",
        "Participant.confirmTitle.action/cancelled":"Pembatalan",
        "Participant.confirmTitle.action/subscribe":"Berlangganan",
        "Participant.confirmTitle.action/payment_claimed":"Klaim Pembayaran",
        "Participant.confirmTitle.action/activate":"Aktifkan",
        "Participant.confirmTitle.action/deactivate":"Nonaktifkan",
        "Participant.confirmContent.action/payment_confirmed":"Apakah Anda yakin akan mengkonfimasi pembayaran subscriber ini?",
        "Participant.confirmContent.action/unpaid":"Apakah Anda yakin akan mengubah subscriber ini menjadi belum bayar?",
        "Participant.confirmContent.action/ongoing_training":"Apakah Anda yakin akan mengubah subscriber ini sebagai 'Pelatihan Berlangsung'",
        "Participant.confirmContent.action/accepted":"Apakah Anda yakin akan menerima subscriber ini?",
        "Participant.confirmContent.action/rejected":"Apakah Anda yakin akan menolak subscriber ini?",
        "Participant.confirmContent.action/resigned":"Apakah Anda yakin akan mengubah subscriber ini menjadi 'Mengundurkan Diri'?",
        "Participant.confirmContent.action/cancelled":"Apakah Anda yakin akan membatalkan subscriber ini?",
        "Participant.confirmContent.action/subscribe":"Apakah Anda yakin akan mengembalikan data ini ke 'berlangganan'?",
        "Participant.confirmContent.action/payment_claimed":"Apakah Anda yakin akan mengklaim pembayaran subscriber ini?",
        "Participant.confirmContent.action/activate":"Apakah Anda yakin akan mengklaim mengaktifkan subscriber ini?",
        "Participant.confirmContent.action/deactivate":"Apakah Anda yakin akan mengklaim menonaktifkan subscriber ini?",
        "Participant.success.action/payment_confirmed":"Pembayaran telah terkonfirmasi",
        "Participant.success.action/unpaid":"Subscriber telah diset menjadi 'Belum Bayar'",
        "Participant.success.action/ongoing_training":"Subscriber telah diset menjadi 'Pelatihan Berlangsung'",
        "Participant.success.action/accepted":"Subscriber telah berhasil diterima",
        "Participant.success.action/rejected":"Subscriber telah berhasil ditolak",
        "Participant.success.action/resigned":"Subscriber telah berhasil diset menjadi 'Mengundurkan Diri'",
        "Participant.success.action/cancelled":"Subscriber telah berhasil dibatalkan",
        "Participant.success.action/subscribe":"Subscriber telah dikembalikan ke berlangganan",
        "Participant.success.action/payment_claimed":"Pembayaran peserta telah berhasil diklaim",
        "Participant.success.action/activate":"Subscriber telah berhasil diaktifkan",
        "Participant.success.action/deactivate":"Pembayaran subscriber telah berhasil dinonaktifkan",
        "Participant.information":"Informasi subscriber",
        "Participant.additionalInfo":"Informasi tambahan",
        "Participant.paymentInfo":"Informasi pembayaran",
        "Participant.campaignAndContent":"Kampanye dan Konten",
        "Participant.newsletter":"Buletin",
        "Participant.newsletterInfo":"Informasi Buletin",
        "Participant.newsletterRead":"Dibaca",
        "Participant.newsletterReadDate":"Tgl. Baca",

        //Campaign
        "Campaign":"Kampanye",
        "campaign":"Kampanye",
        "SocialMediaContent":"Konten Sosial Media",
        "social_media_content":"Konten Sosial Media",
        "Campaign.name":"Nama",
        "Campaign.code":"Kode",
        "Campaign.date":"Tanggal",
        "Campaign.description":"Deskripsi",
        "Campaign.link":"Tautan",
        "Campaign.copyCode":"Salin Kode",
        "Campaign.copyCode.success":"Kode telah disalin ke clipboard",
        "Campaign.viewCount":"Jumlah View",

        //portfolio
        "Portfolio":"Portfolio",
        "Portfolio.name":"Judul",
        "Portfolio.cleanURL":"Clean URL",
        "Portfolio.description":"Deskripsi",
        "Portfolio.metaDescription":"Deskripsi Meta",
        "Portfolio.category":"Kategori",
        "Portfolio.tags":"Tag",
        "Portfolio.showcases":"Konten Portfolio",
        "Portfolio.image":"Gambar",
        "Portfolio.attachmentTitle":"Judul Gambar",
        "Portfolio.attachmentDescription":"Deskripsi",
        "Portfolio.state":"Portfolio",
        "Portfolio.viewCount":"Jumlah View",

        //Portfolio Category
        "PortfolioCategory":"Kategori Portfolio",
        "PortfolioCategory.name":"Nama",
        "PortfolioCategory.sequence":"Urutan",
        "PortfolioCategory.image":"Gambar",
        "PortfolioCategory.thumbnail":"Cuplikan",
        "PortfolioCategory.state":"Status",
        "PortfolioCategory.parent":"Kategori Induk",
        "PortfolioCategory.metaDescription":"Desksipsi Meta",
        "PortfolioCategory.cleanURL":"Clean URL",

        //Portfolio Tag
        "PortfolioTag":"Tag Portfolio",
        "PortfolioTag.name":"Nama",
        "PortfolioTag.image":"Gambar",
        "PortfolioTag.thumbnail":"Cuplikan",
        "PortfolioTag.state":"Status",
 
        //options & states
        "read":"Sudah Dibaca",
        "unread":"Belum Dibaca",

        // Page (sitemap)
        "Page":"Halaman",
        "Page.name":"Nama",
        "Page.loc":"Lokasi (URL)",
        "Page.lastMod":"Terakhir Dimodifikasi",
        "Page.changeFreq":"Frekuensi Perubahan",
        "Page.priority":"Prioritas",
        "Page.blog":"Blog",
        "Page.blogCategory":"Kategori Blog",
        "Page.portfolio":"Portfolio",
        "Page.portfolioCategory":"Kategori Portfolio",
        "Page.product":"Barang",
        "Page.productCategory":"Kategori Barang",
        "Page.brand":"Merk",
        
        //Blog Category
        "BlogCategory" : 'Kategori Blog',
        "BlogCategory.code":"Kode",
        "BlogCategory.name":"Nama",
        "BlogCategory.cleanUrl":"Clean URL",
        "BlogCategory.sequence":"Urutan",
        "BlogCategory.parent":"Kategori Induk",
        "BlogCategory.image":"Gambar",
        "BlogCategory.thumbnail":"Cuplikan",
        "BlogCategory.hideOnReport":"Sembunyikan di laporan",
        "BlogCategory.metaDescription":"Deskripsi Meta",
        "BlogCategory.state":"Status",
        "BlogCategory.activate":"Aktifkan",
        "BlogCategory.deactivate":"Nonaktifkan",
        "BlogCategory.confirmTitle.action/activate":"Aktifkan",
        "BlogCategory.confirmTitle.action/deactivate":"Nonaktifkan",
        "BlogCategory.confirmContent.action/activate":"Apa anda yakin akan mengaktifkan kategori ini?",
        "BlogCategory.confirmContent.action/deactivate":"Apa anda yakin akan menonaktifkan kategori ini?",
        "BlogCategory.success.action/activate":"Kategori Blog berhasil diaktifkan",
        "BlogCategory.success.action/deactivate":"Kategori Blog berhasil dinonaktifkan",

        //Testimony
        "Testimony":"Testimoni",
        "Testimony.name":"Nama",
        "Testimony.title":"Gelar",
        "Testimony.testimony":"Testimoni",
        "Testimony.photo":"Foto",
        "Testimony.portfolio":"Portfolio",
        "Testimony.state":"status",
        "Testimony.activate":"Aktifkan",
        "Testimony.deactivate":"Nonaktifkan",
        "Testimony.confirmTitle.action/activate":"Aktifkan",
        "Testimony.confirmTitle.action/deactivate":"Nonaktifkan",
        "Testimony.confirmContent.action/activate":"Apa anda yakin akan mengaktifkan testimoni ini?",
        "Testimony.confirmContent.action/deactivate":"Apa anda yakin akan menonaktifkan testimoni ini?",
        "Testimony.success.action/activate":"Testimoni berhasil diaktifkan",
        "Testimony.success.action/deactivate":"Testimoni berhasil dinonaktifkan",

        // Blog Writer
        "Writer":"Penulis",
        "Writer.name":"Nama",
        "Writer.contact":"Kontak",
        "Writer.email":"E-mail",
        "Writer.link":"Link",
        "Writer.information":"Informasi",
        "Writer.linkedIn":"LinkedIn",
        "Writer.linkedInName":"Nama LinkedIn",
        "Writer.linkedInDataVanity":"Data Vanity",
        "Writer.linkedInLink":"Link LinkedIn",
        "Writer.state":"Status",
        "Writer.activate":"Aktifkan",
        "Writer.deactivate":"Nonaktifkan",
        "Writer.confirmTitle.action/activate":"Aktifkan",
        "Writer.confirmTitle.action/deactivate":"Nonaktifkan",
        "Writer.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan penulis ini?",
        "Writer.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan penulis ini?",
        "Writer.success.action/activate":"Penulis berhasil diaktifkan",
        "Writer.success.action/deactivate":"Penulis berhasil dinonaktifkan",

        //referral
        "Referral":"Referal",
        "Referral.code":"Kode",
        "Referral.name":"Name",
        "Referral.type":"Tipe",
        "Referral.adType":"Tipe Iklan",
        "Referral.site":"Website / Aplikasi",
        "Referral.partner":"Partner",
        "Referral.description":"Deskripsi",
        "Referral.state":"Status",
        "Referral.activate":"Aktifkan",
        "Referral.deactivate":"Nonaktifkan",
        "Referral.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan kode referal ini?",
        "Referral.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan kode referal ini?",
        "Referral.success.action/activate":"Kode referal telah berhasil diaktifkan",
        "Referral.success.action/deactivate":"Kode referal telah berhasil dinonaktifkan",

        "ReferralVisit":"Referral Visit",
        "ReferralVisit.dateTime":"Date & Time",
        "ReferralVisit.url":"URL",
        "ReferralVisit.referral":"Referral",
        "ReferralVisit.previousVisit":"Previous Visit",
        "ReferralVisit.conversionValue":"Conversion Value",
        "ReferralVisit.conversionQuantity":"Conversion Quantity",
        "ReferralVisit.state":"Status",

        //Site
        "Site":"Situs",
        "Site.name":"Nama",
        "Site.url":"URL",
        "Site.activate":"Aktifkan",
        "Site.deactivate":"Nonaktifkan",
        "Site.confirmContent.action/activate":"Apa Anda yakin akan mengaktifkan situs ini?",
        "Site.confirmContent.action/deactivate":"Apa Anda yakin akan menonaktifkan situs ini?",
        "Site.success.action/activate":"Situs telah berhasil diaktifkan",
        "Site.success.action/deactivate":"Situs telah berhasil dinonaktifkan",

        "always":"Selalu",
        "hourly":"Per Jam",
        "daily":"Per Hari",
        "weekly":"Per Minggu",
        "monthly":"Per Bulan",
        "yearly":"Per Tahun",
        "never":"Tidak Pernah",
        "clickWrite" : 'Ubah Konten',
        "noteEditor" : 'Catatan: Editor tidak mendukung perangkat selular.',
        "draft":"Draf",
        "published"  : "Dipublikasi",
        "unpublished": "Belum Dipublikasi",
        "payment_claimed":"Klaim Bayar",
        "unpaid":"Belum Bayar", 
        "payment_confirmed":"Terkonfirmasi Bayar", 
        "ongoing_training":"Mengikuti Training",
        "accepted":"Diterima", 
        "rejected":"Ditolak", 
        "resigned":"Mengundurkan Diri", 
        "cancelled":"Dibatalkan",
    },
    en : {
        //Menu folders
        "Web":"Web",
        "Web Configuration":"Web Configuration",

        //blog
        BlogPostActivity : "Blog Post",
        Post: "Post",
        unposted:"Unposted",
        "Blog.Title" : 'Title',
        uploadImage : "Upload Image",
        pasteLink : "Paste link here...",
        maxFile : "Max, accepted file size is 2MB",
        postTitle : "Post Title",
        publishDate : "Date Publish",
        writer : "Writer",
        "BlogPost.confirmContent.delete" : "Are you sure you want to delete this post?",
        "Blog.cleanUrl" : 'Clean URL ',
        "Blog.category" : 'Category',
        "Item.thumbnail" : 'Thumbnail',
        shortDescription : 'Short Description',
        metaDescription : 'Meta Description',
        headerImage : 'Header Image',
        "BlogPost.viewCount":"View Count",
        "BlogPost.writer":"Writer",
        "BlogPost.state":"Status",
        "BlogPost.draft":"Draft",
        "BlogPost.publish":"Publish",
        "BlogPost.unpublish":"Unpublish",
        "BlogPost.confirmTitle.action/draft":"Draft",
        "BlogPost.confirmTitle.action/publish":"Publish",
        "BlogPost.confirmTitle.action/unpublish":"Unpublish",
        "BlogPost.confirmContent.action/draft":"Are you sure want to revert this post back to draft?",
        "BlogPost.confirmContent.action/publish":"Are you sure want to publish this post?",
        "BlogPost.confirmContent.action/unpublish":"Are you sure want to unpublish this post?",
        "BlogPost.success.action/draft":"The post is sucessfully changed to draft",
        "BlogPost.success.action/publish":"The post is successfully published",
        "BlogPost.success.action/unpublish":"The post is successfully unpublished",
        "Blog.state" : "State",

        //contact submission
        "ContactSubmission":"Contact",
        "contact_submission":"Contact",
        "ContactSubmission.name":"Name",
        "ContactSubmission.additionalInfo":"Additional Info",
        "ContactSubmission.email":"E-mail",
        "ContactSubmission.mobile":"Mobile",
        "ContactSubmission.message":"Message",
        "ContactSubmission.contactSubmissionTopic":"Topic",
        "ContactSubmission.state":"Status",

        //contact submission topic
        "ContactSubmissionTopic":"Contact Topic",
        "cantact_submission_topic":"Contact Topic",
        "ContactSubmissionTopic.name":"Topic Name",
        "ContactSubmissionTopic.sequence":"Sequence",
        "ContactSubmissionTopic.state":"Status",

        //Newsletter
		"Newsletter":"Newsletter",
		"Newsletter.name":"Newsletter number",
		"Newsletter.Title":"Title",
		"Newsletter.emailTitle":"E-mail Title",
		"Newsletter.date":"Date",
		"Newsletter.content":"Content",
		"Newsletter.emailContent":"E-mail Content",
		"Newsletter.attachments":"Attachment",
		"Newsletter.attachment":"File",
		"Newsletter.teaser":"Teaser",
        "Newsletter.sendAfterDays":"Sent After (days)",
        "Newsletter.shortDescription":"Short Description",
        "Newsletter.state":"Status",
        "Newsletter.publish":"Publish",
        "Newsletter.unpublish":"Unpublish",
        "Newsletter.confirmTitle.action/publish":"Publish",
        "Newsletter.confirmContent.action/publish":"Are you sure want to publish this newsletter?",
        "Newsletter.success.action/publish":"Newsletter successfully published",
        "Newsletter.confirmTitle.action/unpublish":"Unpublish",
        "Newsletter.confirmContent.action/unpublish":"Are you sure want to unpublish this newsletter?",
        "Newsletter.success.action/unpublish":"Newsletter successfully unpublished",
        "Newsletter.readerList":"Read by",
        "Newsletter.header":"Header",
        "Newsletter.thumbnail":"Thumbnail",
        "Newsletter.noOneHasRead":"This newsletter has no readers yet, make sure you have published it first",
        
        //Participants
        "Participant":"Subscribers",
        "participant":"Subscribers",
        "Participant.title":"Title",
        "Participant.name":"Full Name",
        "Participant.email":"E-mail",
        "Participant.joinDate":"Join Date",
        "Participant.mobile":"Phone / Mobile",
        "Participant.whatsApp":"WhatsApp Number",
        "Participant.street":"Address",
        "Participant.birthPlace":"Birthplace",
        "Participant.birthDate":"Date of Birth",
        "Participant.hobby":"Hobby",
        "Participant.state":"Status",
        "Participant.facebook":"Facebook",
        "Participant.instagram":"Instagram",
        "Participant.lastEducation":"Last Education",
        "Participant.workExperience":"Work Experience",
        "Participant.everJoinedBroker":"Ever Joined Broker?",
        "Participant.brokerBefore":"Broker Before",
        "Participant.attachment":"Proof of payment",
        "Participant.campaign":"Campaign",
        "Participant.socmedContent":"Social media content",
        "Participant.screeningState":"Screening Status",
        "Participant.payment_confirmed":"Payment Confirmed",
        "Participant.unpaid":"Unpaid",
        "Participant.ongoing_training":"Ongoing Training",
        "Participant.accepted":"Accept",
        "Participant.rejected":"Reject",
        "Participant.resigned":"Resign",
        "Participant.cancelled":"Cancel",
        "Participant.subscribe":"Subscribe",
        "Participant.payment_claimed":"Claim Payment",
        "Participant.activate":"Activate",
        "Participant.deactivate":"Deactivate",
        "Participant.confirmTitle.action/payment_confirmed":"Confirm Paid",
        "Participant.confirmTitle.action/unpaid":"Unpaid",
        "Participant.confirmTitle.action/ongoing_training":"Ongoing Training",
        "Participant.confirmTitle.action/accepted":"Accept",
        "Participant.confirmTitle.action/rejected":"Reject",
        "Participant.confirmTitle.action/resigned":"Resign",
        "Participant.confirmTitle.action/cancelled":"Cancellation",
        "Participant.confirmTitle.action/subscribe":"Subscribe",
        "Participant.confirmTitle.action/payment_claimed":"Claim Payment",
        "Participant.confirmTitle.action/activate":"Activate",
        "Participant.confirmTitle.action/deactivate":"Deactivate",
        "Participant.confirmContent.action/payment_confirmed":"Are you sure want to confirm this participant's payment?",
        "Participant.confirmContent.action/unpaid":"Are you sure want to set the payment to unpaid?",
        "Participant.confirmContent.action/ongoing_training":"Are you sure want to set this subscriber to Ongoing Training?",
        "Participant.confirmContent.action/accepted":"Are you sure want to accept this subscriber?",
        "Participant.confirmContent.action/rejected":"Are you sure want to reject this subscriber?",
        "Participant.confirmContent.action/resigned":"Are you sure want to set this subscriber to resigned?",
        "Participant.confirmContent.action/cancelled":"Are you sure want to cancel this subscriber?",
        "Participant.confirmContent.action/subscribe":"Are you sure want to revert back this person to subscribe?",
        "Participant.confirmContent.action/payment_claimed":"Are you sure want to claim this subscriber's payment?",
        "Participant.confirmContent.action/activate":"Are you sure want to activate this subscriber",
        "Participant.confirmContent.action/deactivate":"Are you sure want to deactivate this subscriber?",
        "Participant.success.action/payment_confirmed":"Payment has confirmed",
        "Participant.success.action/unpaid":"The subscriber has been set to unpaid",
        "Participant.success.action/ongoing_training":"The subscriber has been set to ongoing training",
        "Participant.success.action/accepted":"The subscriber has been accepted",
        "Participant.success.action/rejected":"The subscriber has been rejected",
        "Participant.success.action/resigned":"The subscriber has been resigned",
        "Participant.success.action/cancelled":"The subscriber has been cancelled",
        "Participant.success.action/subscribe":"The person has been reverted back to subscribe",
        "Participant.success.action/payment_claimed":"The subscriber's payment has been claimed",
        "Participant.success.action/activate":"The subscriber has been activated",
        "Participant.success.action/deactivate":"The subscriber has been deactivated",
        "Participant.information":"Subscriber Information",
        "Participant.additionalInfo":"Additional Information",
        "Participant.paymentInfo":"Payment Information",
        "Participant.campaignAndContent":"Campaign and Content",
        "Participant.newsletter":"Newsletter",
        "Participant.newsletterInfo":"Newsletter Information",
        "Participant.newsletterRead":"Read",
        "Participant.newsletterReadDate":"Read Date",

        //Campaign
        "Campaign":"Campaign",
        "campaign":"Campaign",
        "SocialMediaContent":"Social Media Content",
        "social_media_content":"Social Media Content",
        "Campaign.name":"Name",
        "Campaign.code":"Code",
        "Campaign.date":"Date",
        "Campaign.description":"Description",
        "Campaign.link":"Link",
        "Campaign.copyCode":"Copy Code",
        "Campaign.copyCode.success":"Code copied to clipboard",
        "Campaign.viewCount":"View Count",

        //portfolio
        "Portfolio":"Portfolio",
        "Portfolio.name":"Title",
        "Portfolio.cleanURL":"Clean URL",
        "Portfolio.description":"Description",
        "Portfolio.metaDescription":"Meta Description",
        "Portfolio.category":"Category",
        "Portfolio.tags":"Tag",
        "Portfolio.showcases":"Showcase",
        "Portfolio.image":"Image",
        "Portfolio.attachmentTitle":"Image Title",
        "Portfolio.attachmentDescription":"Description",
        "Portfolio.state":"Status",
        "Portfolio.viewCount":"View Count",

        //Portfolio Category
        "PortfolioCategory":"Portfolio Category",
        "PortfolioCategory.name":"Name",
        "PortfolioCategory.sequence":"Sequence",
        "PortfolioCategory.image":"Image",
        "PortfolioCategory.thumbnail":"Thumbnail",
        "PortfolioCategory.state":"Status",
        "PortfolioCategory.parent":"Parent Category",
        "PortfolioCategory.metaDescription":"Meta Description",
        "PortfolioCategory.cleanURL":"Clean URL",

        //Portfolio Tag
        "PortfolioTag":"Portfolio Tag",
        "PortfolioTag.name":"Name",
        "PortfolioTag.image":"Image",
        "PortfolioTag.thumbnail":"Thumbnail",
        "PortfolioTag.state":"Status",

        // Page (sitemap)
        "Page":"Page",
        "Page.name":"Name",
        "Page.loc":"Location (URL)",
        "Page.lastMod":"Last Modified",
        "Page.changeFreq":"Change Frequency",
        "Page.priority":"Priority",
        "Page.blog":"Blog",
        "Page.blogCategory":"Blog Category",
        "Page.portfolio":"Portfolio",
        "Page.portfolioCategory":"Portfolio Category",
        "Page.product":"Item",
        "Page.productCategory":"Item Category",
        "Page.brand":"Brand",
        
        //Blog Category
        "BlogCategory" : 'Blog Category',
        "BlogCategory.code":"Code",
        "BlogCategory.name":"Name",
        "BlogCategory.cleanUrl":"Clean URL",
        "BlogCategory.sequence":"Sequence",
        "BlogCategory.parent":"Parent Category",
        "BlogCategory.image":"Image",
        "BlogCategory.thumbnail":"Thumbnail",
        "BlogCategory.hideOnReport":"Hide On Report",
        "BlogCategory.metaDescription":"Meta Description",
        "BlogCategory.state":"Status",
        "BlogCategory.activate":"Activate",
        "BlogCategory.deactivate":"Deactivate",
        "BlogCategory.confirmTitle.action/activate":"Activate",
        "BlogCategory.confirmTitle.action/deactivate":"Deactivate",
        "BlogCategory.confirmContent.action/activate":"Are you sure want to activate this category?",
        "BlogCategory.confirmContent.action/deactivate":"Are you sure want to deactivate this category?",
        "BlogCategory.success.action/activate":"Blog Category successfully activated",
        "BlogCategory.success.action/deactivate":"Blog Category successfully deactivated",

        //Testimony
        "Testimony":"Testimony",
        "Testimony.name":"Name",
        "Testimony.title":"Title",
        "Testimony.testimony":"Testimony",
        "Testimony.photo":"Photo",
        "Testimony.portfolio":"Portfolio",
        "Testimony.state":"Status",
        "Testimony.activate":"Activate",
        "Testimony.deactivate":"Deactivate",
        "Testimony.confirmTitle.action/activate":"Activate",
        "Testimony.confirmTitle.action/deactivate":"Deactivate",
        "Testimony.confirmContent.action/activate":"Are you sure want to activate this testimony?",
        "Testimony.confirmContent.action/deactivate":"Are you sure want to deactivate this testimony?",
        "Testimony.success.action/activate":"Testimony successfully activated",
        "Testimony.success.action/deactivate":"Testimony successfully deactivated",
        
        // Blog Writer
        "Writer":"Writer",
        "Writer.name":"Name",
        "Writer.contact":"Contact",
        "Writer.email":"E-mail",
        "Writer.link":"Link",
        "Writer.information":"Information",
        "Writer.linkedIn":"LinkedIn",
        "Writer.linkedInName":"LinkedIn Name",
        "Writer.linkedInDataVanity":"Data Vanity",
        "Writer.linkedInLink":"LinkedIn Link",
        "Writer.state":"Status",
        "Writer.activate":"Activate",
        "Writer.deactivate":"Deactivate",
        "Writer.confirmTitle.action/activate":"Activate",
        "Writer.confirmTitle.action/deactivate":"Deactivate",
        "Writer.confirmContent.action/activate":"Are you sure want to activate this writer?",
        "Writer.confirmContent.action/deactivate":"Are you sure want to deactivate this writer?",
        "Writer.success.action/activate":"Writer sucessfully activated",
        "Writer.success.action/deactivate":"Writer successfully deactivated",

        //referral
        "Referral":"Referral",
        "Referral.code":"Code",
        "Referral.name":"Name",
        "Referral.type":"Type",
        "Referral.adType":"Ad type",
        "Referral.site":"Website / App",
        "Referral.partner":"Partner",
        "Referral.description":"Description",
        "Referral.state":"Status",
        "Referral.activate":"Activate",
        "Referral.deactivate":"Deactivate",
        "Referral.confirmContent.action/activate":"Are you sure want to activate this referral code?",
        "Referral.confirmContent.action/deactivate":"Are you sure want to deactivate this referral code?",
        "Referral.success.action/activate":"Referal code has successfully activated",
        "Referral.success.action/deactivate":"Referal code has successfully deactivated",


        "ReferralVisit":"Referral Visit",
        "ReferralVisit.dateTime":"Date & Time",
        "ReferralVisit.url":"URL",
        "ReferralVisit.referral":"Referral",
        "ReferralVisit.previousVisit":"Previous Visit",
        "ReferralVisit.conversionValue":"Conversion Value",
        "ReferralVisit.conversionQuantity":"Conversion Quantity",
        "ReferralVisit.state":"Status",

        //Site
        "Site":"Site",
        "Site.name":"Name",
        "Site.url":"URL",
        "Site.activate":"Activate",
        "Site.deactivate":"Deactivate",
        "Site.confirmContent.action/activate":"Are you sure want to activate this site?",
        "Site.confirmContent.action/deactivate":"Are you sure want to deactivate this site?",
        "Site.success.action/activate":"Site has successfully activated",
        "Site.success.action/deactivate":"Site has successfully deactivated",

        //options & states
        "always":"Always",
        "hourly":"Houly",
        "daily":"Daily",
        "weekly":"Weekly",
        "monthly":"Monthly",
        "yearly":"Yearly",
        "never":"Never",
        "read":"Read",
        "unread":"Unread",
        "clickWrite" : 'Edit Content',
        "noteEditor" : 'Note: Writing is not supported on mobile browsers.',
        "draft":"Draft",
        "published"  : "Published",
        "unpublished": "Unpublished",
        "payment_claimed":"payment Claimed",
        "unpaid":"Unpaid", 
        "payment_confirmed":"Payment Confirmed", 
        "ongoing_training":"Ongoing Training",
        "accepted":"Accepted", 
        "rejected":"Rejected", 
        "resigned":"Resigned", 
        "cancelled":"Cancelled",
    }
}

export default Translations