import React, { Component } from "react";
import LazyContainer from "framework/containers/LazyContainer";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";
import { withRouter } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

//material-UI
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.props.empty({
      viewType: "activity",
    });
    this.props.push(null, {
      name: tl(Cookies.get("lang"), this.props.name),
      viewType: "activity",
      mode: "read",
      record: {},
      component: this.props.RenderedComponent,
      actions: this.props.formActions,
    });
  }

  handleUpdate = (payload) => {
    this.handleBack(payload);
  };

  handleBack = (payload) => {
    if (this.props.match.params.id) {
      let location = this.props.location.pathname;
      let currentLocation = location.split("/");
      this.props.history.push(
        "/" + currentLocation[1] + "/" + currentLocation[2],
      );
    }
    this.props.back(payload);
  };

  render() {
    return (
      <React.Fragment>
        <div className="breadcrumb-container">
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                className="breadcrumb-separator"
                fontSize="small"
              />
            }
            aria-label="Breadcrumb"
          >
            {this.props.View.activityStack.map((payload, i) => (
              <Link
                onClick={() => this.handleBack(payload)}
                className="breadcrumb-item"
                key={i}
              >
                {payload.name.length > 30 && i > 0
                  ? payload.name.substring(0, 30) + "..."
                  : payload.name}
              </Link>
            ))}
          </Breadcrumbs>
        </div>
        {this.props.View.activityStack.length > 0 ? (
          <LazyContainer
            match={this.props.match ? this.props.match : null}
            payload={
              this.props.View.activityStack[
                this.props.View.activityStack.length - 1
              ]
            }
            context={this.props.context}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activity),
);
