import React, { Component } from "react";
import { withStyles, ButtonBase } from "@material-ui/core";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      content: this.props.content,
    };
  }

  handleYes = () => {
    this.props.handleYes();
  };

  handleNo = () => {
    this.props.handleNo();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.modalContainer}>
        <div className={classes.modal}>
          <p className={classes.modalTitle}>{this.state.title}</p>
          <p className={classes.modalContent}>{this.state.content}</p>
          <div className={classes.modalAction}>
            <ButtonBase className={classes.btnNo} onClick={this.handleNo}>
              {tl(Cookies.get("lang"), "no")}
            </ButtonBase>
            <ButtonBase className={classes.btnOk} onClick={this.handleYes}>
              {tl(Cookies.get("lang"), "yes")}
            </ButtonBase>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  modalContainer: {
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: "10px 50px",
    position: "fixed",
    height: "100%",
    width: "100%",
    color: "white",
    zIndex: "10",
    display: "flex",
    flexDirection: "column",
  },
  modal: {
    padding: "10px",
    backgroundColor: "#ffffff",
    margin: "auto 0px",
    borderRadius: "5px",
  },
  modalTitle: {
    color: "#000000",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  modalContent: {
    fontSize: "0.9rem",
    fontWeight: "normal",
    color: "rgba(0, 0, 0, 0.54)",
  },
  modalAction: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#000000",
  },
  btnNo: {
    padding: "6.5px 20px",
    margin: "0px 5px",
    borderRadius: "5px",
    border: "1px solid",
  },
  btnOk: {
    padding: "6.5px 20px",
    margin: "0px 5px",
    borderRadius: "5px",
    backgroundColor: "#4caf50",
    color: "#ffffff",
    boxShadow:
      "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)",
    "&:hover": {
      boxShadow: "0 7px 40px 2px hsla(210,1%,58%,.3)",
    },
  },
};

export default withStyles(styles)(Modal);
