import React, { Component } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import API from 'framework/utils/API'
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'
import {tl} from 'framework/utils/Translator'
import Cookies from 'js-cookie'
import {can} from 'framework/utils/AccessRights'
import axios from "axios"
import FileDownload from 'js-file-download'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

//icons
import DownloadIcon from '@material-ui/icons/CloudDownload'

class CashierReportAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
		this.FileDownload = FileDownload
		this.headers = {Authorization:"Bearer "+Cookies.get("accessToken")}
	}

	handleDownload = () => {
		let record = this.props.record
		let format = ".xlsx"
		this.setState({isLoading:true})
		axios.post(this.props.Session.host+"/api/instant_sales/cashier_report", {
			...record,
            reportType:"excel",
        }, {
            headers:this.headers
        }).then((response)=>{
			this.FileDownload(response.data, 'cashier_report'+format)
			setTimeout(()=>{
				this.setState({isLoading:false})
			}, 1000)
        }).catch((error)=>{
			this.setState({isLoading:false})
            console.error(error)
        })
	}
	
	render() {
		let buttons = []
		buttons.push(
			<Button 
				color="success" 
				key="download-pdf"
				size="sm"
				className="rounded-button"
				disabled={this.state.isLoading}  
				onClick={()=>this.handleDownload()}>
				<DownloadIcon/>{tl(Cookies.get("lang"),"Download")}
			</Button>
		)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(CashierReportAction)
