import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class BillingConfigurationBillingCycleActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Property/BillingCycle/BillingCycleBrowser")
	}

	render() {
		return (
			<Activity 
				name="BillingConfigurationBillingCycle"
				RenderedComponent={this.RenderedComponent}
				context="BillingConfigurationBillingCycle"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(BillingConfigurationBillingCycleActivity)
