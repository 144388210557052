const Translation = {
    id:{
        "SaasClient":"Klien SaaS",
        "saas_client":"Klien SaaS",
        "SaasClient.name":"Nama Klien",
        "SaasClient.domain":"Domain",
        "SaasClient.host":"Host",
        "SaasClient.title":"Judul",
        "SaasClient.defaultPage":"Halaman Utama",
        "SaasClient.loginBackground":"Background Login",
        "SaasClient.favicon":"Favicon",
        "SaasClient.companyLogo":"Logo Form Login",
        "SaasClient.loginLogo":"Logo Navbar Login",
        "SaasClient.sidebarLogo":"Logo Sidebar",
        "SaasClient.loginCardColor":"Latar Belakang Logo Login",
        "SaasClient.loginButtonColor":"Tombol Login",
        "SaasClient.activeSidebarColor":"Menu yang Aktif",
        "SaasClient.activeMenuShadowColor":"Warna Bayangan Menu yang Aktif",
        "SaasClient.blogFontName":"Nama Font Editor Blog",
        "SaasClient.blogFontFile":"Berkas Font Editor Blog",
        "SaasClient.attachmentStorage":"Penyimpanan Lampiran",
        "SaasClient.accounting":"Akunting",
        "SaasClient.property":"Properti",
        "SaasClient.hr":"HR",
        "SaasClient.base":"Admin",
        "SaasClient.shop":"Toko Online",
        "SaasClient.web":"Web",
        "SaasClient.freight":"Kargo",
        "SaasClient.realEstate":"Real Estate",
        "SaasClient.themePark":"Taman Bermain",
        "SaasClient.bakery":"Toko Roti",
        "SaasClient.debug":"Debug",
        "SaasClient.saasCenter":"Pusat SaaS",
        "SaasClient.hideAccounting":"Sembunyikan Akunting",
        "SaasClient.state":"Status",
        "SaasClient.webUrl":"URL Situs Web",
        "SaasClient.externalServices":"Layanan Eksternal",
        "SaasClient.mapsApiKey":"Google Maps API Key",
        "SaasClient.accountingOptions":"Pengaturan Akunting",
        "SaasClient.instantSalesItemPick":"Mode Pemilihan Barang di Penjualan Instan",
        "SaasClient.instantSalesStrictMode":"POS Ketat",
        "SaasClient.advancedProcurement":"Pengadaan Lanjutan",
        "SaasClient.separateBranchAccounting":"Akunting Cabang Terpisah",
        "SaasClient.unitRepresentedByAnItem":"Satu Barang Untuk Satu Satuan",
        "SaasClient.center":"Pusat",
        "SaasClient.branchCode":"Kode Cabang",
        "SaasClient.centralAndBranches":"Pusat & Cabang",
        "SaasClient.disableMarketplace":"Nonaktifkan Marketplace",
        "SaasClient.images":"Gambar",
        "SaasClient.colors":"Warna",
        "SaasClient.fonts":"Font",
        "SaasClient.modules":"Modul",
        "SaasClient.storage":"Penyimpanan",
        "SaasClient.offlineMode":"Mode Offline",
        "SaasClient.enableOfflineMode":"Aktifkan Mode Offline",
        "SaasClient.offlineModeURL":"URL Mode Offline",
        "SaasClient.manualPaymentInfo":"Informasi Pembayaran Manual",
        "SaasClient.bank":"Nama Bank",
        "SaasClient.bankName":"Nama Rekening",
        "SaasClient.bankAccount":"Nomor Rekening",
        "SaasClient.autoApplyPromotion":"Terapkan Promo Otomatis",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Sederhana",
        "detailed":"Detil",
        "branch":"Detil Cabang"
    },
    en:{
        "SaasClient":"SaaS Client",
        "saas_client":"Saas Client",
        "SaasClient.name":"Client Name",
        "SaasClient.domain":"Domain",
        "SaasClient.host":"Host",
        "SaasClient.title":"Title",
        "SaasClient.defaultPage":"Default Page",
        "SaasClient.loginBackground":"Login Background",
        "SaasClient.favicon":"Favicon",
        "SaasClient.companyLogo":"Login Form Logo",
        "SaasClient.loginLogo":"Login Navbar Logo",
        "SaasClient.sidebarLogo":"Sidebar Logo",
        "SaasClient.loginCardColor":"Login Logo Background",
        "SaasClient.loginButtonColor":"Login Button Color",
        "SaasClient.activeSidebarColor":"Active Menu",
        "SaasClient.activeMenuShadowColor":"Active Menu Shadow Color",
        "SaasClient.blogFontName":"Blog Font Name",
        "SaasClient.blogFontFile":"Blog Font File",
        "SaasClient.attachmentStorage":"Attachment Storage",
        "SaasClient.accounting":"Accounting",
        "SaasClient.property":"Property",
        "SaasClient.hr":"HR",
        "SaasClient.base":"Admin",
        "SaasClient.shop":"Online Shop",
        "SaasClient.web":"Web",
        "SaasClient.freight":"Freight",
        "SaasClient.realEstate":"Real Estate",
        "SaasClient.themePark":"Theme Park",
        "SaasClient.bakery":"Bakery",
        "SaasClient.debug":"Debug",
        "SaasClient.saasCenter":"SaaS Center",
        "SaasClient.hideAccounting":"Hide Accounting",
        "SaasClient.state":"Status",
        "SaasClient.webUrl":"Website URL",
        "SaasClient.externalServices":"External Services",
        "SaasClient.mapsApiKey":"Google Maps API Key",
        "SaasClient.accountingOptions":"Accounting Options",
        "SaasClient.instantSalesItemPick":"Item Selection Mode in Instant Sales",
        "SaasClient.instantSalesStrictMode":"Strict POS",
        "SaasClient.advancedProcurement":"Advanced Procurement",
        "SaasClient.separateBranchAccounting":"Separate Branch Accounting",
        "SaasClient.unitRepresentedByAnItem":"Unit Represented By An Item",
        "SaasClient.center":"Center",
        "SaasClient.branchCode":"BranchCode",
        "SaasClient.centralAndBranches":"Central And Branches",
        "SaasClient.unitRepresentedByAnItem":"Unit Represented by An Item",
        "SaasClient.disableMarketplace":"Disable Marketplace",
        "SaasClient.images":"Images",
        "SaasClient.colors":"Colors",
        "SaasClient.fonts":"Fonts",
        "SaasClient.modules":"Modules",
        "SaasClient.storage":"Storage",
        "SaasClient.offlineMode":"Offline Mode",
        "SaasClient.enableofflineMode":"Enable Offline Mode",
        "SaasClient.offlineModeURL":"Offline Mode URL",
        "SaasClient.manualPaymentInfo":"Manual Payment Information",
        "SaasClient.bank":"Bank Name",
        "SaasClient.bankName":"Account Name",
        "SaasClient.bankAccount":"Account Number",
        "SaasClient.autoApplyPromotion":"Automatically Apply Promotion",

        //options
        "filesystem":"Filesystem",
        "database":"Database",
        "s3":"Amazon S3",
        "simple":"Simple",
        "detailed":"Detailed",
        "branch":"Detailed Branch",
    }
}

export default Translation