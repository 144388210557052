import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import { Provider } from "react-redux";
import store from "./store";
import RemoteConfig from "./RemoteConfig";

let hist = createBrowserHistory();

ReactDOM.render(
  <>
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <RemoteConfig history={hist} />
      </SnackbarProvider>
    </Provider>
  </>,
  document.getElementById("root"),
);
