import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/ReportAging/ReportAgingAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class APReportAgingActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/ReportAging/ReportAging")
        this.compName = "APAgingReport"
	}

	render() {
		return (
			<Activity 
				name={this.compName}
				RenderedComponent={this.RenderedComponent}
				context={this.compName}
				action={this.compName}
				formActions={FormAction}
            />
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(APReportAgingActivity)
