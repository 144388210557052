import axios from "axios";
import Cookies from "js-cookie";
import store from "store";
import { setNotification } from "framework/actions/View";
import moment from "moment";
import Config from "company/Config";

function showNotification(store, message, color) {
  store.dispatch(setNotification(true, message, color));
  setTimeout(
    () => store.dispatch(setNotification(false, message, color)),
    3000,
  );
}

function showNotificationError(store, message, color) {
  store.dispatch(setNotification(true, message, color));
  setTimeout(
    () => store.dispatch(setNotification(false, message, color)),
    5000,
  );
}

function refreshTokenLogin(host, refreshToken) {
  axios
    .post(host + "/api/oauth/token", {
      refresh_token: refreshToken,
    })
    .then((response) => {
      console.log(response);
      Cookies.set("accessToken", response.data.accessToken, {
        path: "/",
        expires: moment.unix(response.data.atExpiredAt).toDate(),
      });
      Cookies.set("refreshToken", response.data.refreshToken, {
        path: "/",
        expires: moment.unix(response.data.rtExpiredAt).toDate(),
      });
    })
    .catch((error) => {
      if (error) {
        Cookies.remove("refreshToken", { path: "/" });
        Cookies.remove("username", { path: "/" });
        window.location.replace("/");
        return Promise.reject(error);
      }
    });
}

function handleError(error, store) {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    if (Cookies.get("refreshToken")) {
      let state = store.getState();
      refreshTokenLogin(state.Session.host, Cookies.get("refreshToken"));
      showNotification(store, "pleaseTryAgain", "warning");
    } else {
      Cookies.remove("accessToken", { path: "/" });
      Cookies.remove("refreshToken", { path: "/" });
      window.location.replace("/");
    }
  } else if (
    error.response &&
    error.response.status &&
    error.response.status === 400
  ) {
    if (error.response.data && error.response.data.errorDescription) {
      showNotificationError(
        store,
        error.response.data.errorDescription,
        "danger",
      );
    } else {
      showNotification(store, "inputError", "danger");
    }
  } else if (
    error.response &&
    error.response.status &&
    error.response.status === 409
  ) {
    if (error.response.data && error.response.data.errorDescription) {
      showNotificationError(
        store,
        error.response.data.errorDescription,
        "danger",
      );
    } else {
      showNotification(store, "inputError", "danger");
    }
  } else if (
    error.response &&
    error.response.status &&
    error.response.status === 404
  ) {
    showNotification(store, "serverNotFound", "danger");
  } else if (
    error.response &&
    error.response.status &&
    (error.response.status === 500 || error.response.status === 501)
  ) {
    showNotification(store, "serverError", "danger");
  } else if (
    error.response &&
    error.response.status &&
    error.response.status >= 502
  ) {
    showNotification(store, "serverUnavailable", "danger");
  } else {
    showNotification(store, "serverUnavailable", "danger");
  }
}

function handleSuccess(method, store) {
  if (method === "create") {
    showNotification(store, "Data Created", "success");
  } else if (method === "update") {
    showNotification(store, "Data Updated", "success");
  }
}

const API = (method, model, body) => {
  let state = store.getState();
  let host = state.Session.host;
  let promise = null;
  let route = null;
  body = body || null;
  const authHeader = {
    headers: {
      Authorization: "Bearer " + Cookies.get("accessToken"),
    },
  };

  //set request route/url
  switch (method) {
    case "get":
      route = host + "/api/" + model + "/" + body;
      break;
    case "get_multi":
      route = host + "/api/" + model + "/get";
      break;
    case "browse":
      route = host + "/api/" + model + "/browse";
      break;
    case "browse_pick":
      route = host + "/api/" + model + "/browse_pick";
      break;
    case "browse_group":
      route = host + "/api/" + model + "/browse/grouped";
      break;
    case "create":
      route = host + "/api/" + model + "/create";
      break;
    case "update":
      route = host + "/api/" + model + "/update";
      break;
    case "options":
      route = host + "/api/" + model + "/options";
      break;
    case "delete":
      route = host + "/api/" + model + "/delete";
      break;
    case "default":
      route = host + "/api/" + model + "/default";
      break;
    case "search":
      route = host + "/api/" + model + "/search";
      break;
    case "action":
      route = host + body;
      break;
    case "bulk_action":
      route = host + "/api/" + model;
      break;
    case "download":
      route = host + "/api/" + model;
      break;
    case "generate_sku":
      route = host + "/api/item/generatesku";
      break;
    case "print":
      route = host + "/api/" + model + "/" + body + "/print";
      break;
    case "price_save":
      route = host + `/api/${model}/price_save`;
      break;
    case "bulk_save":
      route = host + `/api/${model}/bulk_save`;
      break;
    case "draft_list" : route = host+"/api/"+model+"/draft_list"
			break
		case "draft" : route = host+"/api/"+model+"/draft"
			break
    case "custom_action":
      route = host + `/api/${model}`;
      break;
    default:
      break;
  }

  if (Config.isOfflineClient) {
    route = route.replace("/api/", "/api/offline/");
  }

  //execute request
  if (["get", "action", "default", "print"].includes(method)) {
    promise = axios
      .get(route, authHeader)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        handleError(error, store, { method, model, body });
        return Promise.reject(error);
      });
  } else if (method === "download") {
    authHeader["responseType"] = "blob";
    promise = axios
      .post(route, body, authHeader)
      .then((response) => {
        handleSuccess(method, store);
        return response;
      })
      .catch((error) => {
        handleError(error, store);
        return Promise.reject(error);
      });
  } else {
    promise = axios
      .post(route, body, authHeader)
      .then((response) => {
        handleSuccess(method, store);
        return response;
      })
      .catch((error) => {
        handleError(error, store);
        return Promise.reject(error);
      });
  }
  return promise;
};

export default API;
