import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class HumanCapitalConfigurationBranchActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/components/Hr/Branch/BranchBrowser")
	}

	render() {
		return (
			<Activity 
				name="HumanCapitalConfigurationBranch"
				RenderedComponent={this.RenderedComponent}
				context="HumanCapitalConfigurationBranch"
			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(HumanCapitalConfigurationBranchActivity)
