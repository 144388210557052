import Translation from "translation";
import moment from "moment-timezone";
import Cookies from "js-cookie";

export function tl(lang, stringName) {
  let langs = Object.keys(Translation);

  if (langs.includes(lang)) {
    let string = Translation[lang];
    if (string[stringName] !== undefined) {
      return string[stringName];
    } else {
      return stringName;
    }
  } else {
    let string = Translation[Cookies.get("lang")];
    if (string[lang] !== undefined) {
      return string[lang];
    } else {
      return lang;
    }
  }
}

export function tlStrict(stringName) {
  let lang = Cookies.get("lang");
  if (Translation[lang]) {
    return Translation[lang][stringName] || null;
  } else {
    return null;
  }
}

export function formatDateLocale(lang, date8601) {
  let string = Translation[lang];
  let formattedDate = date8601;
  if (lang === "en") {
    formattedDate =
      string[moment(date8601).tz("Asia/Jakarta").format("MM")] +
      " " +
      moment(date8601).tz("Asia/Jakarta").format("DD, YYYY");
  } else if (lang === "id") {
    formattedDate =
      moment(date8601).tz("Asia/Jakarta").format("DD") +
      " " +
      string[moment(date8601).format("MM")] +
      " " +
      moment(date8601).format("YYYY");
  } else {
    formattedDate = "missing date format for [" + lang + "] " + date8601;
  }
  return formattedDate;
}

export function formatTimeLocale(lang, time) {
  let formattedTime = time;
  if (lang === "en") {
    formattedTime = moment(time).format("HH:mm");
  } else {
    formattedTime = "missing time format for [" + lang + "] " + time;
  }
  return formattedTime + lang;
}

export function formatDateTimeLocale(lang, dateTime8601) {
  let string = Translation[lang];
  let formattedDateTime = dateTime8601;
  if (lang === "en") {
    formattedDateTime =
      string[moment(dateTime8601).format("MM")] +
      " " +
      moment(dateTime8601).format("DD, YYYY. HH.mm");
  } else if (lang === "id") {
    formattedDateTime =
      moment(dateTime8601).format("DD") +
      " " +
      string[moment(dateTime8601).format("MM")] +
      " " +
      moment(dateTime8601).format("YYYY, HH:mm");
  } else {
    formattedDateTime =
      "missing datetime format for [" + lang + "] " + dateTime8601;
  }
  return formattedDateTime;
}
