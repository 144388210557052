import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Hr/reports/AttendanceReport/EmploymentReportAttendanceAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class EmploymentReportAttendanceActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Hr/reports/AttendanceReport/EmploymentReportAttendance")
	}

	render() {
		return (
			<Activity 
				name="EmploymentReportAttendance"
				RenderedComponent={this.RenderedComponent}
				context="EmploymentReportAttendance"
				action="EmploymentReportAttendance"
				formActions={FormAction}			/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(EmploymentReportAttendanceActivity)
