import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ChecklistIcon from "@material-ui/icons/ListAlt";
import Boolean from "framework/inputs/Boolean";
import { tl } from "framework/utils/Translator";
import _ from "lodash";
import API from "framework/utils/API";

class ColumnSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      columns: [
        {
          field: "date",
          label: "Tanggal",
          show: true,
        },
        {
          field: "name",
          label: "Nama",
          show: true,
        },
        {
          field: "customer",
          label: "Pelanggan",
          show: true,
        },
        {
          field: "payment",
          label: "Pembayaran",
          show: true,
        },
        {
          field: "marketplace",
          label: "Marketplace",
          show: true,
        },
        {
          field: "itemName",
          label: "Nama Barang",
          show: true,
        },
        {
          field: "category",
          label: "Kategori",
          show: true,
        },
        {
          field: "quantity",
          label: "Jumlah",
          show: true,
        },
        {
          field: "unit",
          label: "Satuan",
          show: true,
        },
        {
          field: "purchasePrice",
          label: "Harga Beli",
          show: true,
        },
        {
          field: "unitPrice",
          label: "Harga Satuan",
          show: true,
        },
        {
          field: "perItemDiscount",
          label: "Diskon per Barang",
          show: true,
        },
        {
          field: "discount",
          label: "Diskon",
          show: true,
        },
        {
          field: "subtotal",
          label: "Subtotal",
          show: true,
        },
        {
          field: "tax",
          label: "Pajak",
          show: true,
        },
        {
          field: "total",
          label: "Total",
          show: true,
        },
        {
          field: "discountTotal",
          label: "Total Diskon",
          show: true,
        },
        {
          field: "sales",
          label: "Penjualan",
          show: true,
        },
        {
          field: "potentialProfit",
          label: "Potensi Keuntungan",
          show: true,
        },
        {
          field: "cashier",
          label: "Kasir",
          show: true,
        },
      ],
    };
  }

  componentDidMount() {
    // this.getData()
  }

  getData = () => {
    API("custom_action", "report/config/get", null)
      .then((response) => {
        this.setState({
          columns: response.data.columns,
        });
      })
      .catch((error) => {
        console.error(error)
      });
  };

  togglePane = () => {
    if (!this.state.isOpen) {
      document.addEventListener("click", this.handleClickOutside, false);
    } else {
      document.removeEventListener("click", this.handleClickOutside, false);
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleClickOutside = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.togglePane();
    }
  };

  handleChange = (field, value) => {
    let newColumns = this.state.columns;
    let fieldIndex = _.findIndex(newColumns, ["field", field]);
    newColumns[fieldIndex].show = value;
    API("custom_action", "report/config/save", {
      code: "salesReport",
      columns: newColumns,
    }).catch((error) => {
      console.error(error)
    });
    this.setState(
      {
        columns: newColumns,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.columns);
        }
        console.log(this.state.columns);
      },
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} ref={(node) => (this.node = node)}>
        <Button
          justIcon
          size="sm"
          color="transparent"
          onClick={() => this.togglePane()}
        >
          <ChecklistIcon />
        </Button>
        <div className={"pane " + (this.state.isOpen ? "show" : "")}>
          <div className="title">{tl("columnConfig")}</div>
          <div className="list">
            {this.state.columns.map((column, index) => {
              return (
                <div className="listItem" key={index}>
                  <Boolean
                    inlineLabel
                    field={column.field}
                    label={column.label}
                    value={column.show}
                    renderMode="formEdit"
                    onChange={this.handleChange}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// const jsonExample = {
//   code: "salesReport",
//   columns: [
//     {
//       field: "date",
//       label: "Tanggal",
//       show: true,
//     },
//     {
//       field: "name",
//       label: "Nama",
//       show: true,
//     },
//     {
//       field: "customer",
//       label: "Pelanggan",
//       show: true,
//     },
//     {
//       field: "payment",
//       label: "Pembayaran",
//       show: true,
//     },
//   ],
// };

const styles = {
  container: {
    position: "relative",
    "&>.pane": {
      position: "absolute",
      backgroundColor: "#fff",
      top: "40px",
      right: "0px",
      opacity: "0",
      minWidth: "280px",
      height: "400px",
      zIndex: "100",
      boxShadow: "0px 5px 10px rgba(0,0,0,0.1)",
      borderRadius: "10px",
      pointerEvents: "none",
      "&>.title": {
        padding: "10px",
        borderBottom: " 1px solid #888",
        fontWeight: "bold",
      },
      "&>.list": {
        overflowY: "auto",
        height: "358px",
        "&>.listItem": {
          width: "100%",
          padding: "5px 10px",
          borderBottom: " 1px solid #eee",
          "&:hover": {
            backgroundColor: "#f8f8f8",
          },
        },
      },
      "&.show": {
        opacity: "1",
        pointerEvents: "all",
      },
    },
  },
};

export default withStyles(styles)(ColumnSelector);
