import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/TrialBalance/TrialBalanceAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class AccountingTrialBalanceActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/TrialBalance/TrialBalance")
	}

	render() {
		return (
			<Activity 
				name="TrialBalance"
				RenderedComponent={this.RenderedComponent}
				context="TrialBalance"
				action="TrialBalance"
				formActions={FormAction}/>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountingTrialBalanceActivity)
