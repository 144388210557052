//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import CloudIcon from "@material-ui/icons/Cloud";
import CloudCircleIcon from "@material-ui/icons/CloudCircle";

//activities
import SaasClientActivity from 'app/modules/Saas/activities/SaasClientActivity'

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Saas"),
	    menu		: "SAAS",
	    icon 		: CloudIcon,
	    state		: "SAAS",
	    views		:[
			{
				path		: "/saas-client/:id?",
				name		: tl(Cookies.get("lang"),"SaasClient"),
				menu		: "SAAS01",
				icon 		: CloudCircleIcon,
				component 	: SaasClientActivity,
				layout		: "/admin"
			},
		]
	},
]

export default Routes