//translation
import {tl} from "framework/utils/Translator"
import Cookies from 'js-cookie'

// @material-ui/icons
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HighlightIcon from "@material-ui/icons/Highlight";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import PolicyIcon from "@material-ui/icons/Beenhere"
import ItemIcon from "@material-ui/icons/CardGiftcard";
import CategoryIcon from "@material-ui/icons/Category"
import BrandIcon from "@material-ui/icons/Loyalty"
import GigIcon from "@material-ui/icons/Work"
import OccupationIcon from "@material-ui/icons/WorkOutline"
import PriceIcon from "@material-ui/icons/MoneyRounded"
import CodeIcon from "@material-ui/icons/Code"
import BuildIcon from "@material-ui/icons/Build"
import TagIcon from "@material-ui/icons/Label"
import ServiceOrderIcon from '@material-ui/icons/AssignmentOutlined'
import OrderRequestIcon from '@material-ui/icons/Assignment'
import RatingTemplateIcon from "@material-ui/icons/RateReview"
import ImportIcon from "@material-ui/icons/CloudUpload"
import MenuIcon from "@material-ui/icons/List"
import CourierIcon from "@material-ui/icons/LocalShippingOutlined"

//activities
import ShopShoppingCartActivity from 'app/modules/Shop/activities/Shop/ShopShoppingCartActivity'
import ShopHighlightActivity from 'app/modules/Shop/activities/Shop/ShopHighlightActivity'
import ShopSlideshowActivity from 'app/modules/Shop/activities/Shop/ShopSlideshowActivity'
import ShopShopPolicyActivity from 'app/modules/Shop/activities/Shop/ShopShopPolicyActivity'
import ShopItemActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopItemActivity'
import ShopItemCategoryActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopConfigurationItemCategoryActivity'
import ShopBrandActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopConfigurationBrandActivity'
import GigActivity from 'app/modules/Shop/activities/Services/GigActivity'
import ServicePriceActivity from 'app/modules/Shop/activities/Services/ServicePriceActivity'
import ServiceContentActivity from 'app/modules/Shop/activities/Services/ServiceContentActivity'
import ServiceSubCategoryContentsActivity from 'app/modules/Shop/activities/Services/ServiceSubCategoryContentsActivity'
import ServiceTypeActivity from 'app/modules/Shop/activities/Services/ServiceTypeActivity'
import ServiceMetadataActivity from 'app/modules/Shop/activities/Services/ServiceMetadataActivity'
import AccountingConfigurationTagActivity from 'app/modules/Accounting/activities/AccountingConfiguration/AccountingConfigurationTagActivity'
import ServiceOccupationActivity from 'app/modules/Shop/activities/Services/ServiceOccupationActivity'
import ServiceOrderActivity from 'app/modules/Shop/activities/Services/ServiceOrderActivity'
import OrderRequestActivity from 'app/modules/Shop/activities/Services/OrderRequestActivity'
import RatingTemplateActivity from 'app/modules/Shop/activities/Services/RatingTemplateActivity'
import ShopMarketplaceActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopMarketplaceActivity'
import ShopVariantActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopVariantActivity'
import ShopItemImportActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopItemImportActivity'
import ShopConfigurationItemOptionActivity from 'app/modules/Shop/activities/ShopConfiguration/ShopConfigurationItemOptionActivity'
import ShopConfigurationCourierActivity from "./activities/ShopConfiguration/ShopConfigurationCourierActivity";

var Routes = [
    {
    	collapse	: true,
	    name		: tl(Cookies.get("lang"),"Shop"),
	    menu		: "SHOP",
	    icon 		: StoreIcon,
	    state		: "SHOP",
	    views		:[
			{
				path		: "/shop-shopping-cart/:id?",
				name		: tl(Cookies.get("lang"),"ShopShoppingCart"),
				menu		: "SHOP01",
				icon 		: ShoppingCartIcon,
				component 	: ShopShoppingCartActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-highlight/:id?",
				name		: tl(Cookies.get("lang"),"ShopHighlight"),
				menu		: "SHOP02",
				icon 		: HighlightIcon,
				component 	: ShopHighlightActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-slideshow/:id?",
				name		: tl(Cookies.get("lang"),"ShopSlideshow"),
				menu		: "SHOP03",
				icon 		: SlideshowIcon,
				component 	: ShopSlideshowActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-shop-policy/:id?",
				name		: tl(Cookies.get("lang"),"ShopShopPolicy"),
				menu		: "SHOP04",
				icon 		: PolicyIcon,
				component 	: ShopShopPolicyActivity,
				layout		: "/admin"
			},
			
		]
	},
	{
		collapse	: true,
	    name		: tl(Cookies.get("lang"),"ShopConfiguration"),
	    menu		: "SHOPCONF",
	    icon 		: StoreIcon,
	    state		: "SHOPCONF",
	    views		:[
			{
				path		: "/shop-item/:id?",
				name		: tl(Cookies.get("lang"),"ShopItem"),
				menu		: "SHOPCONF01",
				icon 		: ItemIcon,
				component 	: ShopItemActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-item-category/:id?",
				name		: tl(Cookies.get("lang"),"ItemCategory"),
				menu		: "SHOPCONF02",
				icon 		: CategoryIcon,
				component 	: ShopItemCategoryActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-brand/:id?",
				name		: tl(Cookies.get("lang"),"Brand"),
				menu		: "SHOPCONF03",
				icon 		: BrandIcon,
				component 	: ShopBrandActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-tag/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationTag"),
				menu		: "ACCTCONF13",
				icon 		: TagIcon,
				component 	: AccountingConfigurationTagActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-marketplace/:id?",
				name		: tl(Cookies.get("lang"),"Marketplace"),
				menu		: "SHOPCONFMKTP",
				icon 		: TagIcon,
				component 	: ShopMarketplaceActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-variant",
				name		: tl(Cookies.get("lang"),"Variant"),
				menu		: "SHOPCONFVAR",
				icon 		: TagIcon,
				component 	: ShopVariantActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-item-import",
				name		: tl(Cookies.get("lang"),"ShopItemImport"),
				menu		: "ACCTCONFIMPT",
				icon 		: ImportIcon,
				component 	: ShopItemImportActivity,
				layout		: "/admin"
			},
			{
				path		: "/item-option/:id?",
				name		: tl(Cookies.get("lang"),"ItemOption"),
				menu		: "SHOPCONFITOP",
				icon 		: MenuIcon,
				component 	: ShopConfigurationItemOptionActivity,
				layout		: "/admin"
			},
			{
				path		: "/shop-courier/:id?",
				name		: tl(Cookies.get("lang"),"Courier"),
				menu		: "SHOPCONFCOURIER",
				icon 		: CourierIcon,
				component 	: ShopConfigurationCourierActivity,
				layout		: "/admin"
			},
		]
	},
	{
		collapse	: true,
	    name		: tl(Cookies.get("lang"),"Service"),
	    menu		: "SERVICE",
	    icon 		: GigIcon	,
	    state		: "COMM",
		views		:[
			{
				path		: "/services-gig/:id?",
				name		: tl(Cookies.get("lang"),"gig_admin"),
				menu		: "SERVICEGIG",
				icon 		: GigIcon,
				component 	: GigActivity,
				layout		: "/admin"
			},
			{
				path		: "/services-price/:id?",
				name		: tl(Cookies.get("lang"),"ServicePrice"),
				menu		: "SERVICEPRC",
				icon 		: PriceIcon,
				component 	: ServicePriceActivity,
				layout		: "/admin"
			},
			{
				path		: "/services-content/:id?",
				name		: tl(Cookies.get("lang"),"ServiceContent"),
				menu		: "SERVICECNT",
				icon 		: ItemIcon,
				component 	: ServiceContentActivity,
				layout		: "/admin"
			},
			{
				path		: "/services-subcategory-content/:id?",
				name		: tl(Cookies.get("lang"),"ServiceSubCategoryContents"),
				menu		: "SERVICECONF",
				icon 		: BuildIcon,
				component 	: ServiceSubCategoryContentsActivity,
				layout		: "/admin"
			},
			{
				path		: "/services-type/:id?",
				name		: tl(Cookies.get("lang"),"ServiceType"),
				menu		: "SERVICETYPE",
				icon 		: CategoryIcon,
				component 	: ServiceTypeActivity,
				layout		: "/admin"
			},
			{
				path		: "/services-metadata/:id?",
				name		: tl(Cookies.get("lang"),"ServiceMetadata"),
				menu		: "SERVICEMTDT",
				icon 		: CodeIcon,
				component 	: ServiceMetadataActivity,
				layout		: "/admin"
			},
			{
				path		: "/accounting-configuration-tag/:id?",
				name		: tl(Cookies.get("lang"),"AccountingConfigurationTag"),
				menu		: "SERVICETAG",
				icon 		: TagIcon,
				component 	: AccountingConfigurationTagActivity,
				layout		: "/admin"
			},
			{
				path		: "/service-occupation/:id?",
				name		: tl(Cookies.get("lang"),"ServiceOccupation"),
				menu		: "SERVICEOCPT",
				icon 		: OccupationIcon,
				component 	: ServiceOccupationActivity,
				layout		: "/admin"
			},
			{
				path		: "/service-order/:id?",
				name		: tl(Cookies.get("lang"),"ServiceOrder"),
				menu		: "SERVICEOCPT",
				icon 		: ServiceOrderIcon,
				component 	: ServiceOrderActivity,
				layout		: "/admin"
			},
			{
				path		: "/order-request/:id?",
				name		: tl(Cookies.get("lang"),"OrderRequest"),
				menu		: "ORDREQ",
				icon 		: OrderRequestIcon,
				component 	: OrderRequestActivity,
				layout		: "/admin"
			},
			{
				path		: "/rating-template/:id?",
				name		: tl(Cookies.get("lang"),"RatingTemplate"),
				menu		: "ORDREQ",
				icon 		: RatingTemplateIcon,
				component 	: RatingTemplateActivity,
				layout		: "/admin"
			},
		]
	}
]
export default Routes
