const View = (
  state = {
    //Example of a stack item:
    /*{
		name		: "Something",
		viewType	: "activity"/"modal",
		mode		: "read"/"edit"/"create",
		record		: {
			id		: 1,
			name	: "John Doe"
		},
		component	: import("./SomeForm")
		currentState : {}
	}*/
    activityStack: [],
    modalStack: [],
    notification: {
      isOpen: false,
      message: "",
      color: "",
    },
    unsavedConfirm:{
      open:false
    }
  },
  action,
) => {
  switch (action.type) {
    case "PUSH":
      if (action.payload.viewType === "activity") {
        action.payload.index = state.activityStack.length;
        if (action.currentState === null) {
          state = {
            ...state,
            activityStack: [...state.activityStack, action.payload],
          };
        } else {
          let lastPayload = state.activityStack[state.activityStack.length - 1];
          let currentStack = state.activityStack.slice(
            0,
            action.payload.index - 1,
          );
          lastPayload.currentState = action.currentState;
          currentStack.push(lastPayload);
          currentStack.push(action.payload);
          state = {
            ...state,
            activityStack: currentStack,
          };
        }
      } else {
        action.payload.index = state.modalStack.length;
        state = {
          ...state,
          modalStack: [...state.modalStack, action.payload],
        };
      }
      break;
    case "BACK":
      if (action.payload.viewType === "activity") {
        if (action.payload.index + 1 !== state.activityStack.length) {
          state = {
            ...state,
            activityStack: state.activityStack.slice(
              0,
              action.payload.index + 1,
            ),
          };
        }
      } else {
        if (action.payload.index + 1 !== state.modalStack.length) {
          state = {
            ...state,
            modalStack: state.modalStack.slice(0, action.payload.index + 1),
          };
        }
      }
      break;
    case "BACK_FROM":
      if (action.payload.viewType === "activity") {
        if (action.payload.index !== state.activityStack.length) {
          state = {
            ...state,
            activityStack: state.activityStack.slice(0, action.payload.index),
          };
        }
      } else {
        if (action.payload.index !== state.modalStack.length) {
          state = {
            ...state,
            modalStack: state.modalStack.slice(0, action.payload.index),
          };
        }
      }
      break;
    case "POP":
      if (action.payload.viewType === "activity") {
        state = {
          ...state,
          activityStack: state.activityStack.slice(0, action.payload.index),
        };
      } else {
        state = {
          ...state,
          modalStack: state.modalStack.slice(0, action.payload.index),
        };
      }
      break;
    case "UPDATE":
      if (action.payload.viewType === "activity") {
        const updatedStack = state.activityStack.map((record, i) => {
          if (i === action.payload.index) {
            return { ...action.payload };
          }
          return record;
        });
        return { ...state, activityStack: [...updatedStack] };
      } else {
        const updatedStack = state.modalStack.map((record, i) => {
          if (i === action.payload.index) {
            return { ...action.payload };
          }
          return record;
        });
        return { ...state, modalStack: [...updatedStack] };
      }
    case "EMPTY":
      if (action.payload.viewType === "activity") {
        state = {
          ...state,
          activityStack: [],
        };
      } else {
        state = {
          ...state,
          modalStack: [],
        };
      }
      break;
    case "SET_NOTIFICATION":
      state = {
        ...state,
        notification: {
          message: action.payload.message,
          color: action.payload.color,
          isOpen: action.payload.isOpen,
        },
      };
      break;
    case "SET_FORM_EDITED":
      state = {
        ...state,
        activityStack:state.activityStack.map((activity, index)=>{
          if (index === state.activityStack.length - 1) {
            return {
              ...activity,
              isEdited:true
            }
          } else {
            return activity
          }
        })
      }
      break;
    case "SET_UNSAVED_CONFIRM":
      state = {
        ...state,
        unsavedConfirm:{
          open:true,
          handleYes:action.payload
        }
      }
      break
    case "SET_UNSAVED_CONFIRM_CLOSE":
      state = {
        ...state,
        unsavedConfirm:{
          open:false
        }
      }
      break
    default:break
  }
  return state;
};

export default View;
