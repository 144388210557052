import React, { Component } from "react";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import Tooltip from "@material-ui/core/Tooltip";
import API from "framework/utils/API";
import "assets/scss/animate.css";

import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import "moment/locale/id";
import SearchBar from "../inputs/SearchBar";
import Dialog from "@material-ui/core/Dialog";
import Date from "framework/inputs/Date";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "framework/components/Pagination";

import MiniBrowserIcon from "@material-ui/icons/ReceiptOutlined";
import LoadingIcon from "@material-ui/icons/Sync";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
  paneContainer: {
    position: "absolute",
    right: "0px",
    zIndex: "1000",
    "&.closed": {
      display: "none",
    },
  },
  paneContainerMobile: {
    position: "fixed",
    left: "0px",
    bottom: "0px",
    zIndex: "1000",
    width: "100%",
    transition: "0.3s ease-in-out",
    "&.closed": {
      opacity: "0",
      bottom: "-100%",
    },
  },
  callout: {
    display: "flex",
    justifyContent: "flex-end",
    width: "260px",
    padding: "7px",
    zIndex: "1001",
    marginLeft: "auto",
    position: "relative",
  },
  calloutNip: {
    width: "25px",
    height: "25px",
    transform: "rotate(45deg)",
    backgroundColor: "#fff",
    boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
  },
  pane: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "10px",
    marginTop: "-25px",
    boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
    width: "300px",
    height: "400px",
    zIndex: "1002",
    position: "relative",
    "&.mobile": {
      width: "100%",
      height: "80vh",
      boxShadow: "0px -10px 100px rgba(0,0,0,0.2)",
    },
  },
  paneContent: {
    width: "100%",
    height: "270px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&.mobile": {
      height: "calc(100% - 122px)",
    },
  },
  paneHeading: {
    width: "100%",
    margin: "10px 0px",
  },
  itemContainer: {
    width: "100%",
    height: "270px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "10px",
      marginRight: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "10px",
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#ccc",
      },
    },
    "&.mobile": {
      height: "calc(100% - 122px)",
    },
  },
  listItem: {
    width: "100%",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    cursor: "pointer",
    "&:last-of-type": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  listItemHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
  },
  listHeadingContainer: {
    flexGrow: "1",
    overlow: "hidden",
  },
  listCancelButton: {
    width: "20px",
    height: "20px",
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#aaa",
    "&:hover": {
      color: "#f44336",
    },
  },
  listItemTitle: {
    lineHeight: "1",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
  listSubtitleItem: {
    marginTop: "3px",
    fontSize: "0.7rem",
    color: "#aaa",
    lineHeight: "1",
    "& span": {
      color: "#888",
    },
  },
  fieldContainer: {
    display: "flex",
  },
  fieldName: {
    marginRight: "10px",
  },
  fieldValue: {
    fontWeight: "bold",
  },
  closeIcon: {
    width: "18px",
    height: "18px",
  },
  modal: {
    width: "500px",
    height: "700px",
    backgroundColor: "#fff",
    overflow: "hidden",
    "@media(max-width:560px)": {
      width: "100%",
      height: "100%",
    },
  },
  modalToolbar: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
    "&.mobile": {
      flexDirection: "column",
      height: "auto",
      alignItems: "flex-start",
    },
  },
  modalTitle: {
    fontSize: "1rem",
    height: "50px",
    fontWeight: "bold",
    padding: "0px 10px",
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
  },
  searchBar: {
    width: "150px",
    padding: "0px 10px",
    "&.mobile": {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
  },
  inputContainer: {
    width: "170px",
    "&.mobile": {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
  },
  contentContainer: {
    width: "100%",
    height: "calc(100vh - 200px)",
    overflowY: "scroll",
    "&.mobile": {
      height: "350px",
      overflowY: "scroll",
    },
  },
  noContentContainer: {
    width: "100%",
    height: "465px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.mobile": {
      height: "350px",
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    boxShadow: "0px -2px 5px rgba(0,0,0,0.2)",
  },
};

class DraftBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paneOpen: false,
      modalOpen: false,
      data: [],
      filters: [],
      sorts: [],
      maxPage: 0,
      currentPage: 0,
      isLoading: false,
      isDraftLoading: false,
      isDone: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.customRef) {
      this.props.customRef(this);
    }
    if (this.mounted) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = () => {
    this.setState({ isLoading: true });
    let body = {
      filters: this.state.filters,
      sorts: [],
      limit: 10,
      offset: this.state.currentPage * 10,
    };
    // if (this.props.isOnline) {
    API("browse", this.props.model, body)
      .then((response) => {
        this.setState({
          data: [
            ...(response && response.data && response.data.records
              ? response.data.records
              : []),
          ],
          maxPage: response.data.maxPage,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
    // }
    // else {
    //     axios.post(this.props.offlineHost+"/api/offline/instant_sales/browse", {body},{
    //         headers:{Authorization:"Bearer "+Cookies.get("accessToken")}
    //     }).then(response=>{
    //         this.setState({
    //             data    : [...response.data.records],
    //             maxPage : response.data.maxPage
    //         })
    //     }).finally(()=>{
    //         this.setState({isLoading:false})
    //     })
    // }
  };

  togglePane = () => {
    if (!this.state.paneOpen) {
      document.addEventListener("click", this.handleClickOutSide, false);
    } else {
      document.removeEventListener("click", this.handleClickOutSide, false);
    }
    this.setState(
      {
        paneOpen: !this.state.paneOpen,
      },
      () => {
        if (this.state.paneOpen) {
          this.getData();
        }
      },
    );
  };

  handleClickOutSide = (e) => {
    if (!e.target.id.includes("react-select")) {
      if (this.node && !this.node.contains(e.target)) {
        this.togglePane();
      }
    }
  };

  formatTotal = (total) => {
    return total.toLocaleString("en-US", { minimumFractionDigits: 2 });
  };

  handleSearchChange = (value) => {
    let newFilters = [];
    if (value) {
      if (_.find(this.state.filters, ["field", "name"])) {
        newFilters = this.state.filters.map((obj) => {
          if (obj.field === "name") {
            return { ...obj, value };
          } else {
            return obj;
          }
        });
      } else {
        newFilters = [
          ...this.state.filters,
          {
            field: "name",
            operator: "like",
            value,
          },
        ];
      }
    } else {
      if (_.find(this.state.filters, ["field", "name"])) {
        newFilters = this.state.filters.filter((obj) => {
          if (obj.field !== "name") {
            return obj;
          }
        });
      }
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.getData();
      },
    );
  };

  handleClickRow = (i) => {
    if (this.props.onClickRow) {
      this.props.onClickRow(this.state.data[i]);
    }
    this.togglePane();
    if (this.state.modalOpen) {
      this.setState({
        modalOpen: false,
        paneOpen: false,
      });
    }
  };

  handleClickCancel = (event, id) => {
    event.stopPropagation();
    if (this.props.onClickCancel) {
      this.props.onClickCancel(id);
    }
  };

  handleDateChange = (field, value) => {
    let newFilters = [];
    if (value) {
      if (_.find(this.state.filters, ["field", "date"])) {
        newFilters = this.state.filters.map((obj) => {
          if (obj.field === "date" && obj.operator === ">=") {
            return { ...obj, value: moment(value).format("YYYY-MM-DD") };
          } else if (obj.field === "date" && obj.operator === "<") {
            return {
              ...obj,
              value: moment(value).add(1, "days").format("YYYY-MM-DD"),
            };
          } else {
            return obj;
          }
        });
      } else {
        newFilters = [
          ...this.state.filters,
          {
            field: "date",
            operator: ">=",
            value: moment(value).format("YYYY-MM-DD"),
          },
          {
            field: "date",
            operator: "<",
            value: moment(value).add("days", 1).format("YYYY-MM-DD"),
          },
        ];
      }
    } else {
      if (_.find(this.state.filters, ["field", "date"])) {
        newFilters = this.state.filters.filter((obj) => {
          if (obj.field !== "date") {
            return obj;
          }
        });
      }
    }
    this.setState(
      {
        filters: newFilters,
      },
      () => {
        this.getData();
      },
    );
  };

  handleSaveDraft = () => {
    if (this.props.renderMode === "formCreate") {
      this.setState({
        isDraftLoading: true,
      });
      // let recordToSave = {
      //   ...this.props.record,
      //   draftId: this.props.record.id,
      // };
      // API("custom_action", "/api/instant_sales/save_draft", recordToSave).then((response)=>{
      //     this.setState({
      //         isDone:true,
      //         isDraftLoading: false
      //     }, ()=>{
      //         if (this.props.onDraftSaved) {
      //             this.props.onDraftSaved()
      //         }
      //         setTimeout(()=>{
      //             this.setState({isDone:false})
      //         }, 1000)
      //     })
      // })
      console.log("save draft", this.props.record.lines);
      setTimeout(() => {
        this.setState(
          {
            isDone: true,
            isDraftLoading: false,
          },
          () => {
            if (
              this.props.onDraftSaved &&
              !this.props.record.id
              // && response.data.id
            ) {
              this.props.onDraftSaved();
            }
            setTimeout(() => {
              this.setState({ isDone: false });
            }, 1000);
          },
        );
      }, 3000);
    }
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.getData(),
    );
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      filters: [],
      currentPage: 0,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div ref={(node) => (this.node = node)} style={{ position: "relative" }}>
        {this.props.renderMode === "formCreate" && (
          <Tooltip title={this.props.title}>
            <Button
              justIcon
              size="sm"
              color="transparent"
              onClick={() => this.togglePane()}
            >
              {this.state.isDone ? (
                <CheckIcon className="animated zoomIn fast" />
              ) : this.state.isDraftLoading ? (
                <LoadingIcon className="animated rotateIn infinite" />
              ) : (
                <MiniBrowserIcon />
              )}
            </Button>
          </Tooltip>
        )}

        <div
          className={
            classes[
              this.props.width === "xs"
                ? "paneContainerMobile"
                : "paneContainer"
            ] + ` ${this.state.paneOpen ? "" : "closed"}`
          }
        >
          {this.props.width !== "xs" && (
            <div className={classes.callout}>
              <div className={classes.calloutNip}></div>
            </div>
          )}
          <div
            className={
              classes.pane + ` ${this.props.width === "xs" && "mobile"}`
            }
          >
            <div className={classes.paneHeading}>
              <b>
                {this.props.model
                  ? this.props.title
                    ? this.props.title
                    : tl(Cookies.get("lang"), this.props.model)
                  : "Browser"}
              </b>
              <SearchBar onChange={this.handleSearchChange} />
            </div>
            {this.state.isLoading && (
              <div
                className={
                  classes.paneContent +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                <div style={{ margin: "20px" }}>
                  <CircularProgress size={20} />
                </div>
                {tl("pleaseWait")}
              </div>
            )}
            {!this.state.isLoading && this.state.data.length === 0 && (
              <div
                className={
                  classes.paneContent +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                {tl("noDataFound")}
              </div>
            )}
            {!this.state.isLoading && this.state.data.length > 0 && (
              <div
                className={
                  classes.itemContainer +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                {this.state.data.map((row, key) => {
                  return (
                    <div
                      className={classes.listItem}
                      key={key}
                      onClick={() => this.handleClickRow(key)}
                    >
                      <div className={classes.listItemHeader}>
                        <div className={classes.listHeadingContainer}>
                          <div className={classes.listItemTitle}>
                            {row.customer ? row.customer.name : ""}
                          </div>
                          <div className={classes.listSubtitleItem}>
                            {row.name ? (
                              <span>{"No. " + row.name + " - "}</span>
                            ) : (
                              ""
                            )}
                            {moment(row.date)
                              .locale(Cookies.get("lang"))
                              .tz("Asia/Jakarta")
                              .fromNow()}
                          </div>
                        </div>
                        <div
                          className={classes.listCancelButton}
                          onClick={(e) => this.handleClickCancel(e, row.id)}
                        >
                          <CloseIcon className={classes.closeIcon} />
                        </div>
                      </div>
                      <div className={classes.fieldContainer}>
                        <div className={classes.fieldName}>
                          {tl(Cookies.get("lang"), "Total")}
                        </div>
                        <div className={classes.fieldValue}>
                          Rp.
                          {this.formatTotal(
                            row.totalAfterPaymentFee || row.total,
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <Button
              color="transparent"
              fullWidth
              style={{ marginLeft: "0" }}
              onClick={() => this.setState({ modalOpen: true })}
            >
              {tl("viewAll")}
            </Button>
          </div>
        </div>
        <Dialog open={this.state.modalOpen} onClose={this.handleModalClose}>
          <div className={classes.modal}>
            <div
              className={
                classes.modalToolbar +
                ` ${this.props.width === "xs" && "mobile"}`
              }
            >
              <div className={classes.modalTitle}>{this.props.title}</div>
              <div
                className={
                  classes.inputContainer +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                <Date
                  field="date"
                  value={
                    _.find(this.state.filters, ["field", "date"])
                      ? _.find(this.state.filters, ["field", "date"]).value
                      : null
                  }
                  disableLabel
                  renderMode="formCreate"
                  onChange={this.handleDateChange}
                />
              </div>
              <div
                className={
                  classes.searchBar +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                <SearchBar onChange={this.handleSearchChange} />
              </div>
            </div>
            {this.state.isLoading && (
              <div
                className={
                  classes.noContentContainer +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                <div style={{ marginRight: "20px" }}>
                  <CircularProgress size={20} />
                </div>
                {tl("pleaseWait")}
              </div>
            )}
            {!this.state.isLoading && this.state.data.length === 0 && (
              <div
                className={
                  classes.noContentContainer +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                {tl("noDataFound")}
              </div>
            )}
            {!this.state.isLoading && this.state.data.length > 0 && (
              <div
                className={
                  classes.contentContainer +
                  ` ${this.props.width === "xs" && "mobile"}`
                }
              >
                {this.state.data.map((row, key) => {
                  return (
                    <div
                      className={classes.listItem}
                      key={key}
                      onClick={() => this.handleClickRow(key)}
                    >
                      <div className={classes.listItemHeader}>
                        <div className={classes.listHeadingContainer}>
                          <div className={classes.listItemTitle}>
                            {row.customer ? row.customer.name : ""}
                          </div>
                          <div className={classes.listSubtitleItem}>
                            {row.name ? (
                              <span>{"No. " + row.name + " - "}</span>
                            ) : (
                              ""
                            )}
                            {moment(row.date)
                              .locale(Cookies.get("lang"))
                              .tz("Asia/Jakarta")
                              .fromNow()}
                          </div>
                        </div>
                        <div
                          className={classes.listCancelButton}
                          onClick={(e) => this.handleClickCancel(e, row.id)}
                        >
                          <CloseIcon className={classes.closeIcon} />
                        </div>
                      </div>

                      <div className={classes.fieldContainer}>
                        <div className={classes.fieldName}>
                          {tl(Cookies.get("lang"), "Total")}
                        </div>
                        <div className={classes.fieldValue}>
                          Rp.
                          {this.formatTotal(
                            row.totalAfterPaymentFee || row.total,
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Pagination
                maxPage={this.state.maxPage}
                currentPage={this.state.currentPage}
                onPageChange={this.handlePageChange}
              />
              <Button
                color="transparent"
                onClick={() => this.handleModalClose()}
              >
                {Cookies.get("lang") === "id" ? "Tutup" : "Close"}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(DraftBrowser));
