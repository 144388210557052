import React, { Component } from "react";
import { withStyles, ButtonBase } from "@material-ui/core";
import axios from "axios";
import io from "socket.io-client";
import moment from "moment-timezone";
import _ from "lodash";
import Avatar from "@material-ui/core/Avatar";
import CheckListIcon from "@material-ui/icons/DoneAll";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ReactChatView from "react-chatview";
import Cookies from "js-cookie";
import BackIcon from "@material-ui/icons/ArrowBack";
import { tl } from "framework/utils/Translator";
import styles from "../styles/ChatRoomStyle";
import ConfirmDialog from "./ConfirmDialog";
import PreviewImage from "./PreviewImage";
import InputMessage from "./InputMessage";
import Config from "../../company/Config";

const ENDPOINT = Config.host;
let socket;

class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      messages: [],
      userId: this.props.userId,
      roomId: "",
      chatRoomId: this.props.chatRoomId,
      chatRoomName: this.props.chatRoomName,
      chatRoomUserId: this.props.chatRoomUserId,
      offset: 0,
      whosTyping: "",
      errorDescription: false,
      open: false,
      currentDate: "",
      isSocketOn: false,
      files: null,
    };
    this.delayedCallback = _.debounce(this.applyChange, 1000);
    this.delayedInfiniteScroll = _.debounce(this.loadMore, 250);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      chatRoomId: nextProps.chatRoomId,
      chatRoomName: nextProps.chatRoomName,
      chatRoomUserId: nextProps.chatRoomUserId,
    });
    if (nextProps.chatRoomId !== this.state.chatRoomId) {
      this.getMessageOnRoom(nextProps.chatRoomId, this.state.chatRoomUserId, 0);
    }
  }

  initializeSocketIo() {
    const options = {
      transports: ["websocket"],
      reconnectionLimit: 1,
    };
    socket = io(ENDPOINT + "/chat", options);
    if (!this.state.isSocketOn) {
      socket.on("connect", () => {
        this.setState({ isSocketOn: true });
      });
    }
    socket.emit("authorization");
    socket.emit("request auth", {
      token: "97684eee-21b6-435f-8948-e8e2f2afaddc",
    });
    socket.emit("chat with", { roomName: this.state.chatRoomName });
  }

  onNewMessage() {
    socket.on("new message", (message) => {
      let newMessage = JSON.parse(message);
      this.setState({
        messages: [
          ...this.state.messages,
          {
            id: newMessage.message.id,
            text: newMessage.message.text,
            userId: newMessage.message.userId,
            image: newMessage.message.image,
            sent: newMessage.message.sent,
            received: newMessage.message.received,
            chatRoomId: newMessage.message.chatRoomId,
          },
        ],
      });

      socket.emit("received", {
        messageId: newMessage.message.id.toString(),
        userId: this.state.userId.toString(),
        roomId: this.state.chatRoomId.toString(),
        roomName: this.state.chatRoomName,
      });
    });
  }

  isTyping() {
    socket.on("is typing", (data) => {
      this.setState({
        whosTyping: data,
      });
    });
    socket.on("is not typing", () => {
      this.setState({
        whosTyping: "",
      });
    });
  }

  componentDidMount() {
    this.initializeSocketIo();
    this.onNewMessage();
    this.isTyping();
    this.getMessageOnRoom(
      this.state.chatRoomId,
      this.state.chatRoomUserId,
      this.state.offset,
    );
  }

  applyChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        socket.emit("not typing", {
          roomName: this.state.chatRoomName,
          from: "string",
        });
      },
    );
  };

  handleChange = (event) => {
    event.persist();
    this.delayedCallback(event);
    socket.emit("typing", {
      roomName: this.state.chatRoomName,
      username: Cookies.get("username"),
    });
  };

  getMessageOnRoom = (roomId, chatRoomUserId, offset) => {
    let bodyFormData = new FormData();
    bodyFormData.append("roomId", roomId);
    bodyFormData.append("offset", offset);
    bodyFormData.append("chatRoomUserId", chatRoomUserId);
    axios
      .post(ENDPOINT + "/chat/messages", bodyFormData)
      .then((res) => {
        this.setState({
          messages: res.data.messages,
          offset: this.state.offset + 20,
        });
      })
      .catch((err) => {
        console.error(err)
      });
  };

  sendMessage = () => {
    socket.on("authorized");
    let Data = {
      text: this.state.text,
      userId: this.state.userId,
      image: "",
      sent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      chatRoomId: this.state.chatRoomId,
      chatRoomName: this.state.chatRoomName,
    };
    if (this.state.text.length !== 0) {
      socket.emit("send message", JSON.stringify(Data));
      this.setState({ errorDescription: false });
      this.clearForm();
    } else {
      this.setState({ errorDescription: true });
    }
  };

  clearForm = () => {
    document.getElementById("myForm").reset();
    this.setState({
      text: "",
    });
  };

  handleSendMessage = () => {
    this.sendMessage();
  };

  handleImageFile = (file) => {
    this.setState({ files: file });
  };

  handleSendImage() {
    socket.on("authorized");
    let Data = {
      text: "",
      userId: this.state.userId,
      image: this.state.files,
      sent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      chatRoomId: this.chatRoomId,
      chatRoomName: this.state.chatRoomName,
    };
    socket.emit("send image", JSON.stringify(Data));
    this.closePreview();
  }

  handleBack = () => {
    this.props.handleBack();
  };

  timeFormat(date) {
    let time = moment.utc(date).format("HH:mm A");
    return time;
  }

  dateFormat(date) {
    let format = moment.utc(date).format("MMM DD, YYYY");
    return format;
  }

  loadMore = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("roomId", this.state.chatRoomId);
    bodyFormData.append("offset", this.state.offset);
    bodyFormData.append("chatRoomUserId", this.state.chatRoomUserId);
    axios
      .post(ENDPOINT + "/chat/messages", bodyFormData)
      .then((res) => {
        let count = res.data.messages.length;
        if (count > 0) {
          let data = _.union(this.state.messages, res.data.messages);
          this.setState({
            messages: data,
            offset: this.state.offset + 20,
          });
        }
      })
      .catch((err) => {
        console.error(err)
      });
  };

  onInfiniteLoad() {
    return new Promise((resolve) => {
      this.delayedInfiniteScroll();
      resolve();
    });
  }

  handleLeaveChat = () => {
    this.setState({ open: true });
  };

  handleYes() {
    socket.emit("end", { roomName: this.state.chatRoomName });
    this.setState({ open: false });
    this.props.leaveChat(this.state.chatRoomId, this.state.chatRoomName);
  }

  handleNo() {
    this.setState({ open: false });
  }

  currentDate(date) {
    if (date !== this.state.currentDate) {
      this.state.currentDate = date;
    }
  }

  closePreview() {
    this.setState({ files: null });
  }

  render() {
    const { classes } = this.props;
    let currentMessage = _.sortBy(this.state.messages, ["sent"]);
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className={classes.headerChat}>
          <div className={classes.identity}>
            {this.props.mobile ? (
              <ButtonBase onClick={this.handleBack}>
                <BackIcon style={{ color: "#3c4858", marginRight: "10px" }} />
              </ButtonBase>
            ) : (
              ""
            )}
            <Avatar>A</Avatar>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className={classes.chatName}>{this.state.chatRoomName}</p>
              {this.state.whosTyping && this.state.whosTyping !== "admin" ? (
                <label>{this.state.whosTyping + " "}sedang mengetik...</label>
              ) : (
                ""
              )}
            </div>
          </div>
          <ButtonBase
            onClick={this.handleLeaveChat}
            style={{ borderRadius: "50%" }}
          >
            <ExitIcon style={{ color: "#3c4858" }} />
          </ButtonBase>
        </div>
        {this.state.open ? (
          <ConfirmDialog
            title={tl(Cookies.get("lang"), "confirmation")}
            content={tl(Cookies.get("lang"), "leaveChat")}
            handleYes={this.handleYes.bind(this)}
            handleNo={this.handleNo.bind(this)}
          />
        ) : (
          ""
        )}
        <ReactChatView
          className={classes.containerChat}
          flipped={true}
          reversed={true}
          scrollLoadThreshold={0}
          onInfiniteLoad={this.onInfiniteLoad.bind(this)}
        >
          <div className={classes.chatSection}>
            {currentMessage.map((obj, key) => (
              <div key={key}>
                {this.state.currentDate !== this.dateFormat(obj.sent) ? (
                  <>
                    <p>{this.currentDate(this.dateFormat(obj.sent))}</p>
                    <p className={classes.hrText}>{this.state.currentDate}</p>
                  </>
                ) : (
                  ""
                )}
                <div
                  className={
                    classes.containerMessage +
                    ` ${this.state.userId === obj.userId ? "" : " opposite"}`
                  }
                >
                  <div
                    className={
                      classes.messageWrapper +
                      ` ${this.state.userId === obj.userId ? "" : " opposite"}`
                    }
                  >
                    <div
                      className={
                        classes.triangle +
                        ` ${
                          this.state.userId === obj.userId ? "" : " opposite"
                        }`
                      }
                    ></div>
                    <div
                      className={
                        classes.message +
                        ` ${
                          this.state.userId === obj.userId ? "" : " opposite"
                        }`
                      }
                    >
                      <p>{obj.text}</p>
                      <img
                        src={obj.image && obj.image.file}
                        style={{ maxWidth: "80%" }}
                      />
                      <span>
                        {this.timeFormat(obj.sent)}
                        {this.state.userId === obj.userId && obj.received ? (
                          <CheckListIcon
                            style={{ fontSize: 14, marginLeft: 5 }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ReactChatView>
        {this.state.files !== null ? (
          <PreviewImage
            sendImage={this.handleSendImage.bind(this)}
            closePreview={this.closePreview.bind(this)}
            files={this.state.files}
            classes={classes}
          />
        ) : (
          ""
        )}
        {this.state.files === null ? (
          <InputMessage
            handleChange={this.handleChange}
            handleImageFile={this.handleImageFile}
            handleSendMessage={this.handleSendMessage}
            classes={classes}
            errorDescription={this.state.errorDescription}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ChatRoom);
