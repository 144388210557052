import {tl} from "framework/utils/Translator"

//activities
import AchievementActivity from 'app/modules/RealEstate/activities/AchievementActivity'
import DeveloperActivity from 'app/modules/RealEstate/activities/DeveloperActivity'
import OfficeActivity from 'app/modules/RealEstate/activities/OfficeActivity'
import RealEstatePartnerActivity from 'app/modules/RealEstate/activities/RealEstatePartnerActivity'
import RealEstateProjectActivity from 'app/modules/RealEstate/activities/RealEstateProjectActivity'
import PropertyActivity from 'app/modules/RealEstate/activities/PropertyActivity'
import AgentActivity from 'app/modules/RealEstate/activities/AgentActivity'
import PropertyFeatureActivity from 'app/modules/RealEstate/activities/PropertyFeatureActivity'
import PropertyCollectionActivity from 'app/modules/RealEstate/activities/PropertyCollectionActivity'
import PropertyCollectionCategoryActivity from 'app/modules/RealEstate/activities/PropertyCollectionCategoryActivity'
import TopListingActivity from 'app/modules/RealEstate/activities/TopListingActivity'
import TopAgentActivity from 'app/modules/RealEstate/activities/TopAgentActivity'
import ReferralActivity from 'app/modules/RealEstate/activities/Referral'

//icons
import HouseIcon from "@material-ui/icons/Home"
import PropertyIcon from "@material-ui/icons/HomeOutlined"
import PropertyCollectionIcon from "@material-ui/icons/Home"
import PartnerIcon from "@material-ui/icons/AccountBox"
import AgentIcon from "@material-ui/icons/AccountBoxOutlined"
import AchievementIcon from "@material-ui/icons/Stars"
import DeveloperIcon from "@material-ui/icons/Business"
import OfficeIcon from "@material-ui/icons/Work"
import ProjectIcon from "@material-ui/icons/LocationCity"
import TopIcon from "@material-ui/icons/TrendingUp"



const routes = [
    {
    	collapse	: true,
	    name		: tl("RealEstate"),
	    menu		: "REALESTATE",
	    icon 		: HouseIcon,
	    state		: "RealEstate",
	    views		:[
			{
				path		: "/realestate-agent/:id?",
				name		: tl("Agent"),
				menu		: "RE-AGNT",
				icon 		: AgentIcon,
				component 	: AgentActivity,
				layout		: "/admin"
			},
			{
				path		: "/realestate-property/:id?",
				name		: tl("Property"),
				menu		: "RE-PROP",
				// menu		: "SHOP",
				icon 		: PropertyIcon,
				component 	: PropertyActivity,
				layout		: "/admin"
			},
			{
				path		: "/realestate-property-feature/:id?",
				name		: tl("PropertyFeature"),
				menu		: "RE-PROPF",
				icon 		: PropertyIcon,
				component 	: PropertyFeatureActivity,
				layout		: "/admin"
			},
			{
				path		: "/realestate-collection-property/:id?",
				name		: tl("PropertyCollection"),
				menu		: "RE-PROPC",
				icon 		: PropertyCollectionIcon,
				component 	: PropertyCollectionActivity,
				layout		: "/admin"
			},
			{
				path		: "/realestate-collection-category/:id?",
				name		: tl("CollectionCategory"),
				menu		: "RE-PROPCC",
				icon 		: PropertyCollectionIcon,
				component 	: PropertyCollectionCategoryActivity,
				layout		: "/admin"
			},
            {
				path		: "/achievement/:id?",
				name		: tl("Achievement"),
				menu		: "RE-ACHV",
				icon 		: AchievementIcon,
				component 	: AchievementActivity,
				layout		: "/admin"
			},
            {
				path		: "/developer/:id?",
				name		: tl("Developer"),
				menu		: "RE-DEV",
				icon 		: DeveloperIcon,
				component 	: DeveloperActivity,
				layout		: "/admin"
			},
            {
				path		: "/office/:id?",
				name		: tl("Office"),
				menu		: "RE-OFC",
				icon 		: OfficeIcon,
				component 	: OfficeActivity,
				layout		: "/admin"
			},
            {
				path		: "/real-estate-partner/:id?",
				name		: tl("RealEstatePartner"),
				menu		: "RE-PRTN",
				icon 		: PartnerIcon,
				component 	: RealEstatePartnerActivity,
				layout		: "/admin"
			},
            {
				path		: "/real-estate-project/:id?",
				name		: tl("RealEstateProject"),
				menu		: "RE-PROJ",
				icon 		: ProjectIcon,
				component 	: RealEstateProjectActivity,
				layout		: "/admin"
			},
			{
				path		: "/top-listing/:id?",
				name		: tl("TopListing"),
				menu		: "RE-TLIST",
				icon 		: TopIcon,
				component 	: TopListingActivity,
				layout		: "/admin"
			},
			{
				path		: "/top-agent/:id?",
				name		: tl("TopAgent"),
				menu		: "RE-TAGNT",
				icon 		: TopIcon,
				component 	: TopAgentActivity,
				layout		: "/admin"
			},
			{
				path		: "/real-estate-referral/:id?",
				name		: tl("Referral"),
				menu		: "RE-REF",
				icon 		: TopIcon,
				component 	: ReferralActivity,
				layout		: "/admin"
			},
        ]
    }
]

export default routes